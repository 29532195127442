import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, MenuItem, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'
import React from 'react'
import UrlsConst from '../../config/config';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { ConstructionOutlined } from '@mui/icons-material';

const filtros = [
    { id: 1, label: 'CONCLUIDAS' },
    { id: 2, label: 'SIN CONCLUIR' },
    { id: 3, label: 'TODAS' }
];
export default function Asignaciones() {
    const [openConfirmacion, setConfirmacion] = React.useState(false);
    const [openDesconcluir, setDesconcluir] = React.useState(false);
    const [pruebasFiltro, setPruebasFiltro] = React.useState([]);
    const [filtroS, setFiltroS] = React.useState(3);
    const [alertGeneral, setAlertGeneral] = React.useState({
        show: false,
        severity: 'warning',
        message: '',
        vertical: "top",
        horizontal: "right"
    });
    const [search, setSearch] = React.useState({
        string_search: ''
    });
    const [searchCodigoBarra, setSearchCodigoBarra] = React.useState({
        searchCodBarra: ''
    });
    const [prueba, setPrueba] = React.useState({
        fk_ven_orden: 0,
        linea: 0,
        fk_rh_trabajador: 0,
        fecha_registro: '',
        hora_registro: '',
        fecha_conclusion: '',
        hora_conclusion: '',
        fecha_entrega: '',
        hora_entrega: '',
        fecha_programada: '',
        hora_programada: '',
        descripcion: '',
    });
    const [pruebas, setPruebas] = React.useState([{
        linea: 0,
        descripcion: '',
        fecha_registro: '',
        hora_registro: '',
        fecha_programada: '',
        hora_programada: '',
        fecha_conclusion: '',
        hora_conclusion: ''
    }]);
    React.useEffect(() => {
        getPruebas();
    }, []);
    const getPruebas = async () => {
        const usuario = JSON.parse(sessionStorage.getItem('usuario_log'));
        const getPruebas = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarPruebaTrabajador.php?id=` + usuario.fk_rh_trabajador, {
            method: 'POST'
        });
        const response = await getPruebas.json();
        setPruebas(response);
    }
    const getPruebasXCodBarra = async () => {
        // var codigo = searchCodigoBarra.searchCodBarra.trim();
        var codigo = searchCodigoBarra.searchCodBarra.replace(/\s+/g, '');
        const usuario = JSON.parse(sessionStorage.getItem('usuario_log'));
        const getPruebas = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarPruebaTrabajador.php?id=` + usuario.fk_rh_trabajador, {
            method: 'POST'
        });
        const response = await getPruebas.json();
        const buscado = response.filter((el) => el.fk_ven_orden.includes(codigo));
        setPruebas(buscado);
        if (buscado.length !== 1) {
            setAlertGeneral({ show: true, message: 'No se encontraron datos', severity: 'warning', vertical: "top", horizontal: "center" });
            setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning', vertical: "top", horizontal: "right" }) }, 3500);
        }
        if (buscado.length > 1) {
            setAlertGeneral({ show: false, message: '', severity: 'warning', vertical: "top", horizontal: "right" })
        }
    }

    const handleClose = () => {
        setConfirmacion(false);
        setDesconcluir(false);
    }
    const concluir = () => {
        setConfirmacion(true);
        // setPrueba(thisRow);
    }
    const desconcluir = () => {
        setDesconcluir(true);
        // setPrueba(thisRow);
    }
    const searchPruebas = async () => {
        if (search.string_search != "") {
            const usuario = JSON.parse(sessionStorage.getItem('usuario_log'));
            const getPruebas = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarPruebaTrabajador.php?id=` + usuario.fk_rh_trabajador, {
                method: 'POST'
            });
            const response = await getPruebas.json();
            const filtrar = search.string_search;
            const buscado = response.filter((prueba) => prueba.descripcion.includes(filtrar));
            setPruebas(buscado);
            if (buscado.length !== 1) {
                setAlertGeneral({ show: true, message: 'No se encontraron datos', severity: 'warning', vertical: "top", horizontal: "center" });
                setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning', vertical: "top", horizontal: "right" }) }, 3500);
            }
            if (buscado.length > 1) {
                setAlertGeneral({ show: false, message: '', severity: 'warning', vertical: "top", horizontal: "right" })
            }
        }
        setSearchCodigoBarra({ searchCodBarra: '' });
    }
    const cambiarBtn = (e) => {
        var input = document.getElementById('search').value;
        if (input != "" && e.which == 13) {
            document.getElementById('btnSearch').click();
        }
    }
    const handleEntrega = async () => {
        const fecha = new Date();
        const updateOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarPrueba.php`, {
            method: 'POST',
            body: JSON.stringify({
                fk_ven_orden: prueba.fk_ven_orden,
                linea: prueba.linea,
                fecha_registro: prueba.fecha_registro,
                hora_registro: prueba.hora_registro,
                fecha_entrega: prueba.fecha_entrega,
                hora_entrega: prueba.hora_entrega,
                fecha_conclusion: fecha.getFullYear() + '-' + fecha.getMonth() + '-' + fecha.getDate(),
                hora_conclusion: fecha.toLocaleTimeString(),
                fecha_programada: prueba.fecha_programada,
                hora_programada: prueba.hora_programada,
                descripcion: prueba.descripcion,
                fk_rh_trabajador: prueba.fk_rh_trabajador
            })
        });
        const response = await updateOrden.json();
        getPruebas();
        setConfirmacion(false);
    }
    const handleDesconcluir = async () => {
        const fecha = new Date();
        const updateOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarPrueba.php`, {
            method: 'POST',
            body: JSON.stringify({
                fk_ven_orden: prueba.fk_ven_orden,
                linea: prueba.linea,
                fecha_registro: prueba.fecha_registro,
                hora_registro: prueba.hora_registro,
                fecha_entrega: prueba.fecha_entrega,
                hora_entrega: prueba.hora_entrega,
                fecha_conclusion: '',
                hora_conclusion: '',
                fecha_programada: prueba.fecha_programada,
                hora_programada: prueba.hora_programada,
                descripcion: prueba.descripcion,
                fk_rh_trabajador: prueba.fk_rh_trabajador,
            })
        });
        const response = await updateOrden.json();
        getPruebas();
        setDesconcluir(false);
    }
    const handleFiltro = async (e, values) => {
        const usuario = JSON.parse(sessionStorage.getItem('usuario_log'));
        const getPruebas = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarPruebaTrabajador.php?id=` + usuario.fk_rh_trabajador, {
            method: 'POST'
        });
        const response = await getPruebas.json();
        setFiltroS(values.props.value);
        let respuesta = [];
        if (values.props.value === 1) {
            response.forEach((item) => { if (item.fecha_conclusion != null) { respuesta.push(item) } });
            setPruebas(respuesta);
            limpiarbuscadores();
        }
        if (values.props.value === 2) {
            response.forEach((item) => { if (item.fecha_conclusion === null) { respuesta.push(item) } });
            setPruebas(respuesta);
            limpiarbuscadores();
        }
        if (values.props.value === 3) {
            setPruebas(response);
            limpiarbuscadores();
        }
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const limpiarbuscadores = () => {
        setSearch({ string_search: '' });
        setSearchCodigoBarra({ searchCodBarra: '' });
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //buscar por codigo de barra
    const cambiarBtnCodBarra = (e) => {
        var input = document.getElementById('searchCodBarra').value;
        if (input != "" && e.which == 13) {
            document.getElementById('btnSearchCodBarra').click();
        }
    }
    const searchPruebasXCodBarra = async () => {
        if (searchCodigoBarra.searchCodBarra === "") {
            searchPruebas();
        } else {
            getPruebasXCodBarra();
            setSearch({ string_search: '' });
        }
    }
    return (
        <>
            <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignContent: 'center' }}>
                <Snackbar open={alertGeneral.show} autoHideDuration={6000} onClose={() => setAlertGeneral({ show: false, message: "", severity: "warning", vertical: "top", horizontal: "right" })} anchorOrigin={{ vertical: alertGeneral.vertical, horizontal: alertGeneral.horizontal }}>
                    <Alert onClose={() => setAlertGeneral({ show: false, message: "", severity: "warning", vertical: "top", horizontal: "right" })} severity={alertGeneral.severity} sx={{ width: '100%' }}>
                        {alertGeneral.message}
                    </Alert>
                </Snackbar>
                <Grid item md={11.5} xs={11.5} style={{ justifyContent: 'center', marginTop: window.screen.width < 500 ? '40px' : '0' }}>
                    <Paper elevation={1} style={{ padding: '1.5em' }}>
                        <Grid container style={{ justifyContent: 'space-between', textAlign: 'center' }}>
                            <Typography variant='h5'>MIS TRABAJOS</Typography>
                        </Grid>
                        <Grid container style={{ justifyContent: 'space-between' }} spacing={2}>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    id="search"
                                    fullWidth
                                    margin='normal'
                                    variant='standard'
                                    label="BUSCAR"
                                    name="string_search"
                                    value={search.string_search}
                                    onChange={
                                        (e) => {
                                            if (e.target.value !== "") {
                                                const { name, value } = e.target;
                                                setSearch({ ...search, [name]: value.toUpperCase() });
                                            } else {
                                                setSearch({ ...search, string_search: '' });
                                                getPruebas();
                                            }
                                        }
                                    }
                                    onKeyUp={cambiarBtn}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={searchPruebas} id="btnSearch">
                                                    <SearchIcon color="warning" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    id="searchCodBarra"
                                    fullWidth
                                    margin='normal'
                                    variant='standard'
                                    label="BUSCAR POR CODIGO DE BARRA"
                                    name="searchCodBarra"
                                    value={searchCodigoBarra.searchCodBarra}
                                    onChange={
                                        (e) => {
                                            if (e.target.value !== "") {
                                                const { name, value } = e.target;
                                                setSearchCodigoBarra({ ...searchCodigoBarra, [name]: value.toUpperCase() });
                                            } else {
                                                setSearchCodigoBarra({ ...searchCodigoBarra, searchCodBarra: '' });
                                                getPruebas();
                                            }
                                        }
                                    }
                                    onKeyUp={cambiarBtnCodBarra}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={searchPruebasXCodBarra} id="btnSearchCodBarra">
                                                    <SearchIcon color="warning" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    style={{ textAlign: 'start' }}
                                    select
                                    margin='normal'
                                    fullWidth
                                    value={filtroS}
                                    label="FILTRO"
                                    variant="standard"
                                    onChange={handleFiltro}
                                >
                                    {filtros.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Paper>
                                    <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}  >
                                        <Table aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }}>N° ORDEN</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>SERVICIO</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>MATERIAL</TableCell>
                                                    <TableCell style={{ width: '40%', fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>LINEA</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>FECHA REGISTRO</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>FECHA PROGRAMADA</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>ESTADO</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {console.log({ pruebas })}
                                            <TableBody>
                                                {
                                                    (rowsPerPage > 0
                                                        ? pruebas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        : pruebas
                                                    ).map((prueba) => (
                                                        <TableRow
                                                            key={prueba.hora_registro}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {prueba.fk_ven_orden}
                                                            </TableCell>
                                                            <TableCell>{prueba.servicio}</TableCell>
                                                            <TableCell>{prueba.material}</TableCell>
                                                            <TableCell>{prueba.descripcion}</TableCell>
                                                            <TableCell>{prueba.linea}</TableCell>
                                                            <TableCell>{prueba.fecha_registro + " " + prueba.hora_registro}</TableCell>
                                                            <TableCell>{prueba.fecha_programada + " " + prueba.hora_programada}</TableCell>
                                                            <TableCell>
                                                                {
                                                                    prueba.fecha_conclusion != null ?
                                                                        <Button style={{ borderRadius: '20px', width: '120px' }} onClick={() => { desconcluir(); setPrueba(prueba) }} size="small" variant='contained' color="success">CONCLUIDO</Button>
                                                                        :
                                                                        <Button style={{ borderRadius: '20px', width: '120px' }} onClick={() => { concluir(); setPrueba(prueba) }} size="small" variant='contained' color='error'>SIN CONCLUIR</Button>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50]}
                                        component="div"
                                        labelRowsPerPage="Filas por columnas"
                                        count={pruebas.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={openConfirmacion} onClose={handleClose} maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant='h6'>Concluir Trabajo</Typography>
                    ¿Esta seguro de concluir el trabajo?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} style={{ background: '#2856ed', color: '#fff' }} onClick={handleEntrega}>Aceptar</Button>
                    <Button startIcon={<CloseIcon />} style={{ background: 'red', color: '#fff' }} onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDesconcluir} onClose={handleClose} maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant='h6'>Cancelar Trabajo Terminada</Typography>
                    ¿Esta seguro de cancelar el trabajo terminada?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} style={{ background: '#2856ed', color: '#fff' }} onClick={handleDesconcluir}>Aceptar</Button>
                    <Button startIcon={<CloseIcon />} style={{ background: 'red', color: '#fff' }} onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
