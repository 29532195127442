import React from "react";
import Navbar from './screen/plantilla/navbar';
import { useLocation } from 'react-router-dom';
import Login from './screen/login';
import './App.css';
import { Box } from "@mui/system";
import UrlsConst from "./config/config";
// routers
import { Route, Routes } from 'react-router-dom';
import Home from "./screen/Home";
import { ProtegerRuta } from "./components/ProtegerRuta";
import Trabajador from "./screen/sistema/trabajador";
import Parametro from "./screen/sistema/parametro";
import Usuario from "./screen/sistema/usuario";
import Perfil from "./screen/sistema/perfil";
import Historial from "./screen/ordenTrabajo/historial";
import OrdenTrabajo from "./screen/ordenTrabajo/ordenTrabajo";
// import OrdenDeTrabajo from './screen/ordenTrabajo/ordenDeTrabajo';
import OrdenServicio from "./screen/ordenTrabajo/ordenServicio";
import OrdenProfesional from "./screen/ordenTrabajo/ordenProfesional";
import TrabajosProfesional from "./screen/Trabajos/trabajosProfesional";
import TrabajoTrabajador from "./screen/Trabajos/trabajoTrabajador";
import Servicio from "./screen/mantenimiento/servicio";
import Cajas from "./screen/Caja/cajas";
import Error404 from "./screen/error404";
import Asignaciones from "./screen/Trabajos/asignaciones";
import Paciente from "./screen/mantenimiento/paciente";
import Profesional from "./screen/mantenimiento/profesional";
import ReportDeCaja from "./screen/reportes/reportDeCaja";
// import ReportPorProfesional from "./screen/reportes/reportPorProfesional";
import OrdenDeServicio from "./screen/reportes/reportPorProfesional";
import RepOrdenDeServicio from "./screen/reportes/repOrdenDeServicio";
import DemoProgresBar from "./screen/reportes/DemoProgresBar";
import PorTrabajador from "./screen/reportes/trabajos/porTrabajador";
import RepTrabajo from "./screen/reportes/repTrabajo";
import NewNavbar from "./screen/plantilla/newNavbar";
import NewOdontograma from "./components/newodontograma";
import Cajachica from "./screen/Caja/cajachica";
import RegistroGastos from "./screen/Caja/registroGastos";
import RutasPermitidas from "./components/operaciones/RutasPermitidas";
// import ValidarRoute from "./routes/validarRoute";

export default function App() {
  let sesion = JSON.parse(sessionStorage.getItem('usuario_log'));

  const [datosPerfilDetalle, setDatosPerfilDetalle] = React.useState([]);

  const [logeado, setLogeado] = React.useState(sesion);
  const cerrarSesion = () => {
    sessionStorage.removeItem('usuario_log');
    setLogeado(false);
  };
  const ValidacionLogueado = (sesion) => {
    setLogeado(sesion);
  }
  // console.log(sesion.perfil);

  React.useEffect(() => {
    if (sesion) {
      const sesionPerfil = sesion.perfil;
      const cargarPerfilDB = async () => {
        const perfilMenusFetch = await fetch(`${UrlsConst.URL_API}/sistema/perfilDetalle/listarMenuPerfil.php?codigo=${sesionPerfil}`);
        const perfilJSON = await perfilMenusFetch.json();
        setDatosPerfilDetalle(perfilJSON);
      }
      cargarPerfilDB();
    }
  }, []);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const handleCloseNavbar = () => {
    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);

  }
  let menusArray = [];
  datosPerfilDetalle.map((m) => {
    menusArray.push(m.href);
  })
  const user = {
    permiso: menusArray
  };
  let location = useLocation();
  // console.log(datosPerfilDetalle);
  
  return (
    <>
      {
        logeado ?
          <Box sx={{ display: 'flex' }} >
            {location.pathname !== "/"
              && <NewNavbar perfilDetalle={datosPerfilDetalle} parentLogOut={cerrarSesion} />}
            <Box component="main" sx={{ flexGrow: 1 }} onClick={handleCloseNavbar}  >
              {/* <Outlet /> */}
              <RutasPermitidas userName={sesion.perfil} cerrar={cerrarSesion} />
            </Box>
          </Box>
          :
          <Login parentCallBack={ValidacionLogueado} datosPerfilDetalle={setDatosPerfilDetalle} />
      }
    </>
  );
}