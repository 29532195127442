import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { Button } from '@mui/material';
import excel from '../../../img/excel.png'


export default function ExportExcel(props) {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const [pagosCaja, setPagosCaja] = React.useState([]);
    const exportToCSV = () => {
        const csvData = props.data;
        const caja = [props.caja.fecha_apertura];
        let pagosC = [].concat(pagosCaja);
        csvData.forEach((pagos) => {
            pagosC.push({
                'N°': pagos.id,
                'PROFESIONAL': pagos.profesional,
                'ORDEN': pagos.descOrden,
                'SERVICIO': pagos.servicio,
                'PRESUPUESTO': 'S/' + pagos.presupuesto,
                'IGV': "S/ 0.00",
                'PAGADO': 'S/' + pagos.monto,
                'SALDO': 'S/' + (pagos.presupuesto - pagos.monto),
                'REFERENCIA': pagos.referencia,
                'ESTADO': pagos.estado_pago === "G" ? "PAGADO" : pagos.estado_pago === "A" ? 'A CUENTA' : '',
                'TIPO DE PAGO': pagos.tipo === "E" ? 'EFECTIVO' : pagos.tipo === "D" ? 'DEPOSITO' : pagos.tipo === "T" ? 'TARJETA' : pagos.tipo === 'Y' ? 'YAPE' : '',
            });
        });
        pagosC.push({
            'N°': "",
            'PROFESIONAL': "TOTAL",
            'ORDEN': "",
            'SERVICIO': "",
            'PRESUPUESTO': 'S/' + csvData.reduce((total, item) => parseFloat(total) + parseFloat(item.presupuesto), 0).toFixed(2),
            'IGV': "0.00",
            'PAGADO': "S/" + csvData.reduce((total, item) => parseFloat(total) + parseFloat(item.monto), 0).toFixed(2),
            'SALDO': "S/" + csvData.reduce((total, item) => parseFloat(total) + parseFloat(item.presupuesto) - parseFloat(item.monto), 0).toFixed(2),
            'REFERENCIA': "",
            'ESTADO': "",
            'TIPO DE PAGO': "",
        });
        setPagosCaja(pagosC);
        const ws = XLSX.utils.json_to_sheet(pagosC, { skipHeader: false });
        var wscols = [
            { wch: 5, },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 15 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
        ];

        ws['!cols'] = wscols;
        const wb = { Sheets: { caja: ws }, SheetNames: ['caja'] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'RPTE-RESUMEN-CAJA' + fileExtension);
    };
    return (
        <Button startIcon={<img style={{ width: '20px', height: '20px' }} src={excel} />} variant='contained' color="success" onClick={exportToCSV}>EXCEL</Button>
    )
}
