import * as React from 'react';
import { TextField, Switch, FormControlLabel, Alert, Collapse, IconButton, Grid, Typography, Checkbox, FormGroup, ListItemButton, ListItemIcon, ListItemText, List } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import UrlsConst from '../../../config/config';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Container } from '@mui/system';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export default function DialogPerfiles(props) {
    // constantes
    const [alerta, setAlerta] = React.useState(false);
    const [alertaCodigo, setAlertaCodigo] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [menuArray, setMenuArray] = React.useState([]);
    const [perfilDetalleMenu, setPerfilDetalleMenu] = React.useState({ fk_log_perfil: "", fk_log_menu: "" });
    const [perfilSubmit, setPerfilSubmit] = React.useState({
        codigo: '',
        nombre: '',
        descripcion: '',
        es_activo: 0
    });
    // handles
    const handleSwitch = (e, values) => {
        const { name } = e.target;
        if (e.target.checked !== false) {
            setPerfilSubmit({ ...perfilSubmit, [name]: 1 });
        } else {
            setPerfilSubmit({ ...perfilSubmit, [name]: 0 });
        }
    }
    const handleCodigo = (e) => {
        let valor = e.target.value;
        let valorF = valor.replace(/ /g, "");
        setPerfilSubmit({ ...perfilSubmit, codigo: valorF.toUpperCase() });
    }
    const handlePerfil = (e) => {
        const { name, value } = e.target;
        setPerfilSubmit({ ...perfilSubmit, [name]: value.toUpperCase() });
    }

    React.useEffect(() => {
        if (props.fntCodigoPErfil !== undefined) {
            recuperarPerfil();
            cargarMenus();
        } else {
            cargarMenus();
        }
    }, []);
    // funciones
    const recuperarPerfil = async () => {
        const perfilGet = await fetch(`${UrlsConst.URL_API}/sistema/perfil/recuperarPerfil.php?codigo=` + props.fntCodigoPErfil, {
            method: 'GET'
        });
        const response = await perfilGet.json();
        setPerfilSubmit(response);
    }
    const cargarMenus = async () => {
        const menuFetch = await fetch(`${UrlsConst.URL_API}/sistema/listarMenu.php`);
        const menuJSON = await menuFetch.json();

        if (props.fntCodigoPErfil) {
            const perfilMenusFetch = await fetch(`${UrlsConst.URL_API}/sistema/perfilDetalle/listarMenuPerfil.php?codigo=${props.fntCodigoPErfil}`);
            const perfilMenusFetchDB = await perfilMenusFetch.json();
            menuJSON.forEach((m, i) => {
                perfilMenusFetchDB.forEach((f) => {
                    if (f.codigo === m.codigo) {
                        menuJSON[i].checked = 1;
                    }
                })
            })
            setMenuArray(menuJSON);
        } else {
            setMenuArray(menuJSON);
        }
    }

    const fntSetPerfil = async () => {
        if (props.fntCodigoPErfil !== undefined) {
            // console.log("editar");
            if (perfilSubmit.codigo !== "" && perfilSubmit.nombre !== "" && perfilSubmit.descripcion !== "") {
                setLoading(true);
                const savePerfil = await fetch(`${UrlsConst.URL_API}/sistema/perfil/guardarPerfil.php`, {
                    method: 'POST',
                    body: JSON.stringify(perfilSubmit)
                });
                if (perfilDetalleMenu.fk_log_perfil !== "") {
                    if (props.fntCodigoPErfil) {
                        const deleteDetalle = await fetch(`${UrlsConst.URL_API}/sistema/perfilDetalle/eliminarTodoPerfil.php?codigo=${props.fntCodigoPErfil}`);
                        perfilDetalleMenu.forEach(async (menuPerfil) => {
                            const savePerfilMenu = await fetch(`${UrlsConst.URL_API}/sistema/perfilDetalle/guardarPerfilDetalle.php`, {
                                method: "POST",
                                body: JSON.stringify({
                                    fk_log_perfil: menuPerfil.fk_log_perfil,
                                    fk_log_menu: menuPerfil.fk_log_menu,
                                }),
                            });
                        });
                    } else {
                        perfilDetalleMenu.forEach(async (menuPerfil) => {
                            const savePerfilMenu = await fetch(`${UrlsConst.URL_API}/sistema/perfilDetalle/guardarPerfilDetalle.php`, {
                                method: "POST",
                                body: JSON.stringify({
                                    fk_log_perfil: menuPerfil.fk_log_perfil,
                                    fk_log_menu: menuPerfil.fk_log_menu,
                                }),
                            });
                        });
                    }
                }
                if (savePerfil.ok === true) { props.alertDialog("Datos Actualizados correctamente", "success"); }
                props.fntPerfiles();
                props.fntClose();
            } else { setAlerta(true); setTimeout(() => { setAlerta(false) }, 2500); }
        } else {
            // console.log("nuevo");
            if (perfilSubmit.codigo !== "" && perfilSubmit.nombre !== "" && perfilSubmit.descripcion !== "" && perfilDetalleMenu.fk_log_menu !== "") {
                setLoading(true);
                const savePerfil = await fetch(`${UrlsConst.URL_API}/sistema/perfil/guardarPerfil.php`, {
                    method: 'POST',
                    body: JSON.stringify(perfilSubmit)
                });
                if (perfilDetalleMenu.fk_log_perfil !== "") {
                    if (props.fntCodigoPErfil) {
                        const deleteDetalle = await fetch(`${UrlsConst.URL_API}/sistema/perfilDetalle/eliminarTodoPerfil.php?codigo=${props.fntCodigoPErfil}`);
                        perfilDetalleMenu.forEach(async (menuPerfil) => {
                            const savePerfilMenu = await fetch(`${UrlsConst.URL_API}/sistema/perfilDetalle/guardarPerfilDetalle.php`, {
                                method: "POST",
                                body: JSON.stringify({
                                    fk_log_perfil: menuPerfil.fk_log_perfil,
                                    fk_log_menu: menuPerfil.fk_log_menu,
                                }),
                            });
                        });
                    } else {
                        perfilDetalleMenu.forEach(async (menuPerfil) => {
                            const savePerfilMenu = await fetch(`${UrlsConst.URL_API}/sistema/perfilDetalle/guardarPerfilDetalle.php`, {
                                method: "POST",
                                body: JSON.stringify({
                                    fk_log_perfil: menuPerfil.fk_log_perfil,
                                    fk_log_menu: menuPerfil.fk_log_menu,
                                }),
                            });
                        });
                    }
                }
                if (savePerfil.ok === true) { props.alertDialog("Datos Actualizados correctamente", "success"); }
                props.fntPerfiles();
                props.fntClose();
            } else { setAlerta(true); setTimeout(() => { setAlerta(false) }, 2500); }
        }
    }
    const handleChangeChecked = (e, menu) => {
        if (perfilSubmit.codigo !== "") {
            const tempArray = menuArray.slice();
            var indice = -1;
            var indicePadre = -1;
            tempArray.forEach((element, i) => {
                if (element.codigo === menu.fk_log_menu_padre) {
                    indicePadre = i;
                }
                if (element.codigo === menu.codigo) {
                    indice = i;
                }
            });
            tempArray[indice].checked = e.target.checked ? 1 : 0;
            if (indicePadre >= 0) {
                tempArray[indicePadre].checked = tempArray.filter(h => h.fk_log_menu_padre === tempArray[indicePadre].codigo && h.checked === 1).length > 0 ? 1 : 0;
            } else {
                tempArray.forEach(hijo => {
                    if (hijo.fk_log_menu_padre === menu.codigo) {
                        hijo.checked = e.target.checked ? 1 : 0;
                    }
                })
            }
            setMenuArray(tempArray);
            let menuGuardarArray = [];
            menuArray.forEach(menuG => {
                if (menuG.checked !== 0) {
                    var codigoMenu = menuG.codigo;
                    var menuGuardarArrayElement = {
                        fk_log_perfil: props.fntCodigoPErfil ? props.fntCodigoPErfil : perfilSubmit.codigo,
                        fk_log_menu: codigoMenu,
                    }
                    menuGuardarArray.push(menuGuardarArrayElement);
                };
            });
            setPerfilDetalleMenu(menuGuardarArray);
        } else {
            setAlertaCodigo(true);
            setTimeout(() => { setAlertaCodigo(false) }, 2500);
        }
    }

    const [openCollCaj, setOpenCollCaj] = React.useState();
    const [openCollMan, setOpenCollMan] = React.useState();
    const [openCollOrd, setOpenCollOrd] = React.useState();
    const [openCollPru, setOpenCollPru] = React.useState();
    const [openCollSys, setOpenCollSys] = React.useState();
    const [openCollRep, setOpenCollRep] = React.useState();

    const funCollCheckP = (dato) => {
        if (dato === "CAJ") { setOpenCollCaj(!openCollCaj); }
        if (dato === "MAN") { setOpenCollMan(!openCollMan); }
        if (dato === "ORD") { setOpenCollOrd(!openCollOrd); }
        if (dato === "PRU") { setOpenCollPru(!openCollPru); }
        if (dato === "SYS") { setOpenCollSys(!openCollSys); }
        if (dato === "REP") { setOpenCollRep(!openCollRep); }
    }
    return (
        <>
            <Collapse in={alerta}>
                <Alert severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlerta(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    ¡Error!. Los campos no pueden ir vacios
                </Alert>
            </Collapse>
            <Collapse in={alertaCodigo}>
                <Alert severity="warning"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertaCodigo(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    Codigo Obligatorio
                </Alert>
            </Collapse>
            <Grid container spacing={3} sx={{ paddingBottom: "0.5em" }}>
                <Grid item md={6} sx={{ borderRight: " solid 1px #000", padding: "1em" }} >
                    <Grid container>
                        <Grid item md={8}>
                            <TextField
                                disabled={props.fntCodigoPErfil === undefined ? false : true}
                                fullWidth
                                margin="normal"
                                label="CODIGO"
                                name="codigo"
                                variant='standard'
                                inputProps={{ maxLength: '20' }}
                                onChange={handleCodigo}
                                value={perfilSubmit ? perfilSubmit.codigo : ''}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <FormControlLabel
                                sx={{ paddingTop: "1.6em" }}
                                label="ESTADO" labelPlacement="start"
                                control={
                                    <Switch color="success"
                                        name='es_activo'
                                        checked={perfilSubmit.es_activo === 1}
                                        onChange={handleSwitch}
                                    />
                                }
                            />
                        </Grid>
                        <TextField
                            fullWidth
                            label="NOMBRE"
                            margin="normal"
                            name="nombre"
                            variant='standard'
                            inputProps={{ maxLength: '50' }}
                            onChange={handlePerfil}
                            value={perfilSubmit ? perfilSubmit.nombre : ''}

                        />
                        <TextField
                            fullWidth
                            label="DESCRIPCION"
                            margin="normal"
                            multiline
                            rows={4}
                            name="descripcion"
                            onChange={handlePerfil}
                            inputProps={{ maxLength: '250' }}
                            value={perfilSubmit ? perfilSubmit.descripcion : ''}
                        />


                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: "start", padding: "0.5em 2em" }}>
                            <Grid>
                                <Typography
                                    variant="h5"
                                    gutterBottom
                                    align="center"
                                    color="text.secondary"
                                    sx={{ fontSize: 16 }}
                                >
                                    Menu
                                </Typography>
                            </Grid>
                            <Container>
                                <Grid container >
                                    {menuArray.filter(m => m.fk_log_menu_padre === null).map((padre) => (
                                        <Grid item xs={12} key={padre.codigo + "gr"}>
                                            <ListItemButton onClick={() => funCollCheckP(padre.codigo)}>
                                                {/* {openCollMan ? <ExpandLess /> : <ExpandMore />} */}
                                                {padre.codigo === "CAJ" && openCollCaj ||
                                                    padre.codigo === "MAN" && openCollMan ||
                                                    padre.codigo === "ORD" && openCollOrd ||
                                                    padre.codigo === "PRU" && openCollPru ||
                                                    padre.codigo === "SYS" && openCollSys ||
                                                    padre.codigo === "REP" && openCollRep
                                                    ? <ExpandLess /> : <ExpandMore />}
                                                <FormControlLabel
                                                    key={padre.codigo + "fcp"} label={padre.nombre}
                                                    control={<Checkbox key={padre.codigo} checked={padre.checked === 1} onChange={(e) => handleChangeChecked(e, padre)} />}
                                                />
                                            </ListItemButton>
                                            <Grid container item xs={12}>
                                                {menuArray.filter(mh => mh.fk_log_menu_padre === padre.codigo).map((h) => (
                                                    <Collapse
                                                        in={padre.codigo === "CAJ" ? openCollCaj :
                                                            padre.codigo === "MAN" ? openCollMan :
                                                                padre.codigo === "ORD" ? openCollOrd :
                                                                    padre.codigo === "PRU" ? openCollPru :
                                                                        padre.codigo === "SYS" ? openCollSys :
                                                                            padre.codigo === "REP" ? openCollRep :
                                                                                openCollMan}
                                                        timeout="auto" unmountOnExit>
                                                        <Grid item xs={12} sx={{ display: "flex", direction: "column", ml: 8 }} key={h.codigo + "gr"}>
                                                            <FormControlLabel
                                                                key={h.codigo + "fch"} label={h.nombre}
                                                                control={<Checkbox key={h.codigo} checked={h.checked === 1} onChange={(e) => handleChangeChecked(e, h)} />}
                                                            />
                                                        </Grid>
                                                    </Collapse>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Container>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
            <LoadingButton
                onClick={fntSetPerfil}
                startIcon={<CheckIcon />}
                loading={loading}
                loadingPosition="start"
                variant="outlined"
                color="success"
            >
                {perfilSubmit.codigo !== '' ? 'ACTUALIZAR' : 'GUARDAR'}
            </LoadingButton>
        </>
    )
}