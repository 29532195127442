import React from "react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import RepOrdenDeServicio from "../../screen/reportes/repOrdenDeServicio";
import UrlsConst from "../../config/config";
import OrdenTrabajo from "../../screen/ordenTrabajo/ordenTrabajo";
import Home from "../../screen/Home";
import OrdenServicio from "../../screen/ordenTrabajo/ordenServicio";
import OrdenProfesional from "../../screen/ordenTrabajo/ordenProfesional";
import Historial from "../../screen/ordenTrabajo/historial";
import Asignaciones from "../../screen/Trabajos/asignaciones";
import TrabajoTrabajador from "../../screen/Trabajos/trabajoTrabajador";
import TrabajosProfesional from "../../screen/Trabajos/trabajosProfesional";
import Servicio from "../../screen/mantenimiento/servicio";
import Profesional from "../../screen/mantenimiento/profesional";
import Paciente from "../../screen/mantenimiento/paciente";
import Parametro from "../../screen/sistema/parametro";
import Trabajador from "../../screen/sistema/trabajador";
import Usuario from "../../screen/sistema/usuario";
import Perfil from "../../screen/sistema/perfil";
import Cajas from "../../screen/Caja/cajas";
import Cajachica from "../../screen/Caja/cajachica";
import RepTrabajo from "../../screen/reportes/repTrabajo";
import Error404 from "../../screen/error404";
import RegistroGastos from "../../screen/Caja/registroGastos";
import ReportDeCaja from "../../screen/reportes/reportDeCaja";

function RutasPermitidas(props) {
  const userName = props.userName;
  const [menuDetalle, setMenuDetalle] = React.useState([]);
  const componentList = (codigo) => {
    const routeArray = [
      { codigo: "ORD_NEW", componente: <OrdenTrabajo /> },
      { codigo: "ORD_ORD", componente: <OrdenServicio /> },
      { codigo: "ORD_PRO", componente: <OrdenProfesional /> },
      { codigo: "ORD_HIS", componente: <Historial /> },
      { codigo: "PRU_ORD", componente: <Asignaciones /> },
      { codigo: "PRU_ORD", componente: <Asignaciones /> },
      { codigo: "PRU_TRA", componente: <TrabajoTrabajador /> },
      { codigo: "PRU_PRO", componente: <TrabajosProfesional /> },
      { codigo: "MAN_SER", componente: <Servicio /> },
      { codigo: "MAN_PRO", componente: <Profesional /> },
      { codigo: "MAN_PAC", componente: <Paciente /> },
      { codigo: "SYS_PAR", componente: <Parametro /> },
      { codigo: "SYS_TRA", componente: <Trabajador /> },
      { codigo: "SYS_USU", componente: <Usuario /> },
      { codigo: "SYS_PER", componente: <Perfil /> },
      { codigo: "CAJ_COB", componente: <Cajas /> },
      { codigo: "CAJ_CHIC", componente: <Cajachica /> },
      { codigo: "CAJ_GAS", componente: <RegistroGastos /> },
      { codigo: "REP_ORD", componente: <RepOrdenDeServicio /> },
      { codigo: "REP_TRAB", componente: <RepTrabajo /> },
      { codigo: "REP_CAJA", componente: <ReportDeCaja /> },
    ];
    const filteredComponent = routeArray.filter(
      (route) => route.codigo === codigo
    );
    if (filteredComponent.length > 0) {
      return filteredComponent[0].componente;
    } else {
      return <Error404 />;
    }
  };
  useEffect(() => {
    const cargarPerfilDB = async () => {
      const perfilMenusFetch = await fetch(
        `${UrlsConst.URL_API}/sistema/perfilDetalle/listarMenuPerfil.php?codigo=${userName}`
      );
      const perfilJSON = await perfilMenusFetch.json();
      setMenuDetalle(perfilJSON);
    };
    cargarPerfilDB();
  }, []);

  const cerrarSesion = () => {
    props.cerrar();
  };

  // console.log(props.userName);
  // console.log(menuDetalle);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Home perfilDetalle={menuDetalle} parentLogOut={cerrarSesion} />
        }
      />
      {menuDetalle.map((menu) => (
        <Route
          key={menu.codigo}
          path={menu.href}
          element={componentList(menu.codigo)}
        />
      ))}
      <Route path="rordenserv" exact element={<RepOrdenDeServicio />} />
    </Routes>
  );
}
export default RutasPermitidas;
