import React from 'react';
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
// import Logo from '../../../img/logoReporte.png';
import Logo from '../../../img/logo-guzman.png';

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: 'Oswald'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 10,
        marginHorizontal: 50,
        width: '45px',
        height: '45px'
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 40,
        textAlign: 'right',
        color: 'grey',
    },
});

export default function PdfProfesional(props) {
    const estilosTablaPdf = { padding: "4px 0", bgHeader: "#f57c00" }
    const reportCaja = props.reportCaja;
    const datosEmpresa = props.datosEmpresa;
    const profesionalesS = props.profesionalesS;
    const profesionales = props.profesionales;
    return (
        <Document>
            <Page style={styles.body} orientation="landscape">
                <View style={{ display: 'fixed', flexDirection: 'row' }} fixed>
                    <View style={{ display: 1 }}>
                        <Image
                            style={{ width: '55px', height: '50px' }}
                            src={Logo}
                        />
                    </View>
                    <View style={{ display: 2, marginHorizontal: '15px', width: '45%' }} >
                        <Text style={{ fontSize: '20px' }}>{datosEmpresa.razon_social}</Text>
                        <Text style={{ fontSize: '12px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                        <Text style={{ fontSize: '12px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text>
                    </View>
                    <View style={{ display: 3 }}>
                        <Text style={{ fontSize: '20px' }}>REPORTE COBRANZAS</Text>
                    </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }} fixedx>
                    <View style={{ width: '20%', backgroundColor: estilosTablaPdf.bgHeader, color: '#fff', padding: '6px', textAlign: 'center', justifyContent: 'center' }}>
                        <View style={{ display: 1, width: '60%', textAlign: 'center' }}>
                            <Text style={{ fontSize: '8px' }}>TIPO DE PAGOS</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', width: '80%', padding: '6px', border: '1px solid #000' }}>
                        <View style={{ display: 2, width: '25%', textAlign: 'center' }}>
                            <Text style={{ fontSize: '8px' }}>D = DEPOSITO</Text>
                        </View>
                        <View style={{ display: 3, width: '25%', textAlign: 'center' }}>
                            <Text style={{ fontSize: '8px' }}>T = TARJETA</Text>
                        </View>
                        <View style={{ display: 4, width: '25%', textAlign: 'center' }}>
                            <Text style={{ fontSize: '8px' }}>E = EFECTIVO</Text>
                        </View>
                        <View style={{ display: 5, width: '25%', textAlign: 'center' }}>
                            <Text style={{ fontSize: '8px' }}>Y = YAPE</Text>
                        </View>
                        <br />
                    </View>
                </View>
                {
                    profesionalesS.map(prof => (
                        profesionales.filter(p => prof === p.id).map((mp, index) => (
                            <View key={index}>
                                <View style={{ width: '60%', marginTop: '10px', border: '1px solid #000' }}>
                                    <View style={{ backgroundColor: estilosTablaPdf.bgHeader, padding: '4px' }}>
                                        <Text style={{ fontSize: '9px', marginHorizontal: '6px', color: '#fff' }}>PROFESIONAL</Text>
                                    </View>
                                    <View style={{ display: 'flex', marginTop: '5px', flexDirection: 'row', padding: '4px' }}>
                                        <View style={{ display: 1, marginHorizontal: '6px' }} >
                                            <Text style={{ fontSize: '9px' }}>nombre: {mp.apellidos_nombres}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{
                                    display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: estilosTablaPdf.bgHeader, padding: '2px',
                                    marginTop: '10px'
                                }}>
                                    <View style={{ display: 1, width: '80px', margin: '2px' }}>
                                        <Text style={{ fontSize: '8px' }}>N° PAGO</Text>
                                    </View>
                                    <View style={{ display: 2, width: '250px', margin: '2px' }}>
                                        <Text style={{ fontSize: '8px' }}>PROFESIONAL</Text>
                                    </View>
                                    <View style={{ display: 3, width: '100px', margin: '2px' }}>
                                        <Text style={{ fontSize: '8px' }}>FECHA</Text>
                                    </View>
                                    <View style={{ display: 4, width: '250px', margin: '2px' }}>
                                        <Text style={{ fontSize: '8px' }}>ORDEN</Text>
                                    </View>
                                    <View style={{ display: 5, width: '250px', margin: '2px' }}>
                                        <Text style={{ fontSize: '8px' }}>SERVICIO</Text>
                                    </View>
                                    <View style={{ display: 6, width: '50px', margin: '2px' }}>
                                        <Text style={{ fontSize: '8px' }}>TIPO</Text>
                                    </View>
                                    <View style={{ display: 7, width: '100px', margin: '2px' }}>
                                        <Text style={{ fontSize: '8px' }}>T. PAGADO</Text>
                                    </View>
                                    <View style={{ display: 8, width: '100px', margin: '2px' }}>
                                        <Text style={{ fontSize: '8px' }}>T. EMITIDO</Text>
                                    </View>
                                    <View style={{ display: 9, width: '250px', margin: '2px' }}>
                                        <Text style={{ fontSize: '8px' }}>REFERENCIA</Text>
                                    </View>
                                </View>
                                {
                                    reportCaja.filter(f => f.id_profesional === prof).map((pago) => (
                                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f8f9fa' }} key={pago.id_pago}>
                                            <View style={{ display: 1, width: '80px', margin: '2px' }}>
                                                <Text style={{ fontSize: '8px' }}>{pago.id_pago}</Text>
                                            </View>
                                            <View style={{ display: 2, width: '250px', margin: '2px' }}>
                                                <Text style={{ fontSize: '8px' }}>{pago.profesional}</Text>
                                            </View>
                                            <View style={{ display: 3, width: '100px', margin: '2px' }}>
                                                <Text style={{ fontSize: '8px', textAlign: 'center' }}>{pago.fecha_pago}</Text>
                                            </View>
                                            <View style={{ display: 4, width: '250px', margin: '2px' }}>
                                                <Text style={{ fontSize: '8px' }}>{pago.orden_descripcion}</Text>
                                            </View>
                                            <View style={{ display: 5, width: '250px', margin: '2px' }}>
                                                <Text style={{ fontSize: '8px' }}>{pago.servicio}</Text>
                                            </View>
                                            <View style={{ display: 6, width: '50px', margin: '2px' }}>
                                                <Text style={{ fontSize: '8px' }}>{pago.tipo}</Text>
                                            </View>
                                            <View style={{ display: 7, width: '100px', margin: '2px' }}>
                                                <Text style={{ fontSize: '8px' }}>s/. {pago.presupuesto}</Text>
                                            </View>
                                            <View style={{ display: 8, width: '100px', margin: '2px' }}>
                                                <Text style={{ fontSize: '8px' }}>s/. {pago.total_pagado}</Text>
                                            </View>
                                            <View style={{ display: 9, width: '250px', margin: '2px' }}>
                                                <Text style={{ fontSize: '8px' }}>{pago.referencia}</Text>
                                            </View>
                                            <br />
                                        </View>
                                    ))
                                }
                            </View>
                        ))
                    ))
                }
                <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
                <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `Pag ${pageNumber}`
                )} fixed />
            </Page>
        </Document >
    );
}   