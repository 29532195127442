import * as React from 'react';
import {
  Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Snackbar, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TablePagination, TableRow, TextField, Tooltip, Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import ServicioDialog from './dialogs/servicioDialog';
import UrlsConst from '../../config/config';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/system';
import MaterialServicio from './dialogs/materialServicio';
import MaterialDialog from './dialogs/materialDialog';
import { Search } from '@mui/icons-material';
import FilterMaterial from './dialogs/filterMaterial';
function Row(props) {
  const servicio = props.servicio;
  const [idServicio, setIdServicio] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [idMaterial, setIdMaterial] = React.useState(0);
  const [materialesServicio, setMaterialesServicio] = React.useState([]);
  const [dialogMaterial, setDialogMaterial] = React.useState(false);
  const [dialogDelete, setDialogDelete] = React.useState(false);
  const handleCollapse = () => {
    if (open !== false) {
      setOpen(false);
    } else {
      servicioMaterial(servicio.id)
      setOpen(true);
    }
  }
  const servicioMaterial = async (id) => {
    const getServioMateriales = await fetch(`${UrlsConst.URL_API}/ventas/servicio/listarServicioMaterial.php?id=` + id, {
      method: 'POST'
    });
    const response = await getServioMateriales.json();
    setMaterialesServicio(response);
  }
  const deleteMaterialServicio = async () => {
    const deleteMaterial = await fetch(`${UrlsConst.URL_API}/ventas/servicio/eliminarServicioMaterial.php?fk_ven_servicio=${idServicio}&fk_cmp_material=${idMaterial}`, {
      method: 'GET'
    });
    const response = await deleteMaterial.json();
    servicioMaterial(idServicio);
    setDialogDelete(false);
    props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'Material eliminado correctamente', open: true });
    setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
  }
  return (
    <React.Fragment>
      <TableRow key={servicio.id}>
        <TableCell component="th" scope="row">
          {servicio.id}
        </TableCell>
        <TableCell component="th" scope="row">
          {servicio.nombre}
        </TableCell>
        <TableCell>{servicio.descripcion}</TableCell>
        <TableCell>{servicio.observaciones}</TableCell>
        <TableCell>
          {
            servicio.multipieza !== 0 ?
              <div style={{ background: '#2f9e44', color: '#fff', textAlign: 'center', borderRadius: '20px' }}>SI</div> :
              <div style={{ background: '#ff7256', color: '#fff', textAlign: 'center', borderRadius: '20px' }}>NO</div>
          }
        </TableCell>
        <TableCell>
          <Tooltip title="AGREGAR MATERIAL">
            <IconButton aria-label="delete" size="small" onClick={() => { setDialogMaterial(true); setIdServicio(servicio.id); }}>
              <AddCircleOutlineIcon color='secundary' fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="EDITAR SERVICIO">
            <IconButton aria-label="delete" onClick={
              () => {
                props.openEdit(true);
                props.servicioId(servicio.id);
              }}
              size="small">
              <EditIcon color='primary' fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="ELIMINAR SERVICIO">
            <IconButton aria-label="delete" size="small" onClick={
              () => {
                props.openDelete(true);
                props.servicioId(servicio.id);
              }}>
              <DeleteIcon color='error' fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="VER MATERIALES">
            <IconButton aria-label="delete" size="small" onClick={handleCollapse}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: '#f1f3f5' }} colSpan={20}>
          <Collapse in={open} timeout="auto" style={{ justifyContent: 'center' }}>
            <Box sx={{ margin: 1 }}>
              <Typography style={{ fontSize: '15px', fontWeight: 'bold' }} component="div">
                MATERIALES DE {servicio.nombre}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>N° MATERIAL</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>NOMBRE DE MATERIAL</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>COSTO</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>PRECIO</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materialesServicio.map((material) => (
                    <TableRow key={material.fk_cmp_material}>
                      <TableCell component="th" scope="row">
                        {material.fk_cmp_material}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {material.nombre}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {material.descripcion}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {material.costo}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {material.precio}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title="ELIMINAR MATERIAL">
                          <IconButton aria-label="delete" size="small" onClick={() => {
                            setIdMaterial(material.fk_cmp_material);
                            setIdServicio(servicio.id);
                            setDialogDelete(true);
                          }}>
                            <DeleteIcon color="error" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog open={dialogMaterial} onClose={() => setDialogMaterial(false)} fullWidth={true} maxWidth="xs">
        <DialogTitle>AGREGAR MATERIAL</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <MaterialServicio servicioMaterialId={idServicio} fntServicioMaterial={servicioMaterial} handleClose={setDialogMaterial} alertGeneral={props.alertGeneral} setAlertGeneral={props.setAlertGeneral} />
          <Button style={{ marginLeft: '6px' }} startIcon={<CloseIcon />} variant="outlined" color="error" onClick={() => setDialogMaterial(false)}>CANCELAR</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={dialogDelete} onClose={() => setDialogDelete(false)}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <div>
            <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
          </div>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant="h5">Eliminar Material</Typography>
          ¿Esta seguro de eliminar el material?
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button startIcon={<CheckIcon />} variant="contained" color="error" onClick={deleteMaterialServicio}>Aceptar</Button>
          <Button startIcon={<CloseIcon />} variant="contained" color="primary" onClick={() => setDialogDelete(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment >
  );
}
export default function Servicio() {
  // alert
  const [typeAlert, setTypeAlert] = React.useState('success');
  const [textAlert, setTextAlert] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [idServicio, setServicioId] = React.useState(0);
  const [materiales, setMateriales] = React.useState([]);
  const [idMaterial, setIdMaterial] = React.useState(0);
  const [deleteMaterial, setDeleteMaterial] = React.useState(false);
  const [dialogMantenimiento, setDialogMantenimiento] = React.useState(false);
  const [dialogMaterialEdit, setDialogMaterialEdit] = React.useState(false);
  const [alertGeneral, setAlertGeneral] = React.useState({
    open: false,
    type: 'success',
    text: ''
  });
  const [search, setSearch] = React.useState({
    string_search: ''
  });
  const [dialogMaterial, setDialogMaterial] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenDelete(false);
  };
  const [serviciosTable, setServiciosTable] = React.useState([{
    id: 0,
    nombre: '',
    descripcion: '',
    observaciones: '',
    costo: 0.00,
    color: '',
    multipieza: ''
  }]);
  // get servicios 
  const listarServicios = async () => {
    const getServicios = await fetch(`${UrlsConst.URL_API}/ventas/servicio/listarServicio.php`, {
      method: 'POST'
    });
    const response = await getServicios.json();
    setServiciosTable(response);
  }
  const fntMateriales = async () => {
    const getmateriales = await fetch(`${UrlsConst.URL_API}/compras/material/listarMaterial.php`, {
      method: 'POST'
    });
    const response = await getmateriales.json();
    setMateriales(response);
  }

  React.useEffect(() => {
    listarServicios();
    fntMateriales();
  }, []);
  // comlumnas
  const handleClickDelete = async () => {
    const eliminarTodoMaterial = await fetch(`${UrlsConst.URL_API}/ventas/servicio/eliminarTodoServicioMaterial.php?id=` + idServicio, {
      method: 'POST'
    });
    const eliminarServicio = await fetch(`${UrlsConst.URL_API}/ventas/servicio/eliminarServicio.php?id=` + idServicio, {
      method: 'POST'
    });
    const response = await eliminarServicio.json();
    if (response !== false) {
      setOpenDelete(false);
      setTextAlert('Se elimino correctamente el servicio');
      setTypeAlert('success');
      setAlertGeneral(true);
      setTimeout(() => { setAlertGeneral(false) }, 3000);
      listarServicios();
    }
  }
  const searchServicio = async () => {
    const getServicios = await fetch(`${UrlsConst.URL_API}/ventas/servicio/listarServicio.php`, {
      method: 'POST'
    });
    const response = await getServicios.json();
    if (search.string_search != "") {
      const filtrar = search.string_search;
      const buscado = response.filter((serv) => serv.nombre.includes(filtrar) || serv.descripcion.includes(filtrar) || serv.observaciones.includes(filtrar));
      setServiciosTable(buscado);
    }
  }
  const cambiarBtn = (e) => {
    var input = document.getElementById('search').value;
    if (input !== "" && e.which === 13) {
      document.getElementById('btnSearch').click();
    }
  }
  // table 1
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // table 2
  const [page2, setPage2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };
  // table 3
  const [page3, setPage3] = React.useState(0);
  const [rowsPerPage3, setRowsPerPage3] = React.useState(10);
  const handleChangePage3 = (event, newPage) => {
    setPage3(newPage);
  };

  const handleChangeRowsPerPage3 = (event) => {
    setRowsPerPage3(parseInt(event.target.value, 10));
    setPage3(0);
  };
  const fntdeleteMaterial = async () => {
    const eliminarMaterial = await fetch(`${UrlsConst.URL_API}/compras/material/eliminarMaterial.php?id=` + idMaterial, {
      method: 'POST'
    }).then(function (response) {
      if (response.ok === true) {
        setAlertGeneral({ ...alertGeneral, type: 'success', text: 'Material eliminado correctamente', open: true });
        setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
        fntMateriales();
      } else {
        setAlertGeneral({ ...alertGeneral, type: 'error', text: 'Material esta asociado a un servicio', open: true });
        setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
      }
      setDeleteMaterial(false);
    })
  }
  const [searchMaterial, setSearchMaterial] = React.useState('');

  const filterMaterial = () => {
    if (searchMaterial === "") {
      return materiales;
    } else {
      let filtro = materiales.filter(f => f.nombre.includes(searchMaterial.toUpperCase()));
      return filtro;
    }
  }
  return (
    <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <Snackbar open={alertGeneral.open} autoHideDuration={6000} onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} severity={alertGeneral.type} sx={{ width: '100%' }}>
          {alertGeneral.text}
        </Alert>
      </Snackbar>
      <Grid item md={11.5} xs={11}>
        <Paper elevation={1} style={{ padding: '1.5em' }}>
          <Grid container style={{ justifyContent: 'space-between', textAlign: 'center' }}>
            <Typography variant='h5'>SERVICIOS</Typography>
            <div>
              <Button startIcon={<SettingsIcon />} variant="contained" color="primary" onClick={() => setDialogMantenimiento(true)}>MANTENIMIENTO MATERIAL</Button>
              <Button style={{ marginLeft: '6px' }} onClick={handleClickOpen} startIcon={<AddCircleIcon />} variant="contained" color="success" >NUEVO SERVICIO</Button>
            </div>
          </Grid>
          <Grid container>
            <Grid item md={5} xs={12}>
              <TextField
                id="search"
                fullWidth
                margin='normal'
                variant='standard'
                label="BUSCAR"
                name="string_search"
                value={search.string_search}
                onChange={
                  (e) => {
                    console.log(e.target.value);
                    if (e.target.value != "") {
                      const { name, value } = e.target;
                      setSearch({ ...search, [name]: value.toUpperCase() });
                    } else {
                      setSearch({ ...search, string_search: '' });
                      listarServicios();
                    }
                  }
                }
                onKeyUp={cambiarBtn}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton id="btnSearch" onClick={searchServicio}>
                        <SearchIcon color="warning" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: '20px' }}>
            <Paper>
              <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}>
                <Table aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '5%', fontWeight: 'bold' }}>N°</TableCell>
                      <TableCell style={{ width: '20%', fontWeight: 'bold' }}>NOMBRE DE SERVICIO</TableCell>
                      <TableCell style={{ width: '30%', fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                      <TableCell style={{ width: '30%', fontWeight: 'bold' }}>OBSERVACIONES</TableCell>
                      <TableCell style={{ width: '5%', fontWeight: 'bold' }}>MULTIPIEZA</TableCell>
                      <TableCell style={{ width: '20%', fontWeight: 'bold' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage2 > 0
                      ? serviciosTable.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                      : serviciosTable
                    ).map((servicio) => (
                      <Row
                        key={servicio.id}
                        servicio={servicio}
                        servicioId={setServicioId}
                        openEdit={setOpenEdit}
                        openDelete={setOpenDelete}
                        materiales={materiales}
                        alertGeneral={alertGeneral}
                        setAlertGeneral={setAlertGeneral}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                labelRowsPerPage="Filas por columnas"
                count={serviciosTable.length}
                rowsPerPage={rowsPerPage2}
                page={page2}
                onPageChange={handleChangePage2}
                onRowsPerPageChange={handleChangeRowsPerPage2}
              />
            </Paper>
          </Grid>
        </Paper>
      </Grid>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle>NUEVO SERVICIO</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <ServicioDialog
            fntClose={handleClose}
            fntServicios={listarServicios}
            setTextAlert={setTextAlert}
            setTypeAlert={setTypeAlert}
            setAlertGeneral={setAlertGeneral}
          />
          <Button style={{ marginLeft: '8px' }} startIcon={<CloseIcon />} variant='outlined' color="error" onClick={handleClose}>Cancelar</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openEdit} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle>EDITAR SERVICIO</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <ServicioDialog
            fntEdit={idServicio}
            fntClose={handleClose}
            fntServicios={listarServicios}
            setTextAlert={setTextAlert}
            setTypeAlert={setTypeAlert}
            setAlertGeneral={setAlertGeneral}
          />
          <Button style={{ marginLeft: '8px' }} startIcon={<CloseIcon />} variant='outlined' color="error" onClick={handleClose}>Cancelar</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openDelete} onClose={handleClose}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <div>
            <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
          </div>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant="h5">Eliminar Servicio</Typography>
          ¿Esta seguro de eliminar el servicio?
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button startIcon={<CheckIcon />} variant="contained" color="error" onClick={handleClickDelete}>Aceptar</Button>
          <Button startIcon={<CloseIcon />} variant="contained" color="primary" onClick={handleClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteMaterial} onClose={() => setDeleteMaterial(false)}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <div>
            <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
          </div>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant="h5">Eliminar Material</Typography>
          ¿Esta seguro de eliminar el material?
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button startIcon={<CheckIcon />} variant="contained" color="error" onClick={fntdeleteMaterial}>Aceptar</Button>
          <Button startIcon={<CloseIcon />} variant="contained" color="primary" onClick={() => setDeleteMaterial(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogMantenimiento} onClose={() => setDialogMantenimiento(false)} fullWidth={true} maxWidth="md">
        <DialogTitle>
          <Grid container style={{ justifyContent: 'space-between', textAlign: 'center' }}>
            <Typography variant='h5'>MANTENIMIENTO DE MATERIALES </Typography>
            <Button sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: "#000",
            }} startIcon={<CloseIcon />} onClick={() => setDialogMantenimiento(false)} ></Button>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent={'space-between'}>
            <Grid item md={5}>
              <TextField fullWidth variant='standard' label="BUSCAR MATERIAL" inputProps={{ style: { textTransform: 'uppercase' } }} InputProps={{
                startAdornment: (
                  <Search color='primary' sx={{ fontSize: '20px' }} />
                )
              }}
                onChange={
                  (e) => {
                    const { value } = e.target;
                    if (value !== "") {
                      setSearchMaterial(value);
                    } else {
                      setSearchMaterial('');
                    }
                  }
                }
              />
            </Grid>
            <Grid item md={2}>
              <Button fullWidth onClick={() => setDialogMaterial(true)} startIcon={<AddCircleIcon />} variant="contained" color="success" >Nuevo</Button>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Paper>
                <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500, marginTop: '10px' }}>
                  <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '5%', fontWeight: 'bold' }}>N°</TableCell>
                        <TableCell style={{ width: '30%', fontWeight: 'bold' }}>NOMBRE DE MATERIAL</TableCell>
                        <TableCell style={{ width: '35%', fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                        <TableCell style={{ width: '15%', fontWeight: 'bold' }}>COLOR</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        filterMaterial().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(material => (
                          <FilterMaterial key={material.id} material={material} setDialogMaterialEdit={setDialogMaterialEdit} setDeleteMaterial={setDeleteMaterial} setIdMaterial={setIdMaterial} />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  labelRowsPerPage="Filas por columnas"
                  count={filterMaterial().length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={dialogMaterial} onClose={() => setDialogMaterial(false)} maxWidth="md">
        <DialogTitle>NUEVO MATERIAL</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <MaterialDialog
            fntClose={setDialogMaterial}
            fntMateriales={fntMateriales}
            alertGeneral={alertGeneral}
            setAlertGeneral={setAlertGeneral}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={dialogMaterialEdit} onClose={() => setDialogMaterialEdit(false)} fullWidth={true} maxWidth="md">
        <DialogTitle>EDITAR MATERIAL</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <MaterialDialog
            fntClose={setDialogMaterialEdit}
            fntMateriales={fntMateriales}
            fntEdit={idMaterial}
            alertGeneral={alertGeneral}
            setAlertGeneral={setAlertGeneral}
          />
        </DialogContent>
      </Dialog>
    </Grid>

  )
}

