import React from 'react';
import {
  Alert, Avatar, Card,
  CardContent, Dialog, DialogContent, DialogTitle, Grid, IconButton,
  Paper, Snackbar, Table, TableBody, TableCell, TableContainer,
  TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, Checkbox, Button, Collapse, DialogActions, Chip, Stack,
} from '@mui/material';

import UrlsConst from '../../config/config';
// icons 
import BadgeIcon from '@mui/icons-material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CallIcon from '@mui/icons-material/Call';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import HouseIcon from '@mui/icons-material/House';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// componenentes
import ProfesionalPaciente from '../mantenimiento/dialogs/ProfesionalPaciente';
import ProfesionalDialog from '../mantenimiento/dialogs/profesionalDialog';
import { Box } from '@mui/system';
import DialogTrabajo from './dialogs/dialogTrabajo';
import PacienteDialog from '../mantenimiento/dialogs/pacienteDialog';
import DialogOrdenServicio from './dialogs/dialogOrdenServicio';
import Dentadura from '../../components/dentadura';
import NewOdontograma from '../../components/newodontograma';
import { ConfirmationNumber, ConstructionOutlined, Print, PrintOutlined, ViewWeek, ViewWeekOutlined } from '@mui/icons-material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import ExportTicket from '../../components/exportTicket/exportTicket';
import html2canvas from 'html2canvas';

import checkedSvg from "../../components/exportTicket/img/checked.svg";

function Orden(props) {
  // const orden = props.orden;
  const profesional = props.profesional;
  const alertGeneral = props.alertGeneral;
  const setAlertGeneral = props.setAlertGeneral;
  // const 
  const [orden, setOrden] = React.useState(props.orden);
  const [ordenR, setOrdenR] = React.useState({});
  const [trabajos, setTrabajos] = React.useState([]);
  
  const [profesionalE, setProfesionalE] = React.useState({});
  const [servicioE, setServicioE] = React.useState({});
  const [materialesE, setMaterialesE] = React.useState([]);
  const [materialE, setMaterialE] = React.useState({});
  const [pruebasE, setPruebasE] = React.useState([]);
  // collapse
  const [open, setOpen] = React.useState(false);
  // trabajo
  const [lineaTrabajo, setLineaTrabajo] = React.useState(false);
  // dialog 
  const [newTrabajo, setNewTrabajo] = React.useState(false);
  const [viewOrden, setViewOrden] = React.useState(false);
  const [deleteTrabajo, setDeleteTrabajo] = React.useState(false);
  const [searchCodigoBarra, setSearchCodigoBarra] = React.useState({ id: 0 });

  const [exportTicketOrd, setExportTicketOrd] = React.useState(false);
  const [exportTicketOrd2, setExportTicketOrd2] = React.useState(false);
  const [exportTicket, setExportTicket] = React.useState(false);
  const [datosEmpresa, setDatosEmpresa] = React.useState({
    ruc: '',
    razon_social: '',
    direccion: ''
  });
  // ================ORDEN VER EDIT JC ==================
  const [openEdit, setOpenEdit] = React.useState(false);


// ================ ==================
  const [nTrabajo, setNTrabajo] = React.useState(0);

  const handleCollapse = () => {
    if (open !== false) {
      setOpen(false)
    } else {
      ordenTrabajos(orden.id);
      setOpen(true);
    }
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const recuperarOrden2 = async (id) => {
    const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + id, {
      method: 'GET'
    });
    const response = await getOrden.json();
    setOrdenR(response);
    ordenTrabajos(id);
  }
  const ordenTrabajos = async (orden) => {
    const getPruebasOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarPruebaOrden.php?id=` + orden, {
      method: 'GET'
    });
    const response = await getPruebasOrden.json();
    setTrabajos(response);
    // console.log(response);
  }
  const fntNewTrabajo = async (id) => {
    const getPruebasOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarPruebaOrden.php?id=` + id, {
      method: 'GET'
    }); const response = await getPruebasOrden.json();
    if (response.length > 0) {
      setNTrabajo(response[response.length - 1].linea + 1);
    } else {
      setNTrabajo(1);
    }
    setTrabajos(response);
    setNewTrabajo(true);

  }
  const recuperarOrden = async (id) => {
    setViewOrden(true);
    ordenTrabajos(id);
  }
  // ====EDIT ORDEN JC====
  const ordenEdit = async (id) => {
    console.log(id);
    const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + id, {
        method: 'GET'
    });
    const response_orden = await getOrden.json();
    setOrdenR(response_orden);
    const getProfesional = await fetch(`${UrlsConst.URL_API}/ventas/profesional/recuperarProfesional.php?id=` + response_orden.fk_ven_profesional, {
        method: "GET"
    });
    const response = await getProfesional.json();
    setProfesionalE(response);
    const getServicio = await fetch(`${UrlsConst.URL_API}/ventas/servicio/recuperarServicio.php?id=` + response_orden.fk_ven_servicio, {
        method: 'GET'
    });
    const response_servicio = await getServicio.json();
    setServicioE(response_servicio);
    const getMaterial = await fetch(`${UrlsConst.URL_API}/ventas/servicio/listarServicioMaterial.php?id=` + response_orden.fk_ven_servicio, {
        method: 'GET'
    });
    const response_materiales = await getMaterial.json();
    // console.log(response_materiales)
    setMaterialesE(response_materiales);
    const getmaterial = response_materiales.filter((item) => item.fk_cmp_material === response_orden.fk_cmp_material);
    getmaterial.forEach(item => {
        setMaterialE(item);
    });
    const getPruebasOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarPruebaOrden.php?id=` + id, {
        method: 'GET'
    });
    const response_pruebas = await getPruebasOrden.json();
    setPruebasE(response_pruebas);
    setOpenEdit(true);
}
const handleClose = () => {
  setOpenEdit(false);
  // setOpenDelete(false);
}
  const fntdeleteTrabajo = async () => {
    const deleteTrabajo = await fetch(`${UrlsConst.URL_API}/ventas/orden/eliminarPrueba.php?id=${orden.id}&linea=${lineaTrabajo}`, {
      method: 'GET'
    });
    const response = await deleteTrabajo.json();
    ordenTrabajos(orden.id);
    setDeleteTrabajo(false);
    setAlertGeneral({ ...alertGeneral, type: 'success', text: 'Trabajo eliminado correctamente', open: true });
    setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
  }

  const cerrarDlgExportTicket = () => {
    setExportTicketOrd(false);
    // window.print();
  }
  const cerrarDlgExportTicket2 = () => {
    setExportTicketOrd2(false);
  }
  const abrirDlgExportTicket = () => {
    recuperarOrden2(orden.id);
    setExportTicketOrd(true);
  }
  const abrirDlgExportTicket2 = () => {
    recuperarOrden2(orden.id);
    setExportTicketOrd2(true);

  }
  const [obtTrabajoTicket, setObtTrabajoTicket] = React.useState();
  const abrirDlgExportTicketTrab = (trabajo) => {
    recuperarOrden2(orden.id);
    setObtTrabajoTicket(trabajo);
    setExportTicket(true);
  }
  const listarOrden = async () => {
    const ordenProfesional = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenProfesional.php?id=` + profesional.id, {
      method: "GET"
    });
    const response_ordenes = await ordenProfesional.json();
    if(response_ordenes.length>0){
      var responseFilterDB=response_ordenes.filter(f=>f.id===orden.id)[0];
      setOrden(responseFilterDB);
      setTimeout(async() => {
        ordenTrabajos(responseFilterDB.id);
      }, 500);
    }
  }
  React.useEffect(() => {
    const getDatosEmpresa = async () => {
      const getRuc = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RUC_EMPRESA`, {
        method: 'GET'
      });
      const response_getRuc = await getRuc.json();
      const getRazon = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RAZON_SOCIAL`, {
        method: 'GET'
      });
      const response_getRazon = await getRazon.json();
      const getDireccion = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=DIRECCION_EMPRESA`, {
        method: 'GET'
      });
      const response_getDireccion = await getDireccion.json();
      setDatosEmpresa({ ruc: response_getRuc.valor_string, razon_social: response_getRazon.valor_string, direccion: response_getDireccion.valor_string });
    }
    getDatosEmpresa();
    recuperarOrden2(orden.id);

  }, []);

  return (
    <React.Fragment>
      <TableRow key={orden.id}>
        <TableCell style={{ padding: '0' }}>
          <Tooltip title="VER TRABAJOS">
            <IconButton size="small" onClick={handleCollapse}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell component="th" scope="row">
          {orden.numero}
        </TableCell>
        <TableCell component="th" scope="row">
          {orden.descripcion}
        </TableCell>
        <TableCell component="th" scope="row">
          {orden.fecha_ingreso}
        </TableCell>
        <TableCell>{orden.apellidos_nombres}</TableCell>
        <TableCell>{orden.nombre}</TableCell>
        <TableCell>
          <Tooltip title="AGREGAR TRABAJO">
            <IconButton aria-label="delete" size="small" onClick={() => fntNewTrabajo(orden.id)}>
              <AddCircleIcon color='success' fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={trabajos.length>0?"VER ORDEN":"EDITAR ORDEN"}>
            {trabajos.length>0?(
              <IconButton aria-label="edit" size="small" onClick={() => recuperarOrden(orden.id)}>
                <VisibilityIcon color='secondary' fontSize="small" />
              </IconButton>
            ):(
              <IconButton aria-label="delete" onClick={() => {ordenEdit(orden.id);}} size="small">
                  <EditIcon color="warning" fontSize="small" />
              </IconButton>
            )}
          </Tooltip>
          <Tooltip title="EXPORTAR TICKET">
            <IconButton aria-label="export" size="small" onClick={() => { abrirDlgExportTicket(); ordenTrabajos(orden.id); }}>
              <Print color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="EXPORTAR TICKET RESUMEN">
            <IconButton aria-label="export" size="small" onClick={() => { abrirDlgExportTicket2(); ordenTrabajos(orden.id); }}>
              <PrintOutlined color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: '#f1f3f5' }} colSpan={20}>
          <Collapse in={open} timeout="auto" style={{ justifyContent: 'center' }}>
            <Box sx={{ margin: 1 }}>
              <Typography style={{ fontSize: '15px', fontWeight: 'bold' }} component="div">
                TRABAJOS DE {orden.numero}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>N°</TableCell>
                    <TableCell>TRABAJADOR</TableCell>
                    <TableCell>DESCRIPCION</TableCell>
                    <TableCell>F. REGISTRO</TableCell>
                    <TableCell>F. PROGRAMADA</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trabajos.map((trabajo) => (
                    <TableRow key={trabajo.linea}>
                      <TableCell component="th" scope="row">
                        {trabajo.linea}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {trabajo.trabajador_asignado}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {trabajo.descripcion}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {trabajo.fecha_registro + " " + trabajo.hora_registro}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {trabajo.fecha_programada + " " + trabajo.hora_programada}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title="EXPORTAR TICKET">
                          <IconButton aria-label="export" size="small" onClick={() => { abrirDlgExportTicketTrab(trabajo); setLineaTrabajo(trabajo.linea) }}>
                            <Print color="primary" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="ELIMINAR TRABAJO">
                          <IconButton aria-label="delete" size="small" onClick={() => { setDeleteTrabajo(true); setLineaTrabajo(trabajo.linea) }}>
                            <DeleteIcon color="error" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog open={newTrabajo} onClose={() => setNewTrabajo(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>NUEVO TRABAJO</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogTrabajo
            idOrden={orden.id}
            nTrabajo={nTrabajo}
            ordenTrabajos={ordenTrabajos}
            setNewTrabajo={setNewTrabajo}
            alertGeneral={alertGeneral}
            setAlertGeneral={setAlertGeneral}
          />
          <Button
            startIcon={<CloseIcon />} style={{ marginLeft: '6px' }}
            variant="outlined" color="error" className="btnClose"
            onClick={() => setNewTrabajo(false)}>
            Cancelar
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={deleteTrabajo} onClose={() => setDeleteTrabajo(false)} maxWidth="sm">
        <DialogTitle style={{ textAlign: 'center' }}>
          <div>
            <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
          </div>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant="h5">Eliminar Trabajo</Typography>
          ¿Esta seguro de eliminar el trabajo?
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="contained" color="error" onClick={() => fntdeleteTrabajo()}>Aceptar</Button>
          <Button variant="contained" onClick={() => setDeleteTrabajo(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={exportTicketOrd} onClose={() => setExportTicketOrd(false)} maxWidth="sm" fullWidth>
        <DialogTitle >
          <IconButton
            aria-label="close"
            onClick={() => setExportTicketOrd(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Grid container spacing={1} sx={{ justifyContent: "center" }}>
            <Grid item md={6}>
              <Typography color="Text.secondary" sx={{ pt: 3 }}><span style={{ fontWeight: 'bold' }}> EXPORTAR TICKET COD.</span></Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ justifyContent: "center" }}>

            <Grid item md={12}>
              <PDFViewer width={400} height={400}>
                <ExportTicket type="ticketOrd" datosOrd={orden} datosOrdR={ordenR} datosTrab={trabajos} datosEmpresa={datosEmpresa} profesional={profesional} />
              </PDFViewer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          {/* <Button variant="contained" color="success" >Aceptar</Button> */}
          <PDFDownloadLink document={<ExportTicket type="ticketOrd" datosOrd={orden} datosOrdR={ordenR} datosTrab={trabajos} datosEmpresa={datosEmpresa} profesional={profesional} />
          } fileName="EXPORTAR-TICKET">
            {({ loading }) => (loading ? <Button variant="contained">Aceptar</Button> : <Button variant="contained" onClick={() => cerrarDlgExportTicket()}>Aceptar</Button>)}
          </PDFDownloadLink>
          <Button variant="contained" color="error" onClick={() => cerrarDlgExportTicket()}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={exportTicketOrd2} onClose={() => setExportTicketOrd2(false)} maxWidth="sm" fullWidth>
        <DialogTitle >
          <IconButton
            aria-label="close"
            onClick={() => setExportTicketOrd2(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Grid container spacing={1} sx={{ justifyContent: "center" }}>
            <Grid item md={6}>
              <Typography color="Text.secondary" sx={{ pt: 3 }}><span style={{ fontWeight: 'bold' }}>EXPORTAR TICKET v2 COD.</span></Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item md={12}>
              <PDFViewer width={400} height={400} >
                <ExportTicket type="ticketOrdResum" datosOrd={orden} datosOrdR={ordenR} datosTrab={trabajos} datosEmpresa={datosEmpresa} profesional={profesional} />
              </PDFViewer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <PDFDownloadLink document={<ExportTicket type="ticketOrdResum" datosOrd={orden} datosOrdR={ordenR} datosTrab={trabajos} datosEmpresa={datosEmpresa} profesional={profesional} />
          } fileName="EXPORT-TICKET-DETALLE">
            {({ loading }) => (loading ? <Button variant="contained">Aceptar</Button> : <Button variant="contained" onClick={() => cerrarDlgExportTicket2()}>Aceptar</Button>)}
          </PDFDownloadLink>
          <Button variant="contained" color="error" onClick={() => cerrarDlgExportTicket2()}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={exportTicket} onClose={() => setExportTicket(false)} maxWidth="sm" fullWidth>
        <DialogTitle style={{ textAlign: 'center' }}>
          <Typography color="Text.secondary" sx={{ pt: 3 }}><span style={{ fontWeight: 'bold' }}> EXPORTAR TICKET TRABAJO</span></Typography>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item md={12}>
              <PDFViewer width={400} height={400}>
                <ExportTicket type="ticketTrab" datosOrd={orden} datosOrdR={ordenR} datosTrab={obtTrabajoTicket} datosEmpresa={datosEmpresa} profesional={profesional} />
              </PDFViewer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Stack direction="row" spacing={1}>
            <PDFDownloadLink document={<ExportTicket type="ticketTrab" datosOrd={orden} datosOrdR={ordenR} datosTrab={obtTrabajoTicket} datosEmpresa={datosEmpresa} profesional={profesional} />
            } fileName="EXPORTAR-TICKET">
              {({ loading }) => (loading ? <Button variant="contained">Aceptar</Button> : <Button variant="contained" onClick={() => setExportTicket(false)}>Aceptar</Button>)}
            </PDFDownloadLink>
            <Button variant="contained" color="error" onClick={() => setExportTicket(false)}>Cancel</Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Dialog open={viewOrden} onClose={() => setViewOrden(false)} fullWidth={true} maxWidth="lg">
        <Button sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: "#000",
        }} startIcon={<CloseIcon />} onClick={() => setViewOrden(false)}  ></Button>
        <DialogTitle>ORDEN DE SERVICIO {ordenR.id}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={8}>
              <Typography variant='secundary'>DATOS DE SERVICIO</Typography>
              <Paper style={{ padding: '1em', marginBottom: '10px', marginTop: '10px' }}>
                <Grid container>
                  <Grid item md={7}>
                    <div style={{ margin: '6px' }}> <span style={{ fontWeight: 'bold' }}>DESCRIPCION:</span> {orden.descripcion}</div>
                    <div style={{ margin: '6px' }}><span style={{ fontWeight: 'bold' }}>OBSERVACIONES:</span> {orden.observaciones} </div>
                    <div style={{ margin: '6px' }}><span style={{ fontWeight: 'bold' }}>USUARIO: </span> {orden.fk_log_usuario} </div>
                    <div style={{ margin: '6px' }}><span style={{ fontWeight: 'bold' }}>PRESUPUESTO:</span> s/. {orden.presupuesto} </div>
                  </Grid>
                  <Grid item md={4}>
                    <div style={{ margin: '6px' }}><span style={{ fontWeight: 'bold' }}>F.E PROGRAMADA: </span> {orden.fecha_entrega} </div>
                    <div style={{ margin: '6px' }}><span style={{ fontWeight: 'bold' }}>F. INGRESO:</span> {orden.fecha_ingreso} </div>
                    <div style={{ margin: '6px' }}><span style={{ fontWeight: 'bold' }}>CUBETA:</span> {orden.cubeta !== 0 ? <Chip color='success' label="SI" /> : <Chip color='error' label="NO" />} </div>
                    <div style={{ margin: '6px' }}><span style={{ fontWeight: 'bold' }}>ESTADO:</span> {orden.estado_pago === 'P' ? <Chip color='error' label="PENDIENTE" /> : orden.estado_pago === 'A' ? <Chip color='warning' label="A CUENTA" /> : ordenR.estado_pago === 'G' ? <Chip color='success' label="PAGADO" /> : ''} </div>
                  </Grid>
                </Grid>
              </Paper>
              <Typography variant='secundary'>TRABAJOS DE LA ORDEN</Typography>
              <Paper style={{ marginTop: '10px' }}>
                <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 200 }}  >
                  <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', width: '5%' }}>N°</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>TRABAJADOR</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>FECHA PROGRAMADA</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        (rowsPerPage > 0
                          ? trabajos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : trabajos
                        ).map((trabajo) => (
                          <TableRow key={trabajo.linea}>
                            <TableCell>{trabajo.linea}</TableCell>
                            <TableCell>{trabajo.trabajador_asignado}</TableCell>
                            <TableCell>{trabajo.descripcion}</TableCell>
                            <TableCell>{trabajo.fecha_programada + " " + trabajo.hora_programada}</TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  labelRowsPerPage="Filas por columnas"
                  count={trabajos.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>
            <Grid item md={4} style={{ textAlign: 'center' }}>
              <Dentadura
                historial={1}
                fntOrden={orden}
                viewOrden={1}
              />
              {/* <NewOdontograma view={1} recuperarOrden={orden} /> */}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={openEdit} onClose={handleClose} fullScreen={true}>
        <Button sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: "#000",
          }} startIcon={<CloseIcon />} onClick={handleClose} />
        <DialogTitle>EDITAR ORDEN</DialogTitle>
          <DialogContent>
            <DialogOrdenServicio
              profesional={profesionalE}
              fntOrden={ordenR}
              fntServicio={servicioE}
              fntMaterial={materialesE}

              material={materialE}
              fntPruebas={pruebasE}

               fntClose={handleClose}
               ordenes={listarOrden}
            />
          </DialogContent>
          
          {/* <DialogContent>
            <OrdenTrabajo
               profesional={profesional}
               fntOrden={orden}
               fntServicio={servicio}
               fntMaterial={materiales}
               material={material}
               fntPruebas={pruebas}
               fntClose={handleClose}
               />
          </DialogContent> */}
      </Dialog>
    </React.Fragment >
  );
}


export default function OrdenTrabajo() {
  //  alerta
  const [alertGeneral, setAlertGeneral] = React.useState({
    open: false,
    type: 'success',
    text: ''
  });
  // color avatar
  const [color, setColor] = React.useState('');
  // dialog 
  const [newProfexPaci, setNewProfesionalPaci] = React.useState(false);
  const [searchProfesional, setSearchProfesional] = React.useState(false);
  const [editProfesional, setEditProfesional] = React.useState(false);
  const [newPaciente, setNewPaciente] = React.useState(false);
  const [newOrden, setNewOrden] = React.useState(false);
  const [dlgSearchCodigoBarra, setDlgSearchCodigoBarra] = React.useState(false);
  const [searchCodigoBarra, setSearchCodigoBarra] = React.useState("");
  const [exportTicketOrd, setExportTicketOrd] = React.useState(false);
  const [exportTicketOrd2, setExportTicketOrd2] = React.useState(false);

  const [orden, setOrden] = React.useState({});
  const [ordenR, setOrdenR] = React.useState({});
  const [trabajos, setTrabajos] = React.useState([]);
  const [paciente, setPaciente] = React.useState([]);
  const [datosEmpresa, setDatosEmpresa] = React.useState({
    ruc: '',
    razon_social: '',
    direccion: ''
  });
  // profesional search
  const [profesionalSearch, setProfesionalSearch] = React.useState({
    apellidos_nombres: '',
    numero_documento: ''
  });
  // profesionaes
  const [profesionales, setProfesionales] = React.useState([]);
  const [profesional, setProfesional] = React.useState({
    id: 0,
    apellidos_nombres: 'DEFAULT',
    colegiatura: 'SIN COLEGIATURA',
    direccion: 'SIN DIRECCION',
    numero_documento: '00000000',
    numero_telefono: '999999999'
  });
  //  profesional x paciente
  const [profeXpaci, setProfeXpaci] = React.useState([]);
  // ordenes
  const [ordenes, setOrdenes] = React.useState([]);
  // select checkbox
  const [selected, setSelected] = React.useState([]);
  // handles

  // tabla 1
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // tabla 2
  const [page2, setPage2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };
  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };
  // tabla 3
  const [page3, setPage3] = React.useState(0);
  const [rowsPerPage3, setRowsPerPage3] = React.useState(10);
  const handleChangePage3 = (event, newPage) => {
    setPage3(newPage);
  };
  const handleChangeRowsPerPage3 = (event) => {
    setRowsPerPage3(parseInt(event.target.value, 10));
    setPage3(0);
  };
  const uploadProfesional = async (profesional) => {
    setProfesional(profesional);
    setSearchProfesional(false);
    setProfesionalSearch({
      apellidos_nombres: '',
      numero_documento: ''
    });
    setProfesionales([]);
    listarPaciXProfesional(profesional.id);
    const ordenProfesional = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenProfesional.php?id=` + profesional.id, {
      method: "GET"
    });
    const response_ordenes = await ordenProfesional.json();
    setOrdenes(response_ordenes);
  }
  const listarPaciXProfesional = async (id) => {
    const listPacientesPro = await fetch(`${UrlsConst.URL_API}/ventas/profesional/listarPacienteXProfesional.php?id=` + id, {
      method: "POST",
    });
    const response = await listPacientesPro.json();
    setProfeXpaci(response);
    const newSelected = response.map((n) => n.id);
    setSelected(newSelected);
  }
  const listarOrden = async () => {
    const ordenProfesional = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenProfesional.php?id=` + profesional.id, {
      method: "GET"
    });
    const response_ordenes = await ordenProfesional.json();
    setOrdenes(response_ordenes);
  }
  const fntSearchProfesional = async () => {
    const search = await fetch(`${UrlsConst.URL_API}/ventas/profesional/buscarProfesional.php`, {
      method: "POST",
      body: JSON.stringify(profesionalSearch)
    });
    const response = await search.json();
    setProfesionales(response);
    if (response.length > 0) {
      setProfesionales(response);
    } else {
      setAlertGeneral({ ...alertGeneral, type: 'warning', text: 'El profesional no se encuentra registrado', open: true });
      setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
    }
  }
  const fntButton = (e) => {
    if (e.which === 13) {
      fntSearchProfesional();
    }
  }
  const getColor = () => {
    setColor('#' + ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'].sort(() => .5 - Math.random()).slice(0, 6).join(''))
  }
  const handleSelectAllClick = async (event) => {
    if (event.target.checked) {
      const newSelected = profeXpaci.map((n) => n.id);
      setSelected(newSelected);
      const ordenProfesional = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenProfesional.php?id=` + profesional.id, {
        method: "GET"
      });
      const response = await ordenProfesional.json();
      setOrdenes(response);
      return;
    } else {
      setOrdenes([]);
    }
    setSelected([]);
  };
  const onchSearchCodigoBarra = (e) => {
    setSearchCodigoBarra(e.target.value);
  }
  const btnBuscarPorCodigo = async () => {
    const getPacientes = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenUI.php?paciente=&profesional=&id=` + searchCodigoBarra, {
      method: "GET"
    });
    const responseDB = await getPacientes.json();
    var fk_ven_paciente = responseDB[0].fk_ven_paciente;
    var fk_ven_profesional = responseDB[0].fk_ven_profesional;

    listarPaciXProfesional(fk_ven_paciente);
    const ordenProfesional = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenProfesional.php?id=` + fk_ven_profesional, {
      method: "GET"
    });
    const response_ordenes = await ordenProfesional.json();
    setOrdenes(response_ordenes);
    const searchProf = await fetch(`${UrlsConst.URL_API}/ventas/profesional/buscarProfesional.php`, {
      method: "POST",
      body: JSON.stringify(profesionalSearch)
    });
    const responseProf = await searchProf.json();
    const profesionalDB = responseProf.filter(el => { return el.id === fk_ven_profesional });
    if (response_ordenes) {
      setSearchCodigoBarra("");
      setDlgSearchCodigoBarra(false);
    } {
      console.log("algo salio mal");
    }
    setProfesional(profesionalDB[0]);
    setProfesionales([]);

  }


  const handleClick = async (event, id) => {
    const selectedIndex = selected.indexOf(id);

    if (event.target.checked) {
      const ordenProfesional = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenProfesional.php?id=` + profesional.id, {
        method: "GET"
      });
      const response = await ordenProfesional.json();
      const orden = response.filter((item) => item.fk_ven_paciente === id);
      const ordenesA = orden.concat(ordenes);
      const ordenSort = ordenesA.sort(function (a, b) {
        return (a.id - b.id)
      });
      setOrdenes(ordenSort);
    } else {
      const validar = ordenes.filter((item) => item.fk_ven_paciente === id);
      let ordenes_p = ordenes.filter((item) => item.fk_ven_paciente !== id);
      setOrdenes(ordenes_p);
    }
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  React.useEffect(() => {
    const getDatosEmpresa = async () => {
      const getRuc = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RUC_EMPRESA`, {
        method: 'GET'
      });
      const response_getRuc = await getRuc.json();
      const getRazon = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RAZON_SOCIAL`, {
        method: 'GET'
      });
      const response_getRazon = await getRazon.json();
      const getDireccion = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=DIRECCION_EMPRESA`, {
        method: 'GET'
      });
      const response_getDireccion = await getDireccion.json();
      setDatosEmpresa({ ruc: response_getRuc.valor_string, razon_social: response_getRazon.valor_string, direccion: response_getDireccion.valor_string });
    }
    getDatosEmpresa();
    getColor();
  }, []);
  // exportar ticket 
  const ordenTrabajos = async (orden) => {
    const getPruebasOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarPruebaOrden.php?id=` + orden, {
      method: 'GET'
    });
    const response = await getPruebasOrden.json();
    setTrabajos(response);
  }
  const recuperarOrden2 = async (id) => {
    const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + id, {
      method: 'GET'
    });
    const response = await getOrden.json();
    setOrdenR(response);
  }

  const abrirDlgExportTicket = async (orden, pruebas) => {
    recuperarOrden2(orden.id);
    setTrabajos(pruebas);
    const ordenProfesional = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenProfesional.php?id=` + profesional.id, {
      method: "GET"
    });
    const response_ordenes = await ordenProfesional.json();
    const ordenFilter = response_ordenes.filter(el => { return el.id === orden.id });
    setOrden(ordenFilter[0]);
    setExportTicketOrd(true);
  }
  const cerrarDlgExportTicket = () => {
    setExportTicketOrd(false);
    setExportTicketOrd2(true);
  }
  const cerrarDlgExportTicket2 = () => {
    setExportTicketOrd2(false);
  }
  return (
    <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignContent: 'center' }} className="contained">
      <Snackbar open={alertGeneral.open} autoHideDuration={6000} onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} severity={alertGeneral.type} sx={{ width: '100%' }}>
          {alertGeneral.text}
        </Alert>
      </Snackbar>


      <Dialog open={exportTicketOrd} onClose={() => cerrarDlgExportTicket(false)} maxWidth="sm" fullWidth>
        <DialogTitle >
          <IconButton
            aria-label="close"
            onClick={() => cerrarDlgExportTicket()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Grid container spacing={1} sx={{ justifyContent: "center" }}>
            <Grid item md={6}>
              <Typography color="Text.secondary" sx={{ pt: 3 }}><span style={{ fontWeight: 'bold' }}> EXPORTAR TICKET COD.</span></Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item md={12}>
              <PDFViewer width={400} height={400}>
                <ExportTicket type="ticketOrdAutomat" datosOrd={orden} datosOrdR={ordenR} datosTrab={trabajos} datosEmpresa={datosEmpresa} profesional={profesional} />
              </PDFViewer>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions style={{ justifyContent: 'center' }}>
          <PDFDownloadLink document={<ExportTicket type="ticketOrdAutomat" datosOrd={orden} datosOrdR={ordenR} datosTrab={trabajos} datosEmpresa={datosEmpresa} profesional={profesional} />
          } fileName="EXPORTAR-TICKET">
            {({ loading }) => (loading ? <Button variant="contained">Aceptar</Button> : <Button variant="contained" onClick={() => cerrarDlgExportTicket()}>Aceptar</Button>)}
          </PDFDownloadLink>
          <Button variant="contained" color="error" onClick={() => cerrarDlgExportTicket()}>Cancel</Button>
        </DialogActions> */}
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="contained" color="error" onClick={() => cerrarDlgExportTicket()}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={exportTicketOrd2} onClose={() => cerrarDlgExportTicket2()} maxWidth="sm" fullWidth>
        <DialogTitle >
          <IconButton
            aria-label="close"
            onClick={() => cerrarDlgExportTicket2()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Grid container spacing={1} sx={{ justifyContent: "center" }}>
            <Grid item md={6}>
              <Typography color="Text.secondary" sx={{ pt: 3 }}><span style={{ fontWeight: 'bold' }}>EXPORTAR TICKET RESUMEN COD.</span></Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item md={12}>
              <PDFViewer width={400} height={400} >
                <ExportTicket type="ticketOrdResumV2" datosOrd={orden} datosOrdR={ordenR} datosTrab={trabajos} datosEmpresa={datosEmpresa} profesional={profesional} />
              </PDFViewer>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions style={{ justifyContent: 'center' }}>
          <PDFDownloadLink document={<ExportTicket type="ticketOrdResum" datosOrd={orden} datosOrdR={ordenR} datosTrab={trabajos} datosEmpresa={datosEmpresa} profesional={profesional} />
          } fileName="EXPORT-TICKET-DETALLE">
            {({ loading }) => (loading ? <Button variant="contained">Aceptar</Button> : <Button variant="contained" onClick={() => cerrarDlgExportTicket2()}>Aceptar</Button>)}
          </PDFDownloadLink>
          <Button variant="contained" color="error" onClick={() => cerrarDlgExportTicket2()}>Cancel</Button>
        </DialogActions> */}
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="contained" color="error" onClick={() => cerrarDlgExportTicket2()}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dlgSearchCodigoBarra} onClose={() => setDlgSearchCodigoBarra(false)} maxWidth="xs" fullWidth>
        <DialogTitle>
          <IconButton sx={{
            position: 'absolute',
            right: 4,
            top: 8,
            color: "#000",
          }} onClick={() => setDlgSearchCodigoBarra(false)} > <CloseIcon /></IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} sx={{ justifyContent: "center" }}>
            <Grid item md={12}>
              <TextField
                id="searchCodigoBarra"
                label="BUSCAR POR CODIGO DE BARRA"
                type="search"
                variant="standard"
                fullWidth
                onChange={onchSearchCodigoBarra}
                value={searchCodigoBarra}
              />
            </Grid>
            <Grid item md={4}>
              <Button fullWidth variant="contained" color="primary" onClick={btnBuscarPorCodigo} startIcon={<SearchIcon />}>BUSCAR</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Grid item md={11.5} xs={11.5} style={{ marginTop: window.screen.width < 500 ? '40px' : '0' }}>
        <Paper elevation={1} style={{ padding: '1em' }}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>PROFESIONAL</Typography>
                    <div>
                      <Tooltip title="NUEVO PROFESIONAL">
                        <IconButton color="success" variant='contained' size="large" onClick={() => setNewProfesionalPaci(true)}>
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="BUSCAR PROFESIONAL">
                        <IconButton color='warning' variant='contained' size="large" onClick={() => setSearchProfesional(true)}>
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="BUSCAR POR CODIGO DE BARRAS">
                        <IconButton color='primary' variant='contained' size="large" onClick={() => setDlgSearchCodigoBarra(true)}>
                          <ViewWeek />
                        </IconButton>
                        {/* <Button variant='contained' size='small' color="primary" onClick={handleSearchCodigoBarra}><ViewWeek /></Button> */}
                      </Tooltip>
                      <IconButton color='primary' variant='contained' size="large" disabled={profesional.id === 0} onClick={() => setEditProfesional(true)}>
                        <Tooltip title="EDITAR PROFESIONAL">
                          <EditIcon />
                        </Tooltip>
                      </IconButton>
                    </div>
                  </div>
                  <Card style={{ textAlign: 'center' }}>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={6}>
                          <Avatar sx={{ bgcolor: color, width: '60px', height: '60px', fontSize: '30px', margin: 'auto' }}>
                            {profesional ? profesional.apellidos_nombres.charAt(0) : 0}
                          </Avatar>
                          <Typography gutterBottom component="div" style={{ marginTop: '15px', fontSize: '18px' }}>
                            {profesional ? profesional.apellidos_nombres.toUpperCase() : 'Default'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={6} style={{ marginTop: '5%' }}>
                          <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                            <BadgeIcon style={{ fontSize: '20px' }} />
                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{profesional.numero_documento.toUpperCase()}</span>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                            <SchoolIcon style={{ fontSize: '20px' }} />
                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{profesional.colegiatura.toUpperCase()}</span>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                            <HouseIcon style={{ fontSize: '20px' }} />
                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{profesional.direccion.toUpperCase()}</span>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                            <CallIcon style={{ fontSize: '20px' }} />
                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{profesional.numero_telefono}</span>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography style={{ marginTop: '20px', marginBottom: '10px' }}>PACIENTES</Typography>
                    <IconButton color="success" variant='contained' size="large"
                      onClick={() => setNewPaciente(true)} disabled={profesional.id === 0}>
                      <Tooltip title="NUEVO PACIENTE">
                        <AddCircleIcon />
                      </Tooltip>
                    </IconButton>
                  </div>
                  <Paper>
                    <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: '38vh' }}  >
                      <Table stickyHeader aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: '10px', padding: '0px' }}>
                              <Checkbox
                                indeterminate={selected.length > 0 && selected.length < profeXpaci.length}
                                checked={profeXpaci.length > 0 && selected.length === profeXpaci.length}
                                onChange={handleSelectAllClick}
                              />
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold', width: '' }}>PACIENTE</TableCell>
                            <TableCell style={{ fontWeight: 'bold', width: '' }}>DOCUMENTO</TableCell>
                            <TableCell style={{ fontWeight: 'bold', width: '' }}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            (rowsPerPage2 > 0
                              ? profeXpaci.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                              : profeXpaci
                            ).map((paciente) => {
                              const isItemSelected = isSelected(paciente.id);
                              return (
                                <TableRow
                                  key={paciente.id}>
                                  <TableCell component="th" scope="row" style={{ padding: '0px' }}>
                                    <Checkbox
                                      checked={isItemSelected}
                                      onClick={(event) => handleClick(event, paciente.id)}
                                    />
                                  </TableCell>
                                  <TableCell>{paciente.apellidos_nombres}</TableCell>
                                  <TableCell>{paciente.numero_documento}</TableCell>
                                  <TableCell>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          {
                            // profesional.id === 0 &&
                            profeXpaci.length < 1 &&
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>SIN PACIENTES</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      component="div"
                      labelRowsPerPage="Filas por columnas"
                      count={profeXpaci.length}
                      rowsPerPage={rowsPerPage2}
                      page={page2}
                      onPageChange={handleChangePage2}
                      onRowsPerPageChange={handleChangeRowsPerPage2}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8} xs={12}>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>ORDENES DE SERVICIO</Typography>
                    <Button startIcon={<NoteAddIcon />} variant="contained" color="success" onClick={() => {
                      profesional.id !== 0 ? setNewOrden(true) : setAlertGeneral({ ...alertGeneral, type: 'warning', text: 'Debe buscar un profesional primero', open: true });
                      setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
                    }}>Nueva Orden</Button>
                  </div>
                  <Paper>
                    <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: '71vh', marginTop: '13px' }}  >
                      <Table aria-label="simple table" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: '5%' }} ></TableCell>
                            <TableCell style={{ width: '8%', fontWeight: 'bold' }}>N°</TableCell>
                            <TableCell style={{ width: '25%', fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                            <TableCell style={{ fontWeight: 'bold', width: '11%' }}>F. INGRESO</TableCell>
                            <TableCell style={{ width: '25%', fontWeight: 'bold' }}>PACIENTE</TableCell>
                            <TableCell style={{ width: '25%', fontWeight: 'bold' }}>SERVICIO</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* {console.log(ordenes)} */}
                          {
                            (rowsPerPage3 > 0
                              ? ordenes.slice(page3 * rowsPerPage3, page3 * rowsPerPage3 + rowsPerPage3)
                              : ordenes
                            ).map((orden) => (
                              <Orden
                                key={orden.id}
                                orden={orden}
                                alertGeneral={alertGeneral}
                                setAlertGeneral={setAlertGeneral}
                                profesional={profesional}
                                uploadProfesional={uploadProfesional}
                              />
                            ))}
                          {
                            // profesional.id === 0 &&
                            ordenes.length < 1 &&
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>SIN ORDENES</TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      component="div"
                      labelRowsPerPage="Filas por columnas"
                      count={ordenes.length}
                      rowsPerPage={rowsPerPage3}
                      page={page3}
                      onPageChange={handleChangePage3}
                      onRowsPerPageChange={handleChangeRowsPerPage3}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid >
      {/* dialogs */}
      <Dialog open={newProfexPaci} onClose={() => setNewProfesionalPaci(false)} fullWidth={true} maxWidth="lg">
        <DialogTitle>NUEVO PROFESIONAL</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <ProfesionalPaciente
            setNewProfesionalPaci={() => setNewProfesionalPaci(false)}
            setProfesional={setProfesional}
            listarPaciXProfesional={listarPaciXProfesional}
            alertGeneral={alertGeneral}
            setAlertGeneral={setAlertGeneral}
          />
          <Button style={{ marginLeft: '6px' }} startIcon={<CloseIcon />} variant="outlined" color="error" onClick={() => setNewProfesionalPaci(false)}>Cancelar</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={searchProfesional} onClose={() => { setSearchProfesional(false); setProfesionales([]) }} fullWidth={true} maxWidth="md">
        <Button sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: "#000",
        }} startIcon={<CloseIcon />} onClick={() => { setSearchProfesional(false); setProfesionales([]); }}  ></Button>
        <DialogTitle>BUSCAR PROFESIONAL</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item md={5} xs={12}>
              <TextField
                fullWidth
                label="NOMBRES"
                name='apellidos_nombres'
                variant='standard'
                margin='normal'
                value={profesionalSearch.apellidos_nombres}
                onKeyUp={fntButton}
                onChange={
                  (e) => {
                    const value = e.target.value;
                    setProfesionalSearch({ ...profesionalSearch, apellidos_nombres: value.toUpperCase() });
                  }
                }
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                fullWidth
                label="DOCUMENTO"
                name='numero_documento'
                variant='standard'
                margin='normal'
                value={profesionalSearch.numero_documento}
                onChange={
                  (e) => {
                    const value = e.target.value;
                    setProfesionalSearch({ ...profesionalSearch, numero_documento: value.toUpperCase() });
                  }
                }
                onKeyUp={fntButton}
              />
            </Grid>

            <Grid item md={2} xs={12} style={{ marginTop: '25px', textAlign: 'center' }}>
              <Button onClick={fntSearchProfesional} variant="contained" color="warning" startIcon={<SearchIcon />}>
                BUSCAR
              </Button>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Paper>
              <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 300, marginTop: '20px' }}  >
                <Table aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '5%', fontWeight: 'bold' }}>N°</TableCell>
                      <TableCell style={{ width: '50%', fontWeight: 'bold' }}>PROFESIONAL</TableCell>
                      <TableCell style={{ width: '45%', fontWeight: 'bold' }}>DOCUMENTO</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      (rowsPerPage > 0
                        ? profesionales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : profesionales
                      ).map((profesional) => (
                        <TableRow onClick={() => uploadProfesional(profesional)}
                          key={profesional.id}
                          style={{ cursor: 'pointer' }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {profesional.id}
                          </TableCell>
                          <TableCell>{profesional.apellidos_nombres}</TableCell>
                          <TableCell>{profesional.numero_documento}</TableCell>
                        </TableRow>
                      ))}

                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                labelRowsPerPage="Filas por columnas"
                count={profesionales.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={editProfesional} onClose={() => setEditProfesional(false)} fullWidth={true} maxWidth="md">
        <DialogTitle>EDITAR PROFESIONAL</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <ProfesionalDialog
            fntIdprofesional={profesional.id}
            fntClose={() => setEditProfesional(false)}
            setProfesional={setProfesional}
            alertGeneral={alertGeneral}
            setAlertGeneral={setAlertGeneral}
          />
          <Button startIcon={<CloseIcon />} style={{ marginLeft: '6px' }} variant="outlined" color="error" className="btnClose" onClick={() => setEditProfesional(false)}>Cancelar</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={newPaciente} onClose={() => setNewPaciente(false)} fullWidth={true} maxWidth="md">
        <DialogTitle>NUEVO PACIENTE</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <PacienteDialog
            fntIdprofesional={profesional.id}
            setNewPaciente={() => setNewPaciente(false)}
            alertGeneral={alertGeneral}
            fntPacientesProfesionales={listarPaciXProfesional}
            setAlertGeneral={setAlertGeneral}
          />
          <Button startIcon={<CloseIcon />} style={{ marginLeft: '6px' }} variant="outlined" color="error" className="btnClose" onClick={() => setNewPaciente(false)}>Cancelar</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={newOrden} onClose={() => setNewOrden(false)} fullScreen={true} >
        <Button sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: "#000",
        }} startIcon={<CloseIcon />} onClick={() => setNewOrden(false)}  ></Button>
        <DialogTitle>NUEVA ORDEN</DialogTitle>
        <DialogContent>
          <DialogOrdenServicio
            profesional={profesional}
            setNewOrden={() => setNewOrden(false)}
            ordenes={listarOrden}
            exportTicket={abrirDlgExportTicket}
          />
        </DialogContent>
      </Dialog>
    </Grid >
  )
}
