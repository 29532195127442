import * as React from 'react';
import { Alert, Avatar, Button, Card, CardActions, CardContent, CardMedia, Chip, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import UrlsConst from '../../config/config';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import BadgeIcon from '@mui/icons-material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function OrdenProfesional() {
    // table 
    const [profesionalSearch, setProfesionalSearch] = React.useState({
        apellidos_nombres: '',
        numero_documento: ''
    });
    const [profesionales, setProfesionales] = React.useState([]);
    const [textAlert, setTextAlert] = React.useState('');
    const [alert, setAlert] = React.useState(false);
    const [profesional, setProfesional] = React.useState({
        id: 0,
        apellidos_nombres: '',
        direccion: '',
        numero_documento: ''
    });
    const [ordenProfesional, setOrdenProfesional] = React.useState([]);
    const [openEntregar, setOpenEntregar] = React.useState(false);
    const [openCancelarEntregar, setOpenCancelarEntregar] = React.useState(false);
    const [ordenId, setOrdenId] = React.useState(0);
    const [openSearch, setOpenSearch] = React.useState(false);

    const uploadProfesional = async (id) => {
        const search = await fetch(`${UrlsConst.URL_API}/ventas/profesional/recuperarProfesional.php?id=` + id, {
            method: 'GET'
        });
        const response = await search.json();
        setProfesional(response);
        ListarOrdenProfesional(id);
        setProfesionalSearch({
            apellidos_nombres: '',
            numero_documento: ''
        });
        setOpenSearch(false);
        setProfesionales([]);
    }
    const handleProfesional = (e) => {
        const { name, value } = e.target;
        if (value != "") {
            setProfesionalSearch({ ...profesionalSearch, [name]: value.toUpperCase() });
        } else {
            setProfesionalSearch({ ...profesionalSearch, [name]: '' });
        }
    }
    const searchOrdenProfesional = async () => {
        const search = await fetch(`${UrlsConst.URL_API}/ventas/profesional/buscarProfesional.php`, {
            method: "POST",
            body: JSON.stringify(profesionalSearch),
        });
        const response = await search.json();
        setProfesionales(response);
    }
    const ListarOrdenProfesional = async (id) => {
        const ordenProfesional = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenProfesional.php?id=` + id, {
            method: "GET"
        });
        const response = await ordenProfesional.json();
        setOrdenProfesional(response);
    }
    const handleClickOpen = () => {
        setOpenSearch(true);
    }
    const handleClose = () => {
        setOpenSearch(false);
        setOpenEntregar(false);
        setOpenCancelarEntregar(false);
        setProfesionales([]);
    }
    const cambiarBtn = (e) => {
        if (e.which == 13) {
            searchOrdenProfesional();
        }
    }
    const handleOrden = async () => {
        const recuperar_orden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + ordenId, {
            method: 'GET'
        });
        const response_orden = await recuperar_orden.json();
        const setorden = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarOrden.php`, {
            method: 'POST',
            body: JSON.stringify({
                id: response_orden.id,
                descripcion: response_orden.descripcion,
                observaciones: response_orden.observaciones,
                fecha_ingreso: response_orden.fecha_ingreso,
                fecha_entrega: response_orden.fecha_entrega,
                cubeta: response_orden.cubeta,
                esta_entregado: 1,
                fk_ven_profesional: response_orden.fk_ven_profesional,
                fk_ven_paciente: response_orden.fk_ven_paciente,
                esta_facturado: response_orden.esta_facturado,
                fk_log_usuario: response_orden.fk_log_usuario,
                fk_cmp_material: response_orden.fk_cmp_material,
                fk_ven_servicio: response_orden.fk_ven_servicio
            })
        });
        const response = await setorden.json();
        // const 
        setOpenEntregar(false);
        setTextAlert('!COMPLETADO¡ Se entrego correctamente la orden');
        setAlert(true);
        setTimeout(() => { setAlert(false) }, 2500);
        ListarOrdenProfesional(response_orden.fk_ven_profesional);
    }
    const handleCancelarOrden = async () => {
        const recuperar_orden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + ordenId, {
            method: 'GET'
        });
        const response_orden = await recuperar_orden.json();
        const setorden = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarOrden.php`, {
            method: 'POST',
            body: JSON.stringify({
                id: response_orden.id,
                descripcion: response_orden.descripcion,
                observaciones: response_orden.observaciones,
                fecha_ingreso: response_orden.fecha_ingreso,
                fecha_entrega: response_orden.fecha_entrega,
                cubeta: response_orden.cubeta,
                esta_entregado: 0,
                fk_ven_profesional: response_orden.fk_ven_profesional,
                fk_ven_paciente: response_orden.fk_ven_paciente,
                esta_facturado: response_orden.esta_facturado,
                fk_log_usuario: response_orden.fk_log_usuario,
                fk_cmp_material: response_orden.fk_cmp_material,
                fk_ven_servicio: response_orden.fk_ven_servicio
            })
        });
        const response = await setorden.json();
        setOpenCancelarEntregar(false);
        setTextAlert('!COMPLETADO¡ Se cancelo la entrega de la orden correctamente');
        setAlert(true);
        setTimeout(() => { setAlert(false) }, 2500);
        ListarOrdenProfesional(response_orden.fk_ven_profesional);
    }
    React.useEffect(() => {
        getColor()
    }, []);
    const [color, setColor] = React.useState('');
    function getColor() {
        setColor('#' + ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'].sort(() => .5 - Math.random()).slice(0, 6).join(''))
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [page2, setPage2] = React.useState(0);
    const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
    const handleChangePage2 = (event, newPage) => {
        setRowsPerPage2(newPage);
    };
    const handleChangeRowsPerPage2 = (event) => {
        setRowsPerPage2(parseInt(event.target.value, 10));
        setPage2(0);
    };
    return (
        <>
            <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignContent: 'center' }}>
                <Grid item md={11.5} xs={11} style={{ marginTop: window.screen.width < 500 ? '40px' : '0' }}>
                    <Paper elevation={1} style={{ padding: '1.5em' }}>
                        <Grid container>
                            <Grid item md={4} xs={12} style={{ textAlign: 'center' }}>
                                <Typography variant='h5'>ORDEN DE SERVICIO POR PROFESIONAL</Typography>
                            </Grid>
                            <Grid item md={6}></Grid>
                            <Grid item md={2} xs={12} style={{ textAlign: 'center' }}>
                                <Button variant='contained' color="warning" onClick={handleClickOpen}><SearchIcon />Buscar profesional</Button>
                            </Grid>
                        </Grid>
                        <Snackbar open={alert} onClose={() => setAlert(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                            <Alert severity="success"
                                onClose={() => setAlert(false)}
                            >
                                {textAlert}
                            </Alert>
                        </Snackbar>
                        {profesional.id === 0 &&
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant='h5' color="text.secondary" style={{ margin: 'auto' }}>No se realizo ninguna busqueda</Typography>
                            </div>
                        }
                        {profesional.id != 0 &&
                            <>
                                <Grid container style={{ marginTop: '20px' }} spacing={2}>
                                    <Grid item md={3} xs={12} style={{ marginBottom: '10px' }}>
                                        <Card style={{ textAlign: 'center' }}>
                                            <div className='imgCard'>
                                                <Avatar sx={{ bgcolor: color, width: '80px', height: '80px', fontSize: '40px', margin: 'auto', top: '80%' }}>
                                                    {profesional.apellidos_nombres.charAt(0)}
                                                </Avatar>
                                            </div>
                                            <CardContent style={{ marginTop: '35px' }}>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    {profesional ? profesional.apellidos_nombres : ''}
                                                </Typography>
                                                <ul style={{ listStyle: 'none', color: 'rgba(0, 0, 0, 0.6)' }}>
                                                    <li style={{ display: 'flex' }}>
                                                        <BadgeIcon style={{ fontSize: '30px' }} />
                                                        <span style={{ marginLeft: '0.5em', marginTop: '0.5em' }}>{profesional ? profesional.numero_documento : ''}</span>
                                                    </li>
                                                    <li style={{ display: 'flex' }}>
                                                        <SchoolIcon style={{ fontSize: '30px' }} />
                                                        <span style={{ marginLeft: '0.5em', marginTop: '0.5em' }}>{profesional ? profesional.colegiatura : ''}</span>
                                                    </li>
                                                    <li style={{ display: 'flex' }}>
                                                        <BadgeIcon style={{ fontSize: '30px' }} />
                                                        <span style={{ marginLeft: '0.5em', marginTop: '0.5em' }}>{profesional ? profesional.direccion : ''}</span>
                                                    </li>
                                                </ul>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item md={9} xs={12}>
                                        <Paper>
                                            <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}  >
                                                <Table aria-label="simple table" stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>N°</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>NOMBRES Y APELLIDOS</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>ORDEN</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>FECHA DE INGRESO</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>FECHA DE ENTREGA</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>ESTADO</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            (rowsPerPage2 > 0
                                                                ? ordenProfesional.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                                                                : ordenProfesional
                                                            ).map((orden) => (
                                                                <TableRow
                                                                    key={orden.id}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        {orden.id}
                                                                    </TableCell>
                                                                    <TableCell>{orden.apellidos_nombres}</TableCell>
                                                                    <TableCell>{orden.nombre}</TableCell>
                                                                    <TableCell>{orden.fecha_ingreso}</TableCell>
                                                                    <TableCell>{orden.fecha_entrega}</TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            orden.esta_entregado != 0 ?
                                                                                <Chip color="success" style={{ width: '130px', borderRadius: '20px' }} onClick={() => { setOrdenId(orden.id); setOpenCancelarEntregar(true); }} size="small" variant='contained' label="ENTREGADO" />
                                                                                : <Chip color="error" style={{ width: '130px', borderRadius: '20px' }} onClick={() => { setOrdenId(orden.id); setOpenEntregar(true); }} size="small" variant='contained' label="SIN ENTREGAR" />
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                component="div"
                                                labelRowsPerPage="Filas por columnas"
                                                count={ordenProfesional.length}
                                                rowsPerPage={rowsPerPage2}
                                                page={page2}
                                                onPageChange={handleChangePage2}
                                                onRowsPerPageChange={handleChangeRowsPerPage2}
                                            />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Paper>
                </Grid>
            </Grid >
            <Dialog open={openSearch} onClose={handleClose} fullWidth={true} maxWidth="md">
                <Button sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000",
                }} startIcon={<CloseIcon />} onClick={handleClose} ></Button>
                <DialogTitle>
                    BUSCAR PROFESIONAL
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item md={5} xs={12}>
                            <TextField
                                fullWidth
                                id="nombre"
                                variant='standard'
                                label="NOMBRES"
                                name='apellidos_nombres'
                                margin='normal'
                                onChange={handleProfesional}
                                value={profesionalSearch.apellidos_nombres}
                                onKeyUp={cambiarBtn}
                            />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <TextField
                                fullWidth
                                id="documento"
                                variant='standard'
                                label="DOCUMENTO"
                                name='numero_documento'
                                margin='normal'
                                onChange={handleProfesional}
                                value={profesionalSearch.numero_documento}
                                onKeyUp={cambiarBtn}
                            />
                        </Grid>
                        <Grid item md={2} xs={12} style={{ textAlign: 'center' }}>
                            <Button style={{ marginTop: '25px' }} startIcon={<SearchIcon />} variant="contained" color="warning" onClick={searchOrdenProfesional}>BUSCAR</Button>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Paper>
                                <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 300 }}  >
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>N°</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>PROFESIONAL</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>DOCUMENTO</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>DIRECCION</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                (rowsPerPage > 0
                                                    ? profesionales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : profesionales
                                                ).map((profesional) => (
                                                    <TableRow onClick={() => uploadProfesional(profesional.id)}
                                                        key={profesional.id}
                                                        style={{ cursor: 'pointer' }}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {profesional.id}
                                                        </TableCell>
                                                        <TableCell>{profesional.apellidos_nombres}</TableCell>
                                                        <TableCell>{profesional.numero_documento}</TableCell>
                                                        <TableCell>{profesional.direccion}</TableCell>
                                                    </TableRow>
                                                ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component="div"
                                    labelRowsPerPage="Filas por columnas"
                                    count={profesionales.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog >
            <Dialog open={openEntregar} onClose={handleClose}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant='h6'>Entregar Orden</Typography>
                    ¿Esta seguro de entregar la orden?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} variant='contained' color="success" onClick={handleOrden}>Aceptar</Button>
                    <Button startIcon={<CloseIcon />} variant='contained' color="error" onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openCancelarEntregar} onClose={handleClose}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h6">Cancelar Orden</Typography>
                    ¿Esta seguro de cancelar la entrega de la orden?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} variant='contained' color="success" onClick={handleCancelarOrden}>Aceptar</Button>
                    <Button startIcon={<CloseIcon />} variant='contained' color="error" onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
