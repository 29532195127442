import * as React from 'react';
import {
    Alert, Avatar, Button, Card, CardContent, Chip, Dialog,
    DialogActions, DialogContent, DialogTitle, Grid,
    IconButton,
    InputAdornment,
    Paper, Snackbar, Table, TableBody, TableCell, TableContainer,
    TableHead, TablePagination, TableRow, TextField, Typography
}
    from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import UrlsConst from '../../config/config';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DemoProgresBar from '../reportes/DemoProgresBar';
import { BadgeRounded, ConstructionOutlined, House, Person, School } from '@mui/icons-material';

export default function TrabajoTrabajador() {
    // const 
    const [openSearch, setOpenSearch] = React.useState(false);
    const [openEntregar, setOpenEntregar] = React.useState(false);
    const [openConcluir, setOpenConcluir] = React.useState(false);
    const [openCancelar, setOpenCancelar] = React.useState(false);
    const [openCancelarCocl, setOpenCancelarConcl] = React.useState(false);
    const [searchCodigoBarra, setSearchCodigoBarra] = React.useState({
        searchCodBarra: ''
    });
    const [pintarPruebaXcodigoBarra, setPintarPruebaXcodigoBarra] = React.useState(false);
    const [alertGeneralAnt, setAlertGeneralAnt] = React.useState(false);
    const [typeAlert, setTypeAlert] = React.useState('success');
    const [textAlert, setTextAlert] = React.useState('');
    const [alertGeneral, setAlertGeneral] = React.useState({
        show: false,
        severity: 'warning',
        message: '',
        vertical: "top",
        horizontal: "right"
    });
    const [prueba, setPrueba] = React.useState({
        fk_ven_orden: 0,
        linea: 0,
        fk_rh_trabajador: 0,
        fecha_registro: '',
        hora_registro: '',
        fecha_conclusion: '',
        hora_conclusion: '',
        fecha_entrega: '',
        hora_entrega: '',
        fecha_programada: '',
        hora_programada: '',
        descripcion: '',
        nombre_trabajador: ''
    });
    const [trabajadorSearch, setTrabajadorSearch] = React.useState({
        apellidos_nombres: '',
        numero_documento: ''
    });
    const [trabajadores, setTrabajadores] = React.useState([]);
    const [pruebasTrabajador, setPruebaTrabajador] = React.useState([]);
    const [trabajador, setTrabajador] = React.useState({
        id: 0,
        nombres_apellidos: '',
        numero_documento: '',
        cargo: '',
        es_activo: 0
    });
    const uploadTrabajador = (trabajador) => {
        setTrabajador(trabajador);
        ListarPruebaTrabajador(trabajador.id);
        setOpenSearch(false);
        setTrabajadorSearch({
            apellidos_nombres: '',
            numero_documento: ''
        });
        setTrabajadores([]);
        setPintarPruebaXcodigoBarra(false);
        setSearchCodigoBarra({ searchCodBarra: '' });
    }
    // funciones
    const handleClickOpen = () => {
        setOpenSearch(true);
    }
    const handleClose = () => {
        setOpenSearch(false);
        setOpenEntregar(false);
        setOpenConcluir(false);
        setOpenCancelar(false);
        setOpenCancelarConcl(false)
        setTrabajadorSearch({
            apellidos_nombres: '',
            numero_documento: ''
        });
        setTrabajadores([]);
    }
    const handleTrabajador = (e) => {
        const { name, value } = e.target;
        if (value !== "") {
            setTrabajadorSearch({ ...trabajadorSearch, [name]: value.toUpperCase() });
        } else {
            setTrabajadorSearch({ ...trabajadorSearch, [name]: '' });
        }
    }
    const searchPruebaTrabajador = async () => {
        const search = await fetch(`${UrlsConst.URL_API}/sistema/trabajador/buscarTrabajador.php`, {
            method: "POST",
            body: JSON.stringify(trabajadorSearch),
        });
        const response = await search.json();
        setTrabajadores(response);
    }
    const cambiarBtn = (e) => {
        if (e.which === 13) {
            document.getElementById('btnSearch').click();
        }
    }
    const ListarPruebaTrabajador = async (id) => {
        const pruebaTrabajador = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarPruebaTrabajador.php?id=` + id, {
            method: "GET"
        });
        const response = await pruebaTrabajador.json();
        setPruebaTrabajador(response);
    }
    const handleEntregar = async () => {
        const fecha = new Date();
        const updatePrueba = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarPrueba.php`, {
            method: 'POST',
            body: JSON.stringify({
                fk_ven_orden: prueba.fk_ven_orden,
                linea: prueba.linea,
                fecha_registro: prueba.fecha_registro,
                hora_registro: prueba.hora_registro,
                fecha_entrega: new Date().toJSON().slice(0, 10),
                hora_entrega: fecha.toLocaleTimeString(),
                fecha_conclusion: prueba.fecha_conclusion,
                hora_conclusion: prueba.hora_conclusion,
                fecha_programada: prueba.fecha_programada,
                hora_programada: prueba.hora_programada,
                descripcion: prueba.descripcion,
                fk_rh_trabajador: prueba.fk_rh_trabajador
            })
        });
        const response = await updatePrueba.json();
        setOpenEntregar(false);
        ListarPruebaTrabajador(prueba.fk_rh_trabajador);
        setTextAlert('El trabajo fue entregado exitosamente');
        setTypeAlert('success');
        setAlertGeneralAnt(true);
        setTimeout(() => { setAlertGeneralAnt(false) }, 3000);
        ListarPruebaTrabajador(trabajador.id);
    }
    const handleConcluir = async () => {
        const fecha = new Date();
        const updatePruebaConclusion = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarPrueba.php`, {
            method: 'POST',
            body: JSON.stringify({
                fk_ven_orden: prueba.fk_ven_orden,
                linea: prueba.linea,
                fecha_registro: prueba.fecha_registro,
                hora_registro: prueba.hora_registro,
                fecha_entrega: prueba.fecha_entrega,
                hora_entrega: prueba.hora_entrega,
                fecha_conclusion: new Date().toJSON().slice(0, 10),
                hora_conclusion: fecha.toLocaleTimeString(),
                fecha_programada: prueba.fecha_programada,
                hora_programada: prueba.hora_programada,
                descripcion: prueba.descripcion,
                fk_rh_trabajador: prueba.fk_rh_trabajador
            })
        });
        const response = await updatePruebaConclusion.json();
        setOpenEntregar(false);
        setOpenConcluir(false);
        ListarPruebaTrabajador(prueba.fk_rh_trabajador);
        setTextAlert('El trabajo fue concluido con exito');
        setTypeAlert('success');
        setAlertGeneralAnt(true);
        setTimeout(() => { setAlertGeneralAnt(false) }, 3000);
        ListarPruebaTrabajador(trabajador.id);
    }

    const handleCancelarEntregar = async () => {
        const fecha = new Date();
        const updatePrueba = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarPrueba.php`, {
            method: 'POST',
            body: JSON.stringify({
                fk_ven_orden: prueba.fk_ven_orden,
                linea: prueba.linea,
                fecha_registro: prueba.fecha_registro,
                hora_registro: prueba.hora_registro,
                fecha_entrega: null,
                hora_entrega: null,
                fecha_conclusion: prueba.fecha_conclusion,
                hora_conclusion: prueba.hora_conclusion,
                fecha_programada: prueba.fecha_programada,
                hora_programada: prueba.hora_programada,
                descripcion: prueba.descripcion,
                fk_rh_trabajador: prueba.fk_rh_trabajador
            })
        });
        const response = await updatePrueba.json();
        setOpenCancelar(false);
        ListarPruebaTrabajador(prueba.fk_rh_trabajador);
        setTextAlert('El trabajo fue cancelado exitosamente');
        setTypeAlert('success');
        setAlertGeneralAnt(true);
        setTimeout(() => { setAlertGeneralAnt(false) }, 3000);
    }
    const handleCancelarConclusion = async () => {
        const fecha = new Date();
        const updateConclusion = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarPrueba.php`, {
            method: 'POST',
            body: JSON.stringify({
                fk_ven_orden: prueba.fk_ven_orden,
                linea: prueba.linea,
                fecha_registro: prueba.fecha_registro,
                hora_registro: prueba.hora_registro,
                fecha_entrega: prueba.fecha_entrega,
                hora_entrega: prueba.hora_entrega,
                fecha_conclusion: null,
                hora_conclusion: null,
                fecha_programada: prueba.fecha_programada,
                hora_programada: prueba.hora_programada,
                descripcion: prueba.descripcion,
                fk_rh_trabajador: prueba.fk_rh_trabajador
            })
        });
        const response = await updateConclusion.json();
        setOpenCancelar(false);
        setOpenCancelarConcl(false);
        setOpenConcluir(false);
        ListarPruebaTrabajador(prueba.fk_rh_trabajador);
        setTextAlert('El trabajo fue cancelado exitosamente');
        setTypeAlert('success');
        setAlertGeneralAnt(true);
        setTimeout(() => { setAlertGeneralAnt(false) }, 3000);
    }

    React.useEffect(() => {
        getColor()
    }, []);
    const [color, setColor] = React.useState('');
    function getColor() {
        setColor('#' + ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'].sort(() => .5 - Math.random()).slice(0, 6).join(''))
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [page2, setPage2] = React.useState(0);
    const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
    const handleChangePage2 = (event, newPage) => {
        setPage2(newPage);
    };

    const handleChangeRowsPerPage2 = (event) => {
        setRowsPerPage2(parseInt(event.target.value, 10));
        setPage2(0);
    };

    //buscar por codigo de barra
    const cambiarBtnCodBarra = (e) => {
        var input = document.getElementById('searchCodBarra').value;
        if (input != "" && e.which == 13) {
            document.getElementById('btnSearchCodBarra').click();
        }
    }
    const searchPruebasXCodBarra = async () => {
        console.log(searchCodigoBarra.searchCodBarra.split("-"));
        var arrayCodigo = searchCodigoBarra.searchCodBarra.split("-");
        var fk_ven_orden = arrayCodigo[0];
        var linea = arrayCodigo[1];
        if (searchCodigoBarra.searchCodBarra !== "" && fk_ven_orden !== undefined && linea !== undefined) {
            const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrdenPrueba.php?fk_ven_orden=${fk_ven_orden}&linea=${linea}`, {
                method: 'GET'
            });
            const response = await getOrden.json();
            console.log(response);
            if (response.length > 0) {
                var idTrabajador = response[0].fk_rh_trabajador;
                ListarPruebaTrabajador(idTrabajador);
                const search = await fetch(`${UrlsConst.URL_API}/sistema/trabajador/buscarTrabajador.php`, {
                    method: "POST",
                    body: JSON.stringify(trabajadorSearch),
                });
                const responseTrab = await search.json();
                const trabajadorDB = responseTrab.filter(el => { return el.id === idTrabajador });
                setTrabajador(trabajadorDB[0]);
                setTrabajadores([]);
                if (trabajadorDB.length > 0) {
                    setPintarPruebaXcodigoBarra(true);
                    setAlertGeneral({ show: true, message: 'Datos encontrados con exito', severity: 'success', vertical: "top", horizontal: "center" });
                    setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning', vertical: "top", horizontal: "right" }) }, 3500);
                } else {
                    console.log("no se encontro datos");
                }
            } else {
                setAlertGeneral({ show: true, message: 'No se encontraron datos para el codigo', severity: 'warning', vertical: "top", horizontal: "center" });
                setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning', vertical: "top", horizontal: "right" }) }, 3500);
            }
        }
        if (linea === undefined) {
            setAlertGeneral({ show: true, message: 'Falta numero de trabajo', severity: 'warning', vertical: "top", horizontal: "center" });
            setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning', vertical: "top", horizontal: "right" }) }, 3500);
        }
    }
    return (
        <>
            <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignContent: 'center' }}>
                <Snackbar open={alertGeneralAnt} autoHideDuration={6000} onClose={() => setAlertGeneralAnt(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={handleClose} severity={typeAlert} sx={{ width: '100%' }}>
                        {textAlert}
                    </Alert>
                </Snackbar>
                <Snackbar open={alertGeneral.show} autoHideDuration={6000} onClose={() => setAlertGeneral({ show: false, message: "", severity: "warning", vertical: "top", horizontal: "right" })} anchorOrigin={{ vertical: alertGeneral.vertical, horizontal: alertGeneral.horizontal }}>
                    <Alert onClose={() => setAlertGeneral({ show: false, message: "", severity: "warning", vertical: "top", horizontal: "right" })} severity={alertGeneral.severity} sx={{ width: '100%' }}>
                        {alertGeneral.message}
                    </Alert>
                </Snackbar>
                <Grid item md={11.5} xs={11}>
                    <Paper elevation={1} style={{ padding: '1.5em' }}>
                        <Grid container sx={{ justifyContent: "space-between", pb: 2 }}>
                            <Grid item md={4} xs={12} sx={{ textAlign: { xs: 'center', md: 'start' } }}>
                                <Typography variant='h5'>TRABAJOS POR TRABAJADOR</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    id="searchCodBarra"
                                    fullWidth
                                    size='small'
                                    variant='outlined'
                                    label="BUSCAR POR CODIGO DE BARRA"
                                    name="searchCodBarra"
                                    value={searchCodigoBarra.searchCodBarra}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder="000000000000000000-0"
                                    onChange={
                                        (e) => {
                                            if (e.target.value !== "") {
                                                const { name, value } = e.target;
                                                setSearchCodigoBarra({ ...searchCodigoBarra, [name]: value.toUpperCase() });
                                            } else {
                                                setSearchCodigoBarra({ ...searchCodigoBarra, searchCodBarra: '' });
                                            }
                                        }
                                    }
                                    onKeyUp={cambiarBtnCodBarra}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={searchPruebasXCodBarra} id="btnSearchCodBarra">
                                                    <SearchIcon color="warning" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item md={4} xs={12} style={{ textAlign: 'center' }}>
                                <Button onClick={handleClickOpen} variant="contained" color="warning"><SearchIcon />Buscar TRABAJADOR</Button>
                            </Grid>
                        </Grid>
                        {trabajador.id === 0 &&
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant='h5' color="text.secondary" style={{ margin: 'auto' }}>No se realizo ninguna busqueda</Typography>
                            </div>
                        }
                        {
                            trabajador.id !== 0 &&
                            <Grid container spacing={2} style={{ justifyContent: 'center', marginTop: '12px' }}>
                                {
                                    trabajador.id !== 0 &&
                                    <Grid item md={6}>
                                        <Card style={{ textAlign: 'center' }}>
                                            <CardContent>
                                                <Grid container spacing={1}>
                                                    <Grid item md={6} xs={6}>
                                                        <Avatar sx={{ bgcolor: color, width: '60px', height: '60px', fontSize: '30px', margin: 'auto' }}>
                                                            {trabajador ? trabajador.nombres_apellidos.charAt(0) : 0}
                                                        </Avatar>
                                                        <Typography gutterBottom component="div" style={{ marginTop: '15px', fontSize: '18px' }}>
                                                            {trabajador ? trabajador.nombres_apellidos.toUpperCase() : 'Default'}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={6} xs={6} style={{ marginTop: '5%' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                                                            <BadgeRounded style={{ fontSize: '20px' }} />
                                                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{trabajador.numero_documento.toUpperCase()}</span>
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                                                            <School style={{ fontSize: '20px' }} />
                                                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{trabajador.cargo.toUpperCase()}</span>
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                                                            <Person style={{ fontSize: '20px' }} />
                                                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{trabajador.es_activo !== 0 ? 'ACTIVO' : 'INACTIVO'}</span>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                }
                                <Grid item md={12} xs={12}>
                                    <Paper elevation={2} style={{ padding: '10px' }}>
                                        <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}  >
                                            <Table aria-label="simple table" stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold' }}>N° ORDEN</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>SERVICIO</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>MATERIAL</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>F. REGISTRO</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>F. PROGRAMADA</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>ESTADO</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>CONCLUIR</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>ENTREGAR</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        (rowsPerPage2 > 0
                                                            ? pruebasTrabajador.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                                                            : pruebasTrabajador
                                                        ).map((prueba) => (
                                                            <TableRow
                                                                key={prueba.fecha_registro + " " + prueba.hora_registro}

                                                                sx={pintarPruebaXcodigoBarra && prueba.fk_ven_orden === searchCodigoBarra.searchCodBarra.split("-")[0] ?
                                                                    { backgroundColor: "#C2EAFF", '&:last-child td, &:last-child th': { border: 0 } } : { backgroundColor: "#fff" }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    {prueba.fk_ven_orden + '-' + prueba.linea}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">{prueba.servicio}</TableCell>
                                                                <TableCell component="th" scope="row">{prueba.material}</TableCell>
                                                                <TableCell component="th" scope="row">{prueba.descripcion}</TableCell>
                                                                <TableCell component="th" scope="row">{prueba.fecha_registro + ' ' + prueba.hora_registro}</TableCell>
                                                                <TableCell component="th" scope="row">{prueba.fecha_programada + ' ' + prueba.hora_programada}</TableCell>

                                                                <TableCell component="th" scope="row">
                                                                    {
                                                                        prueba.fecha_registro != null && prueba.fecha_conclusion === null && prueba.fecha_entrega === null &&
                                                                        <DemoProgresBar value={33} />
                                                                    }
                                                                    {
                                                                        prueba.fecha_registro != null && prueba.fecha_conclusion !== null && prueba.fecha_entrega === null &&
                                                                        <DemoProgresBar value={70} />
                                                                    }
                                                                    {
                                                                        prueba.fecha_registro != null && prueba.fecha_conclusion != null && prueba.fecha_entrega != null &&
                                                                        <DemoProgresBar value={100} />
                                                                    }
                                                                </TableCell >
                                                                <TableCell component="th" scope="row">
                                                                    {
                                                                        prueba.fecha_conclusion !== null ?
                                                                            <Chip color="success" style={{ borderRadius: '20px', width: '100px  ' }} size="small" onClick={() => {
                                                                                if (prueba.fecha_entrega === null) {
                                                                                    // if (true) {
                                                                                    setPrueba(prueba); setOpenCancelarConcl(true);
                                                                                } else {
                                                                                    setTextAlert('Cancelar la entrega primero');
                                                                                    setTypeAlert('warning');
                                                                                    setAlertGeneralAnt(true);
                                                                                    setTimeout(() => { setAlertGeneralAnt(false) }, 4000);
                                                                                }
                                                                            }} variant='contained' label="CONCLUIDO" />
                                                                            :
                                                                            <Chip color="error" style={{ borderRadius: '20px', width: '80px' }} size="small" onClick={() => { setPrueba(prueba); setOpenConcluir(true); }} variant='contained' label="SIN CONCLUIR" />
                                                                    }
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {
                                                                        prueba.fecha_entrega !== null ?
                                                                            <Chip color="success" style={{ borderRadius: '20px', width: '100px  ' }} size="small" onClick={() => {
                                                                                setPrueba(prueba); setOpenCancelar(true);
                                                                            }} variant='contained' label="ENTREGADO" />
                                                                            :
                                                                            <Chip color="error" style={{ borderRadius: '20px', width: '80px' }} size="small" onClick={() => {
                                                                                if (prueba.fecha_conclusion !== null) {
                                                                                    setPrueba(prueba); setOpenEntregar(true);
                                                                                } else {
                                                                                    setTextAlert('El trabajo no puede ser entregado, falta concluir');
                                                                                    setTypeAlert('warning');
                                                                                    setAlertGeneralAnt(true);
                                                                                    setTimeout(() => { setAlertGeneralAnt(false) }, 4000);
                                                                                }
                                                                            }} variant='contained' label="SIN ENTREGAR" />
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 50]}
                                            component="div"
                                            labelRowsPerPage="Filas por columnas"
                                            count={trabajadores.length}
                                            rowsPerPage={rowsPerPage2}
                                            page={page2}
                                            onPageChange={handleChangePage2}
                                            onRowsPerPageChange={handleChangeRowsPerPage2}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        }
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={openSearch} onClose={handleClose} fullWidth={true} maxWidth="md">
                <DialogTitle>
                    BUSCAR TRABAJADOR
                    <Button sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "#000",
                    }} startIcon={<CloseIcon />} onClick={handleClose} ></Button>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item md={5} xs={12}>
                            <TextField
                                fullWidth
                                variant='standard'
                                label="NOMBRES"
                                name='apellidos_nombres'
                                margin='normal'
                                onChange={handleTrabajador}
                                value={trabajadorSearch.apellidos_nombres}
                                onKeyUp={cambiarBtn}
                            />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <TextField
                                fullWidth
                                variant='standard'
                                label="DOCUMENTO"
                                name='numero_documento'
                                margin='normal'
                                onChange={handleTrabajador}
                                value={trabajadorSearch.numero_documento}
                                onKeyUp={cambiarBtn}
                            />
                        </Grid>
                        <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
                            <Button onClick={searchPruebaTrabajador} style={{ marginTop: '25px', textAlign: 'center' }} variant="contained" color="warning" startIcon={<SearchIcon />}>
                                BUSCAR
                            </Button>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Paper>
                                <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 300 }}  >
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>N°</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>NOMBRES Y APELLIDOS</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>DOCUMENTO</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>CARGO</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                (rowsPerPage > 0
                                                    ? trabajadores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : trabajadores
                                                ).map((trabajador) => (
                                                    <TableRow onClick={() => uploadTrabajador(trabajador)}
                                                        key={trabajador.id}
                                                        style={{ cursor: 'pointer' }}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{trabajador.id}</TableCell>
                                                        <TableCell>{trabajador.nombres_apellidos}</TableCell>
                                                        <TableCell>{trabajador.numero_documento}</TableCell>
                                                        <TableCell>{trabajador.cargo}</TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component="div"
                                    labelRowsPerPage="Filas por columnas"
                                    count={trabajadores.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog open={openEntregar} onClose={handleClose} maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant='h6'>Entregar Trabajo</Typography>
                    ¿Esta seguro de entregar el trabajo?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} variant='contained' color="success" onClick={handleEntregar}>Aceptar</Button>
                    <Button startIcon={<CloseIcon />} variant='contained' color="error" onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openConcluir} onClose={handleClose} maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant='h6'>Concluir Trabajo</Typography>
                    ¿Esta seguro de concluir el trabajo?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} variant='contained' color="success" onClick={handleConcluir}>Aceptar</Button>
                    <Button startIcon={<CloseIcon />} variant='contained' color="error" onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openCancelar} onClose={handleClose} maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant='h6'>Cancelar entrega</Typography>
                    ¿Esta seguro de cancelar la entrega de trabajo?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} variant='contained' color="success" onClick={handleCancelarEntregar}>Aceptar</Button>
                    <Button startIcon={<CloseIcon />} variant='contained' color="error" onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openCancelarCocl} onClose={handleClose} maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant='h6'>Cancelar la conclusion del trabajo</Typography>
                    ¿Esta seguro de cancelar?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} variant='contained' color="success" onClick={handleCancelarConclusion}>Aceptar</Button>
                    <Button startIcon={<CloseIcon />} variant='contained' color="error" onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}