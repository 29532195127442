import * as React from 'react'
import { Button, Container, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, Badge, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Collapse, Alert, Tooltip, InputAdornment, Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Datatable from '../../components/datatable';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UrlsConst from '../../config/config';
import DialogTrabajadores from './Dialogs/dialogTrabajadores';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SearchIcon from '@mui/icons-material/Search';


export default function Trabajador() {
  // constantes 
  const [trabajadores, setTrabajadores] = React.useState([{ id: 0, numero_documento: '', nombres_apellidos: '', es_activo: '', cargo: '', fk_con_empresa: '' }]);
  const [trabajadoresD, setTrabajadoresD] = React.useState([{ id: 0, numero_documento: '', nombres_apellidos: '', es_activo: '', cargo: '', fk_con_empresa: '' }]);
  const [openNewTrabajador, setOpenNewTrabajador] = React.useState(false);
  const [openEditTrabajador, setOpenEditTrabajador] = React.useState(false);
  const [trabajadorId, setTrabajadorId] = React.useState(0);
  const [openDeleteTrabajador, setOpenDeleteTrabajador] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState('');
  const [alertSuccess, setAlertSuccess] = React.useState(false);
  const [alertError, setAlertError] = React.useState(false);

  React.useEffect(() => {
    trabajadoresList();
  }, []);
  const trabajadoresList = async () => {
    const trabajadores = await fetch(`${UrlsConst.URL_API}/sistema/trabajador/listarTrabajador.php`, {
      method: "POST"
    });
    const response = await trabajadores.json();
    setTrabajadores(response);
  }
  const handleOpenNewTrabajador = () => {
    setOpenNewTrabajador(true);
  }
  const handleClose = () => {
    setOpenNewTrabajador(false);
    setOpenEditTrabajador(false);
    setOpenDeleteTrabajador(false);
  }
  const deleteTrabajador = async () => {
    const eliminar_trabajador = await fetch(`${UrlsConst.URL_API}/sistema/trabajador/eliminarTrabajador.php?id=` + trabajadorId, {
      method: 'POST'
    });
    const response = await eliminar_trabajador.json();
    setAlertGeneral({ ...alertGeneral, type: 'success', text: 'Trabajador eliminado correctamente', open: true });
    setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
    trabajadoresList();
    // if (response.executed != false) {
    //   setTextAlert('Completado ' + response.message);
    //   setAlertSuccess(true);
    //   setTimeout(() => { setAlertSuccess(false) }, 2500);
    // } else {
    //   setTextAlert('¡Error! ' + response.message);
    //   setAlertError(true);
    //   setTimeout(() => { setAlertError(false) }, 2500);
    // }
    setOpenDeleteTrabajador(false);
  }
  const [search, setSearch] = React.useState({
    string: ''
  });
  const cambiarBtn = (e) => {
    if (e.which == 13) {
      searchTrabajador();
    }
  }
  const searchTrabajador = async () => {
    if (search.string !== "") {
      const trabajadores = await fetch(`${UrlsConst.URL_API}/sistema/trabajador/listarTrabajador.php`, {
        method: "POST"
      });
      const response = await trabajadores.json();
      const filtrar = search.string;
      const buscado = response.filter((trabajador) => trabajador.numero_documento.includes(filtrar) || trabajador.nombres_apellidos.includes(filtrar) || trabajador.cargo.includes(filtrar));
      setTrabajadores(buscado);
    }
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [alertGeneral, setAlertGeneral] = React.useState({
    open: false,
    type: 'success',
    text: ''
  });
  return (
    <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignContent: 'center' }}>
      <Snackbar open={alertGeneral.open} autoHideDuration={6000} onClose={() => setAlertGeneral({ ...alertGeneral, opne: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setAlertGeneral({ ...alertGeneral, opne: false })} severity={alertGeneral.type} sx={{ width: '100%' }}>
          {alertGeneral.text}
        </Alert>
      </Snackbar>
      <Grid item md={11} xs={11} style={{ justifyContent: 'center' }}>
        <Paper elevation={1} style={{ padding: '1.5em' }}>
          <Grid container style={{ padding: '8px', justifyContent: 'space-between', textAlign: 'center' }}>
            <Typography variant='h5'>TRABAJADORES</Typography>
            <Button onClick={handleOpenNewTrabajador} startIcon={<AddIcon />} variant="contained" color="success">Nuevo</Button>
          </Grid>
          <Collapse in={alertSuccess}>
            <Alert variant="outlined"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertSuccess(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {textAlert}
            </Alert>
          </Collapse>
          <Collapse in={alertError}>
            <Alert variant="outlined" severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {textAlert}
            </Alert>
          </Collapse>
          <div style={{ justifyContent: 'center' }}>
            <Grid item md={6} xs={12}>
              <TextField
                id="search"
                name="string"
                fullWidth label="BUSCAR"
                margin='normal' variant="standard"
                value={search.string}
                onChange={
                  (e) => {
                    if (e.target.value != "") {
                      const { name, value } = e.target;
                      setSearch({ ...search, [name]: value.toUpperCase() });
                    } else {
                      setSearch({ ...search, string: '' });
                      trabajadoresList();
                    }
                  }
                }
                onKeyUp={cambiarBtn}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={searchTrabajador}>
                        <SearchIcon color="warning" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </div>
          <div style={{ marginTop: '20px' }}>
            <Paper>
              <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}>
                <Table aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '5%', fontWeight: 'bold' }}>N°</TableCell>
                      <TableCell style={{ width: '35%', fontWeight: 'bold' }}>NOMBRE Y APELLIDOS</TableCell>
                      <TableCell style={{ width: '15%', fontWeight: 'bold' }}>N° DOCUMENTO</TableCell>
                      <TableCell style={{ width: '25%', fontWeight: 'bold' }}>CARGO</TableCell>
                      <TableCell style={{ width: '10%', fontWeight: 'bold' }}>ESTADO</TableCell>
                      <TableCell style={{ width: '20%', fontWeight: 'bold' }}>ACCIONES</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      (rowsPerPage > 0
                        ? trabajadores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : trabajadores
                      ).map((trabajador) => (
                        <TableRow
                          key={trabajador.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {trabajador.id}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {trabajador.nombres_apellidos}
                          </TableCell>
                          <TableCell>{trabajador.numero_documento}</TableCell>
                          <TableCell>{trabajador.cargo}</TableCell>
                          <TableCell>{
                            trabajador.es_activo != 0 ?
                              <div style={{ background: '#10ad47', width: '100%', textAlign: 'center', borderRadius: '8px', color: '#fff', fontSize: '13px' }}>ACTIVO</div>
                              :
                              <div style={{ background: '#b81907', width: '100%', textAlign: 'center', borderRadius: '8px', color: '#fff', fontSize: '13px' }}>INACTIVO</div>
                          }</TableCell>
                          <TableCell>
                            <Tooltip title="EDITAR TRABAJADOR">
                              <IconButton onClick={() => {
                                setOpenEditTrabajador(true);
                                setTrabajadorId(trabajador.id);
                              }} size="small">
                                <EditIcon color="primary" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="ELIMINAR TRABAJADOR">
                              <IconButton onClick={() => {
                                setOpenDeleteTrabajador(true);
                                setTrabajadorId(trabajador.id);
                              }} aria-label="delete" size="small">
                                <DeleteIcon color="error" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                labelRowsPerPage="Filas por columnas"
                count={trabajadores.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </Paper>
      </Grid>
      <Dialog open={openNewTrabajador} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>NUEVO TRABAJADOR</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogTrabajadores
            fntTrabajadores={trabajadoresList}
            fntCloseModal={handleClose}
            alertGeneral={alertGeneral}
            setAlertGeneral={setAlertGeneral}
          />
          <Button startIcon={<CloseIcon />} variant="outlined" color='error' style={{ marginLeft: '6px' }} onClick={handleClose}>Cancelar</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openEditTrabajador} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>EDITAR TRABAJADOR</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogTrabajadores
            fntTrabajadores={trabajadoresList}
            fntCloseModal={handleClose}
            fntIdTrabajador={trabajadorId}
            alertGeneral={alertGeneral}
            setAlertGeneral={setAlertGeneral}
          />
          <Button startIcon={<CloseIcon />} variant='outlined' color='error' style={{ marginLeft: '6px' }} onClick={handleClose}>Cancelar</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openDeleteTrabajador} onClose={handleClose}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <div>
            <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
          </div>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant="h5">Eliminar Trabajador</Typography>
          ¿Esta seguro de eliminar el trabajador?
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="contained" color="error" onClick={deleteTrabajador}>Aceptar</Button>
          <Button variant="contained" onClick={handleClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
