import React from 'react';
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
// import Logo from '../../../img/logoReporte.png';
import Logo from '../../../img/logo-guzman.png';
import UrlsConst from '../../../config/config';

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: 'Oswald'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 10,
        marginHorizontal: 50,
        width: '45px',
        height: '45px'
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 40,
        textAlign: 'right',
        color: 'grey',
    },
});

export default function ExportPdf(props) {
    const estilosTablaPdf = { padding: "4px 0", bgHeader: "#f57c00" }
    const totalCajaChica = props.totalCajaChica;
    const datosEmpresa = props.datosEmpresa;
    const ordenesPagadas = props.ordenesPagadas;
    const cajaRecuperada = props.cajaRecuperada;
    let total_deposito = 0;
    let total_tarjeta = 0;
    let total_efectivo = 0;
    let total_yape = 0;

    let deposito = ordenesPagadas.filter((item) => item.tipo === "D");
    let tarjeta = ordenesPagadas.filter((item) => item.tipo === "T");
    let efectivo = ordenesPagadas.filter((item) => item.tipo === "E");
    let yape = ordenesPagadas.filter((item) => item.tipo === "Y");

    deposito.length > 0 ? deposito.forEach((pago) => total_deposito += Number(pago.monto)) : total_deposito = 0;
    tarjeta.length > 0 ? tarjeta.forEach((pago) => total_tarjeta += Number(pago.monto)) : total_tarjeta = 0;
    efectivo.length > 0 ? efectivo.forEach((pago) => total_efectivo += Number(pago.monto)) : total_efectivo = 0;
    yape.length > 0 ? yape.forEach((pago) => total_yape += Number(pago.monto)) : total_yape = 0;
    let saldo = Number(total_efectivo) - totalCajaChica;
    return (
        <Document>
            <Page style={styles.body} orientation="landscape">
                <View style={{ display: 'fixed', flexDirection: 'row' }} fixed>
                    <View style={{ display: 1 }}>
                        <Image
                            style={{ width: '55px', height: '50px' }}
                            src={Logo}
                        />
                    </View>
                    <View style={{ display: 2, marginHorizontal: '15px', width: '45%' }} >
                        <Text style={{ fontSize: '17px' }}>{datosEmpresa.razon_social}</Text>
                        <Text style={{ fontSize: '11px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                        <Text style={{ fontSize: '11px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text>
                    </View>
                    <View style={{ display: 3 }}>
                        <Text style={{ fontSize: '17px' }}>REPORTE COBRANZA</Text>
                        <Text style={{ fontSize: '11px', marginTop: '5px', marginHorizontal: '45px' }}>Fecha: {cajaRecuperada.fecha_apertura}</Text>
                    </View>
                </View>
                <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '12px' }}>DETALLE DE CAJA</Text>
                <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: estilosTablaPdf.bgHeader, padding: '2px' }} fixed>
                    <View style={{ display: 1, width: '10px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}></Text>
                    </View>
                    <View style={{ display: 2, width: '250px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>PROFESIONAL</Text>
                    </View>
                    <View style={{ display: 3, width: '250px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>ORDEN</Text>
                    </View>
                    <View style={{ display: 4, width: '180px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>SERVICIO</Text>
                    </View>
                    <View style={{ display: 5, width: '150px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px', textAlign: 'right' }}>PRESUPUESTO</Text>
                    </View>
                    <View style={{ display: 6, width: '80px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>IGV</Text>
                    </View>
                    <View style={{ display: 7, width: '120px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>PAGADO</Text>
                    </View>
                    <View style={{ display: 8, width: '120px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px', textAlign: 'center' }}>SALDO</Text>
                    </View>
                    <View style={{ display: 9, width: '180px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px', textAlign: "center" }}>REFERENCIA</Text>
                    </View>
                    <View style={{ display: 10, width: '150px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>ESTADO</Text>
                    </View>
                    <View style={{ display: 11, width: '50px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>TIPO</Text>
                    </View>
                </View>
                {
                    ordenesPagadas !== undefined &&
                    ordenesPagadas.map((pago) => (
                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }} key={pago.id}>
                            <View style={{ display: 1, width: '10px', margin: '2px' }}>
                                <Text style={{ fontSize: '8px' }}></Text>
                            </View>
                            <View style={{ display: 2, width: '250px', margin: '2px' }}>
                                <Text style={{ fontSize: '8px' }}>{pago.profesional}</Text>
                            </View>
                            <View style={{ display: 3, width: '250px', margin: '2px' }}>
                                <Text style={{ fontSize: '8px' }}>{pago.descOrden}</Text>
                            </View>
                            <View style={{ display: 4, width: '180px', margin: '2px' }}>
                                <Text style={{ fontSize: '8px' }}>{pago.servicio}</Text>
                            </View>
                            <View style={{ display: 5, width: '150px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px', textAlign: 'right' }}>S/ {pago.presupuesto}</Text>
                            </View>
                            <View style={{ display: 6, width: '80px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px', textAlign: 'right' }}>S/ 0.00</Text>
                            </View>
                            <View style={{ display: 7, width: '120px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px', textAlign: 'right' }}>S/ {pago.monto}</Text>
                            </View>
                            <View style={{ display: 8, width: '120px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px', textAlign: 'right' }}>S/ {pago.presupuesto - pago.monto}</Text>
                            </View>
                            <View style={{ display: 9, width: '180px', margin: '2px' }}>
                                <Text style={{ fontSize: '8px', textAlign: 'center' }}>{pago.referencia}</Text>
                            </View>
                            <View style={{ display: 10, width: '150px', margin: '2px' }}>
                                {pago.estado_pago === "G" ? <Text style={{ fontSize: '8px' }}>PAGADO</Text> : pago.estado_pago === 'A' ? <Text style={{ fontSize: '8px' }}>A CUENTA</Text> : ''}
                            </View>
                            <View style={{ display: 11, width: '50px', margin: '2px' }}>
                                <Text style={{ fontSize: '8px', textAlign: 'center' }}>{pago.tipo}</Text>
                            </View>
                            <br />
                        </View>
                    ))
                }
                <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: estilosTablaPdf.bgHeader, padding: "4px 0" }}>
                    <View style={{ display: 1, width: '10px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <View style={{ display: 2, width: '180px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>TOTAL</Text>
                    </View>
                    <View style={{ display: 3, width: '500px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <View style={{ display: 4, width: '150px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {ordenesPagadas.reduce((total, item) => parseFloat(total) + parseFloat(item.presupuesto), 0).toFixed(2)}</Text>
                    </View>
                    <View style={{ display: 5, width: '80px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <View style={{ display: 6, width: '120px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {ordenesPagadas.reduce((total, item) => parseFloat(total) + parseFloat(item.monto), 0).toFixed(2)}</Text>
                    </View>
                    <View style={{ display: 7, width: '120px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {ordenesPagadas.reduce((total, item) => parseFloat(total) + parseFloat(item.presupuesto) - parseFloat(item.monto), 0).toFixed(2)}</Text>
                    </View>
                    <View style={{ display: 8, width: '380px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}></Text>
                    </View>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }} fixed>
                    <View style={{ width: '20%', backgroundColor: estilosTablaPdf.bgHeader, color: '#fff', padding: '6px', textAlign: 'center', justifyContent: 'center' }}>
                        <View style={{ display: 1, width: '60%', textAlign: 'center' }}>
                            <Text style={{ fontSize: '8px' }}>TIPO DE PAGOS</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', width: '80%', padding: '6px', border: '1px solid #000' }}>
                        <View style={{ display: 2, width: '25%', textAlign: 'center' }}>
                            <Text style={{ fontSize: '8px' }}>D = DEPOSITO</Text>
                        </View>
                        <View style={{ display: 3, width: '25%', textAlign: 'center' }}>
                            <Text style={{ fontSize: '8px' }}>T = TARJETA</Text>
                        </View>
                        <View style={{ display: 4, width: '25%', textAlign: 'center' }}>
                            <Text style={{ fontSize: '8px' }}>E = EFECTIVO</Text>
                        </View>
                        <View style={{ display: 5, width: '25%', textAlign: 'center' }}>
                            <Text style={{ fontSize: '8px' }}>Y = YAPE</Text>
                        </View>
                        <br />
                    </View>
                </View>
                <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
                <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `Pag ${pageNumber}`
                )} fixed />
            </Page>
            <Page style={styles.body} orientation="landscape">
                <View style={{ display: 'fixed', flexDirection: 'row' }} fixed>
                    <View style={{ display: 1 }}>
                        <Image
                            style={{ width: '55px', height: '50px' }}
                            src={Logo}
                        />
                    </View>
                    <View style={{ display: 2, marginHorizontal: '15px', width: '45%' }} >
                        <Text style={{ fontSize: '17px' }}>{datosEmpresa.razon_social}</Text>
                        <Text style={{ fontSize: '11px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                        <Text style={{ fontSize: '11px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text>
                    </View>
                    <View style={{ display: 3 }}>
                        <Text style={{ fontSize: '17px' }}>REPORTE COBRANZA</Text>
                        <Text style={{ fontSize: '11px', marginTop: '5px', marginHorizontal: '45px' }}>Fecha: {cajaRecuperada.fecha_apertura}</Text>
                    </View>
                </View>
                {/* TABLA  RESUMEN DE PAGOS */}
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                    <div style={{ width: '250px', marginHorizontal: '85px' }}>
                        <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: estilosTablaPdf.bgHeader }} fixed>
                            <View style={{ display: 1, width: '100%', margin: '2px', textAlign: 'center' }}>
                                <Text style={{ fontSize: '8px' }}>RESUMEN DE CAJA</Text>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }} fixed>
                            <View style={{ display: 1, width: '250px', margin: '5px' }}>
                                <Text style={{ fontSize: '8px' }}>TOTAL DEPOSITO</Text>
                            </View>
                            <View style={{ display: 2, width: '250px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px' }}>s/. {total_deposito}.00</Text>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }} fixed>
                            <View style={{ display: 1, width: '250px', margin: '5px' }}>
                                <Text style={{ fontSize: '8px' }}>TOTAL TARJETA</Text>
                            </View>
                            <View style={{ display: 2, width: '250px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px' }}>s/. {total_tarjeta}.00</Text>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }} fixed>
                            <View style={{ display: 1, width: '250px', margin: '5px' }}>
                                <Text style={{ fontSize: '8px' }}>TOTAL EFECTIVO</Text>
                            </View>
                            <View style={{ display: 2, width: '250px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px' }}>s/. {total_efectivo}.00</Text>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }} fixed>
                            <View style={{ display: 1, width: '250px', margin: '5px' }}>
                                <Text style={{ fontSize: '8px' }}>TOTAL YAPE</Text>
                            </View>
                            <View style={{ display: 2, width: '250px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px' }}>s/. {total_yape}.00</Text>
                            </View>
                        </View>
                    </div>
                    <div style={{ width: '250px', marginLeft: '20px' }}>
                        <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: estilosTablaPdf.bgHeader }} fixed>
                            <View style={{ display: 1, width: '100%', margin: '2px', textAlign: 'center' }}>
                                <Text style={{ fontSize: '8px' }}>RESUMEN DE EFECTIVO</Text>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }} fixed>
                            <View style={{ display: 1, width: '250px', margin: '5px' }}>
                                <Text style={{ fontSize: '8px' }}>EFECTIVO COBRADO</Text>
                            </View>
                            <View style={{ display: 2, width: '250px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px' }}>s/. {total_efectivo}.00</Text>
                            </View>
                        </View>
                        {props.totalRegistro < 0 &&
                            <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }} fixed>
                                <View style={{ display: 1, width: '250px', margin: '5px' }}>
                                    <Text style={{ fontSize: '8px' }}>REPOSICION</Text>
                                </View>
                                <View style={{ display: 2, width: '250px', margin: '2px', textAlign: 'right' }}>
                                    <Text style={{ fontSize: '8px' }}>s/. {props.totalRegistro}.00</Text>
                                </View>
                            </View>
                        }
                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }} fixed>
                            <View style={{ display: 1, width: '250px', margin: '5px' }}>
                                <Text style={{ fontSize: '8px' }}>EFECTIVO A RECIBIR</Text>
                            </View>
                            <View style={{ display: 2, width: '250px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px' }}>s/. {saldo < 0 ? 0 : saldo}.00</Text>
                            </View>
                        </View>
                        {/* <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }} fixed>
                            <View style={{ display: 1, width: '250px', margin: '5px' }}>
                                <Text style={{ fontSize: '8px' }}>REPOSICION</Text>
                            </View>
                            <View style={{ display: 2, width: '250px', margin: '2px' }}>
                                <Text style={{ fontSize: '8px' }}>s/.50.00</Text>
                            </View>
                        </View> */}
                    </div>
                </div>
                <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
                <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `Pag ${pageNumber}`
                )} fixed />
            </Page>
        </Document >
    );
}