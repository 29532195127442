import * as React from 'react';
import { Grid, Typography, Paper, TextField, Button, Dialog, DialogContent, Collapse, Alert, IconButton, DialogTitle, Checkbox, Card, Avatar, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Snackbar } from '@mui/material';
// import { SnackbarProvider, useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import Dentadura from '../../components/dentadura';
import UrlsConst from '../../config/config';
import SearchIcon from '@mui/icons-material/Search';
import BadgeIcon from '@mui/icons-material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import CallIcon from '@mui/icons-material/Call';
import NewOdontograma from '../../components/newodontograma';

export default function Historial() {
    let pintarDetalle = [];
    const [dialogSearch, setDialogSearch] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [pacientes, setPacientes] = React.useState([]);
    const [detallePintar, setDetallePintar] = React.useState([]);
    const [pacienteOrden, setPacienteOrden] = React.useState([]);
    const [ordenes, setOrdenes] = React.useState([]);
    const [profesionales, setProfesionales] = React.useState([]);
    // const [checkOrden, setCheckOrden] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const [paciente, setPaciente] = React.useState({
        id: 0,
        apellidos_nombres: '',
        numero_documento: '',
        fecha_nacimiento: ''
    });
    const [pacienteSearch, setPacienteSearch] = React.useState({
        apellidos_nombres: '',
        numero_documento: '',
    });
    const [alertGeneral, setAlertGeneral] = React.useState({
        open: false,
        type: 'error',
        text: ''
    });
    const searchHistorial = () => {
        setDialogSearch(true);
    }
    const handleClose = () => {
        setDialogSearch(false);
        setPacientes([]);
    }
    const uploadPaciente = async (paciente) => {
        setPaciente(paciente);
        handleOrdenPaciente(paciente.id);
        setPacienteSearch({ ...pacienteSearch, numero_documento: '', apellidos_nombres: '' });
    }
    const handlePaciente = async (e) => {
        const { name, value } = e.target;
        if (value !== "") {
            setPacienteSearch({ ...pacienteSearch, [name]: value.toUpperCase() });
        } else {
            setPacienteSearch({ ...pacienteSearch, [name]: '' });
        }
    }
    const SearchPaciente = async () => {
        const search = await fetch(`${UrlsConst.URL_API}/ventas/paciente/buscarPaciente.php`, {
            method: "POST",
            body: JSON.stringify(pacienteSearch),
        });
        const response = await search.json();
        setPacientes(response);
    }
    const handleOrdenPaciente = async (id) => {
        const listarOrdenes = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenPaciente.php?id=` + id);
        const responseOrdenes = await listarOrdenes.json();
        const piezasPaciente = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenDetallePaciente.php?id=` + id);
        const responsePiezas = await piezasPaciente.json();
        setDetallePintar(responsePiezas.sort((a, b) => a.fecha_ingreso > b.fecha_ingreso));
        let codigOrden = [];
        responseOrdenes.forEach(async (o) => {
            codigOrden.push(o.id);
        });
        setSelected(codigOrden);
        setOrdenes(responseOrdenes);
        setPacientes([]);
        setPacienteOrden(responseOrdenes);
        if (responseOrdenes.length !== 0) {
            let profesionalA = [].concat(profesionales);
            responseOrdenes.forEach(item => {
                profesionalA.push(item.profesional);
            });
            setProfesionales(profesionalA);
            let profesionalU = [...new Set(profesionalA)]
            setProfesionales(profesionalU);
            setDialogSearch(false);
        } else {
            setAlert(true);
            setTimeout(() => { setAlert(false) }, 3000);
        }
    }
    const cambiarBtn = (e) => {
        if (e.which === 13) {
            SearchPaciente();
        }
    }
    React.useEffect(() => {
        setDetallePintar([]);
        getColor()
    }, []);
    const [color, setColor] = React.useState('');
    function getColor() {
        setColor('#' + ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'].sort(() => .5 - Math.random()).slice(0, 6).join(''))
    }
    // table1
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // table2
    const [page2, setPage2] = React.useState(0);
    const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
    const handleChangePage2 = (event, newPage) => {
        setPage2(newPage);
    };
    const handleChangeRowsPerPage2 = (event) => {
        setRowsPerPage2(parseInt(event.target.value, 10));
        setPage2(0);
    };

    const typogFuente = { fuente: "serift", size: 20, cardHeight: 140 };

    const handleSelectAllClick = async (event) => {
        if (event.target.checked === true) {
            const newSelected = ordenes.map((n) => n.id);
            const piezasPaciente = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenDetallePaciente.php?id=` + paciente.id);
            const responsePiezas = await piezasPaciente.json();
            setDetallePintar(responsePiezas.sort((a, b) => a.fecha_ingreso > b.fecha_ingreso));
            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
            setDetallePintar([]);
        }
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
        <Grid container style={{ minHeight: '100vh', alignContent: 'center', justifyContent: 'center' }}>
            <Snackbar open={alertGeneral.open} onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} severity={alertGeneral.type} sx={{ width: '100%' }}>
                    {alertGeneral.text}
                </Alert>
            </Snackbar>
            <Grid item md={11.5} xs={12} style={{ marginTop: window.screen.width < 500 ? '40px' : '0' }}>
                <Paper elevation={1} style={{ padding: '2em 0' }}>
                    <Grid container>
                        <Grid item md={2} xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant='h5'>HISTORIAL</Typography>
                        </Grid>
                        <Grid item md={8}></Grid>
                        <Grid item md={2} xs={12} style={{ textAlign: 'center' }}>
                            <Button startIcon={<SearchIcon />} variant="contained" color="warning" onClick={searchHistorial}>buscar Paciente</Button>
                        </Grid>
                    </Grid>
                    {
                        paciente.id === 0 &&
                        <div style={{ textAlign: 'center' }}>
                            <Typography variant='h5' color="text.secondary" style={{ margin: 'auto' }}>No se realizo ninguna busqueda</Typography>
                        </div>
                    }
                    {
                        paciente.id !== 0 &&
                        <Grid container spacing={1} style={{ padding: '0 20px' }}>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={2} sx={{ justifyContent: 'center', paddingTop: "1em" }}>
                                    <Grid item xl={8} md={8} sm={6} xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item md={12} xs={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item md={7} xs={12}>
                                                        <Card style={{ textAlign: 'center', minHeight: typogFuente.cardHeight }}>
                                                            <CardContent>
                                                                <Grid container spacing={2}>
                                                                    <Grid item md={6} xs={6}>
                                                                        <Avatar sx={{ bgcolor: color, width: '60px', height: '60px', fontSize: '40px', margin: 'auto' }}>
                                                                            {paciente ? paciente.apellidos_nombres.charAt(0) : 0}
                                                                        </Avatar>
                                                                        <Typography gutterBottom variant="h6" color="text.secondary" component="div" style={{ marginTop: '15px', fontSize: "15px" }}>
                                                                            {paciente ? paciente.apellidos_nombres : ''}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item md={6} xs={6}>
                                                                        <div style={{ padding: "5px 0", display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                                                                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>Datos del Paciente:</span>
                                                                        </div>
                                                                        <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                                                                            <BadgeIcon style={{ fontSize: '20px' }} />
                                                                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{paciente.numero_documento}</span>
                                                                        </div>
                                                                        <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                                                                            <CallIcon style={{ fontSize: '20px' }} />
                                                                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{987321456}</span>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={5} xs={12}>
                                                        <Card style={{ textAlign: 'center', minHeight: typogFuente.cardHeight }}>
                                                            <CardContent>
                                                                <Grid item md={12} xs={12}>
                                                                    <div style={{ padding: "5px 0", display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                                                                        <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>Profesionales:</span>
                                                                    </div>
                                                                    {profesionales.map((elem, index) => (
                                                                        <div key={index + "Prof"} style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                                                                            <SchoolIcon style={{ fontSize: '20px' }} />
                                                                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{elem}</span>
                                                                        </div>
                                                                    ))}
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Paper>
                                                    <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 355, }}  >
                                                        <Table sx={{ minWidth: 550 }} aria-label="simple table" stickyHeader>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell style={{ width: '5%' }}>
                                                                        <Checkbox
                                                                            indeterminate={selected.length > 0 && selected.length < pacienteOrden.length}
                                                                            checked={pacienteOrden.length > 0 && selected.length === pacienteOrden.length}
                                                                            onChange={handleSelectAllClick}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>N°</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>SERVICIO</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>PROFESIONAL</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>MATERIAL</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>F. INGRESO</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>F. ENTREGADA</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>COLOR</TableCell>
                                                                    <TableCell style={{ width: '15%', fontWeight: 'bold' }}>ESTADO</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    (rowsPerPage2 > 0
                                                                        ? pacienteOrden.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                                                                        : pacienteOrden
                                                                    ).map((orden) => {
                                                                        const isItemSelected = isSelected(orden.id);
                                                                        return (
                                                                            <TableRow
                                                                                key={orden.id}
                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            >
                                                                                <TableCell>
                                                                                    <Checkbox
                                                                                        checked={isItemSelected}
                                                                                        onChange={async (e) => {
                                                                                            const selectedIndex = selected.indexOf(orden.id);
                                                                                            let newSelected = [];
                                                                                            if (selectedIndex === -1) {
                                                                                                newSelected = newSelected.concat(selected, orden.id);
                                                                                            } else if (selectedIndex === 0) {
                                                                                                newSelected = newSelected.concat(selected.slice(1));
                                                                                            } else if (selectedIndex === selected.length - 1) {
                                                                                                newSelected = newSelected.concat(selected.slice(0, -1));
                                                                                            } else if (selectedIndex > 0) {
                                                                                                newSelected = newSelected.concat(
                                                                                                    selected.slice(0, selectedIndex),
                                                                                                    selected.slice(selectedIndex + 1)
                                                                                                );
                                                                                            }
                                                                                            setSelected(newSelected);
                                                                                            let detalle = [];
                                                                                            const filtrarOrden = detallePintar.filter(d => d.fk_ven_orden === orden.id);
                                                                                            if (filtrarOrden.length === 0) {
                                                                                                const piezasPaciente = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenDetallePaciente.php?id=` + paciente.id);
                                                                                                const responsePiezas = await piezasPaciente.json();
                                                                                                const agregarOrden = responsePiezas.filter(d => d.fk_ven_orden === orden.id);
                                                                                                detallePintar.forEach(d => {
                                                                                                    detalle.push(d);
                                                                                                });
                                                                                                agregarOrden.forEach(d => {
                                                                                                    detalle.push(d);
                                                                                                });
                                                                                                setDetallePintar(detalle.sort((a, b) => a.fecha_ingreso > b.fecha_ingreso));
                                                                                            } else {
                                                                                                const filtrarOrden = detallePintar.filter(d => d.fk_ven_orden !== orden.id);
                                                                                                filtrarOrden.forEach(d => {
                                                                                                    detalle.push(d);
                                                                                                });
                                                                                                setDetallePintar(detalle.sort((a, b) => a.fecha_ingreso > b.fecha_ingreso));
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell component="th" scope="row">
                                                                                    {orden.numero}
                                                                                </TableCell>
                                                                                <TableCell component="th" scope="row">
                                                                                    {orden.nombre}
                                                                                </TableCell>
                                                                                <TableCell>{orden.profesional}</TableCell>
                                                                                <TableCell>{orden.material}</TableCell>
                                                                                <TableCell>{orden.fecha_ingreso}</TableCell>
                                                                                <TableCell>{orden.fecha_entrega}</TableCell>
                                                                                <TableCell>
                                                                                    <div style={{ width: '80%', height: '20px', background: orden.color }}></div>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {orden.esta_entregado !== 0 ?
                                                                                        <div style={{ width: '100%', background: '#008040', textAlign: 'center', color: '#fff', fontSize: '12px', borderRadius: '50px', padding: "0 4px" }}>ENTREGADO</div> :
                                                                                        <div style={{ width: '100%', background: '#ff0000', textAlign: 'center', color: '#fff', fontSize: '12px', borderRadius: '50px', padding: "0 4px" }}>SIN ENTREGAR</div>
                                                                                    }
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <TablePagination
                                                        rowsPerPageOptions={[10, 25, 50]}
                                                        component="div"
                                                        labelRowsPerPage="Filas por columnas"
                                                        count={pacienteOrden.length}
                                                        rowsPerPage={rowsPerPage2}
                                                        page={page2}
                                                        onPageChange={handleChangePage2}
                                                        onRowsPerPageChange={handleChangeRowsPerPage2}
                                                    />
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xl={4} md={4} sm={6} xs={12}>
                                        <Paper>
                                            <NewOdontograma detallePintar={detallePintar} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Paper>
            </Grid>
            <Dialog open={dialogSearch} onClose={handleClose} fullWidth={true} maxWidth="md">
                <Button sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000",
                }} startIcon={<CloseIcon />} onClick={handleClose} ></Button>
                <DialogTitle>BUSCAR PACIENTE</DialogTitle>
                <DialogContent>
                    <Collapse in={alert}>
                        <Alert severity="info"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlert(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            Este paciente no cuenta con ordenes disponibles
                        </Alert>
                    </Collapse>
                    <Grid container spacing={2} >
                        <Grid item md={5} xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <TextField
                                    fullWidth
                                    margin='normal'
                                    variant='standard'
                                    label="NOMBRE"
                                    name="apellidos_nombres"
                                    onChange={handlePaciente}
                                    value={pacienteSearch.apellidos_nombres}
                                    onKeyUp={cambiarBtn}
                                />
                            </div>
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <TextField
                                fullWidth
                                margin='normal'
                                variant='standard'
                                label="DOCUMENTO"
                                name="numero_documento"
                                onChange={handlePaciente}
                                value={pacienteSearch.numero_documento}
                                onKeyUp={cambiarBtn}
                            />
                        </Grid>
                        <Grid item md={2} xs={12} style={{ marginTop: '25px', textAlign: 'center' }}>
                            <Button onClick={SearchPaciente} variant="contained" color="warning" startIcon={<SearchIcon />}>
                                BUSCAR
                            </Button>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Paper>
                                <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 300 }}  >
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>N°</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>PACIENTE</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>DOCUMENTO</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                (rowsPerPage > 0
                                                    ? pacientes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : pacientes
                                                ).map((paciente) => (
                                                    <TableRow onClick={() => { uploadPaciente(paciente); setDetallePintar([]) }
                                                    }
                                                        key={paciente.id}
                                                        style={{ cursor: 'pointer' }}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {paciente.id}
                                                        </TableCell>
                                                        <TableCell>{paciente.apellidos_nombres}</TableCell>
                                                        <TableCell>{paciente.numero_documento}</TableCell>
                                                    </TableRow>
                                                ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component="div"
                                    labelRowsPerPage="Filas por columnas"
                                    count={pacientes.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid >
    );
}