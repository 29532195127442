import React from 'react';
import { Button, Grid, IconButton, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Paper, InputAdornment, Tooltip, Collapse, Alert, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Snackbar } from '@mui/material';
import UrlsConst from '../../config/config';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ProfesionalPaciente from './dialogs/ProfesionalPaciente';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
export default function Profesional() {
    const [openDelete, setOpenDelete] = React.useState(false);
    const [alertGeneral, setAlertGeneral] = React.useState({
        open: false,
        type: 'success',
        text: ''
    });

    const handleClose = () => {
        setOpenDelete(false);
        setOpenNewProfesional(false);
        setOpenEditProfesional(false);
    }
    const [profesionalId, setProfesionalId] = React.useState(0);
    const [textAlert, setTextAlert] = React.useState('');
    const [typeAlert, setTypeAlert] = React.useState('success');
    const [alert, setAlert] = React.useState(false);
    const [openEditProfesional, setOpenEditProfesional] = React.useState(false);
    const [openNewProfesional, setOpenNewProfesional] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [search, setSearch] = React.useState({
        string_search: ''
    });
    const searchProfesional = async () => {
        if (search.string_search != "") {
            const profesionales = await fetch(`${UrlsConst.URL_API}/ventas/profesional/listarProfesional.php`, {
                method: "POST"
            });
            const response = await profesionales.json();
            const filtrar = search.string_search;
            const buscado = response.filter((profesional) => profesional.apellidos_nombres.includes(filtrar) || profesional.numero_documento.includes(filtrar) || profesional.direccion.includes(filtrar));
            setProfesionales(buscado);
        }
    }
    const cambiarBtn = (e) => {
        if (e.which == 13) {
            searchProfesional();
        }
    }
    const [profesionales, setProfesionales] = React.useState([{
        id: 0,
        colegiatura: '',
        apellidos_nombres: '',
        direccion: '',
        observaciones: '',
        numero_telefono: '',
        fk_com_tipo_documento: '00',
        numero_documento: ''
    }]);
    const listarProfesionales = async () => {
        const profesionales = await fetch(`${UrlsConst.URL_API}/ventas/profesional/listarProfesional.php`, {
            method: "POST"
        });
        const response = await profesionales.json();
        setProfesionales(response);
    }
    React.useEffect(() => {
        listarProfesionales();
    }, []);
    const handleClickOpen = () => {
        setOpenNewProfesional(true);
    }
    const deleteProfesional = async () => {
        const deletePacientesProfesional = await fetch(`${UrlsConst.URL_API}/ventas/profesional/eliminarTodosPacientes.php?id=` + profesionalId, {
            method: "POST",
        });
        const pacixProfDelete = await deletePacientesProfesional.json();
        const eliminar_profesional = await fetch(`${UrlsConst.URL_API}/ventas/profesional/eliminarProfesional.php?id=` + profesionalId, {
            method: "POST"
        });
        const response = await eliminar_profesional.json();
        setTextAlert('Se elimino correctamente el profesional');
        setTypeAlert('success');
        setAlertGeneral(true);
        setTimeout(() => { setAlertGeneral(false) }, 3000);
        listarProfesionales();
        setOpenDelete(false);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignContent: 'center' }}>
            <Snackbar open={alertGeneral.open} autoHideDuration={6000} onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity={alertGeneral.type} sx={{ width: '100%' }}>
                    {alertGeneral.text}
                </Alert>
            </Snackbar>
            <Grid item md={11.5} xs={11.5}>
                <Paper elevation={1} style={{ padding: '2.5em' }} >
                    <Grid container style={{ justifyContent: 'space-between', textAlign: 'center' }}>
                        <Typography variant='h5'>PROFESIONALES</Typography>
                        <Button startIcon={<AddIcon />} onClick={handleClickOpen} variant="contained" color="success" >Nuevo</Button>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item md={5} xs={12}>
                            <TextField
                                id="search"
                                name="string_search"
                                fullWidth label="BUSCAR"
                                margin='normal' variant="standard"
                                value={search.string_search}
                                onChange={
                                    (e) => {
                                        if (e.target.value != "") {
                                            const { name, value } = e.target;
                                            setSearch({ ...search, [name]: value.toUpperCase() });
                                        } else {
                                            setSearch({ ...search, string_search: '' });
                                            listarProfesionales();
                                        }
                                    }
                                }
                                onKeyUp={cambiarBtn}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={searchProfesional}>
                                                <SearchIcon color="warning" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                        </Grid>
                        <Grid item md={6}></Grid>
                        <Grid item md={1} xs={12} style={{ marginTop: '20px', textAlign: 'center' }}>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '20px' }}>
                        <Grid item md={12} xs={12}>
                            <Paper>
                                <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}>
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: '2%', fontWeight: 'bold' }}>N°</TableCell>
                                                <TableCell style={{ width: '30%', fontWeight: 'bold' }}>NOMBRES Y APELLIDOS</TableCell>
                                                <TableCell style={{ width: '15%', fontWeight: 'bold' }}>DOCUMENTO</TableCell>
                                                <TableCell style={{ width: '30%', fontWeight: 'bold' }}>TELEFONO</TableCell>
                                                <TableCell style={{ width: '30%', fontWeight: 'bold' }}>DIRECCION</TableCell>
                                                <TableCell style={{ width: '20%', fontWeight: 'bold' }}>COLEGIATURA</TableCell>
                                                <TableCell style={{ width: '35%', fontWeight: 'bold' }}>OBSERVACIONES</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                (rowsPerPage > 0
                                                    ? profesionales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : profesionales
                                                ).map((profesional) => (
                                                    <TableRow
                                                        key={profesional.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {profesional.id}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {profesional.apellidos_nombres}
                                                        </TableCell>
                                                        <TableCell>{profesional.numero_documento}</TableCell>
                                                        <TableCell>{profesional.numero_telefono}</TableCell>
                                                        <TableCell>{profesional.direccion}</TableCell>
                                                        <TableCell>{profesional.colegiatura}</TableCell>
                                                        <TableCell>{profesional.observaciones}</TableCell>
                                                        <TableCell>
                                                            <Tooltip title="EDITAR PROFESIONAL">
                                                                <IconButton aria-label="delete" onClick={
                                                                    () => {
                                                                        setOpenEditProfesional(true);
                                                                        setProfesionalId(profesional.id);
                                                                    }}
                                                                    size="small">
                                                                    <EditIcon color='primary' fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="ELIMINAR PROFESIONAL">
                                                                <IconButton aria-label="delete" onClick={
                                                                    () => {
                                                                        setOpenDelete(true);
                                                                        setProfesionalId(profesional.id);
                                                                    }} size="small">
                                                                    <DeleteIcon color='error' fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component="div"
                                    labelRowsPerPage="Filas por columnas"
                                    count={profesionales.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid >
            <Dialog open={openNewProfesional} onClose={handleClose} fullWidth={true} maxWidth="lg">
                <DialogTitle>NUEVO PROFESIONAL</DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <ProfesionalPaciente
                        fntClose={handleClose}
                        fntProfesionales={listarProfesionales}
                        setTextAlert={setTextAlert}
                        setTypeAlert={setTypeAlert}
                        setAlertGeneral={setAlertGeneral}
                    />
                    <Button style={{ marginLeft: '6px' }} startIcon={<CloseIcon />} variant="outlined" color="error" onClick={handleClose}>Cancelar</Button>
                </DialogContent>
            </Dialog>
            <Dialog open={openEditProfesional} onClose={handleClose} fullWidth={true} maxWidth="lg">
                <DialogTitle>EDITAR PROFESIONAL</DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <ProfesionalPaciente
                        fntIdprofesional={profesionalId}
                        fntClose={handleClose}
                        fntProfesionales={listarProfesionales}
                        setTextAlert={setTextAlert}
                        setTypeAlert={setTypeAlert}
                        setAlertGeneral={setAlertGeneral}
                    />
                    <Button style={{ marginLeft: '6px' }} startIcon={<CloseIcon />} variant="outlined" color="error" onClick={handleClose}>Cancelar</Button>
                </DialogContent>
            </Dialog>
            <Dialog open={openDelete} onClose={handleClose} maxWidth="md">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Eliminar Profesional</Typography>
                    ¿Esta seguro de eliminar el profesional?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button variant="contained" color="error" onClick={deleteProfesional}>Aceptar</Button>
                    <Button variant="contained" color="primary" onClick={handleClose}>CANCELAR</Button>
                </DialogActions>
            </Dialog>
        </Grid >
    );
}