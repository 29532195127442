import * as React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Button } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 17,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor: '#fff',
  },
}));

const theme = createTheme({
  palette: {
    neutral: {
      main: '#2b2b',
      contrastText: '#fff',
    },
  },
});
// const color = "";

export default function DemoProgresBar(props) {
  const validColor = props.value;
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, padding: "2em 1em", width: "60px" }}>
        <BorderLinearProgress
          color={validColor < 35 ? "error" : validColor >= 35 && validColor <= 99 ? "warning" : "neutral"} variant="determinate" value={validColor} />
      </Box>
    </ThemeProvider>
  );
}
