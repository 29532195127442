import * as React from 'react';
import { Container, Grid, Paper, Typography, Button, DialogContent, Dialog, DialogTitle, IconButton, DialogActions, Tooltip, TextField, InputAdornment, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Snackbar, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Datatable from '../../components/datatable';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UrlsConst from '../../config/config';
import DialogPerfiles from './Dialogs/dialogPerfiles';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SearchIcon from '@mui/icons-material/Search';

export default function Perfil() {
  const [alertGeneral, setAlertGeneral] = React.useState(false);
  const [typeAlert, setTypeAlert] = React.useState('success');
  const [textAlert, setTextAlert] = React.useState('');
  const [openNewPerfil, setOpenNewPerfil] = React.useState(false);
  const [openEditPerfil, setOpenEditPerfil] = React.useState(false);
  const [codigoPerfil, setCodigoPerfil] = React.useState('');
  const [openDeletePerfil, setOpenDeletePerfil] = React.useState(false);
  const [perfilId, setPerfilId] = React.useState('');
  const [perfiles, setPerfiles] = React.useState([{
    codigo: '',
    nombre: '',
    descripcion: '',
    es_activo: 0
  }]);

  const listarPerfiles = async () => {
    const perfiles = await fetch(`${UrlsConst.URL_API}/sistema/perfil/listarPerfil.php`, {
      method: "GET"
    });
    const response = await perfiles.json();
    setPerfiles(response);
  }
  React.useState(() => {
    listarPerfiles();
  }, []);
  const handleOpenNewPerfil = () => {
    setOpenNewPerfil(true);
  }
  const handleClose = () => {
    setOpenNewPerfil(false);
    setOpenEditPerfil(false);
    setOpenDeletePerfil(false);
  }
  const alertDialog = (text, tipo) => {
    setTextAlert(text);
    setTypeAlert(tipo);
    setAlertGeneral(true);
    setTimeout(() => { setAlertGeneral(false) }, 3000);
  }
  const deletePerfil = async () => {
    const delete_permisos = await fetch(`${UrlsConst.URL_API}/sistema/perfilDetalle/eliminarTodoPerfil.php?codigo=` + perfilId, {
      method: 'POST'
    });
    const response = await delete_permisos.json();
    const delete_perfil = await fetch(`${UrlsConst.URL_API}/sistema/perfil/eliminarPerfil.php?codigo=` + perfilId, {
      method: 'POST'
    });
    const response_delete = await delete_perfil.json();
    setOpenDeletePerfil(false);
    listarPerfiles();
  }
  const [search, setSearch] = React.useState({
    string: ''
  });
  const cambiarBtn = (e) => {
    if (e.which === 13) {
      searchPerfiles();
    }
  }
  const searchPerfiles = async (e) => {
    if (search.string !== "") {
      const perfiles = await fetch(`${UrlsConst.URL_API}/sistema/perfil/listarPerfil.php`, {
        method: "GET"
      });
      const response = await perfiles.json();
      const filtrar = search.string;
      const buscado = response.filter((perfil) => perfil.codigo.includes(filtrar) || perfil.nombre.includes(filtrar) || perfil.descripcion.includes(filtrar));
      setPerfiles(buscado);
    }
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Grid container style={{ height: '100vh', justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
      <Snackbar open={alertGeneral} autoHideDuration={6000} onClose={() => setAlertGeneral(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setAlertGeneral(false)} severity={typeAlert} sx={{ width: '100%' }}>
          {textAlert}
        </Alert>
      </Snackbar>
      <Grid item md={11.5} xs={11}>
        <Paper elevation={1} style={{ padding: '1.5em' }}>
          <Grid container style={{ padding: '8px', justifyContent: 'space-between', textAlign: 'center' }}>
            <Typography gutterBottom align="center" color="text.primary" sx={{ fontSize: 20 }}>
              PERFILES
            </Typography>
            <Button onClick={handleOpenNewPerfil} startIcon={<AddIcon />} variant="contained" color="success">Nuevo</Button>
          </Grid>
          <div style={{ justifyContent: 'center' }}>
            <Grid item md={6} xs={12}>
              <TextField
                id="search"
                name="string"
                fullWidth label="BUSCAR"
                margin='normal' variant="standard"
                value={search.string}
                onChange={
                  (e) => {
                    if (e.target.value !== "") {
                      const { name, value } = e.target;
                      setSearch({ ...search, [name]: value.toUpperCase() });
                    } else {
                      setSearch({ ...search, string: '' });
                      listarPerfiles();
                    }
                  }
                }
                onKeyUp={cambiarBtn}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={searchPerfiles} id="btnSearch">
                        <SearchIcon color="warning" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </div>
          <Grid container style={{ marginTop: '20px' }}>
            <Grid item md={12} xs={12}>
              <Paper>
                <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}>
                  <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '15%', fontWeight: 'bold' }}>CODIGO</TableCell>
                        <TableCell style={{ width: '30%', fontWeight: 'bold' }}>NOMBRE</TableCell>
                        <TableCell style={{ width: '35%', fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                        <TableCell style={{ width: '10%', fontWeight: 'bold' }}>ESTADO</TableCell>
                        <TableCell style={{ width: '20%', fontWeight: 'bold' }}>ACCIONES</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        (rowsPerPage > 0
                          ? perfiles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : perfiles
                        ).map((perfil) => (
                          <TableRow
                            key={perfil.codigo}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {perfil.codigo}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {perfil.nombre}
                            </TableCell>
                            <TableCell>{perfil.descripcion}</TableCell>
                            <TableCell>{
                              perfil.es_activo !== 0 ? <div style={{ background: '#10ad47', width: '100%', textAlign: 'center', borderRadius: '8px', color: '#fff', fontSize: '13px' }}>ACTIVO</div>
                                :
                                <div style={{ background: '#b81907', width: '100%', textAlign: 'center', borderRadius: '8px', color: '#fff', fontSize: '13px' }}>INACTIVO</div>
                            }</TableCell>
                            <TableCell>
                              <Tooltip title="EDITAR">
                                <IconButton onClick={() => {
                                  setOpenEditPerfil(true);
                                  setCodigoPerfil(perfil.codigo);
                                }} aria-label="delete" size="small">
                                  <EditIcon color="primary" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="ELIMINAR">
                                <IconButton onClick={() => {
                                  setPerfilId(perfil.codigo);
                                  setOpenDeletePerfil(true);
                                }} aria-label="delete" size="small">
                                  <DeleteIcon color="error" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  labelRowsPerPage="Filas por columnas"
                  count={perfiles.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Dialog open={openNewPerfil} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle>
          <Typography gutterBottom align="center" color="text.primary" sx={{ fontSize: 16 }}>
            NUEVO PERFIL
          </Typography>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogPerfiles fntPerfiles={listarPerfiles} fntClose={handleClose} alertDialog={alertDialog} />
          <Button style={{ marginLeft: '6px' }} startIcon={<CloseIcon />} variant="outlined" color="error" onClick={handleClose}>Cancelar</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openEditPerfil} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle>   <Typography gutterBottom align="center" color="text.primary" sx={{ fontSize: 16 }}>
          EDITAR PERFIL
        </Typography></DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogPerfiles fntPerfiles={listarPerfiles} fntClose={handleClose} fntCodigoPErfil={codigoPerfil} alertDialog={alertDialog} />
          <Button style={{ marginLeft: '6px' }} startIcon={<CloseIcon />} variant="outlined" color="error" onClick={handleClose}>Cancelar</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openDeletePerfil} onClose={handleClose}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <div>
            <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
          </div>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant="h5">Eliminar Perfil</Typography>
          ¿Esta seguro de eliminar el perfil?
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="contained" color="error" onClick={deletePerfil}>Aceptar</Button>
          <Button variant="contained" onClick={handleClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </Grid>

  )
}