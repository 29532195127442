import React, { useEffect } from 'react'
import { Page, Text, Image, Document, StyleSheet, View, Svg, Polygon, Rect, Circle } from "@react-pdf/renderer";
// import Logo from '../../img/logo.png';
import Logo from '../../img/logo-guzman.png';
import { height } from '@mui/system';
import { Await } from 'react-router-dom';
import UrlsConst from '../../config/config';
import iconChecked from './img/checked.jpg';
import iconUnChecked from './img/unchecked.jpg';
import checkedSvg from "../../components/exportTicket/img/checked.svg";
// import Barcode from 'react-barcode';
import JsBarcode from 'jsbarcode';
const styles = StyleSheet.create({
    svgContainer: {
        display: "inlineBlock",
        position: "relative",
        width: "100%",
        height: "100%",
        paddingTop: "5px",
        paddingLeft: "20px",
        paddingBottom: "5px",
        verticalAlign: "middle",
        overflow: "hidden",
    },
    body: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingHorizontal: 5,
        border: 0
    },
    body2: {
        paddingTop: 5,
        paddingBottom: 2,
        paddingHorizontal: 5,
        border: 0,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: 'Oswald'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 10,
        marginHorizontal: 50,
        width: '45px',
        height: '45px'
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 4,
        bottom: 5,
        left: 30,
        right: 5,
        textAlign: 'right',
        color: 'grey',
    },
});
export default function ExportTicket({ type = "", datosOrd = {}, datosOrdR = {}, datosTrab = {}, datosEmpresa = {}, profesional = {}, paciente = {} }) {
    const [dataImg, setDataImg] = React.useState("");
    const [idPiezas, setIdPiezas] = React.useState([]);
    const [trabajos, setTrabajos] = React.useState([]);
    const pintarDentadura = async () => {
        const getOrdenesDetalle = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenDetalle.php?id=${datosOrd.id}`, {
            method: 'GET'
        });
        const response = await getOrdenesDetalle.json();
        let newArr = []
        response.forEach((item) => {
            newArr.push(item.pieza);
        });
        setIdPiezas(newArr);
    };
    useEffect(() => {
        let canvas;
        canvas = document.createElement('canvas');
        if (type === "ticketOrd" || type === "ticketOrdAutomat") {
            JsBarcode(canvas, datosOrd.id);
        } else if (type === "ticketOrdResum" || type === "ticketOrdResumV2") {
            JsBarcode(canvas, datosOrd.id, { height: "28px", fontSize: 12, margin: 0 });
        } else if (type === "ticketTrab") {
            JsBarcode(canvas, (datosOrd.id + "-" + datosTrab.linea));
        }
        var barcode = canvas.toDataURL();

        setDataImg(barcode);
        pintarDentadura();
    }, [])
    const ExampleSvg = () => (
        <div style={styles.svgContainer}>
            <Svg version="1.1" viewBox="0 0 500 500"
                preserveAspectRatio="xMinYMin meet" class="svg-content">
                { /* parte superior izquierda */}
                <Circle id="18" cx="20" name='1' cy="205" r="15" fill={idPiezas.includes("18") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="17" cx="25" name="2" cy="170" r="15" fill={idPiezas.includes("17") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="16" cx="30" name="3" cy="135" r="15" fill={idPiezas.includes("16") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="15" cx="40" name="4" cy="100" r="15" fill={idPiezas.includes("15") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="14" cx="55" name="5" cy="70" r="15" fill={idPiezas.includes("14") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="13" cx="75" name="6" cy="45" r="15" fill={idPiezas.includes("13") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="12" cx="103" name="7" cy="30" r="15" fill={idPiezas.includes("12") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="11" cx="135" name='8' cy="20" r="15" fill={idPiezas.includes("11") ? "#000" : "#fff"} stroke='#000' />
                { /* parte superior derecha */}
                <Circle id="21" cx="180" name='9' cy="20" r="15" fill={idPiezas.includes("21") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="22" cx="215" name='10' cy="30" r="15" fill={idPiezas.includes("22") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="23" cx="245" name='11' cy="45" r="15" fill={idPiezas.includes("23") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="24" cx="270" name='12' cy="70" r="15" fill={idPiezas.includes("24") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="25" cx="285" name='13' cy="100" r="15" fill={idPiezas.includes("25") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="26" cx="300" name='14' cy="135" r="15" fill={idPiezas.includes("26") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="27" cx="307" name='15' cy="170" r="15" fill={idPiezas.includes("27") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="28" cx="313" name='16' cy="205" r="15" fill={idPiezas.includes("28") ? "#000" : "#fff"} stroke='#000' />
                { /* Parte abajo derecha */}
                <Circle id="31" cx="180" name='9' cy="450" r="15" fill={idPiezas.includes("31") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="32" cx="215" name='10' cy="445" r="15" fill={idPiezas.includes("32") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="33" cx="245" name='11' cy="425" r="15" fill={idPiezas.includes("33") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="34" cx="270" name='12' cy="395" r="15" fill={idPiezas.includes("34") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="35" cx="285" name="13" cy="365" r="15" fill={idPiezas.includes("35") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="36" cx="300" name="14" cy="330" r="15" fill={idPiezas.includes("36") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="37" cx="307" name="15" cy="295" r="15" fill={idPiezas.includes("37") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="38" cx="313 " name="16" cy="260" r="15" fill={idPiezas.includes("38") ? "#000" : "#fff"} stroke='#000' />
                { /* Parte abajo izquierda */}
                <Circle id="41" cx="135" name="8" cy="450" r="15" fill={idPiezas.includes("41") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="42" cx="103" name="7" cy="445" r="15" fill={idPiezas.includes("42") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="43" cx="75" name="6" cy="425" r="15" fill={idPiezas.includes("43") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="44" cx="55" name='5' cy="395" r="15" fill={idPiezas.includes("44") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="45" cx="40" name='4' cy="365" r="15" fill={idPiezas.includes("45") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="46" cx="30" name='3' cy="330" r="15" fill={idPiezas.includes("46") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="47" cx="25" name='2' cy="295" r="15" fill={idPiezas.includes("47") ? "#000" : "#fff"} stroke='#000' />
                <Circle id="48" cx="20" name='1' cy="260" r="15" fill={idPiezas.includes("48") ? "#000" : "#fff"} stroke='#000' />
            </Svg>
        </div>
    );
    return (
        <Document>
            {type === "ticketOrd" &&
                <Page size={{ width: 144 }} style={styles.body} orientation="portrait">
                    <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "3px" }} fixed>
                        <View style={{ display: 1, width: '20%' }}>
                            <Image
                                style={{ width: '20px', height: '20px' }}
                                src={Logo}
                            />
                        </View>
                        <View style={{ display: 2, marginHorizontal: '1px', width: '40%', textAlign: "left" }} >
                            <Text style={{ fontSize: '3.8px', paddingTop: 3 }}>{datosEmpresa.razon_social}</Text>
                            <Text style={{ fontSize: '3.8px', paddingTop: 1 }}>{datosEmpresa.ruc}</Text>
                            <Text style={{ fontSize: '3.8px', paddingTop: 1 }}>{datosEmpresa.direccion}</Text>
                        </View>
                        <View style={{ display: 3, marginHorizontal: '1px', width: '40%', textAlign: "right" }} >
                            <Text style={{ fontSize: '3.8px', paddingTop: 5 }}>N° {datosOrd.numero}</Text>
                        </View>
                    </View>
                    <View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px" }}>
                            <View style={{ display: 1, width: "100%" }}>
                                <Text style={{ fontSize: '4px' }}>DATOS:</Text>
                            </View>
                        </View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px" }}>
                            <View style={{ display: 1, width: "100%", backgroundColor: "#E5E5E5", borderRadius: "2px", padding: 3 }}>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Profesional: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{profesional.apellidos_nombres}</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Paciente: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosOrd.apellidos_nombres}</Text></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px" }}>
                            <View style={{ display: 1, width: "100%" }}>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '30%' }}><Text style={{ fontSize: '4px' }}>ORDEN DETALLE: </Text></View>
                                    <View style={{ display: 2, width: '15%' }}><Text style={{ fontSize: '4px', textAlign: "right" }}>Ingreso: </Text></View>
                                    <View style={{ display: 3, width: '20%' }}><Text style={{ fontSize: '4px', paddingLeft: "1px" }}>{datosOrd.fecha_ingreso}</Text></View>
                                    <View style={{ display: 4, width: '15%' }}><Text style={{ fontSize: '4px', textAlign: "right" }}>Entrega: </Text></View>
                                    <View style={{ display: 5, width: '20%' }}><Text style={{ fontSize: '4px', paddingLeft: "1px" }}>{datosOrd.fecha_entrega}</Text></View>
                                </View>
                            </View>
                        </View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px" }}>
                            <View style={{ display: 1, width: "100%", backgroundColor: "#E5E5E5", borderRadius: "2px", padding: 3 }}>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Servicio: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosOrd.nombre}</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Descripción: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosOrd.descripcion}</Text></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "1px" }}>
                        <View style={{ display: 1, width: "100%", textAlign: "center" }}>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 1, width: "100%", paddingHorizontal: 5, marginHorizontal: "10px" }}>
                                {datosOrdR.antagonista === 0 &&
                                    <View style={{ display: 1, width: '5px' }}><Image style={{ width: '7px', height: '9px', paddingTop: 3 }} src={iconUnChecked} /></View>}
                                {datosOrdR.antagonista === 1 &&
                                    <View style={{ display: 1, width: '5px' }}><Image style={{ width: '7px', height: '9px', paddingTop: 3 }} src={iconChecked} /></View>}
                                <View style={{ display: 1, }}><Text style={{ fontSize: '4px', paddingTop: 4, marginLeft: "2px", marginRight: "4px" }}>ANTAGONISTA: </Text></View>
                                {datosOrdR.cubeta === 0 &&
                                    <View style={{ display: 1, width: '5px' }}><Image style={{ width: '7px', height: '9px', paddingTop: 3 }} src={iconUnChecked} /></View>}
                                {datosOrdR.cubeta === 1 &&
                                    <View style={{ display: 1, width: '5px' }}><Image style={{ width: '7px', height: '9px', paddingTop: 3 }} src={iconChecked} /></View>}
                                <View style={{ display: 1, }}><Text style={{ fontSize: '4px', paddingTop: 4, marginLeft: "2px", marginRight: "4px" }}>CUBETA: </Text></View>
                                {datosOrdR.prueba_mordida === 0 &&
                                    <View style={{ display: 1, width: '5px' }}><Image style={{ width: '7px', height: '9px', paddingTop: 3 }} src={iconUnChecked} /></View>}
                                {datosOrdR.prueba_mordida === 1 &&
                                    <View style={{ display: 1, width: '5px' }}><Image style={{ width: '7px', height: '9px', paddingTop: 3 }} src={iconChecked} /></View>}
                                <View style={{ display: 1, }}><Text style={{ fontSize: '4px', paddingTop: 4, marginLeft: "2px", marginRight: "4px" }}>MORDIDA: </Text></View>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px", paddingTop: "2px" }}>
                            <View style={{ display: 1, width: "100%" }}>
                                <Text style={{ fontSize: '4px' }}>TRABAJOS:</Text>
                            </View>
                        </View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px" }}>
                            <View style={{ display: 1, width: "100%", backgroundColor: "#E5E5E5", borderRadius: "2px", padding: 3 }}>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1, marginHorizontal: 3 }}>
                                    <View style={{ display: 1, width: '5%' }}><Text style={{ fontSize: '3.3px' }}>N°</Text></View>
                                    <View style={{ display: 2, width: '35%' }}><Text style={{ fontSize: '3.3px' }}>TRABAJADOR</Text></View>
                                    <View style={{ display: 3, width: '35%' }}><Text style={{ fontSize: '3.3px' }}>DESCRIPCIÓN </Text></View>
                                    <View style={{ display: 4, width: '25%' }}><Text style={{ fontSize: '3.3px' }}>F.E. PROGRAMADA</Text></View>
                                </View>
                                {datosTrab.length > 0 &&
                                    datosTrab.map((m, index) => (
                                        <div key={index}>
                                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 1, marginHorizontal: 3 }}>
                                                <View style={{ display: 1, width: '5%' }}><Text style={{ fontSize: '3.3px', textAlign: "left" }}>{m.linea} </Text></View>
                                                <View style={{ display: 2, width: '35%' }}><Text style={{ fontSize: '3.3px' }}>{m.trabajador_asignado}</Text></View>
                                                <View style={{ display: 3, width: '35%' }}  ><Text style={{ fontSize: '3.3px' }}>{m.descripcion}</Text></View>
                                                <View style={{ display: 4, width: '25%' }}><Text style={{ fontSize: '3.3px' }}>{m.fecha_programada}</Text></View>
                                            </View>
                                        </div>))}
                            </View>
                        </View>
                    </View>
                    <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px", paddingTop: 4, textAlign: "right", justifyContent: "flex-end" }}>
                        <Text style={{ fontSize: '4px' }}>PRESUPUESTO: S/ {datosOrdR.presupuesto}</Text>
                    </View>
                    <View style={{ display: 'fixed', flexDirection: 'row' }}>
                        <View style={{ display: "flex", flexDirection: "row", paddingTop: 1, width: "100%", paddingHorizontal: 5, marginHorizontal: "15px", textAlign: "center", justifyContent: "center" }}>
                            <Image
                                style={{ width: '60px', height: '28px', padding: "0 5px" }}
                                src={dataImg}
                            />
                        </View>
                    </View>
                </Page>
            }
            {type === "ticketOrdAutomat" &&
                <Page size={{ width: 144 }} style={styles.body} orientation="portrait">
                    <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "3px" }} fixed>
                        <View style={{ display: 1, width: '20%' }}>
                            <Image
                                style={{ width: '20px', height: '20px' }}
                                src={Logo}
                            />
                        </View>
                        <View style={{ display: 2, marginHorizontal: '1px', width: '40%', textAlign: "left" }} >
                            <Text style={{ fontSize: '3.8px', paddingTop: 3 }}>{datosEmpresa.razon_social}</Text>
                            <Text style={{ fontSize: '3.8px', paddingTop: 1 }}>{datosEmpresa.ruc}</Text>
                            <Text style={{ fontSize: '3.8px', paddingTop: 1 }}>{datosEmpresa.direccion}</Text>
                        </View>
                        <View style={{ display: 3, marginHorizontal: '1px', width: '40%', textAlign: "right" }} >
                            <Text style={{ fontSize: '3.8px', paddingTop: 5 }}>N° {datosOrd.numero}</Text>
                        </View>
                    </View>
                    <View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px" }}>
                            <View style={{ display: 1, width: "100%" }}>
                                <Text style={{ fontSize: '4px' }}>DATOS:</Text>
                            </View>
                        </View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px" }}>
                            <View style={{ display: 1, width: "100%", backgroundColor: "#E5E5E5", borderRadius: "2px", padding: 3 }}>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Profesional: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{profesional.apellidos_nombres}</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Paciente: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosOrd.apellidos_nombres}</Text></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px" }}>
                            <View style={{ display: 1, width: "100%" }}>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '30%' }}><Text style={{ fontSize: '4px' }}>ORDEN DETALLE: </Text></View>
                                    <View style={{ display: 2, width: '15%' }}><Text style={{ fontSize: '4px', textAlign: "right" }}>Ingreso: </Text></View>
                                    <View style={{ display: 3, width: '20%' }}><Text style={{ fontSize: '4px', paddingLeft: "1px" }}>{datosOrd.fecha_ingreso}</Text></View>
                                    <View style={{ display: 4, width: '15%' }}><Text style={{ fontSize: '4px', textAlign: "right" }}>Entrega: </Text></View>
                                    <View style={{ display: 5, width: '20%' }}><Text style={{ fontSize: '4px', paddingLeft: "1px" }}>{datosOrd.fecha_entrega}</Text></View>
                                </View>
                            </View>
                        </View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px" }}>
                            <View style={{ display: 1, width: "100%", backgroundColor: "#E5E5E5", borderRadius: "2px", padding: 3 }}>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Servicio: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosOrd.nombre}</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Descripción: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosOrd.descripcion}</Text></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "1px" }}>
                        <View style={{ display: 1, width: "100%", textAlign: "center" }}>
                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 1, width: "100%", paddingHorizontal: 5, marginHorizontal: "10px" }}>
                                {datosOrdR.antagonista === 0 &&
                                    <View style={{ display: 1, width: '5px' }}><Image style={{ width: '7px', height: '9px', paddingTop: 3 }} src={iconUnChecked} /></View>}
                                {datosOrdR.antagonista === 1 &&
                                    <View style={{ display: 1, width: '5px' }}><Image style={{ width: '7px', height: '9px', paddingTop: 3 }} src={iconChecked} /></View>}
                                <View style={{ display: 1, }}><Text style={{ fontSize: '4px', paddingTop: 4, marginLeft: "2px", marginRight: "4px" }}>ANTAGONISTA: </Text></View>
                                {datosOrdR.cubeta === 0 &&
                                    <View style={{ display: 1, width: '5px' }}><Image style={{ width: '7px', height: '9px', paddingTop: 3 }} src={iconUnChecked} /></View>}
                                {datosOrdR.cubeta === 1 &&
                                    <View style={{ display: 1, width: '5px' }}><Image style={{ width: '7px', height: '9px', paddingTop: 3 }} src={iconChecked} /></View>}
                                <View style={{ display: 1, }}><Text style={{ fontSize: '4px', paddingTop: 4, marginLeft: "2px", marginRight: "4px" }}>CUBETA: </Text></View>
                                {datosOrdR.prueba_mordida === 0 &&
                                    <View style={{ display: 1, width: '5px' }}><Image style={{ width: '7px', height: '9px', paddingTop: 3 }} src={iconUnChecked} /></View>}
                                {datosOrdR.prueba_mordida === 1 &&
                                    <View style={{ display: 1, width: '5px' }}><Image style={{ width: '7px', height: '9px', paddingTop: 3 }} src={iconChecked} /></View>}
                                <View style={{ display: 1, }}><Text style={{ fontSize: '4px', paddingTop: 4, marginLeft: "2px", marginRight: "4px" }}>MORDIDA: </Text></View>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px", paddingTop: "2px" }}>
                            <View style={{ display: 1, width: "100%" }}>
                                <Text style={{ fontSize: '4px' }}>TRABAJOS:</Text>
                            </View>
                        </View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px" }}>
                            <View style={{ display: 1, width: "100%", backgroundColor: "#E5E5E5", borderRadius: "2px", padding: 3 }}>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1, marginHorizontal: 3 }}>
                                    <View style={{ display: 1, width: '5%' }}><Text style={{ fontSize: '3.3px' }}>N°</Text></View>
                                    <View style={{ display: 2, width: '35%' }}><Text style={{ fontSize: '3.3px' }}>TRABAJADOR</Text></View>
                                    <View style={{ display: 3, width: '35%' }}><Text style={{ fontSize: '3.3px' }}>DESCRIPCIÓN </Text></View>
                                    <View style={{ display: 4, width: '25%' }}><Text style={{ fontSize: '3.3px' }}>F.E. PROGRAMADA</Text></View>
                                </View>
                                {datosTrab.length > 0 &&
                                    datosTrab.map((m, index) => (
                                        <div key={index}>
                                            <View style={{ display: "flex", flexDirection: "row", paddingTop: 1, marginHorizontal: 3 }}>
                                                <View style={{ display: 1, width: '5%' }}><Text style={{ fontSize: '3.3px', textAlign: "left" }}>{m.linea} </Text></View>
                                                <View style={{ display: 2, width: '35%' }}><Text style={{ fontSize: '3.3px' }}>{m.nombre_trabajador}</Text></View>
                                                <View style={{ display: 3, width: '35%' }}  ><Text style={{ fontSize: '3.3px' }}>{m.descripcion}</Text></View>
                                                <View style={{ display: 4, width: '25%' }}><Text style={{ fontSize: '3.3px' }}>{m.fecha_programada}</Text></View>
                                            </View>
                                        </div>))}
                            </View>
                        </View>
                    </View>
                    <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px", paddingTop: 4, textAlign: "right", justifyContent: "flex-end" }}>
                        <Text style={{ fontSize: '4px' }}>PRESUPUESTO: S/ {datosOrdR.presupuesto}</Text>
                    </View>
                    <View style={{ display: 'fixed', flexDirection: 'row' }}>
                        <View style={{ display: "flex", flexDirection: "row", paddingTop: 1, width: "100%", paddingHorizontal: 5, marginHorizontal: "15px", textAlign: "center", justifyContent: "center" }}>
                            <Image
                                style={{ width: '60px', height: '28px', padding: "0 5px" }}
                                src={dataImg}
                            />
                        </View>
                    </View>
                </Page>
            }

            {type === "ticketTrab" &&
                <Page size={{ width: 144 }} style={styles.body} orientation="portrait">
                    <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "3px" }} fixed>
                        <View style={{ display: 1, width: '20%' }}>
                            <Image
                                style={{ width: '20px', height: '20px' }}
                                src={Logo}
                            />
                        </View>
                        <View style={{ display: 2, marginHorizontal: '1px', width: '40%', textAlign: "left" }} >
                            <Text style={{ fontSize: '3.8px', paddingTop: 3 }}>{datosEmpresa.razon_social}</Text>
                            <Text style={{ fontSize: '3.8px', paddingTop: 1 }}>{datosEmpresa.ruc}</Text>
                            <Text style={{ fontSize: '3.8px', paddingTop: 1 }}>{datosEmpresa.direccion}</Text>
                        </View>
                        <View style={{ display: 3, marginHorizontal: '1px', width: '40%', textAlign: "right" }} >
                            <Text style={{ fontSize: '3.8px', paddingTop: 5 }}>N° {datosOrd.id}-{datosTrab.linea}</Text>
                        </View>
                    </View>
                    <View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px" }}>
                            <View style={{ display: 1, width: "100%" }}>
                                <Text style={{ fontSize: '4px' }}>DATOS:</Text>
                            </View>
                        </View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px" }}>
                            <View style={{ display: 1, width: "100%", backgroundColor: "#E5E5E5", borderRadius: "2px", padding: 3 }}>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Profesional: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{profesional.apellidos_nombres}</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Paciente: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosOrd.apellidos_nombres}</Text></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "2px", paddingTop: "2px" }}>
                            <View style={{ display: 1, width: "100%" }}>
                                <Text style={{ fontSize: '4px' }}>DATOS DEL TRABAJO:</Text>
                            </View>
                        </View>
                        <View style={{ display: "fixed", flexDirection: "col", paddingBottom: "1px" }}>
                            <View style={{ display: 1, width: "100%", backgroundColor: "#E5E5E5", borderRadius: "2px", padding: 3 }}>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>N° Trabajo: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosTrab.linea}</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Codigo de Orden: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosOrd.id}</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Trabajador: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosTrab.trabajador_asignado}</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Descripción: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosTrab.descripcion}</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Trabajador: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosTrab.trabajador_asignado}</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Fecha Registro: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosTrab.fecha_registro}</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1 }}>
                                    <View style={{ display: 1, width: '28%' }}><Text style={{ fontSize: '4px' }}>Fecha Entrega: </Text></View>
                                    <View style={{ display: 2, width: '72%' }}><Text style={{ fontSize: '4px' }}>{datosTrab.fecha_programada}</Text></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ display: 'fixed', flexDirection: 'row', textAlign: "center", justifyContent: "center", backgroundColor: "#E5E5E5", borderRadius: "5px", height: "50px" }}>
                        <View style={{ display: 1, width: "45%", textAlign: "center", justifyContent: "center", backgroundColor: "#E5E5E5", borderRadius: "5px" }}>
                            <ExampleSvg />
                        </View>
                        <View style={{ display: "fixed", flexDirection: "col", width: "55%", textAlign: "center", justifyContent: "center", padding: "10px 20px" }}>
                            <View style={{ display: 1, width: "100%", height: "50px", backgroundColor: "#E5E5E5", borderRadius: "2px" }}>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <View style={{ display: 1, width: '25%', borderBottom: '0.5px dashed #000', borderTop: "0.5px solid #000", borderLeft: '0.5px solid #000', borderTopLeftRadius: '100%' }}><Text style={{ fontSize: '4px', color: "#E5E5E5" }}>11</Text></View>
                                    <View style={{ display: 2, width: '50%', borderRight: '0.5px dashed #000', borderLeft: '0.5px dashed #000', borderBottom: '0.5px dashed #000', borderTop: '0.5px solid #000' }}><Text style={{ fontSize: '4px', padding: "1px 0" }}>{datosOrdR.color_tercio_incisal}</Text></View>
                                    <View style={{ display: 3, width: '25%', borderBottom: '0.5px dashed #000', borderTop: '0.5px solid #000', borderRight: '0.5px solid #000', borderTopRightRadius: '100%' }}><Text style={{ fontSize: '4px', color: "#E5E5E5" }}>13</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <View style={{ display: 1, width: '25%', borderBottom: '0.5px dashed #000', borderLeft: '0.5px solid #000', }}><Text style={{ fontSize: '4px', color: "#E5E5E5" }}>21</Text></View>
                                    <View style={{ display: 2, width: '50%', borderLeft: '0.5px dashed #000', borderBottom: '0.5px dashed #000' }}><Text style={{ fontSize: '4px', padding: "1px 0" }}>{datosOrdR.color_tercio_medial}</Text></View>
                                    <View style={{ display: 3, width: '25%', borderLeft: '0.5px dashed #000', borderBottom: '0.5px dashed #000', borderRight: '0.5px solid #000' }}><Text style={{ fontSize: '4px', color: "#E5E5E5" }}>23</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <View style={{ display: 1, width: '25%', borderRight: '0px solid #000', borderLeft: '0.5px solid #000', borderBottom: '0.5px solid #000', borderBottomLeftRadius: '100%' }}><Text style={{ fontSize: '4px', color: "#E5E5E5" }}>21</Text></View>
                                    <View style={{ display: 2, width: '50%', borderLeft: '0.5px dashed #000', borderBottom: '0.5px solid #000' }}><Text style={{ fontSize: '4px', padding: "1px 0" }}>{datosOrdR.color_tercio_cervical}</Text></View>
                                    <View style={{ display: 3, width: '25%', borderLeft: '0.5px dashed #000', borderBottom: '0.5px solid #000', borderRight: '0.5px solid #000', borderBottomRightRadius: '100%' }}><Text style={{ fontSize: '4px', color: "#E5E5E5" }}>23</Text></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ display: 'fixed', flexDirection: 'row' }}>
                        <View style={{ display: "flex", flexDirection: "row", paddingTop: 1, width: "100%", paddingHorizontal: 5, marginHorizontal: "15px", textAlign: "center", justifyContent: "center" }}>
                            <Image
                                style={{ width: '60px', height: '28px', padding: "0 5px" }}
                                src={dataImg}
                            />
                        </View>
                    </View>
                </Page>
            }
            {/* ancho 43,2 */}
            {type === "ticketOrdResum" &&
                <Page size={{ width: 162, height: 43 }} style={styles.body2} orientation="portrait">
                    <View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px", justifyContent: "center" }}>
                            <View style={{ display: 1, width: "100%", borderRadius: "2px" }}>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1, textAlign: "center", justifyContent: "space-between" }}>
                                    <View style={{ display: 1, width: "auto" }}><Text style={{ fontSize: '3.5px' }}>N°: {datosOrd.numero} </Text></View>
                                    <View style={{ display: 2, width: "auto" }}><Text style={{ fontSize: '3.5px' }}>FECHA INGRESO: {datosOrd.fecha_ingreso}</Text></View>
                                    <View style={{ display: 3, width: "auto" }}><Text style={{ fontSize: '3.5px' }}>FECHA ENTREGA: {datosOrd.fecha_entrega}</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1, textAlign: "center", justifyContent: "space-between" }}>
                                    <View style={{ display: 1, width: "auto" }}><Text style={{ fontSize: '3.5px', paddingRight: "3px" }}>PROFESIONAL: {profesional.apellidos_nombres}</Text></View>
                                    <View style={{ display: 2, width: "auto" }}><Text style={{ fontSize: '3.5px' }}>PACIENTE: {datosOrd.apellidos_nombres}</Text></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ display: 'fixed', flexDirection: 'row', paddingTop: 4 }}>
                        <View style={{ display: "flex", flexDirection: "row", width: "100%", textAlign: "center", justifyContent: "center" }}>
                            <Image
                                style={{ width: '70%' }}
                                src={dataImg}
                            />
                        </View>
                    </View>
                </Page>
            }
            {type === "ticketOrdResumV2" &&
                <Page size={{ width: 162, height: 43 }} style={styles.body2} orientation="portrait">
                    <View>
                        <View style={{ display: "fixed", flexDirection: "row", paddingBottom: "1px", justifyContent: "center" }}>
                            <View style={{ display: 1, width: "100%", borderRadius: "2px" }}>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1, textAlign: "center", justifyContent: "space-between" }}>
                                    <View style={{ display: 1, width: "auto" }}><Text style={{ fontSize: '3.5px' }}>N°: {datosOrd.numero} </Text></View>
                                    <View style={{ display: 2, width: "auto" }}><Text style={{ fontSize: '3.5px' }}>FECHA INGRESO: {datosOrd.fecha_ingreso}</Text></View>
                                    <View style={{ display: 3, width: "auto" }}><Text style={{ fontSize: '3.5px' }}>FECHA ENTREGA: {datosOrd.fecha_entrega}</Text></View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", paddingTop: 1, textAlign: "center", justifyContent: "space-between" }}>
                                    <View style={{ display: 1, width: "auto" }}><Text style={{ fontSize: '3.5px', paddingRight: "3px" }}>PROFESIONAL: {profesional.apellidos_nombres}</Text></View>
                                    <View style={{ display: 2, width: "auto" }}><Text style={{ fontSize: '3.5px' }}>PACIENTE: {datosOrd.apellidos_nombres}</Text></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ display: 'fixed', flexDirection: 'row', paddingTop: 4 }}>
                        <View style={{ display: "flex", flexDirection: "row", width: "100%", textAlign: "center", justifyContent: "center" }}>
                            <Image
                                style={{ width: '70%' }}
                                src={dataImg}
                            />
                        </View>
                    </View>
                </Page>
            }
        </Document >
    )
}
