import React from 'react';
import { Grid, Typography, Button, IconButton, DialogContent, Dialog, DialogTitle, DialogActions, Collapse, Alert, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, Tooltip, Paper, Input, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import Datatable from '../../components/datatable';
import UrlsConst from '../../config/config';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DialogUsuario from './Dialogs/dialogUsuarios';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyIcon from '@mui/icons-material/Key';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function Usuario() {
  const [openEditUsuario, setOpenEditUsuario] = React.useState(false);
  const [openNewUsuario, setOpenNewUsuario] = React.useState(false);
  const [openDeleteUsuario, setOpenDeleteUsuario] = React.useState(false);
  const [alerta, setAlerta] = React.useState(false);
  const [alertaS, setAlertaS] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState(false);
  const [alertG, setAlertG] = React.useState(false);
  const [typeAlert, setTypeAlert] = React.useState('error');
  const [idUsuario, setIdUsuario] = React.useState(0);
  const [confirmarPassword, setConfirmarPassword] = React.useState(false);
  const [passwordChange, setPasswordChange] = React.useState(false);

  const [users, setUsers] = React.useState([{
    codigo: '',
    perfil: '',
    estado: '',
    numero_documento: '',
    nombres_apellidos: '',
    cargo: ''
  }]);
  const [usuarioR, setUsuarioR] = React.useState({
    codigo: '',
    fk_log_perfil: '',
    clave: '',
    fk_rh_trabajador: '',
    es_activo: 0
  });
  const estilosTable = {
    height: '600px', width: '100%'
  }
  React.useEffect(() => {
    usuariosList();
  }, []);
  const usuariosList = async () => {
    const usuarios = await fetch(`${UrlsConst.URL_API}/sistema/usuario/listarUsuarioUI.php`, {
      method: "POST"
    });
    const response = await usuarios.json();
    setUsers(response);
  }
  const newUsuario = () => {
    setOpenNewUsuario(true);
  }
  const handleClose = () => {
    setOpenNewUsuario(false);
    setOpenEditUsuario(false);
    setOpenDeleteUsuario(false);
    setPasswordChange(false);
  }
  const getUsuario = async (codigo) => {
    const usuarioGet = await fetch(`${UrlsConst.URL_API}/sistema/usuario/recuperarUsuario.php?codigo=` + codigo, {
      method: 'GET'
    });
    const response = await usuarioGet.json();
    setUsuarioR({
      codigo: response.codigo,
      fk_log_perfil: response.fk_log_perfil,
      clave: '',
      fk_rh_trabajador: response.fk_rh_trabajador,
      es_activo: response.es_activo
    });
  }
  const handleClaveUsuario = async () => {
    const saveUsuario = await fetch(`${UrlsConst.URL_API}/sistema/usuario/actualizarUsuarioContrasena.php`, {
      method: 'POST',
      body: JSON.stringify({ codigo: usuarioR.codigo, clave: usuarioR.clave })
    });
    const response = await saveUsuario.json();
    setTypeAlert('success');
    setTextAlert('SE ACTUALIZO CORRECTAMENTE LAS CLAVES');
    setConfirmarPassword(false);
    setAlertG(true);
    setTimeout(() => { setAlertG(false) }, 2500);
    setPasswordChange(false);
  }
  const handleClickDelete = async () => {
    const deleteUsuario = await fetch(`${UrlsConst.URL_API}/sistema/usuario/eliminarUsuario.php?codigo=` + idUsuario, {
      method: 'POST'
    });
    const response = await deleteUsuario.json();
    setOpenDeleteUsuario(false);
    usuariosList();
    setTypeAlert('success');
    setTextAlert('SE ELIMINO CORRECTAMENTE EL USUARIO');
    setAlertG(true);
    setTimeout(() => { setAlertG(false) }, 2500);
    // if (response === true) {

    // }
  }

  /// password
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleChangePassword = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });

    const clave = document.getElementById('clave');
    const claveConfirmar = document.getElementById('confirmarClave');

    if (clave.value != "" && claveConfirmar.value != "") {
      if (clave.value != claveConfirmar.value) {
        setTextAlert('!ERROR¡ LAS CLAVES NO COINCIDEN');
        setAlerta(true);
        setTimeout(() => { setAlerta(false) }, 2500);
        setUsuarioR({ ...usuarioR, clave: '' });
      } else {
        setTextAlert('LAS CLAVES COINCIDEN');
        setAlerta(false);
        setAlertaS(true);
        setTimeout(() => { setAlertaS(false) }, 2500);
        setUsuarioR({ ...usuarioR, clave: claveConfirmar.value });
      }
    }
    // setUsuario({ ...usuario, [name]: event.target.value });
  };

  const handleClickShowPassword = (e, value) => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handlePasswordM = () => {
    if (usuarioR.clave != "") {
      setConfirmarPassword(true);
    } else {
      setTextAlert('!ERROR¡ LAS CLAVES NO PUEDEN IR VACIAS');
      setAlerta(true);
      setTimeout(() => { setAlerta(false) }, 2500);
    }
  }
  const handleClosePassword = () => {
    setConfirmarPassword(false);
  }
  const [search, setSearch] = React.useState({
    string: ''
  });
  const cambiarBtn = (e) => {
    if (e.which == 13) {
      searchUsuario();
    }
  }
  const searchUsuario = async () => {
    if (search.string != "") {
      const usuarios = await fetch(`${UrlsConst.URL_API}/sistema/usuario/listarUsuarioUI.php`, {
        method: "POST"
      });
      const response = await usuarios.json();
      const filtrar = search.string;
      const buscado = response.filter((usuario) => usuario.codigo.includes(filtrar) || usuario.perfil.includes(filtrar) || usuario.cargo.includes(filtrar));
      setUsers(buscado);
    }
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Grid container style={{ minHeight: '95vh', justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
      <Grid item md={11} xs={11} style={{ justifyContent: 'center' }}>
        <Paper elevation={1} style={{ padding: '1.5em' }}>
          <Grid container style={{ padding: '8px', justifyContent: 'space-between', textAlign: 'center' }}>
            <Typography variant='h5'>USUARIOS</Typography>
            <Button onClick={newUsuario} startIcon={<AddIcon />} variant="contained" color="success" >Nuevo</Button>
          </Grid>
          <Collapse in={alertG}>
            <Alert variant="outlined" severity={typeAlert}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertG(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {textAlert}
            </Alert>
          </Collapse>
          <div style={{ justifyContent: 'center' }}>
            <Grid item md={6} xs={12}>
              <TextField
                id="search"
                name="string"
                fullWidth label="BUSCAR"
                margin='normal' variant="standard"
                value={search.string}
                onChange={
                  (e) => {
                    if (e.target.value != "") {
                      const { name, value } = e.target;
                      setSearch({ ...search, [name]: value.toUpperCase() });
                    } else {
                      setSearch({ ...search, string: '' });
                      usuariosList();
                    }
                  }
                }
                onKeyUp={cambiarBtn}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={searchUsuario} id="btnSearch">
                        <SearchIcon color="warning" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </div>
          <Grid container style={{ marginTop: '20px' }}>
            <Grid item md={12} xs={12}>
              <Paper>
                <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}>
                  <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '10%', fontWeight: 'bold' }}>CODIGO</TableCell>
                        <TableCell style={{ width: '30%', fontWeight: 'bold' }}>PERFIL</TableCell>
                        <TableCell style={{ width: '15%', fontWeight: 'bold' }}>N° DOCUMENTO</TableCell>
                        <TableCell style={{ width: '25%', fontWeight: 'bold' }}>CARGO</TableCell>
                        <TableCell style={{ width: '10%', fontWeight: 'bold' }}>ESTADO</TableCell>
                        <TableCell style={{ width: '20%', fontWeight: 'bold' }}>ACCIONES</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        (rowsPerPage > 0
                          ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : users
                        ).map((usuario) => (
                          <TableRow
                            key={usuario.codigo}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {usuario.codigo}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {usuario.perfil}
                            </TableCell>
                            <TableCell>{usuario.numero_documento}</TableCell>
                            <TableCell>{usuario.cargo}</TableCell>
                            <TableCell>{
                              usuario.estado === 'ACTIVO' ? <div style={{ background: '#10ad47', width: '100%', textAlign: 'center', borderRadius: '8px', color: '#fff', fontSize: '13px' }}>ACTIVO</div>
                                :
                                <div style={{ background: '#b81907', width: '100%', textAlign: 'center', borderRadius: '8px', color: '#fff', fontSize: '13px' }}>INACTIVO</div>
                            }</TableCell>
                            <TableCell>
                              <Tooltip title="CAMBIAR CONTRASEÑA">
                                <IconButton onClick={() => {
                                  setPasswordChange(true);
                                  getUsuario(usuario.codigo);
                                }} aria-label="delete" size="small">
                                  <KeyIcon color="warning" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="EDITAR USUARIO">
                                <IconButton onClick={() => {
                                  setOpenEditUsuario(true);
                                  setIdUsuario(usuario.codigo);
                                }} aria-label="delete" size="small">
                                  <EditIcon color="primary" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="ELIMINAR USUARIO">
                                <IconButton onClick={() => {
                                  setOpenDeleteUsuario(true);
                                  setIdUsuario(usuario.codigo);
                                }} aria-label="delete" size="small">
                                  <DeleteIcon color="error" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  labelRowsPerPage="Filas por columnas"
                  count={users.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Dialog open={openNewUsuario} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle>NUEVO USUARIO</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogUsuario
            fntUsuarios={usuariosList}
            fntClose={handleClose}
            usuarios={users}
          />
          <Button startIcon={<CloseIcon />} style={{ marginLeft: '6px' }} variant="outlined" color="error" onClick={handleClose}>Cancelar</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={passwordChange} onClose={handleClose}>
        <DialogTitle>CAMBIAR CLAVE</DialogTitle>
        <DialogContent>
          <Collapse in={alerta}>
            <Alert severity='error'
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlerta(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {textAlert}
            </Alert>
          </Collapse>
          <Collapse in={alertaS}>
            <Alert severity='success'
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertaS(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {textAlert}
            </Alert>
          </Collapse>
          <FormControl sx={{ mt: 4, width: '100%' }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">CLAVE *</InputLabel>
            <Input
              required
              id="clave"
              type={values.showPassword ? 'text' : 'password'}
              // value={values.password}
              name="clave"
              onChange={handleChangePassword('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl sx={{ mt: 4, mb: 2, width: '100%' }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">CONFIRMAR CLAVE *</InputLabel>
            <Input
              required
              id="confirmarClave"
              type={values.showPassword ? 'text' : 'password'}
              name="clave"
              onChange={handleChangePassword('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button startIcon={<CheckIcon />} variant="outlined" color="success" onClick={handlePasswordM}>Aceptar</Button>
          <Button startIcon={<CloseIcon />} variant="outlined" color="error" onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmarPassword} onClose={handleClose}>
        <DialogTitle style={{ textAlign: 'center' }}>¿Esta seguro de cambiar la contraseña?</DialogTitle>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button startIcon={<CheckIcon />} onClick={handleClaveUsuario} variant="outlined" color="success">ACEPTAR</Button>
          <Button startIcon={<CloseIcon />} style={{ marginLeft: '6px' }} variant="outlined" color="error" onClick={handleClosePassword}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditUsuario} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Editar Usuario</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogUsuario
            fntUsuarios={usuariosList}
            fntClose={handleClose}
            fntIdUsuario={idUsuario}
            usuarios={[]}
          />
          <Button startIcon={<CloseIcon />} style={{ marginLeft: '6px' }} variant="outlined" color="error" onClick={handleClose}>Cancelar</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openDeleteUsuario} onClose={handleClose}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <div>
            <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
          </div>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant="h5">Eliminar Usuario</Typography>
          ¿Esta seguro de eliminar el usuario?
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="contained" color="error" onClick={handleClickDelete}>Aceptar</Button>
          <Button variant="contained" onClick={handleClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </Grid >
  )
}