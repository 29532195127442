import * as React from 'react';
import { Alert, Button, Collapse, Grid, IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';

import UrlsConst from '../../../config/config';
import { LoadingButton } from '@mui/lab';

// iconos
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';

export default function PacienteDialog(props) {
  const [maxlength, setMaxlength] = React.useState(8);
  const [openValidation, setOpenVError] = React.useState(false);
  const [openVS, setOpenVS] = React.useState(false);
  const [openW, setOpenW] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState('');
  const [validarDocumento, setValidarDocumento] = React.useState(0);
  const [openVP, setOpenVP] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [typeAlert, setTypeAlert] = React.useState('success');
  const [alerta, setAlerta] = React.useState(false);
  // validaciones paciente
  const [apellidosPaciente, setApellidosPaciente] = React.useState({
    error: false,
    text: ''
  });
  const [documentoPaciente, setDocumentoPaciente] = React.useState({
    error: false,
    text: ''
  });
  // documento
  const [documento, setDocumento] = React.useState([{
    codigo: '',
    descripcion_corta: '',
    descripcion_larga: '',
    longitud: 0,
    tipo: '',
    contribuyente: '',
    longitud_exacta: 0
  }]);
  const [paciente, setPacienteSubmit] = React.useState({
    id: 0,
    fk_com_tipo_documento: '01',
    apellidos_nombres: '',
    numero_documento: '',
    observaciones: ''
  });
  const handleChangePaciente = (e) => {
    const { name, value } = e.target;
    setPacienteSubmit({ ...paciente, [name]: value.toUpperCase() });
  }
  // funciones
  const documentSelectDocumento = (e, values) => {
    const documento = values.props;
    if (documento.value === "00") {
      setDocumentoPaciente({ ...documentoPaciente, error: false, text: '' });
      setValidarDocumento(1);
    } else {
      setValidarDocumento(0);
    }
    setPacienteSubmit({ ...paciente, fk_com_tipo_documento: documento.value, numero_documento: '' });
    setMaxlength(values.props.loguitud);
  }
  const tipoDocumento = async () => {
    const documentos_tipo = await fetch(`${UrlsConst.URL_API}/sistema/listarDocumento.php`, {
      method: "POST"
    });
    const json = await documentos_tipo.json();
    setDocumento(json);
  }
  const getPaciente = async () => {
    const getpaciente = await fetch(`${UrlsConst.URL_API}/ventas/paciente/recuperarPaciente.php?id=` + props.fntPaciente, {
      method: 'POST'
    });
    const response = await getpaciente.json();
    if (response.fk_com_tipo_documento === "00") {
      setValidarDocumento(1);
    } else {
      setValidarDocumento(0);
    }
    if (response.fk_com_tipo_documento === "00") {
      setMaxlength(15);
    }
    if (response.fk_com_tipo_documento === "01") {
      setMaxlength(8);
    }
    if (response.fk_com_tipo_documento === "04") {
      setMaxlength(12);
    }
    if (response.fk_com_tipo_documento === "06") {
      setMaxlength(11);
    }
    if (response.fk_com_tipo_documento === "07") {
      setMaxlength(12);
    }
    if (response.fk_com_tipo_documento === "11") {
      setMaxlength(15);
    }
    setPacienteSubmit(response);
  }
  const fetchSavePaciente = async () => {
    if (paciente.apellidos_nombres === "" || (paciente.numero_documento === "" && paciente.fk_com_tipo_documento !== "00")) {
      if (paciente.apellidos_nombres === "") {
        setApellidosPaciente({ ...apellidosPaciente, error: true, text: 'Nombres y apellidos obligatorio' });
      }
      if (paciente.numero_documento === "") {
        setDocumentoPaciente({ ...documentoPaciente, error: true, text: 'Documento obligatorio' });
      }
    } else {
      if (validarDocumento != 0) {
        const pacienteFetch = await fetch(`${UrlsConst.URL_API}/ventas/paciente/guardarPaciente.php`, {
          method: "POST",
          body: JSON.stringify(paciente),
        });
        const response = await pacienteFetch.json();
        if (props.fntPacientesProfesionales != undefined) {
          props.fntPacientesProfesionales(props.profesional);
        }
        if (props.fntPacientes != undefined) {
          props.fntPacientes();
        }
        props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'Se actualizo correctamente el paciente', open: true });
        setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
        props.fntClose();
      } else {
        setTypeAlert('warning');
        setTextAlert('Debe validar primero el documento');
        setAlerta(true);
        setTimeout(() => {
          setAlerta(false)
        }, 2500);
      }
    }
  }

  const validarDocumentoPaciente = async (id) => {
    if (paciente.tipoDocumento != "" && paciente.numero_documento != "") {
      const validarD = await fetch(`${UrlsConst.URL_API}/ventas/paciente/validarPacienteUI.php`, {
        method: "POST",
        body: JSON.stringify(paciente),
      });
      const response = await validarD.json();
      if (response.existe != false) {
        const getpaciente = await fetch(`${UrlsConst.URL_API}/ventas/paciente/recuperarPaciente.php?id=` + id, {
          method: 'POST'
        });
        const paciente_response = await getpaciente.json();
        if (paciente_response.numero_documento === paciente.numero_documento) {
          setTextAlert('Documento validado correctamente');
          setOpenVS(true);
          setTimeout(() => { setOpenVS(false) }, 2500);
          setValidarDocumento(1);
          setDocumentoPaciente({ ...documentoPaciente, error: false, text: '' });
        } else {
          setValidarDocumento(0);
          setOpenVError(true);
          setTimeout(() => { setOpenVError(false) }, 3000);
        }

      } else {
        setOpenW(false);
        setTextAlert('Este documento no se encuentra registrado para un paciente');
        setOpenVS(true);
        setTimeout(() => { setOpenVS(false) }, 2500);
        setValidarDocumento(1);
        setDocumentoPaciente({ ...documentoPaciente, error: false, text: '' });
      }
    } else {
      setTextAlert('Deberia ingresar primero un paciente');
      setOpenW(true);
      setTimeout(() => { setOpenW(false) }, 2500);
    }
  }
  const fetchSavePacienteProfesional = async () => {
    if (paciente.apellidos_nombres === "" || (paciente.numero_documento === "" && paciente.fk_com_tipo_documento !== "00")) {
      if (paciente.apellidos_nombres === "") {
        setApellidosPaciente({ ...apellidosPaciente, error: true, text: 'Nombres y apellidos obligatorio' });
      }
      if (paciente.numero_documento === "" && paciente.fk_com_tipo_documento !== '00') {
        setDocumentoPaciente({ ...documentoPaciente, error: true, text: 'Documento obligatorio' });
      }
    } else {
      if (validarDocumento != 0) {
        setLoading(true);
        const pacienteFetch = await fetch(`${UrlsConst.URL_API}/ventas/paciente/guardarPaciente.php`, {
          method: "POST",
          body: JSON.stringify(paciente),
        });
        const response = await pacienteFetch.json();
        const pacienteProfInsert = await fetch(`${UrlsConst.URL_API}/ventas/profesional/guardarPacienteProfesional.php`, {
          method: "POST",
          body: JSON.stringify({ fk_ven_profesional: props.fntIdprofesional, fk_ven_paciente: response.id }),
        });
        const responseSavePacxProf = await pacienteProfInsert.json();
        props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'Se guardo correctamente el paciente', open: true });
        setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
        // props.fntPacientes(props.fntIdprofesional);
        if (props.setNewPaciente != undefined) {
          props.setNewPaciente();
        }
        if (props.listPacientes != undefined) {
          props.listPacientes(props.fntIdprofesional);
        }
        if (props.fntPacientesProfesionales != undefined) {
          props.fntPacientesProfesionales(props.fntIdprofesional);
        }
        // if (props.fntPacientes != undefined) {
        //   props.fntPacientes(props.fntIdprofesional);
        // }
        // props.fntClose();
      } else {
        setTextAlert('Primero deberia valir el documento');
        setTypeAlert('warning');
        setAlerta(true);
        setTimeout(() => {
          setAlerta(false)
        }, 2500);
      }
    }
  }
  React.useEffect(() => {
    tipoDocumento();
    setValidarDocumento(0);
    if (props.fntPaciente != undefined && props.fntPaciente != 0) {
      getPaciente();
    }
  }, []);
  return (
    <>
      <Collapse in={alerta}>
        <Alert variant="outlined" severity={typeAlert}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlerta(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {textAlert}
        </Alert>
      </Collapse>
      <Collapse in={openW}>
        <Alert variant="outlined" severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenW(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {textAlert}
        </Alert>
      </Collapse>
      <Collapse in={openVP}>
        <Alert variant="outlined" severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenVP(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {textAlert}
        </Alert>
      </Collapse>
      <Collapse in={openVS}>
        <Alert variant="outlined"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenVS(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {textAlert}
        </Alert>
      </Collapse>
      <Collapse in={openValidation}>
        <Alert variant="outlined" severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenVError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Ya se encuentra registrado el numero de documento ingresado
        </Alert>
      </Collapse>
      {/* {
        validarDocumento === 0 &&
        <label style={{ color: "red",textAlign:'none' }}>Recuerda antes de guardar debe validar el documento *</label>
      } */}
      <TextField
        label="NOMBRES Y APELLIDOS"
        error={apellidosPaciente.error}
        required
        fullWidth
        variant="standard"
        id="apellidos_nombres_p"
        margin='normal'
        name='apellidos_nombres'
        value={paciente ? paciente.apellidos_nombres : ''}
        helperText={apellidosPaciente.text}
        onChange={(e) => {
          const { value } = e.target;
          if (value != "") {
            setPacienteSubmit({ ...paciente, apellidos_nombres: value.toUpperCase() });
            setApellidosPaciente({ ...apellidosPaciente, error: false, text: '' });
          } else {
            setPacienteSubmit({ ...paciente, apellidos_nombres: '' });
            setApellidosPaciente({ ...apellidosPaciente, error: true, text: 'Nombres  y apellidos obligatorio' });
          }
        }}
      />
      <Grid container spacing={1}>
        <Grid item md={5} xs={12}>
          <TextField
            style={{ textAlign: 'start' }}
            select
            fullWidth
            label="TIPO DE DOCUMENTO"
            variant='standard'
            margin='normal'
            onChange={documentSelectDocumento}
            value={paciente ? paciente.fk_com_tipo_documento : ''}
          >
            {documento.map((option) => (
              <MenuItem key={option.codigo} value={option.codigo} loguitud={option.longitud}>
                {option.descripcion_larga}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={paciente.fk_com_tipo_documento != "00" ? 5 : 7} xs={12}>
          <TextField
            fullWidth
            required
            error={documentoPaciente.error}
            id="numero_documento_p"
            name="numero_documento"
            margin='normal'
            label="DOCUMENTO"
            variant="standard"
            value={paciente ? paciente.numero_documento : ''}
            inputProps={{ maxLength: maxlength }}
            helperText={documentoPaciente.text}
            onChange={(e) => {
              const { value } = e.target;
              if ((value !== "" && paciente.fk_com_tipo_documento === "00") || (value !== "" && paciente.fk_com_tipo_documento !== "00")) {
                if (value !== "" && paciente.fk_com_tipo_documento !== "00") {
                  setPacienteSubmit({ ...paciente, numero_documento: value.toUpperCase() });
                  setDocumentoPaciente({ ...documentoPaciente, error: false, text: '' });
                }
                if (value !== "" && paciente.fk_com_tipo_documento === "00") {
                  setPacienteSubmit({ ...paciente, numero_documento: value.toUpperCase() });
                  setDocumentoPaciente({ ...documentoPaciente, error: false, text: '' });
                }
              } else {
                if (value === "" && paciente.fk_com_tipo_documento !== "00") {

                  setPacienteSubmit({ ...paciente, numero_documento: '' });
                  setDocumentoPaciente({ ...documentoPaciente, error: true, text: 'Documento obligatorio' });
                }
                if (value === "" && paciente.fk_com_tipo_documento === "00") {
                  setPacienteSubmit({ ...paciente, numero_documento: '' });
                  setDocumentoPaciente({ ...documentoPaciente, error: false, text: '' });
                }
              }
            }}
          />
        </Grid>
        {
          paciente.fk_com_tipo_documento != "00" &&
          <Grid item md={2} xs={12} style={{ marginTop: '25px' }}>
            <Button
              onClick={() => validarDocumentoPaciente(paciente.id)}
              variant="outlined"
              color={validarDocumento != 0 ? 'success' : 'error'}
              startIcon={validarDocumento != 0 ? <CheckCircleOutlineIcon /> : <CloseIcon />}>
              {validarDocumento != 0 ? ' Verificado' : ' Verificar'}
            </Button>
          </Grid>
        }

      </Grid>
      <TextField
        fullWidth
        id="observaciones"
        name='observaciones'
        margin='normal'
        label='OBSERVACIONES'
        multiline
        rows={2}
        maxRows={4}
        value={paciente ? paciente.observaciones : ''}
        inputProps={{ maxLength: 200 }}
        onChange={handleChangePaciente}
      />
      {
        props.fntIdprofesional != undefined &&
        <LoadingButton
          onClick={fetchSavePacienteProfesional}
          startIcon={<CheckIcon />}
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          color="success"
        >
          GUARDAR
        </LoadingButton>
      }
      {
        props.fntPaciente != undefined &&
        <LoadingButton
          onClick={fetchSavePaciente}
          startIcon={<CheckIcon />}
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          color="success"
        >
          ACTUALIZAR
        </LoadingButton>
      }
    </>
  )
}
