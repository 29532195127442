import { Delete, Edit } from '@mui/icons-material';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import React from 'react'

export default function FilterMaterial(props) {
    const material = props.material;
    const setDialogMaterialEdit = props.setDialogMaterialEdit;
    const setIdMaterial = props.setIdMaterial;
    const setDeleteMaterial = props.setDeleteMaterial;
    return (
        <TableRow
            key={material.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                {material.id}
            </TableCell>
            <TableCell component="th" scope="row">
                {material.nombre}
            </TableCell>
            <TableCell>{material.descripcion}</TableCell>
            <TableCell component="th" scope="row">
                <div style={{ background: material.color, width: '80px', height: '20px' }}></div>
            </TableCell>
            <TableCell>
                <Tooltip title="EDITAR MATERIAL">
                    <IconButton aria-label="delete"
                        onClick={() => { setDialogMaterialEdit(true); setIdMaterial(material.id) }}
                        size="small">
                        <Edit color='primary' fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="ELIMINAR MATERIAL">
                    <IconButton aria-label="delete" size="small"
                        onClick={() => { setDeleteMaterial(true); setIdMaterial(material.id) }}
                    >
                        <Delete color='error' fontSize="small" />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}
