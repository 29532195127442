import { Check, Close, Co2Sharp } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React from 'react'
import UrlsConst from '../../config/config';

export default function DialogCajaChica(props) {
    const dialogCajaChica = props.dialogCajaChica;
    const setDialogCajaChica = props.setDialogCajaChica;
    const cajaRecuperada = props.cajaRecuperada;
    const cajaChicaRecuperada = props.cajaChicaRecuperada;
    const [cajaChicaSubmit, setCajaChicaSubmit] = React.useState({
        id: 0,
        fk_ven_caja_cobranza: '',
        saldo_inicial: 0,
        total_recarga: 0,
        saldo_anterior: 0,
        saldo_al_cierre: 0,
        esta_cerrada: 0
    });
    const handleCajaChica = (e) => {
        const { value, name } = e.target;
        setCajaChicaSubmit({ ...cajaChicaSubmit, [name]: value });
    }
    // console.log(cajaChicaRecuperada)
    // console.log(props.cajaChicaRecuperada)
    const handleSaldoInicial = (e) => {
        if (e.target.value === "") {
            setCajaChicaSubmit({ ...cajaChicaSubmit, saldo_inicial: 0, total_recarga: 0 });
        } else {
            if (isNaN(e.target.value) !== true) {
                setCajaChicaSubmit({ ...cajaChicaSubmit, saldo_inicial: Number(props.cajaChicaRecuperada.saldo_al_cierre) + Number(e.target.value), total_recarga: e.target.value });
            } else {
                setCajaChicaSubmit({ ...cajaChicaSubmit, saldo_inicial: 0, total_recarga: 0 });
            }
        }
    }
    const abrirCajaChica = async () => {
        const caja_chica_recuperar = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${cajaRecuperada.id}`);
        const response_caja = await caja_chica_recuperar.json();
        if (response_caja) {
            console.log('caja_existente')
        } else {
            if (cajaChicaRecuperada.id !== 0) {
                const caja_chica = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/guardarcajachica.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: 0,
                        fk_ven_caja_cobranza: cajaRecuperada.id,
                        saldo_inicial: cajaChicaSubmit.saldo_inicial,
                        total_recarga: cajaChicaSubmit.total_recarga,
                        saldo_anterior: cajaChicaSubmit.saldo_anterior,
                        saldo_al_cierre: cajaChicaSubmit.saldo_al_cierre,
                        esta_cerrada: 0
                    })
                });
                const response = await caja_chica.json();
            } else {
                const caja_chica = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/guardarcajachica.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: 0,
                        fk_ven_caja_cobranza: cajaRecuperada.id,
                        saldo_inicial: cajaChicaSubmit.saldo_inicial,
                        total_recarga: cajaChicaSubmit.total_recarga,
                        saldo_anterior: cajaChicaSubmit.saldo_anterior,
                        saldo_al_cierre: cajaChicaSubmit.saldo_al_cierre,
                        esta_cerrada: 0
                    })
                });
                const response = await caja_chica.json();
            }
        }

        setDialogCajaChica(false);
    }
    const handleCloseCajaChica = () => {
        setDialogCajaChica(false);
    }
    React.useEffect(() => {
        if (props.cajaChicaRecuperada !== undefined) {
            setCajaChicaSubmit({ ...cajaChicaSubmit, saldo_anterior: props.cajaChicaRecuperada.saldo_al_cierre });
        }
    }, []);
    return (
        <Dialog open={dialogCajaChica} onClose={() => setDialogCajaChica(false)} maxWidth="xs">
            <DialogTitle>NUEVA CAJA CHICA</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item md={12}>
                        <TextField name="saldo_inicial" fullWidth label="SALDO INICIAL" margin='normal' disabled value={cajaChicaSubmit ? cajaChicaSubmit.saldo_inicial : 0} />
                    </Grid>
                    <Grid item md={12}>
                        <TextField name="saldo_anterior" fullWidth label="SALDO ANTERIOR" margin='normal' disabled onChange={handleCajaChica} value={cajaChicaSubmit ? cajaChicaSubmit.saldo_anterior : 0} />
                    </Grid>
                    <Grid item md={12}>
                        <TextField name="saldo_al_cierre" fullWidth label="SALDO AL CIERRE" margin='normal' disabled onChange={handleCajaChica} value={cajaChicaSubmit ? cajaChicaSubmit.saldo_al_cierre : 0} />
                    </Grid>
                    <Grid item md={12}>
                        <TextField name="total_recarga" fullWidth label="TOTAL RECARGA" margin='normal' onChange={handleSaldoInicial} value={cajaChicaSubmit ? cajaChicaSubmit.total_recarga : 0} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button variant='outlined' color="success" startIcon={<Check />} onClick={abrirCajaChica}>ACEPTAR</Button>
                <Button variant='outlined' color="error" startIcon={<Close />} onClick={handleCloseCajaChica}>CANCELAR</Button>
            </DialogActions>
        </Dialog>
    )
}
