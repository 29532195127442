import React from 'react'

import { Grid, Typography, Paper, TextField, Button, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Tooltip, InputAdornment, Autocomplete, Chip, Snackbar, Alert } from '@mui/material';
import UrlsConst from '../../config/config';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import excel from '../../img/excel.png'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import pdf from '../../img/pdf.png';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Excel from './exportador/excel';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import Pdf from './exportador/pdf';
import PdfProfesional from './exportador/pdfProfesional';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import GenCodigo from '../Caja/cajaChica/genCodigo';

export default function ReportDeCaja() {
    const [años, setAños] = React.useState([]);
    const meses = [
        { id: '01', name: 'ENERO' },
        { id: '02', name: 'FEBRERO' },
        { id: '03', name: 'MARZO' },
        { id: '04', name: 'ABRIL' },
        { id: '05', name: 'MAYO' },
        { id: '06', name: 'JUNIO' },
        { id: '07', name: 'JULIO' },
        { id: '08', name: 'AGOSTO' },
        { id: '09', name: 'SETIEMBRE' },
        { id: '10', name: 'OCTUBRE' },
        { id: '11', name: 'NOVIEMBRE' },
        { id: '12', name: 'DICIEMBRE' }
    ];
    const [value, setValue] = React.useState('1');
    const [profesionales, setProfesionales] = React.useState([]);
    const [desdeHasta, setDesdeHasta] = React.useState({
        desde: '',
        hasta: ''
    });
    const [mesAñoGeneral, setMesAñoGeneral] = React.useState({
        mes: '',
        año: '',
    });
    const [cajaGeneral, setCajaGeneral] = React.useState([]);
    const [tabMesAñoProfID, setTabMesAñoProfID] = React.useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const hcSelectProf = (e, v) => {
        let filtrarRep = {};
        var arrayF = v.filter(o => filtrarRep[o.id] ? false : filtrarRep[o.id] = true).map(m => { return m.id });
        setTabMesAñoProfID(arrayF)
    };

    const typogFuente = { fuente: "", size: 20 };

    const [cajaUR, setCajaUR] = React.useState([]);
    const filtrarCajas = async () => {

        if (mesAñoGeneral.año === "" || mesAñoGeneral.año === "") {
            console.log('vacio')
        } else {
            const reportMesAño = await fetch(`${UrlsConst.URL_API}/reportes/RPresumenCobranzaMesAnho.php?anho=${mesAñoGeneral.año}&mes=${mesAñoGeneral.mes}`, {
                method: 'GET'
            });
            const response = await reportMesAño.json();
            setCajaGeneral(response);
            let cajas = [];
            response.forEach(report => {
                cajas.push({ id: report.id_caja, fecha_apertura: report.fecha_apertura, fecha_cierre: report.fecha_cierre })
            });

            if (response.length > 0) {
                let cajasR = response.map(item => {
                    return [item.id_caja, item]
                });
                var personasMapArr = new Map(cajasR); // Pares de clave y valor
                let unicos = [...personasMapArr.values()];
                setCajaUR(unicos);
            } else {
                setAlertGeneral({ ...alertGeneral, type: 'warning', text: 'No se econtraron datos para la fecha', open: true });
                setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false, type: "warning", text: "" }) }, 3000);
            }
        }
    }
    const [pagosProfe, setPagosProfe] = React.useState([]);
    async function filtrarCajasProfesional() {
        if (profesionales.length === 0 || desdeHasta.desde === "" || desdeHasta.hasta === "") {
            setAlertGeneral({ ...alertGeneral, type: 'warning', text: 'Debe selecionar un profesional y fechas', open: true });
            setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
        } else {
            let cajasPagosA = [];
            await tabMesAñoProfID.forEach(async (item) => {
                const reportProfesional = await fetch(`${UrlsConst.URL_API}/reportes/RPresumenCobranzaFechas.php?id=${item}&desde=${desdeHasta.desde}&hasta=${desdeHasta.hasta}`, {
                    method: 'GET'
                });
                const response = await reportProfesional.json();
                if (cajasPagosA.length === 0) {
                    cajasPagosA = response;
                } else {
                    cajasPagosA = cajasPagosA.concat(response)
                }
                setPagosProfe(cajasPagosA);
            });
        }
    }

    const [datosEmpresa, setDatosEmpresa] = React.useState({
        ruc: '',
        razon_social: '',
        direccion: ''
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [page2, setPage2] = React.useState(0);
    const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
    const handleChangePage2 = (event, newPage) => {
        setPage2(newPage);
    };
    const handleChangeRowsPerPage2 = (event) => {
        setRowsPerPage2(parseInt(event.target.value, 10));
        setPage2(0);
    };
    React.useState(() => {
        const cargarProfesional = async () => {
            const search = await fetch(`${UrlsConst.URL_API}/ventas/profesional/buscarProfesional.php`, {
                method: "POST",
                body: JSON.stringify(""),
            });
            const response = await search.json();
            setProfesionales(response);
        }
        cargarProfesional();
        let año_actual = new Date().getFullYear();
        let añosA = [];
        setMesAñoGeneral({ ...mesAñoGeneral, año: new Date().getFullYear(), mes: new Date().getMonth() + 1 });
        for (let i = 2018; i <= año_actual; i++) {
            añosA.push({ año: `${i}` });
        }
        setAños(añosA);
        setDesdeHasta({ desde: new Date().toJSON().slice(0, 10), hasta: new Date().toJSON().slice(0, 10) });
        const getDatosEmpresa = async () => {
            const getRuc = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RUC_EMPRESA`, {
                method: 'GET'
            });
            const response_getRuc = await getRuc.json();
            const getRazon = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RAZON_SOCIAL`, {
                method: 'GET'
            });
            const response_getRazon = await getRazon.json();
            const getDireccion = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=DIRECCION_EMPRESA`, {
                method: 'GET'
            });
            const response_getDireccion = await getDireccion.json();
            setDatosEmpresa({ ruc: response_getRuc.valor_string, razon_social: response_getRazon.valor_string, direccion: response_getDireccion.valor_string });
        }
        getDatosEmpresa();
    }, []);
    const [alertGeneral, setAlertGeneral] = React.useState({
        open: false,
        type: 'success',
        text: ''
    });
    const sinBusqueda = () => {
        setAlertGeneral({ ...alertGeneral, type: 'warning', text: 'No se realizo ninguna busqueda', open: true });
        setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
    }
    // console.log({ cajaGeneral });
    const limpiarEstadosTab = () => {
        setCajaGeneral([]);
        setMesAñoGeneral({ mes: '', año: '', });
        setPagosProfe([]);
        setDesdeHasta({ desde: new Date().toJSON().slice(0, 10), hasta: new Date().toJSON().slice(0, 10) });

    }
    // console.log({ desdeHasta });
    return (
        <Grid container sx={{ minHeight: '100vh', alignContent: 'center', justifyContent: 'center' }}>
            <Snackbar open={alertGeneral.open} autoHideDuration={6000} onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} severity={alertGeneral.type} sx={{ width: '100%' }}>
                    {alertGeneral.text}
                </Alert>
            </Snackbar>
            <Grid item md={11.5} xs={12}>
                <Paper elevation={1} style={{ padding: '1.5em' }}>
                    <Grid container sx={{ padding: '8px', justifyContent: 'space-between', textAlign: 'center' }}>
                        <Typography gutterBottom align="center" color="text.primary" sx={{ fontSize: typogFuente.size, fontFamily: typogFuente.fuente }}>
                            REPORTE DE CAJA
                        </Typography>
                    </Grid>
                    <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label="GENERAL" value="1" onClick={limpiarEstadosTab} />
                                            <Tab label="POR PROFESIONAL" value="2" onClick={limpiarEstadosTab} />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
                                            <Grid item md={12} xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item md={3} xs={12}>
                                                        <Autocomplete
                                                            options={meses}
                                                            getOptionLabel={(option) => option.name}
                                                            onChange={(e, values) => {
                                                                if (values != null) {
                                                                    setMesAñoGeneral({ ...mesAñoGeneral, mes: `${values.id}` });
                                                                } else {
                                                                    setMesAñoGeneral({ ...mesAñoGeneral, mes: new Date().getMonth() });
                                                                }
                                                            }}
                                                            renderInput={(params) => <TextField {...params} variant="standard" margin='normal' label="MES" fullWidth />}
                                                        />
                                                    </Grid>
                                                    <Grid item md={3} xs={12}>
                                                        <Autocomplete
                                                            options={años}
                                                            // value={años ? años : new Date().getFullYear()}
                                                            onChange={(e, values) => {
                                                                if (values != null) {
                                                                    setMesAñoGeneral({ ...mesAñoGeneral, año: values.año });
                                                                } else {
                                                                    setMesAñoGeneral({ ...mesAñoGeneral, año: new Date().getFullYear() });
                                                                }
                                                            }}
                                                            getOptionLabel={(option) => option.año}
                                                            renderInput={(params) => <TextField {...params} variant="standard" margin='normal' label="AÑO" fullWidth />}
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <Grid container spacing={2} style={{ paddingTop: "1.5em" }} >
                                                            <Grid item md={2} xs={6}>
                                                                <Tooltip title="Buscar" placement='top'>
                                                                    <Button fullWidth sx={{ alignItems: "center" }} variant="contained" color="warning"
                                                                        onClick={filtrarCajas}
                                                                    ><SearchIcon /></Button>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item md={2} xs={6}>
                                                                {
                                                                    cajaGeneral.length === 0 ?
                                                                        <Tooltip title="EXPORTAR PDF" placement='top'>
                                                                            <Button variant='contained' fullWidth color="error" startIcon={<img style={{ width: '20px', height: '20px' }} src={pdf} />} onClick={sinBusqueda}>PDF</Button>
                                                                        </Tooltip>
                                                                        :
                                                                        <PDFDownloadLink document={<Pdf reportCaja={cajaGeneral} cajas={cajaUR} datosEmpresa={datosEmpresa} filename="FORM" />}>
                                                                            <Tooltip title="EXPORTAR PDF" placement='top'>
                                                                                <Button variant='contained' fullWidth color="error" startIcon={<img style={{ width: '20px', height: '20px' }} src={pdf} />} >PDF</Button>
                                                                            </Tooltip>
                                                                        </PDFDownloadLink>
                                                                }

                                                            </Grid>
                                                            <Grid item md={2} xs={6}>
                                                                {
                                                                    cajaGeneral.length === 0 ?
                                                                        <Tooltip title="EXPORTAR EXCEL" placement='top'>
                                                                            <Button variant='contained' fullWidth color="success" startIcon={<img style={{ width: '20px', height: '20px' }} src={excel} />} onClick={sinBusqueda}>EXCEL</Button>
                                                                        </Tooltip>
                                                                        :
                                                                        <Excel data={cajaGeneral} />
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Paper>
                                                    <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}  >
                                                        <Table aria-label="simple table" >
                                                            <TableHead sx={{ backgroundColor: "#bde0fe" }}>
                                                                <TableRow >
                                                                    <TableCell style={{ width: '6%' }}>N° CAJA</TableCell>
                                                                    <TableCell>ESTADO CAJA</TableCell>
                                                                    <TableCell style={{ width: '6%' }}>N° PAGO</TableCell>
                                                                    <TableCell>F. REGISTRO</TableCell>
                                                                    <TableCell>PROFESIONAL</TableCell>
                                                                    <TableCell>ORDEN</TableCell>
                                                                    <TableCell>SERVICIO</TableCell>
                                                                    <TableCell>TIPO PAGO</TableCell>
                                                                    <TableCell>PRESUPUESTO</TableCell>
                                                                    <TableCell>PAGADO</TableCell>
                                                                    <TableCell>REFERENCIA </TableCell>
                                                                    <TableCell>ESTADO PAGO</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    (rowsPerPage > 0
                                                                        ? cajaGeneral.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                        : cajaGeneral
                                                                    ).map((caja) => (
                                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={caja.id_pago}>
                                                                            <TableCell component="th" scope="row">
                                                                                {caja.id_caja}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {caja.fecha_cierre === null ? <Chip variant="contained" color="error" label="ABIERTO" /> : <Chip variant="contained" color="success" label="CERRADO" />}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {caja.id_pago}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {caja.fecha_pago}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {caja.profesional}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {caja.orden_descripcion}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {caja.servicio}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {caja.tipo === "E" ? 'EFECTIVO' : caja.tipo === 'Y' ? 'YAPE' : caja.tipo === "D" ? 'DEPOSITO' : caja.tipo === "T" ? 'TARJETA' : ''}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {caja.presupuesto}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {caja.total_pagado}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {caja.referencia}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {caja.estado_pago === 'A' ? <Chip variant="contained" color="warning" label="A CUENTA" /> : caja.estado_pago === 'G' ?
                                                                                    <Chip variant="contained" color="success" label="PAGADO" /> : ''}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                        {cajaGeneral.length < 1 &&
                                                            <Grid sx={{ justifyItems: "center", textAlign: "center ", padding: "5em 0" }}>
                                                                <Typography gutterBottom align="center" color="text.secondary" sx={{ fontSize: "15px", fontFamily: typogFuente.fuente }}>
                                                                    NO SE ENCONTRARON DATOS
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    </TableContainer>
                                                    <TablePagination
                                                        rowsPerPageOptions={[10, 25, 50]}
                                                        component="div"
                                                        labelRowsPerPage="Filas por columnas"
                                                        count={cajaGeneral.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
                                            <Grid item md={4} xs={12} >
                                                <Autocomplete
                                                    multiple
                                                    fullWidth
                                                    limitTags={1}
                                                    options={profesionales ? profesionales : []}
                                                    disableCloseOnSelect
                                                    onChange={hcSelectProf}
                                                    getOptionLabel={(option) => option.apellidos_nombres}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>
                                                            <Checkbox
                                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option.apellidos_nombres}
                                                        </li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField {...params} margin='normal' variant="standard" label="BUSCAR PROFESIONAL" placeholder="Seleccionar" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item md={8} xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item md={3} xs={12}>
                                                        <TextField
                                                            id="filtro_desde"
                                                            label="FILTRO DESDE"
                                                            type="date"
                                                            fullWidth
                                                            variant='standard'
                                                            margin='normal'
                                                            value={desdeHasta ? desdeHasta.desde : ''}
                                                            onChange={(e) => {
                                                                const fechaFiltroD = e.target.value;
                                                                if (fechaFiltroD != null) {
                                                                    setDesdeHasta({ ...desdeHasta, desde: fechaFiltroD });
                                                                } else {
                                                                    setDesdeHasta({ ...desdeHasta, desde: '' });
                                                                }
                                                            }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <CalendarMonthIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item md={3} xs={12}>
                                                        <TextField
                                                            id="filtro_hasta"
                                                            label="HASTA"
                                                            type="date"
                                                            fullWidth
                                                            variant='standard'
                                                            margin='normal'
                                                            value={desdeHasta ? desdeHasta.hasta : ''}
                                                            onChange={(e) => {
                                                                const fechaFiltroS = e.target.value;
                                                                if (fechaFiltroS != null) {
                                                                    setDesdeHasta({ ...desdeHasta, hasta: fechaFiltroS });
                                                                } else {
                                                                    setDesdeHasta({ ...desdeHasta, hasta: '' });
                                                                }
                                                            }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <CalendarMonthIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <Grid container style={{ paddingTop: "1.5em" }} spacing={2}>
                                                            <Grid item md={3} xs={6}>
                                                                <Tooltip title="Buscar" placement='top'>
                                                                    <Button fullWidth sx={{ alignItems: "center" }} variant="contained" color="warning"
                                                                        onClick={() => filtrarCajasProfesional()}
                                                                    ><SearchIcon /></Button>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item md={3} xs={6}>
                                                                {
                                                                    pagosProfe.length === 0 ?
                                                                        <Tooltip title="EXPORTAR PDF" placement='top'>
                                                                            <Button variant='contained' fullWidth color="error" startIcon={<img style={{ width: '20px', height: '20px' }} src={pdf} />} onClick={sinBusqueda}>PDF</Button>
                                                                        </Tooltip>
                                                                        :
                                                                        <PDFDownloadLink document={<PdfProfesional profesionalesS={tabMesAñoProfID} datosEmpresa={datosEmpresa} reportCaja={pagosProfe} profesionales={profesionales} filename="FORM" />}>
                                                                            <Tooltip title="EXPORTAR PDF" placement='top'>
                                                                                <Button variant='contained' fullWidth color="error" startIcon={<img style={{ width: '20px', height: '20px' }} src={pdf} />} >PDF</Button>
                                                                            </Tooltip>
                                                                        </PDFDownloadLink>
                                                                }
                                                            </Grid>
                                                            <Grid item md={3} xs={6}>
                                                                {
                                                                    pagosProfe.length === 0 ?
                                                                        <Tooltip title="EXPORTAR EXCEL" placement='top'>
                                                                            <Button variant='contained' fullWidth color="success" startIcon={<img style={{ width: '20px', height: '20px' }} src={excel} />} onClick={sinBusqueda}>EXCEL</Button>
                                                                        </Tooltip>
                                                                        :
                                                                        <Excel data={pagosProfe} />}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <TableContainer component={Paper} sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}  >
                                                    <Table aria-label="simple table" >
                                                        <TableHead sx={{ backgroundColor: "#bde0fe" }}>
                                                            <TableRow >
                                                                <TableCell style={{ width: '6%' }}>N° CAJA</TableCell>
                                                                <TableCell>ESTADO CAJA</TableCell>
                                                                <TableCell style={{ width: '6%' }}>N° PAGO</TableCell>
                                                                <TableCell>F. REGISTRO</TableCell>
                                                                <TableCell>PROFESIONAL</TableCell>
                                                                <TableCell>ORDEN</TableCell>
                                                                <TableCell>SERVICIO</TableCell>
                                                                <TableCell>TIPO PAGO</TableCell>
                                                                <TableCell>PRESUPUESTO</TableCell>
                                                                <TableCell>PAGADO</TableCell>
                                                                <TableCell>REFERENCIA </TableCell>
                                                                <TableCell>ESTADO PAGO</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                (rowsPerPage2 > 0
                                                                    ? pagosProfe.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                                                                    : pagosProfe
                                                                ).map((caja) => (
                                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={caja.id_pago}>
                                                                        <TableCell component="th" scope="row">
                                                                            {caja.id_caja}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {caja.fecha_cierre === null ? <Chip variant="contained" color="success" label="ABIERTO" size='small' /> : <Chip variant="contained" color="error" label="CERRADO" size='small' />}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {caja.id_pago}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {caja.fecha_pago}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {caja.profesional}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {caja.orden_descripcion}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {caja.servicio}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {caja.tipo === "E" ? 'EFECTIVO' : caja.tipo === 'Y' ? 'YAPE' : caja.tipo === "D" ? 'DEPOSITO' : caja.tipo === "T" ? 'TARJETA' : ''}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {caja.presupuesto}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {caja.total_pagado}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {caja.referencia}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {caja.estado_pago === 'A' ? <Chip variant="contained" color="warning" label="A CUENTA" size='small' /> : caja.estado_pago === 'G' ?
                                                                                <Chip variant="contained" color="success" label="PAGADO" size='small' /> : ''}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                    {pagosProfe.length < 1 &&
                                                        <Grid sx={{ justifyItems: "center", textAlign: "center ", padding: "5em 0" }}>
                                                            <Typography gutterBottom align="center" color="text.secondary" sx={{ fontSize: "15px", fontFamily: typogFuente.fuente }}>
                                                                NO SE ENCONTRARON DATOS
                                                            </Typography>
                                                        </Grid>
                                                    }
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 50]}
                                                    component="div"
                                                    labelRowsPerPage="Filas por columnas"
                                                    count={cajaGeneral.length}
                                                    rowsPerPage={rowsPerPage2}
                                                    page={page2}
                                                    onPageChange={handleChangePage2}
                                                    onRowsPerPageChange={handleChangeRowsPerPage2}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value="3">Items Three</TabPanel>
                                </TabContext>
                            </Box>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid >
        </Grid >
    )
}
