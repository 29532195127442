import * as React from "react";
import { Alert, Button, Collapse, Checkbox, FormControlLabel, Grid, Paper, TextField, IconButton, FormControl, InputLabel, Input, InputAdornment } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

import Logo from '../img/logo-guzman.svg';
import UrlsConst from '../config/config';
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function Login(props) {
    const [typeAlert, setTypeAlert] = React.useState('success');
    const [textAlert, setTextAlert] = React.useState('');
    const [alert, setAlert] = React.useState(false);
    const navigate = useNavigate();
    // objeto usuarios
    const [usuario, setUsuario] = React.useState({
        codigo: "",
        clave: ""
    });
    // objeto usuario
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUsuario({ ...usuario, [name]: value.toUpperCase() });
    }
    // cambiar de input
    const cambiarInput = (e) => {
        var input = document.getElementById('nombres').value;
        if (input !== "" && e.which == 13) {
            document.getElementById('contraseña').focus();
        }
    }
    // cambiar de input a botton
    const cambiarBtn = (e) => {
        var input = document.getElementById('contraseña').value;
        if (input !== "" && e.which == 13) {
            enviarDatos();
        }
    }
    // conexion
    const enviarDatos = async () => {
        if (usuario.codigo !== "" && usuario.clave !== "") {
            const login = await fetch(`${UrlsConst.URL_API}/sistema/login.php`, {
                method: "POST",
                body: JSON.stringify(usuario),
            });
            const json = await login.json();
            if (json.authorized === true) {
                sessionStorage.setItem('usuario_log', JSON.stringify(json));
                const perfilMenusFetch = await fetch(`${UrlsConst.URL_API}/sistema/perfilDetalle/listarMenuPerfil.php?codigo=${json.perfil}`);
                const perfilJSON = await perfilMenusFetch.json();
                props.datosPerfilDetalle(perfilJSON);
                props.parentCallBack(true);
                navigate("/");
            } else {
                if (json.authorized === false) {
                    setTypeAlert('warning');
                    setTextAlert('La Contraseña es incorrecta.');
                    setAlert(true);
                    setTimeout(() => { setAlert(false) }, 3500);
                } else if (json.usuario === 'ERROR' && json.authorized === false) {
                    setTypeAlert('error');
                    setTextAlert('ERROR: El usuario no esta registrado o no se encuentra activo. Verifique los datos e intente de nuevo. Si el problema persiste contacte al administrador del sistema.');
                    setAlert(true);
                    setTimeout(() => { setAlert(false) }, 3500);
                }
            }
        } else {
            setTypeAlert('error');
            setTextAlert('ERROR: Los campos de usuario y clave no pueden ir vacios');
            setAlert(true);
            setTimeout(() => { setAlert(false) }, 2500);
        }
    }


    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const handleChangePassword = (prop) => (event) => {
        const { name } = event.target
        setValues({ ...values, [prop]: event.target.value });
        setUsuario({ ...usuario, [name]: event.target.value });
    };

    const handleClickShowPassword = (e, value) => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Grid container className="container-app" style={{ minHeight: '100vh' }} justifyContent="center" alignContent="center">
            <Grid item xs={10} sm={6} md={3.5} xl={2.5} textAlign="center">
                <Paper elevation={3} style={{ padding: "20px", borderRadius: "10px" }}>
                    <Collapse in={alert}>
                        <Alert severity={typeAlert} action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlert(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>}>
                            {textAlert}
                        </Alert>
                    </Collapse>
                    <Grid item xs={12}>
                        <img src={Logo} width='100%' height='120px'></img>
                    </Grid>
                    <Grid item xs={12} >
                        <h1>BIENVENIDO</h1>
                        <TextField required onKeyUp={cambiarInput} autoFocus fullWidth id="nombres" label="USUARIO" variant="standard" name="codigo" margin="normal" onChange={handleChange} value={usuario.codigo} />
                    </Grid>
                    <FormControl sx={{ mt: 4, mb: 4, width: '100%' }} variant="standard">
                        <InputLabel htmlFor="standard-adornment-password">CLAVE *</InputLabel>
                        <Input
                            required
                            id="contraseña"
                            onKeyUp={cambiarBtn}
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            name="clave"
                            onChange={handleChangePassword('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Grid item xs={12}>
                        <Button type="submit" fullWidth margin="normal" variant="contained" color="warning" id="btnSubmit" onClick={enviarDatos}>Iniciar Sesion</Button>
                        <p>Olvidaste tu contraseña - contactar con el administrador</p>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
export default Login;