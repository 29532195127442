import * as React from "react";
import { Divider, List, ListItemButton, ListItemText, ListItemIcon, Collapse, Grid, IconButton, Button, Avatar, Stack, DialogTitle, Dialog, DialogContent, FormControl, InputLabel, Input, InputAdornment, DialogActions, Alert, Snackbar, Typography, Chip } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Link, useLocation } from 'react-router-dom';
// icons mui
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import Person2Icon from '@mui/icons-material/Person2';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import DescriptionIcon from '@mui/icons-material/Description';
import TaskIcon from '@mui/icons-material/Task';
import GradingIcon from '@mui/icons-material/Grading';
import { deepOrange, lightBlue } from "@mui/material/colors";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import UrlsConst from "../../config/config";
import DialogPasswordUpdate from "../sistema/Dialogs/dialogPasswordUpdate";
let drawerWidth = 290;

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: 11.5,
        Transform: 'translate(0, 0)',
    },
}));

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: window.screen.width <= 500 ? 0 : `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: window.screen.width <= 600 ? 0 : drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function Navbar(props) {
    const perfilDetalleProps = props.perfilDetalle;
    const cerrarSesion = () => {
        props.parentLogOut();
        props.setOpen(false);
    }
    const margin = 4;
    const handleDrawerOpen = () => {
        props.setOpen(true);
    };
    const handleDrawerClose = () => {
        props.setOpen(false);
        props.setOpen1(false);
        props.setOpen2(false);
        props.setOpen3(false);
        props.setOpen4(false);
        props.setOpen5(false);
        props.setOpen6(false);
    };
    const handleClick = (collapse) => {
        if (collapse === 'collapse1') {
            props.setOpen1(!props.open1);
            props.setOpen2(false);
            props.setOpen3(false);
            props.setOpen4(false);
            props.setOpen5(false);
            props.setOpen6(false);
        }
        if (collapse === 'collapse2') {
            props.setOpen1(false);
            props.setOpen2(!props.open2);
            props.setOpen3(false);
            props.setOpen4(false);
            props.setOpen5(false);
            props.setOpen6(false);
        }
        if (collapse === 'collapse3') {
            props.setOpen1(false);
            props.setOpen2(false);
            props.setOpen3(!props.open3);
            props.setOpen4(false);
            props.setOpen5(false);
            props.setOpen6(false);
        }// REP
        if (collapse === 'collapse4') {
            props.setOpen1(false);
            props.setOpen2(false);
            props.setOpen3(false);
            props.setOpen4(!props.open4);
            props.setOpen5(false);
            props.setOpen6(false);
        } // SYS
        if (collapse === 'collapse5') {
            props.setOpen1(false);
            props.setOpen2(false);
            props.setOpen3(false);
            props.setOpen4(false);
            props.setOpen5(!props.open5);
            props.setOpen6(false);
        }// MAN
        if (collapse === 'collapse6') {
            props.setOpen1(false);
            props.setOpen2(false);
            props.setOpen3(false);
            props.setOpen4(false);
            props.setOpen5(false);
            props.setOpen6(!props.open6);
        }// CAJ
    };
    let location = useLocation();

    //password
    const [showPasswordUpdate, setShowPasswordUpdate] = React.useState(false);
    const userlog = JSON.parse(sessionStorage.getItem("usuario_log"));

    return (
        <>
            <Dialog open={showPasswordUpdate} onClose={() => setShowPasswordUpdate(false)} sx={{ zIndex: 1310 }}>
                <DialogTitle>
                    CAMBIAR CONTRASEÑA
                    <IconButton
                        aria-label="close"
                        onClick={() => setShowPasswordUpdate(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "#757575",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogPasswordUpdate parentClose={() => { setShowPasswordUpdate(false); }} />
                </DialogContent>
            </Dialog>
            <Button style={{ marginTop: '6px', marginLeft: '0px', height: '40px', width: '30px', position: 'fixed', zIndex: '2', background: '#fff', border: '1px solid #000'}}
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}>
                <MenuIcon style={{ color: '#8aa4af' }} />
            </Button>
            <Drawer variant="permanent" open={props.open} className="navbar">
                <DrawerHeader style={{ background: '#222d32', marginLeft: '0px', justifyContent: props.open ? 'end' : 'start' }}>
                    {props.open ?
                        <Stack direction="row" spacing={2} sx={{ justifyContent: "space-between", width: "100%", paddingLeft: "10px" }}>
                            <BootstrapTooltip title="Cambiar Contraseña" placement="right">
                                <Avatar onClick={() => { setShowPasswordUpdate(true); handleDrawerClose(); }} sx={{ bgcolor: "#8aa4af", color: "#222d32" }}>{userlog ? userlog.usuario.charAt(0) : 0}</Avatar>
                            </BootstrapTooltip>
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon style={{ color: '#8aa4af' }} />
                            </IconButton>
                        </Stack>
                        :
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}>
                            <MenuIcon style={{ color: '#8aa4af' }} />
                        </IconButton>
                    }
                </DrawerHeader>
                <List className="list1 navItem">
                    <Link to="/" style={{ textDecoration: 'none', color: '#8aa4af' }}>
                        {props.open ?
                            <ListItemButton className="textDrawer" onClick={() => props.setOpen(false)}>
                                <ListItemIcon>
                                    <HomeIcon className="textDrawer" />
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItemButton>
                            :
                            <BootstrapTooltip title="HOME" placement="right">
                                <ListItemButton className="textDrawer" onClick={() => props.setOpen(true)}>
                                    <ListItemIcon>
                                        <HomeIcon className="textDrawer" />
                                    </ListItemIcon>
                                    <ListItemText primary="Home" />
                                </ListItemButton>
                            </BootstrapTooltip>
                        }
                    </Link>
                </List>
                {perfilDetalleProps.filter(l => l.codigo === "ORD").map((menuP) => (
                    <List key={menuP.codigo} sx={{ bgcolor: '#222d32' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        {props.open ?
                            <ListItemButton id="collapse1" onClick={() => { handleClick('collapse1') }}
                                className={location.pathname === '/nuevaorden' || location.pathname === '/ordenservicio' || location.pathname === '/ordenprofesional' || location.pathname === '/historial' ? "sidebar_active_barra" : ""}>
                                <ListItemIcon>
                                    <AssignmentIcon className="textDrawer" />
                                </ListItemIcon>
                                <ListItemText primary={menuP.nombre} className="textDrawer" />
                                {props.open4 ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" />}
                            </ListItemButton>
                            :
                            <ListItemButton id="collapse1" onClick={() => { props.setOpen(true) }}
                                className={location.pathname === '/nuevaorden' || location.pathname === '/ordenservicio' || location.pathname === '/ordenprofesional' || location.pathname === '/historial' ? "sidebar_active" : ""}>
                                <BootstrapTooltip title={menuP.nombre} placement="right">
                                    <ListItemIcon>
                                        <AssignmentIcon
                                            className={location.pathname === '/nuevaorden' || location.pathname === '/ordenservicio' || location.pathname === '/ordenprofesional' || location.pathname === '/historial' ? "sidebar_active_icon" : "textDrawer"} />
                                    </ListItemIcon>
                                </BootstrapTooltip>

                                <ListItemText primary={menuP.nombre} className="textDrawer" />
                                {props.open4 ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" />}
                            </ListItemButton>
                        }
                        <Collapse in={props.open1} timeout="auto" unmountOnExit onClick={() => { props.setOpen(false) }}>
                            <List component="div" disablePadding >
                                {perfilDetalleProps.filter(f => f.fk_log_menu_padre === "ORD").map((menuH, index) => (
                                    <Link key={menuH.codigo + index} to={menuH.href} style={{ textDecoration: 'none' }} onClick={() => props.setOpen1(false)}>
                                        <ListItemButton sx={{ pl: margin }}>
                                            <ListItemIcon>
                                                {menuH.codigo === "ORD_HIS" ? <ManageHistoryIcon className={location.pathname === '/historial' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                    menuH.codigo === "ORD_ORD" ? <PlagiarismIcon className={location.pathname === '/ordenservicio' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                        menuH.codigo === "ORD_PRO" ? <AssignmentIndIcon className={location.pathname === '/ordenprofesional' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                            menuH.codigo === "ORD_NEW" ? <NoteAddIcon className={location.pathname === '/nuevaorden' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                                <NoteAddIcon className="textDrawer" />}
                                            </ListItemIcon>
                                            <ListItemText primary={menuH.nombre} className="textDrawer" />
                                        </ListItemButton>
                                    </Link>
                                ))}
                            </List>
                        </Collapse>
                    </List>

                ))}
                {perfilDetalleProps.filter(l => l.codigo === "PRU").map((menuP) => (
                    <List sx={{ bgcolor: '#222d32' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        key={menuP.codigo}
                    >
                        {props.open ?
                            <ListItemButton id="collapse2" onClick={() => { handleClick('collapse2') }}
                                className={location.pathname === '/ordenpruebas' || location.pathname === '/pruebastrabajador' || location.pathname === '/pruebasprofesional' ? "sidebar_active_barra" : ""}>
                                <ListItemIcon>
                                    <ContentPasteGoIcon className="textDrawer" />
                                </ListItemIcon>
                                <ListItemText primary={menuP.nombre} className="textDrawer" />
                                {props.open4 ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" />}
                            </ListItemButton>
                            :
                            <ListItemButton id="collapse2" onClick={() => { props.setOpen(true) }}
                                className={location.pathname === '/ordenpruebas' || location.pathname === '/pruebastrabajador' || location.pathname === '/pruebasprofesional' ? "sidebar_active" : ""}>
                                <BootstrapTooltip title={menuP.nombre} placement="right">
                                    <ListItemIcon>
                                        <ContentPasteGoIcon className={location.pathname === '/ordenpruebas' || location.pathname === '/pruebastrabajador' || location.pathname === '/pruebasprofesional' ? "sidebar_active_icon" : "textDrawer"} />
                                    </ListItemIcon>
                                </BootstrapTooltip>

                                <ListItemText primary={menuP.nombre} className="textDrawer" />
                                {props.open4 ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" />}
                            </ListItemButton>
                        }
                        <Collapse in={props.open2} timeout="auto" unmountOnExit onClick={() => { props.setOpen(false) }}>
                            <List component="div" disablePadding >
                                {perfilDetalleProps.filter(f => f.fk_log_menu_padre === "PRU").map((menuH, index) => (
                                    <Link key={menuH.codigo + index} to={menuH.href} style={{ textDecoration: 'none' }} onClick={() => { props.setOpen2(false) }}>
                                        <ListItemButton sx={{ pl: margin }}>
                                            <ListItemIcon>
                                                {menuH.codigo === "PRU_ORD" ? <AssignmentTurnedInIcon className={location.pathname === '/ordenpruebas' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                    menuH.codigo === "PRU_PRO" ? <AssignmentIndIcon className={location.pathname === '/pruebasprofesional' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                        menuH.codigo === "PRU_TRA" ? <ManageAccountsSharpIcon className={location.pathname === '/pruebastrabajador' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                            <ContentPasteSearchIcon className="textDrawer" />}
                                            </ListItemIcon>
                                            <ListItemText primary={menuH.nombre} className="textDrawer" />
                                        </ListItemButton>
                                    </Link>
                                ))}
                            </List>
                        </Collapse>
                    </List>

                ))}
                {perfilDetalleProps.filter(l => l.codigo === "SYS").map((menuP) => (
                    <List sx={{ bgcolor: '#222d32' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        key={menuP.codigo}
                    >
                        {props.open ?
                            <ListItemButton id="collapse4" onClick={() => { handleClick('collapse4') }}
                                className={location.pathname === '/parametro' || location.pathname === '/trabajador' || location.pathname === '/usuario' || location.pathname === '/perfil' ? "sidebar_active_barra" : ""}>
                                <ListItemIcon>
                                    <AddToQueueIcon className="textDrawer" />
                                </ListItemIcon>
                                <ListItemText primary={menuP.nombre} className="textDrawer" />
                                {props.open4 ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" />}
                            </ListItemButton>
                            :
                            <ListItemButton id="collapse4" onClick={() => { props.setOpen(true) }}
                                className={location.pathname === '/parametro' || location.pathname === '/trabajador' || location.pathname === '/usuario' || location.pathname === '/perfil' ? "sidebar_active" : ""}>
                                <BootstrapTooltip title={menuP.nombre} placement="right">
                                    <ListItemIcon>
                                        <AddToQueueIcon
                                            className={location.pathname === '/parametro' || location.pathname === '/trabajador' || location.pathname === '/usuario' || location.pathname === '/perfil' ? "sidebar_active_icon" : "textDrawer"} />
                                    </ListItemIcon>
                                </BootstrapTooltip>

                                <ListItemText primary={menuP.nombre} className="textDrawer" />
                                {props.open4 ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" />}
                            </ListItemButton>
                        }
                        <Collapse in={props.open4} timeout="auto" unmountOnExit onClick={() => { props.setOpen(false) }}>
                            <List component="div" disablePadding >
                                {perfilDetalleProps.filter(f => f.fk_log_menu_padre === "SYS").map((menuH, index) => (
                                    <Link key={menuH.codigo + index} to={menuH.href} style={{ textDecoration: 'none', color: '#000' }} onClick={() => { props.setOpen4(false) }}>
                                        <ListItemButton sx={{ pl: margin }}>
                                            <ListItemIcon>
                                                {menuH.codigo === "SYS_PAR" ? <AssessmentIcon className={location.pathname === '/parametro' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                    menuH.codigo === "SYS_TRA" ? <ManageAccountsSharpIcon className={location.pathname === '/trabajador' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                        menuH.codigo === "SYS_PER" ? <AccountBoxIcon className={location.pathname === '/perfil' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                            menuH.codigo === "SYS_USU" ? <PersonSharpIcon className={location.pathname === '/usuario' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                                <AddToQueueIcon className="textDrawer" />}
                                            </ListItemIcon>
                                            <ListItemText primary={menuH.nombre} className="textDrawer" />
                                        </ListItemButton>
                                    </Link>
                                ))}
                            </List>
                        </Collapse>
                    </List>
                ))}
                {perfilDetalleProps.filter(l => l.codigo === "MAN").map((menuP) => (
                    <List sx={{ bgcolor: '#222d32' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        key={menuP.codigo}
                    >
                        {props.open ?
                            <ListItemButton id="collapse5" onClick={() => { handleClick('collapse5') }}
                                className={location.pathname === '/servicio' || location.pathname === '/profesional' || location.pathname === '/paciente' ? "sidebar_active_barra" : ""}>
                                <ListItemIcon>
                                    <EngineeringIcon className="textDrawer" />
                                </ListItemIcon>
                                <ListItemText primary={menuP.nombre} className="textDrawer" />
                                {props.open4 ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" />}
                            </ListItemButton>
                            :
                            <ListItemButton id="collapse5" onClick={() => { props.setOpen(true) }}
                                className={location.pathname === '/servicio' || location.pathname === '/profesional' || location.pathname === '/paciente' ? "sidebar_active" : ""}>
                                <BootstrapTooltip title={menuP.nombre} placement="right">
                                    <ListItemIcon>
                                        <EngineeringIcon
                                            className={location.pathname === '/servicio' || location.pathname === "/profesional" || location.pathname === '/paciente' ? "sidebar_active_icon" : "textDrawer"} />
                                    </ListItemIcon>
                                </BootstrapTooltip>

                                <ListItemText primary={menuP.nombre} className="textDrawer" />
                                {props.open4 ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" />}
                            </ListItemButton>
                        }
                        <Collapse in={props.open5} timeout="auto" unmountOnExit onClick={() => { props.setOpen(false) }}>
                            <List component="div" disablePadding >
                                {perfilDetalleProps.filter(f => f.fk_log_menu_padre === "MAN").map((menuH, index) => (
                                    <Link key={menuH.codigo + index} to={menuH.href} style={{ textDecoration: 'none', color: '#000' }} onClick={() => { props.setOpen5(false) }}
                                    // className={location.pathname === menuH.href ? "sidebar_active2" : ""}
                                    >
                                        <ListItemButton sx={{ pl: margin }}>
                                            <ListItemIcon>
                                                {menuH.codigo === "MAN_PAC" ? <Person2Icon className={location.pathname === '/paciente' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                    menuH.codigo === "MAN_PRO" ? <AttachMoneyIcon className={location.pathname === '/profesional' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                        menuH.codigo === "MAN_SER" ? <MedicalServicesIcon className={location.pathname === '/servicio' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                            <PointOfSaleIcon className="textDrawer" />}
                                            </ListItemIcon>
                                            <ListItemText primary={menuH.nombre} className="textDrawer" />
                                        </ListItemButton>
                                    </Link>
                                ))}
                            </List>
                        </Collapse>
                    </List>

                ))}
                {perfilDetalleProps.filter(l => l.codigo === "CAJ").map((menuP) => (
                    <List sx={{ bgcolor: '#222d32' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        key={menuP.codigo}
                    >
                        {props.open ?
                            <ListItemButton id="collapse6" onClick={() => { handleClick('collapse6') }}
                                className={location.pathname === '/cobranza' ? "sidebar_active_barra" : ""}>
                                <ListItemIcon>
                                    <PointOfSaleIcon className="textDrawer" />
                                </ListItemIcon>
                                <ListItemText primary={menuP.nombre} className="textDrawer" />
                                {props.open4 ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" />}
                            </ListItemButton>
                            :
                            <ListItemButton id="collapse6" onClick={() => { props.setOpen(true) }}
                                className={location.pathname === '/cobranza' ? "sidebar_active" : ""} >
                                <BootstrapTooltip title={menuP.nombre} placement="right">
                                    <ListItemIcon>
                                        <PointOfSaleIcon className={location.pathname === '/cobranza' ? "sidebar_active_icon" : "textDrawer"} />
                                    </ListItemIcon>
                                </BootstrapTooltip>

                                <ListItemText primary={menuP.nombre} className="textDrawer" />
                                {props.open4 ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" />}
                            </ListItemButton>
                        }
                        <Collapse in={props.open6} timeout="auto" unmountOnExit onClick={() => { props.setOpen(false) }}>
                            <List component="div" disablePadding >
                                {perfilDetalleProps.filter(f => f.fk_log_menu_padre === "CAJ").map((menuH, index) => (
                                    <Link key={menuH.codigo + index} to={menuH.href} style={{ textDecoration: 'none', color: '#000' }} onClick={() => { props.setOpen6(false) }}>
                                        <ListItemButton sx={{ pl: margin }}>
                                            <ListItemIcon>
                                                {menuH.codigo === "CAJ_COB" ?
                                                    <AttachMoneyIcon className={location.pathname === '/cobranza' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                    <PointOfSaleIcon className="textDrawer" />}
                                            </ListItemIcon>
                                            <ListItemText primary={menuH.nombre} className="textDrawer" />
                                        </ListItemButton>
                                    </Link>
                                ))}
                            </List>
                        </Collapse>
                    </List>

                ))}
                {perfilDetalleProps.filter(l => l.codigo === "REP").map((menuP) => (
                    <List sx={{ bgcolor: '#222d32' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        key={menuP.codigo}
                    >
                        {props.open ?
                            <ListItemButton id="collapse3" onClick={() => { handleClick('collapse3') }}
                                className={location.pathname === '/rordenserv' || location.pathname === '/rtrabajo' || location.pathname === '/reportCaja' ? "sidebar_active_barra" : ""}>
                                <ListItemIcon>
                                    <DescriptionIcon className="textDrawer" />
                                </ListItemIcon>
                                <ListItemText primary={menuP.nombre} className="textDrawer" />
                                {props.open4 ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" />}
                            </ListItemButton>
                            :
                            <ListItemButton id="collapse3" onClick={() => { props.setOpen(true) }}
                                className={location.pathname === '/rordenserv' || location.pathname === '/rtrabajo' || location.pathname === '/reportCaja' ? "sidebar_active" : ""}>
                                <BootstrapTooltip title={menuP.nombre} placement="right">
                                    <ListItemIcon>
                                        <DescriptionIcon className={location.pathname === '/rordenserv' || location.pathname === '/rtrabajo' || location.pathname === '/reportCaja' ? "sidebar_active_icon" : "textDrawer"} />
                                    </ListItemIcon>
                                </BootstrapTooltip>

                                <ListItemText primary={menuP.nombre} className="textDrawer" />
                                {props.open4 ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" />}
                            </ListItemButton>
                        }
                        <Collapse in={props.open3} timeout="auto" unmountOnExit onClick={() => { props.setOpen(false) }}>
                            <List component="div" disablePadding >
                                {perfilDetalleProps.filter(f => f.fk_log_menu_padre === "REP").map((menuH, index) => (
                                    <Link key={menuH.codigo + index} to={menuH.href} style={{ textDecoration: 'none', color: '#000' }} onClick={() => { props.setOpen3(false) }}
                                    >
                                        <ListItemButton sx={{ pl: margin }}>
                                            <ListItemIcon>
                                                {menuH.codigo === "REP_ORD" ? <TaskIcon className={location.pathname === '/rordenserv' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                    menuH.codigo === "REP_TRAB" ? <GradingIcon className={location.pathname === '/rtrabajo' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                        menuH.codigo === "REP_CAJA" ? <RequestPageIcon className={location.pathname === '/reportCaja' ? "sidebar_active_barra2" : "textDrawer"} /> :
                                                            <DescriptionIcon className="textDrawer" />}
                                            </ListItemIcon>
                                            <ListItemText primary={menuH.nombre} className="textDrawer" />
                                        </ListItemButton>
                                    </Link>
                                ))}
                            </List>
                        </Collapse>
                    </List>

                ))}
                <Grid item xs style={{ background: '#222d32', color: '#8aa4af' }} ></Grid>
                <Grid container direction="row" justifyContent="center" align="bottom">
                    <ListItemButton onClick={cerrarSesion} style={{ background: '#222d32', color: '#8aa4af' }} className="textDrawer">
                        <ListItemIcon>
                            <LogoutSharpIcon className="textDrawer" />
                        </ListItemIcon>
                        <ListItemText primary="Salir" />
                    </ListItemButton>
                </Grid>
            </Drawer>
        </>
    );
}
export default Navbar;