
import { Avatar, Button, CardContent, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import ErrorIcon from '@mui/icons-material/Error';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import PriceChangeIcon from '@mui/icons-material/PriceChange';


import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp';

import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import Person2Icon from '@mui/icons-material/Person2';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Link } from "react-router-dom";
import { Description, Grading, Payment, Payments, RequestPage, Task } from "@mui/icons-material";
import { grey, orange } from "@mui/material/colors";

export default function Home(props) {


    const perfilDetalleProps = props.perfilDetalle;
    const typogFuente = { fuente: "", size: 20, color: "#8AA4AF", colorIcon: grey[800] };
    const cardMenuIcon = [
        { codigo: "ORD", icon: <AssignmentIcon key="ORD" className='textDrawer' sx={{ fontSize: '50px', color: typogFuente.colorIcon }} /> },
        { codigo: "ORD_HIS", icon: <ManageHistoryIcon key="ORD_HIS" className="textDrawer" /> },
        { codigo: "ORD_ORD", icon: <PlagiarismIcon key="ORD_ORD" className="textDrawer" /> },
        { codigo: "ORD_PRO", icon: <AssignmentIndIcon key="ORD_PRO" className="textDrawer" /> },
        { codigo: "ORD_NEW", icon: <NoteAddIcon key="ORD_KEY" className="textDrawer" /> },
        { codigo: "PRU", icon: <ContentPasteGoIcon key="PRU" className='textDrawer' sx={{ fontSize: '50px', color: typogFuente.colorIcon }} /> },
        { codigo: "PRU_ORD", icon: <AssignmentTurnedInIcon key="PRU_ORD" className="textDrawer" /> },
        { codigo: "PRU_PRO", icon: <AssignmentIndIcon key="PRU_PRO" className="textDrawer" /> },
        { codigo: "PRU_TRA", icon: <ManageAccountsSharpIcon key="PRU_TRA" className="textDrawer" /> },
        { codigo: "SYS", icon: <AddToQueueIcon key="SYS" className='textDrawer' sx={{ fontSize: '50px', color: typogFuente.colorIcon }} /> },
        { codigo: "SYS_PAR", icon: <AssessmentIcon key="SYS_PAR" className="textDrawer" /> },
        { codigo: "SYS_TRA", icon: <ManageAccountsSharpIcon key="SYS_TRA" className="textDrawer" /> },
        { codigo: "SYS_PER", icon: <AccountBoxIcon key="SYS_PER" className="textDrawer" /> },
        { codigo: "SYS_USU", icon: <PersonSharpIcon key="SYS_USU" className="textDrawer" /> },
        { codigo: "MAN", icon: <EngineeringIcon key="MAN" className='textDrawer' sx={{ fontSize: '50px', color: typogFuente.colorIcon }} /> },
        { codigo: "MAN_PAC", icon: <Person2Icon key="MAN_PAC" className="textDrawer" /> },
        { codigo: "MAN_PRO", icon: <AttachMoneyIcon key="MAN_PRO" className="textDrawer" /> },
        { codigo: "MAN_SER", icon: <MedicalServicesIcon key="MAN_SER" className="textDrawer" /> },
        { codigo: "CAJ", icon: <PointOfSaleIcon key="CAJ" className='textDrawer' sx={{ fontSize: '50px', color: typogFuente.colorIcon }} /> },
        { codigo: "CAJ_COB", icon: <AttachMoneyIcon key="CAJ_COB" className="textDrawer" /> },
        { codigo: "CAJ_CHIC", icon: <PriceChangeIcon key="CAJ_CHIC" className="textDrawer" /> },
        { codigo: "CAJ_GAS", icon: <Payments key="CAJ_GAS" className="textDrawer" /> },
        { codigo: "REP", icon: <Description key="REP" className='textDrawer' sx={{ fontSize: '50px', color: typogFuente.colorIcon }} /> },
        { codigo: "REP_ORD", icon: <Task key="REP_ORD" className="textDrawer" /> },
        { codigo: "REP_TRAB", icon: <Grading key="REP_TRAB" className="textDrawer" /> },
        { codigo: "REP_CAJA", icon: <RequestPage key="REP_CAJA" className="textDrawer" /> },
    ];

    const cerrarSesion = () => {
        props.parentLogOut();
        if (props.setOpen !== undefined) {
            props.setOpen(false);
        }
    }
    const oncHover = () => {
        console.log("");
    }
    return (
        <>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh', padding: "1em 0 1em 0.6em" }}
            >
                <Grid item xs={12} >
                    <Grid container justifyContent="center" spacing={2}>
                        {/* {perfilDetalleProps.filter(m => m.fk_log_menu_padre == null).map((p, index) => (
                            <Grid justifyContent="center" item key={index + "gr"}>
                                <div className="wrap">
                                    <div className="tarjeta-wrap">
                                        <div className="tarjeta">
                                            <div className="adelante cardWrap">
                                                <ListItemIcon fontSize="small" align="center" sx={{ width: "100%", margin: " 15px 0", justifyContent: "center" }}>
                                                    <Avatar sx={{ width: 80, height: 80, bgcolor: orange[700] }}>
                                                        {cardMenuIcon.filter((f) => f.codigo === p.codigo).length > 0 ? cardMenuIcon.filter((f) => f.codigo === p.codigo)[0].icon : <ErrorIcon className="textDrawer" />}
                                                    </Avatar>
                                                </ListItemIcon>
                                                <CardContent className="">
                                                    <Typography className="typoColor" gutterBottom align="center" color="text.primary" sx={{ color: orange[700], fontSize: typogFuente.size, fontFamily: typogFuente.fuente }}>
                                                        {p.nombre}
                                                    </Typography>
                                                </CardContent>
                                            </div>
                                            <div className="atras">
                                                <List component="div" disablePadding >
                                                    {perfilDetalleProps.filter(mh => mh.fk_log_menu_padre === p.codigo).map((h, index) => (
                                                        <Link key={index + "link"} to={h.href} style={{ textDecoration: "none", textAlign: "start", backgroundColor: "red" }}>
                                                            <ListItemButton sx={{ pl: 0 }}>
                                                                <ListItemIcon sx={{ paddingLeft: "1.5em" }}>
                                                                    {cardMenuIcon.filter((f) => f.codigo === h.codigo).length > 0 ? cardMenuIcon.filter((f) => f.codigo === h.codigo)[0].icon : <ErrorIcon className="textDrawer" />}
                                                                </ListItemIcon>
                                                                <ListItemText primary={h.nombre} sx={{ color: "#2b2b" }} onClick={oncHover}></ListItemText>
                                                                <Typography gutterBottom align="center" color="text.primary" sx={{ color: orange[700] }} style={{ zIndex: 99999 }}>
                                                                    {h.nombre}
                                                                </Typography>
                                                            </ListItemButton>
                                                        </Link>
                                                    ))}
                                                </List>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        ))} */}
                        {perfilDetalleProps.filter(m => m.fk_log_menu_padre == null).map((p, index) => (
                            <Grid justifyContent="center" item key={index + "gr"}>
                                <div className="flip">
                                    <div className="front">
                                        <ListItemIcon fontSize="small" align="center" sx={{ width: "100%", margin: " 15px 0", justifyContent: "center" }}>
                                            <Avatar sx={{ width: 80, height: 80, bgcolor: orange[700] }}>
                                                {cardMenuIcon.filter((f) => f.codigo === p.codigo).length > 0 ? cardMenuIcon.filter((f) => f.codigo === p.codigo)[0].icon : <ErrorIcon className="textDrawer" />}
                                            </Avatar>
                                        </ListItemIcon>
                                        <CardContent className="">
                                            <Typography className="typoColor" gutterBottom align="center" color="text.primary" sx={{ color: orange[700], fontSize: typogFuente.size, fontFamily: typogFuente.fuente }}>
                                                {p.nombre}
                                            </Typography>
                                        </CardContent>
                                    </div>
                                    <div className="back" style={{ alignItems: "center", justifyContent: "center", textAlign: "center", display: "flex" }}>
                                        <List component="div" disablePadding>
                                            {perfilDetalleProps.filter(mh => mh.fk_log_menu_padre === p.codigo).map((h, index) => (
                                                <Link key={index + "link"} to={h.href} style={{ textDecoration: "none", textAlign: "start", backgroundColor: "#FFF" }}>
                                                    <ListItemButton sx={{ pl: 0, ":hover": { backgroundColor: grey[700], borderRadius: "5px" } }} >
                                                        <ListItemIcon sx={{ pl: 1 }}>
                                                            {cardMenuIcon.filter((f) => f.codigo === h.codigo).length > 0 ? cardMenuIcon.filter((f) => f.codigo === h.codigo)[0].icon : <ErrorIcon className="textDrawer" />}
                                                        </ListItemIcon>
                                                        <Typography gutterBottom color="text.primary" >
                                                            {h.nombre}
                                                        </Typography>
                                                    </ListItemButton>
                                                </Link>
                                            ))}
                                        </List>
                                    </div>
                                </div>
                            </Grid>
                        ))}

                        {/* <div className="wrap">
                            <div className="tarjeta-wrap">
                                <div className="tarjeta">
                                    <div className="adelante card1"></div>
                                    <div className="atras">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa ex velit beatae. Illum, suscipit, aspernatur!</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}



                    </Grid>
                </Grid>
                <Grid className="btnSalir">
                    <Button className="btnCerrarSesion" onClick={cerrarSesion} variant="contained" sx={{ bgcolor: orange[700], color: grey[800], borderRadius: "10px" }}><LogoutSharpIcon sx={{ padding: "0 0.5em" }} /></Button>
                </Grid>
            </Grid>
        </>
    );
}
