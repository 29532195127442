import React from 'react'
import { Button, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
// iconos 
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckIcon from '@mui/icons-material/Check';
import UrlsConst from '../../../config/config';
import { LoadingButton } from '@mui/lab';

export default function DialogTrabajo(props) {

    const [trabajadores, setTrabajadores] = React.useState([{
        id: 0,
        nombres_apellidos: '',
    }]);

    const [pruebaSubmit, setPruebaSubmit] = React.useState({
        fk_ven_orden: "",
        linea: 0,
        fk_rh_trabajador: 0,
        fecha_registro: '',
        hora_registro: '',
        fecha_conclusion: '',
        hora_conclusion: '',
        fecha_entrega: '',
        hora_entrega: '',
        fecha_programada: '',
        hora_programada: '',
        descripcion: '',
        nombre_trabajador: ''
    });
    // validar trabajo
    const [trabajadorPrueba, setTrabajadorPrueba] = React.useState({
        error: false,
        text: ''
    });
    const [fechaPrueba, setFechaPrueba] = React.useState({
        error: false,
        text: ''
    });
    const [horaPrueba, setHoraPrueba] = React.useState({
        error: false,
        text: ''
    });
    const [descripcionPrueba, setDescripcionPrueba] = React.useState({
        error: false,
        text: ''
    });
    // loading
    const [loading, setLoading] = React.useState(false);
    // handles
    const handleTrabajador = (e, values) => {
        if (values != null) {
            setTrabajadorPrueba({ ...trabajadorPrueba, error: false, text: '' });
            const trabajador = values.props;
            setPruebaSubmit({ ...pruebaSubmit, fk_rh_trabajador: trabajador.value, nombre_trabajador: trabajador.nombre });
        } else {
            setPruebaSubmit({ ...pruebaSubmit, fk_rh_trabajador: 0, nombre_trabajador: '' });
        }
    }
    // functions
    const listarTrabajadores = async () => {
        const trabajador = await fetch(`${UrlsConst.URL_API}/sistema/trabajador/listarTrabajador.php`, {
            method: 'POST'
        });
        const response = await trabajador.json();
        setTrabajadores(response);
    }
    React.useEffect(() => {
        listarTrabajadores();
        if (props.nTrabajo !== 0) {
            setPruebaSubmit({ ...pruebaSubmit, linea: props.nTrabajo, fecha_programada: new Date().toJSON().slice(0, 10) });
        }
    }, []);
    const addPrueba = async () => {
        if (pruebaSubmit.fk_rh_trabajador === 0 || pruebaSubmit.fecha_programada === "" || pruebaSubmit.hora_programada === "" || pruebaSubmit.descripcion === "") {
            if (pruebaSubmit.fk_rh_trabajador === 0) {
                setTrabajadorPrueba({ ...trabajadorPrueba, error: true, text: 'Trabajador obligatorio' });
            }
            if (pruebaSubmit.fecha_programada === "") {
                setFechaPrueba({ ...fechaPrueba, error: true, text: 'Fecha programada obligatoria' });
            }
            if (pruebaSubmit.hora_programada === "") {
                setHoraPrueba({ ...horaPrueba, error: true, text: 'Hora programada obligatoria' });
            }
            if (pruebaSubmit.descripcion === "") {
                setDescripcionPrueba({ ...descripcionPrueba, error: true, text: 'Descripcion obligatoria' });
            }
        } else {
            setLoading(true);
            const ordenPrueba = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarPrueba.php`, {
                method: 'POST',
                body: JSON.stringify({
                    fk_ven_orden: props.idOrden,
                    linea: pruebaSubmit.linea,
                    fk_rh_trabajador: pruebaSubmit.fk_rh_trabajador,
                    fecha_registro: new Date().toJSON().slice(0, 10),
                    hora_registro: new Date().toLocaleTimeString(),
                    fecha_conclusion: '',
                    hora_conclusion: '',
                    fecha_entrega: '',
                    hora_entrega: '',
                    fecha_programada: pruebaSubmit.fecha_programada,
                    hora_programada: pruebaSubmit.hora_programada,
                    descripcion: pruebaSubmit.descripcion
                })
            });
            const response = ordenPrueba.json();
            // console.log({response});
            if (props.ordenTrabajos !== undefined) {
                props.ordenTrabajos(props.idOrden);
            }
            if (props.setNewTrabajo !== undefined) {
                const alertGeneral = props.alertGeneral;
                const setAlertGeneral = props.setAlertGeneral;
                props.setNewTrabajo(false);
                setAlertGeneral({ ...alertGeneral, type: 'success', text: 'Trabajo guardado correctamente', open: true });
                setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
            }
        }
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={2} xs={12}>
                    <TextField
                        label="N° TRABAJO"
                        fullWidth
                        margin='normal'
                        name='linea'
                        value={pruebaSubmit.linea}
                        disabled
                        variant='standard'
                    // onChange={handleLinea}
                    />
                </Grid>
                <Grid item md={10} xs={12}>
                    <TextField
                        style={{ textAlign: 'start' }}
                        select
                        margin='normal'
                        fullWidth
                        error={trabajadorPrueba.error}
                        helperText={trabajadorPrueba.text}
                        value={pruebaSubmit ? pruebaSubmit.fk_rh_trabajador : 0}
                        label="TRABAJADOR"
                        variant='standard'
                        onChange={handleTrabajador}
                    >
                        {trabajadores.map((option) => (
                            <MenuItem key={option.id} value={option.id} nombre={option.nombres_apellidos}>
                                {option.nombres_apellidos}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <TextField
                        type="date"
                        name="fecha_programada"
                        label="FECHA PROGRAMADA"
                        fullWidth
                        variant='standard'
                        margin='normal'
                        error={fechaPrueba.error}
                        helperText={fechaPrueba.text}
                        value={pruebaSubmit ? pruebaSubmit.fecha_programada : ''}
                        onChange={(e) => {
                            const fechaI = e.target.value;
                            if (fechaI != "") {
                                setPruebaSubmit({ ...pruebaSubmit, fecha_programada: fechaI });
                                setFechaPrueba({ ...fechaPrueba, error: false, text: '' });
                            } else {
                                setPruebaSubmit({ ...pruebaSubmit, fecha_programada: '' });
                                setFechaPrueba({ ...fechaPrueba, error: true, text: 'Fecha programada obligatoria' });
                            }
                        }}
                        InputProps={{
                            inputProps: {
                                min: new Date().toISOString().split('T')[0]
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarMonthIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        label="HORA PROGRAMADA"
                        type="time"
                        fullWidth
                        name='hora_programada'
                        margin='normal'
                        variant='standard'
                        error={horaPrueba.error}
                        helperText={horaPrueba.text}
                        value={pruebaSubmit ? pruebaSubmit.hora_programada : ''}
                        onChange={(e) => {
                            const fechaI = e.target.value;
                            if (fechaI != "") {
                                setPruebaSubmit({ ...pruebaSubmit, hora_programada: fechaI });
                                setHoraPrueba({ ...horaPrueba, error: false, text: '' });
                            } else {
                                setPruebaSubmit({ ...pruebaSubmit, hora_programada: '' });
                                setHoraPrueba({ ...horaPrueba, error: true, text: 'Hora programada obligatoria' });
                            }
                        }}
                        InputProps={{
                            inputProps: {
                                min: new Date().toISOString().split('T')[0]
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ScheduleIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <TextField
                label="DESCRIPCION"
                fullWidth
                margin='normal'
                name='descripcion'
                multiline
                rows={3}
                error={descripcionPrueba.error}
                helperText={descripcionPrueba.text}
                value={pruebaSubmit ? pruebaSubmit.descripcion : ''}
                inputProps={{ maxLength: 400 }}
                onChange={
                    (e) => {
                        const { value } = e.target;
                        if (value != "") {
                            setPruebaSubmit({ ...pruebaSubmit, descripcion: value.toUpperCase() });
                            setDescripcionPrueba({ ...descripcionPrueba, error: false, text: '' });
                        } else {
                            setPruebaSubmit({ ...pruebaSubmit, descripcion: '' });
                            setDescripcionPrueba({ ...descripcionPrueba, error: true, text: 'Descripcion obligatoria' });
                        }
                    }
                }
            />

            <LoadingButton onClick={addPrueba} loading={loading}
                variant="outlined" color="success" startIcon={<CheckIcon />}>
                GUARDAR
            </LoadingButton>
        </>
    )
}
