import { Check, Close } from '@mui/icons-material';
import { Button, Grid, TextField } from '@mui/material';
import React from 'react';
import UrlsConst from '../../../config/config';

export default function DialogGasto(props) {
    const [gastoSubmit, setGastoSubmit] = React.useState({
        codigo: '',
        nombre: '',
        descripcion: ''
    });
    const dateNow = new Date()
    const agregarCero = (n) => {
        if (n <= 9) { return "0" + n; }
        return n
    }
    const getRandomNum = () => {
        let min = Math.ceil(1);
        let max = Math.floor(9999);
        var numGenerado = Math.floor(Math.random() * (max - min + 1) + min);
        if (numGenerado <= 9) { return "000" + numGenerado; }
        if (numGenerado <= 99) { return "00" + numGenerado; }
        if (numGenerado <= 999) { return "0" + numGenerado; }
        return numGenerado;
    }
    const genCodigo = () => {
        let dateFormateado2 = dateNow.getFullYear() + "" +
            agregarCero(dateNow.getMonth() + 1) + "" +
            agregarCero(dateNow.getDate()) + "" +
            agregarCero(dateNow.getHours()) + "" +
            agregarCero(dateNow.getMinutes()) + "" +
            agregarCero(dateNow.getSeconds()) + getRandomNum();
        return dateFormateado2;
    }
    const handleGasto = (e) => {
        const { value, name } = e.target;
        setGastoSubmit({ ...gastoSubmit, [name]: value });
    }
    const recuperarGasto = async (codigo) => {
        const gasto_obtener = await fetch(`${UrlsConst.URL_API}/compras/gastos/recuperarGasto.php?codigo=${codigo}`);
        const response = await gasto_obtener.json();
        setGastoSubmit(response);
    }
    const saveGasto = async () => {
        const guardargasto = await fetch(`${UrlsConst.URL_API}/compras/gastos/guardarGasto.php`, {
            method: 'POST',
            body: JSON.stringify({
                codigo: gastoSubmit.codigo === "" ? genCodigo() : gastoSubmit.codigo,
                nombre: gastoSubmit.nombre !== "" ? gastoSubmit.nombre.toUpperCase() : '',
                descripcion: gastoSubmit.descripcion !== "" ? gastoSubmit.descripcion.toUpperCase() : ''
            })
        });
        const response = await guardargasto.json();
        if (props.setAlertGeneral !== undefined) {
            if (response.guardado) {
                if (gastoSubmit.codigo === "") {
                    props.setAlertGeneral({ show: true, message: 'Gasto guardado correctamente', severity: 'success' });
                } else {
                    props.setAlertGeneral({ show: true, message: 'Gasto actualizado correctamente', severity: 'success' });
                }
            } else {
                props.setAlertGeneral({ show: true, message: 'Ocurrio un error', severity: 'error' });
            }
            setTimeout(() => props.setAlertGeneral({ show: false, severity: 'success', message: '' }), 2500);
        }
        props.listarGatos();
        props.closeDialog();
    }
    // var obtCodigo = genCodigo();
    React.useEffect(() => {
        if (props.codigo !== "") {
            recuperarGasto(props.codigo);
        }
        // setGastoSubmit({ ...gastoSubmit, codigo: genCodigo() });
    }, []);
    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <TextField
                    fullWidth
                    label="NOMBRE"
                    margin='normal'
                    name='nombre'
                    onChange={handleGasto}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                    value={gastoSubmit.nombre} />
            </Grid>
            <Grid item md={12}>
                <TextField
                    multiline rows={3}
                    fullWidth label="DESCRIPCION"
                    margin='normal'
                    name="descripcion"
                    onChange={handleGasto}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                    value={gastoSubmit.descripcion} />
            </Grid>
            <Grid container style={{ justifyContent: 'center', marginTop: '6px' }}>
                <Grid item md={4}>
                    <Button variant='outlined' color="success" startIcon={<Check />} onClick={saveGasto}>GUARDAR</Button>
                </Grid>
                <Grid item md={4}>
                    <Button variant='outlined' color="error" startIcon={<Close />} onClick={() => props.closeDialog()}>CANCELAR</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}
