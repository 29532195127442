import { Grid, TextField, Button, FormControlLabel, Switch, Collapse, Alert, IconButton, MenuItem } from '@mui/material';
import * as React from 'react';
import UrlsConst from '../../../config/config';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

export default function DialogTrabajadores(props) {
    const [documento, setDocumento] = React.useState([{
        codigo: '',
        descripcion_corta: '',
        descripcion_larga: '',
        longitud: 0, tipo: '',
        contribuyente: '',
        longitud_exacta: 0
    }]);
    const [maxlength, setMaxlength] = React.useState();
    const [openW, setOpenW] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [trabajadorSubmit, setTrabajadorSubmit] = React.useState(
        {
            id: 0,
            fk_com_tipo_documento: '00',
            numero_documento: '',
            nombres_apellidos: '',
            cargo: '',
            es_activo: 0
        }
    );
    // validar trabajador
    const [documentoTrabajador, setDocumentoTrabajador] = React.useState({
        error: false,
        text: ''
    });
    const [nombreTrabajador, setNombreTrabajador] = React.useState({
        error: false,
        text: ''
    });
    const [cargoTrabajador, setCargoTrabajador] = React.useState({
        error: false,
        text: ''
    });
    React.useEffect(() => {
        if (props.fntIdTrabajador != undefined) {
            recuperarTrabajador();
        }
        tipoDocumento();
    }, []);
    const tipoDocumento = async () => {
        const documentos_tipo = await fetch(`${UrlsConst.URL_API}/sistema/listarDocumento.php`, {
            method: "POST"
        });
        const json = await documentos_tipo.json();
        setDocumento(json);
    }
    const recuperarTrabajador = async () => {
        const trabajadorGet = await fetch(`${UrlsConst.URL_API}/sistema/trabajador/recuperarTrabajador.php?id=` + props.fntIdTrabajador, {
            method: "GET"
        });
        const response = await trabajadorGet.json();
        setDocumentoSelect(response.fk_com_tipo_documento);
        setTrabajadorSubmit(response);
    }
    const insertTrabajador = async () => {
        // console.log(trabajadorSubmit.numero_documento);
        if (trabajadorSubmit.numero_documento === "" || trabajadorSubmit.nombres_apellidos === "" || trabajadorSubmit.cargo === "") {
            if (trabajadorSubmit.numero_documento === "") {
                setDocumentoTrabajador({ ...documentoTrabajador, error: true, text: 'Documento obligatorio' });
            }
            if (trabajadorSubmit.nombres_apellidos === "") {
                setNombreTrabajador({ ...nombreTrabajador, error: true, text: 'Nombre obligatorio' });
            }
            if (trabajadorSubmit.cargo === "") {
                setCargoTrabajador({ ...cargoTrabajador, error: true, text: 'Cargo obligatorio' });
            }
        } else {
            setLoading(true);
            const trabajador = await fetch(`${UrlsConst.URL_API}/sistema/trabajador/guardarTrabajador.php`, {
                method: "POST",
                body: JSON.stringify(trabajadorSubmit),
            });
            const json = await trabajador.json();
            props.fntTrabajadores();
            props.fntCloseModal();
            if (props.fntIdTrabajador != undefined) {
                props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'Trabajador actualizado correctamente', open: true });
                setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
            } else {
                props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'Trabajador guardado correctamente', open: true });
                setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
            }

        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTrabajadorSubmit({ ...trabajadorSubmit, [name]: value.toUpperCase() });
    }
    const handleSwitch = (e) => {
        const { name } = e.target;
        if (e.target.checked != false) {
            setTrabajadorSubmit({ ...trabajadorSubmit, [name]: 1 });
        } else {
            setTrabajadorSubmit({ ...trabajadorSubmit, [name]: 0 });
        }
    }
    const [documentoSelect, setDocumentoSelect] = React.useState('01');
    const handleDocumento = (e, values) => {
        setMaxlength(values.props.loguitud);
        setTrabajadorSubmit({ ...trabajadorSubmit, fk_com_tipo_documento: values.props.value });
        setDocumentoSelect(e.target.value);
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={4} xs={6}>
                    <TextField
                        style={{ textAlign: 'start' }}
                        select
                        margin='normal'
                        fullWidth
                        value={documentoSelect}
                        label="TIPO DE DOCUMENTO"
                        variant='standard'
                        onChange={handleDocumento}
                    >
                        {documento.map((option) => (
                            <MenuItem key={option.codigo} value={option.codigo} loguitud={option.longitud}>
                                {option.descripcion_larga}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item md={5} xs={6}>
                    <TextField
                        required
                        variant='standard'
                        id="documento"
                        fullWidth label="DOCUMENTO"
                        margin='normal'
                        name='numero_documento'
                        inputProps={{ maxLength: maxlength }}
                        value={trabajadorSubmit ? trabajadorSubmit.numero_documento : ''}
                        error={documentoTrabajador.error}
                        helperText={documentoTrabajador.text}
                        onChange={
                            (e) => {
                                const { value } = e.target;
                                if (value != "") {
                                    setTrabajadorSubmit({ ...trabajadorSubmit, numero_documento: value });
                                    setDocumentoTrabajador({ ...documentoTrabajador, error: false, text: '' });
                                } else {
                                    setTrabajadorSubmit({ ...trabajadorSubmit, numero_documento: '' });
                                    setDocumentoTrabajador({ ...documentoTrabajador, error: true, text: 'Documento obligatorio' });
                                }
                            }
                        }
                    />
                </Grid>
                <Grid item md={3} style={{ marginTop: '30px' }}>
                    <FormControlLabel
                        name="es_activo"
                        labelPlacement='start'
                        control={<Switch color='success' checked={trabajadorSubmit.es_activo != 0} onChange={handleSwitch} />}
                        label="Estado"
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <TextField required variant='standard'
                        label="NOMBRE " name="nombres_apellidos" fullWidth margin='normal' type="text"
                        inputProps={{ maxLength: '500' }}
                        value={trabajadorSubmit ? trabajadorSubmit.nombres_apellidos : ''}
                        error={nombreTrabajador.error}
                        helperText={nombreTrabajador.text}
                        onChange={
                            (e) => {
                                const { value } = e.target;
                                if (value != "") {
                                    setTrabajadorSubmit({ ...trabajadorSubmit, nombres_apellidos: value.toUpperCase() });
                                    setNombreTrabajador({ ...nombreTrabajador, error: false, text: '' });
                                } else {
                                    setTrabajadorSubmit({ ...trabajadorSubmit, nombres_apellidos: '' });
                                    setNombreTrabajador({ ...nombreTrabajador, error: true, text: 'Nombre obligatorio' });
                                }
                            }
                        }
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <TextField variant='standard'
                        required label="CARGO"
                        fullWidth margin='normal' name='cargo'
                        inputProps={{ maxLength: '100' }}
                        value={trabajadorSubmit ? trabajadorSubmit.cargo : ''}
                        error={cargoTrabajador.error}
                        helperText={cargoTrabajador.text}
                        onChange={
                            (e) => {
                                const { value } = e.target;
                                if (value != "") {
                                    setTrabajadorSubmit({ ...trabajadorSubmit, cargo: value.toUpperCase() });
                                    setCargoTrabajador({ ...cargoTrabajador, error: false, text: '' });
                                } else {
                                    setTrabajadorSubmit({ ...trabajadorSubmit, cargo: '' });
                                    setCargoTrabajador({ ...cargoTrabajador, error: true, text: 'Cargo obligatorio' });
                                }
                            }
                        }
                    />
                </Grid>
            </Grid>
            <LoadingButton
                onClick={insertTrabajador}
                startIcon={<CheckIcon />}
                loading={loading}
                loadingPosition="start"
                variant="outlined"
                color="success"
            >
                {props.fntIdTrabajador != undefined ? 'ACTUALIZAR' : 'GUARDAR'}
            </LoadingButton>
        </>
    )
}