import React from 'react'
import { Grid, Typography, Paper, TextField, Button, Dialog, DialogContent, DialogActions, Collapse, Alert, IconButton, DialogTitle, Checkbox, Card, Avatar, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Tooltip, InputAdornment, Chip, Snackbar } from '@mui/material';
import UrlsConst from '../../config/config';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PorTrabajador from './trabajos/porTrabajador';
import RepGeneralTrab from './trabajos/repGeneralTrab';

export default function RepTrabajo() {
  const typogFuente = { fuente: "", size: 20 };
  const [valueTabs, setValueTabs] = React.useState('1');
  const [alertGeneralPdf, setAlertGeneralPdf] = React.useState({
    open: false,
    type: 'warning',
    text: ''
  });
  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  const alertPdf = () => {
    setAlertGeneralPdf({
      open: true,
      type: 'error',
      text: 'No existen datos para exportar'
    })
    setTimeout(() => {
      setAlertGeneralPdf({
        open: false,
        type: 'warning',
        text: ''
      })
    }, 2700);
  }
  return (
    <Grid container sx={{ minHeight: '100vh', paddingTop: "2em", alignContent: 'center', justifyContent: 'center' }}>
      <Grid item md={11.5}>

        <Snackbar open={alertGeneralPdf.open} autoHideDuration={6000} onClose={() => setAlertGeneralPdf({ ...alertGeneralPdf, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={() => setAlertGeneralPdf({ ...alertGeneralPdf, open: false })} severity={alertGeneralPdf.type} sx={{ width: '100%' }}>
            {alertGeneralPdf.text}
          </Alert>
        </Snackbar>
        <Paper elevation={1} style={{ padding: '0 1.5em' }}>
          <Grid container sx={{ padding: '8px', justifyContent: 'space-between', textAlign: 'center' }}>
            <Typography gutterBottom align="center" color="text.primary" sx={{ fontSize: typogFuente.size, fontFamily: typogFuente.fuente }}>
              REPORTES TRABAJOS
            </Typography>
            {/* <Button startIcon={<SearchIcon />} variant="contained" color="warning" onClick={""}>buscar Paciente</Button> */}
          </Grid>

          <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
            <Grid item md={12} xs={12}>
              <Box sx={{ width: '100%', height: '100%', typography: 'body1' }}>
                <TabContext value={valueTabs}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                      <Tab label="General" value="1" />
                      <Tab label="Por Trabajador" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <RepGeneralTrab alertPdf={alertPdf} />
                  </TabPanel>
                  <TabPanel value="2">
                    <PorTrabajador alertPdf={alertPdf} />
                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid >
  )
}
