import React from 'react';
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";

import Logo from '../../../img/logo-guzman.png';
const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: 'Oswald'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 10,
        marginHorizontal: 50,
        width: '45px',
        height: '45px'
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 40,
        textAlign: 'right',
        color: 'grey',
    },
});
export default function ExportPdf(props) {
    const estilosTablaPdf = { padding: "4px 0", bgHeader: "#f57c00" }
    const datosEmpresa = props.datosEmpresa;
    const fechas = props.fechas;
    const registros = props.registros;
    return (
        <Document>
            <Page style={styles.body} orientation="landscape">
                <View style={{ display: 'fixed', flexDirection: 'row' }} fixed>
                    <View style={{ display: 1 }}>
                        <Image
                            style={{ width: '55px', height: '50px' }}
                            src={Logo}
                        />
                    </View>
                    <View style={{ display: 2, marginHorizontal: '15px', width: '45%' }} >
                        <Text style={{ fontSize: '17px' }}>{datosEmpresa.razon_social}</Text>
                        <Text style={{ fontSize: '11px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                        <Text style={{ fontSize: '11px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text>
                    </View>
                    <View style={{ display: 3 }}>
                        <Text style={{ fontSize: '17px' }}>REPORTE REGISTROS GASTO</Text>
                        <Text style={{ fontSize: '11px', marginTop: '5px', marginHorizontal: '45px' }}>Desde: {fechas.fechaInicio}</Text>
                        <Text style={{ fontSize: '11px', marginTop: '5px', marginHorizontal: '45px' }}>Hasta: {fechas.fechaFinal}</Text>
                    </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: estilosTablaPdf.bgHeader, padding: '2px', marginTop: '25px' }} fixed>
                    <View style={{ display: 1, width: '160px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>CODIGO</Text>
                    </View>
                    <View style={{ display: 2, width: '100px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>FECHA</Text>
                    </View>
                    <View style={{ display: 3, width: '200px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>REFERENCIA</Text>
                    </View>
                    <View style={{ display: 4, width: '300px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>TRABAJADOR</Text>
                    </View>
                    <View style={{ display: 5, width: '200px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>GASTO</Text>
                    </View>
                    <View style={{ display: 6, width: '80px', margin: '2px', textAlign: 'right' }}>
                        <Text style={{ fontSize: '8px' }}>CANT.</Text>
                    </View>
                    <View style={{ display: 7, width: '80px', margin: '2px', textAlign: 'right' }}>
                        <Text style={{ fontSize: '8px' }}>P.U</Text>
                    </View>
                    <View style={{ display: 8, width: '150px', margin: '2px', textAlign: 'right' }}>
                        <Text style={{ fontSize: '8px' }}>TOTAL</Text>
                    </View>
                </View>
                {
                    registros.map((r) => (
                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }} key={r.codigo}>
                            <View style={{ display: 1, width: '160px', margin: '2px' }}>
                                <Text style={{ fontSize: '8px' }}>{r.codigo}</Text>
                            </View>
                            <View style={{ display: 2, width: '100px', margin: '2px' }}>
                                <Text style={{ fontSize: '8px' }}>{r.fecha}</Text>
                            </View>
                            <View style={{ display: 3, width: '200px', margin: '2px' }}>
                                <Text style={{ fontSize: '8px' }}>{r.referencia}</Text>
                            </View>
                            <View style={{ display: 4, width: '300px', margin: '2px' }}>
                                <Text style={{ fontSize: '8px' }}>{r.nombres_apellidos}</Text>
                            </View>
                            <View style={{ display: 5, width: '200px', margin: '2px' }}>
                                <Text style={{ fontSize: '8px' }}>{r.gasto}</Text>
                            </View>
                            <View style={{ display: 6, width: '80px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px', textAlign: 'right' }}>{r.cantidad}</Text>
                            </View>
                            <View style={{ display: 7, width: '80px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px', textAlign: 'right' }}>{r.precio_unitario}</Text>
                            </View>
                            <View style={{ display: 8, width: '150px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px', textAlign: 'right' }}>S/ {r.total}</Text>
                            </View>
                            <br />
                        </View>
                    ))
                }
                <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: estilosTablaPdf.bgHeader, padding: "4px 0" }}>
                    <View style={{ display: 1, width: '160px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>TOTAL</Text>
                    </View>
                    <View style={{ display: 2, width: '100px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <View style={{ display: 3, width: '200px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <View style={{ display: 4, width: '300px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <View style={{ display: 5, width: '200px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <View style={{ display: 6, width: '80px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <View style={{ display: 7, width: '80px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {registros.reduce((total, item) => parseFloat(total) + parseFloat(item.precio_unitario), 0).toFixed(2)}</Text>
                    </View>
                    <View style={{ display: 8, width: '150px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px', textAlign: 'right' }}>S/ {registros.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}
