import * as React from 'react';
import UrlsConst from '../../../config/config';
import { FormControlLabel, Grid, Switch, TextField, Typography, Button, Dialog, DialogContent, DialogActions, Collapse, Alert, IconButton, MenuItem, DialogTitle, Tooltip, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import MaterialServicio from './materialServicio';

export default function ServicioDialog(props) {
    // const 
    const [typeAlert, setTypeAlert] = React.useState('error');
    const [alert, setAlert] = React.useState(false);
    const [textAlert, setTextAlert] = React.useState('');
    const [openDelete, setOpenDelete] = React.useState(false);
    const [materialId, setMaterialId] = React.useState(0);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [servicioSubmit, setServicioSubmit] = React.useState({
        id: 0,
        nombre: '',
        descripcion: '',
        observaciones: '',
        costo: '',
        multipieza: 0
    });
    const [materiales, setMateriales] = React.useState([{
        id: 0,
        nombre: '',
        descripcion: ''
    }]);
    const [materialSelect, setMaterialSelect] = React.useState({
        fk_cmp_material: 0,
        nombre: '',
        descripcion: '',
        costo: 0,
        precio: 0
    });
    const [rowMaterial, setRowMaterial] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);

    const [nombreServicio, setNombreServicio] = React.useState({
        error: false,
        text: ''
    });
    const [descripcionServicio, setDescripcionServicio] = React.useState({
        error: false,
        text: ''
    });
    const deleteMaterialServicio = () => {
        const newRowMaterial = rowMaterial.filter((item) => item.fk_cmp_material !== materialId);
        setRowMaterial(newRowMaterial);
        setOpenDelete(false);
        setTextAlert('Material eliminado correctamente');
        setTypeAlert('success');
        setAlert(true);
        setTimeout(() => { setAlert(false) }, 2500);
    }
    const getServicio = async () => {
        const getservicio = await fetch(`${UrlsConst.URL_API}/ventas/servicio/recuperarServicio.php?id=` + props.fntEdit, {
            method: 'POST'
        });
        const response = await getservicio.json();
        const getServioMateriales = await fetch(`${UrlsConst.URL_API}/ventas/servicio/listarServicioMaterial.php?id=` + props.fntEdit, {
            method: 'POST'
        });
        const responseSM = await getServioMateriales.json();
        setRowMaterial(responseSM);
        setServicioSubmit(response);
    }
    React.useEffect(() => {
        // getMateriales();
        if (props.fntEdit != undefined) {
            getServicio();
        }
    }, []);
    const handleServicio = (e) => {
        const { name, value } = e.target;
        setServicioSubmit({ ...servicioSubmit, [name]: value.toUpperCase() });
    }
    const handleCheck = (e) => {
        const { name } = e.target;
        if (e.target.checked != false) {
            setServicioSubmit({ ...servicioSubmit, multipieza: 1 });
        } else {
            setServicioSubmit({ ...servicioSubmit, multipieza: 0 });
        }
    }
    const handleAddTable = (e) => {
        const { name, value } = e.target;
        setMaterialSelect({ ...materialSelect, [name]: value });
    }
    const saveServicio = async () => {
        if (servicioSubmit.nombre === "" && servicioSubmit.descripcion === "") {
            if (servicioSubmit.nombre === "") {
                setNombreServicio({ ...nombreServicio, error: true, text: 'Nombre de servicio obligatorio' });
            }
            if (servicioSubmit.descripcion === "") {
                setDescripcionServicio({ ...descripcionServicio, error: true, text: 'Descripcion obligatoria' });
            }
        } else {
            setLoading(true);
            const setServicio = await fetch(`${UrlsConst.URL_API}/ventas/servicio/guardarServicio.php`, {
                method: "POST",
                body: JSON.stringify(servicioSubmit),
            });
            const response = await setServicio.json();
            if (response.guardado) {
                const deleteMS = await fetch(`${UrlsConst.URL_API}/ventas/servicio/eliminarTodoServicioMaterial.php?id=` + response.id, {
                    method: 'POST'
                });
                const responseDelete = await deleteMS.json();
                rowMaterial.forEach(async (material) => {
                    console.log(material)
                    const setSM = await fetch(`${UrlsConst.URL_API}/ventas/servicio/guardarServicioMaterial.php`, {
                        method: 'POST',
                        body: JSON.stringify({
                            fk_ven_servicio: response.id,
                            fk_cmp_material: material.fk_cmp_material,
                            costo: material.costo,
                            precio: material.precio
                        })
                    });
                    const responseSM = await setSM.json();
                });
            }
            if (props.fntEdit != undefined) {
                props.setTextAlert('Se  actualizo correctamente el servicio');
            } else {
                props.setTextAlert('Se guardo correctamente el servicio');
            }
            props.setTypeAlert('success');
            props.setAlertGeneral(true);
            setTimeout(() => { props.setAlertGeneral(false) }, 3000);
            props.fntClose(false);
            props.fntServicios();
        }
    }
    const handleSearchMaterial = () => {
        setOpenDialog(true);
    }
    const handleClose = () => {
        setOpenDialog(false);
        setOpenEdit(false);
        setOpenDelete(false);
    }
    const handleMateriales = (e, values) => {
        if (values != null) {
            setMaterialSelect({ fk_cmp_material: values.id, nombre: values.nombre, descripcion: values.descripcion, costo: 0 });
        } else {
            setMaterialSelect({ fk_cmp_material: 0, nombre: '', descripcion: '', costo: 0 });
        }
    }
    const updateMaterial = () => {
        let materiales = [];
        let validar = rowMaterial.filter((material) => material.fk_cmp_material !== materialId);
        validar.forEach((item) => {
            materiales.push(item);
        });
        materiales.push(materialSelect);
        setRowMaterial(materiales);
        setOpenEdit(false);
        setAlertD({ textAlert: 'El material se actualiazo correctamente', severity: 'success' });
        setAlert(true);
        setTimeout(() => { setAlert(false) }, 2500);
    }
    const [alertD, setAlertD] = React.useState({
        severity: 'error',
        textAlert: ''
    });
    const deleteMaterial = (id) => {
        setOpenDelete(true);
        setMaterialId(id);
    };
    const editMaterial = (id) => {
        let validar = rowMaterial.filter((material) => material.fk_cmp_material === id);
        validar.forEach((item) => {
            setMaterialSelect(item);
        });
        setOpenEdit(true);
        setMaterialId(id);
    }
    const handleChangePage = (newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <>
            <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                <Grid item md={12}>
                    <Collapse in={alert}>
                        <Alert variant="outlined" severity={alertD.severity}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlert(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {alertD.textAlert}
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid item md={9} xs={12}>
                    <TextField
                        fullWidth
                        name="nombre"
                        label="NOMBRES"
                        margin='normal'
                        variant='standard'
                        required
                        value={servicioSubmit ? servicioSubmit.nombre : ''}
                        error={nombreServicio.error}
                        helperText={nombreServicio.text}
                        onChange={
                            (e) => {
                                const { value } = e.target;
                                if (value != "") {
                                    setNombreServicio({ ...nombreServicio, error: false, text: '' });
                                    setServicioSubmit({ ...servicioSubmit, nombre: value.toUpperCase() });
                                } else {
                                    setNombreServicio({ ...nombreServicio, error: true, text: 'Nombre de servicio obligatorio' });
                                    setServicioSubmit({ ...servicioSubmit, nombre: '' });
                                }
                            }
                        }
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <FormControlLabel
                        style={{ marginTop: '25px' }}
                        labelPlacement="start"
                        control={
                            <Switch
                                color="success"
                                name='multipieza'
                                checked={servicioSubmit.multipieza != 0}
                            />
                        }
                        label="ES MULTIPIEZA"
                        onChange={handleCheck}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        fullWidth
                        name="descripcion"
                        margin='normal'
                        label="DESCRIPCION"
                        multiline
                        required
                        rows={2}
                        maxRows={4}
                        error={descripcionServicio.error}
                        helperText={descripcionServicio.text}
                        inputProps={{ maxLength: 200 }}
                        value={servicioSubmit ? servicioSubmit.descripcion : ''}
                        onChange={
                            (e) => {
                                const { value } = e.target;
                                if (value != "") {
                                    setDescripcionServicio({ ...descripcionServicio, error: false, text: '' });
                                    setServicioSubmit({ ...servicioSubmit, descripcion: value.toUpperCase() });
                                } else {
                                    setDescripcionServicio({ ...descripcionServicio, error: true, text: 'Descripcion obligatoria' });
                                    setServicioSubmit({ ...servicioSubmit, descripcion: '' });
                                }
                            }
                        }
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        fullWidth
                        name="observaciones"
                        margin='normal'
                        label="OBSERVACIONES"
                        required
                        multiline
                        rows={2}
                        maxRows={4}
                        inputProps={{ maxLength: 200 }}
                        onChange={handleServicio}
                        value={servicioSubmit ? servicioSubmit.observaciones : ''}
                    />
                </Grid>
            </Grid>
            <div style={{ textAlign: 'center', margin: '12px' }}>
                <Button startIcon={<AddIcon />} variant="outlined" color="primary" onClick={handleSearchMaterial}>Agregar Material</Button>
            </div>
            {
                rowMaterial.length != 0 &&
                <div style={{ marginBottom: '20px' }}>
                    <Paper>
                        <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 300 }}>
                            <Table aria-label="simple table" stickyHeader       >
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>NOMBRE</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>COSTO</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>PRECIO</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? rowMaterial.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rowMaterial
                                    ).map((row) => (
                                        <TableRow key={row.fk_cmp_material}>
                                            <TableCell component="th" scope="row">
                                                {row.nombre}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.descripcion}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.costo}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.precio}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Tooltip title="EDITAR MATERIAL">
                                                    <IconButton onClick={() => editMaterial(row.fk_cmp_material)} aria-label="delete" size="small">
                                                        <EditIcon color="primary" fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="ELIMINAR MATERIAL">
                                                    <IconButton onClick={() => deleteMaterial(row.fk_cmp_material)} aria-label="delete" size="small">
                                                        <DeleteIcon color="error" fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            labelRowsPerPage="Filas por columnas"
                            count={rowMaterial.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            }
            {
                rowMaterial.length === 0 &&
                <Typography style={{ margin: '20px' }}>Sin Materiales agregados</Typography>
            }
            <LoadingButton
                onClick={saveServicio}
                startIcon={<CheckIcon />}
                loading={loading}
                loadingPosition="start"
                variant="outlined"
                color="success"
            >
                Guardar
            </LoadingButton>
            <Dialog open={openDialog} onClose={handleClose} fullWidth={true} maxWidth="sm">
                <DialogTitle>AGREGAR MATERIAL</DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <MaterialServicio
                        rowMaterial={rowMaterial}
                        setRowMaterial={setRowMaterial}
                        handleClose={setOpenDialog}
                        setAlertGeneral={setAlert}
                        alertD={alertD}
                        setAlertD={setAlertD}
                    />
                    <Button style={{ marginLeft: '6px' }} startIcon={<CloseIcon />} variant="outlined" color="error" onClick={handleClose}>CANCELAR</Button>
                </DialogContent>
            </Dialog>
            <Dialog open={openEdit} onClose={handleClose} fullWidth={true} maxWidth="xs">
                <DialogTitle>EDITAR MATERIAL</DialogTitle>
                <DialogContent>
                    <TextField
                        style={{ textAlign: 'start' }}
                        select
                        margin='normal'
                        fullWidth
                        value={materialSelect ? materialSelect.fk_cmp_material : 0}
                        label="MATERIAL"
                        variant='standard'
                        disabled
                        onChange={handleMateriales}
                    >
                        {materiales.map((option) => (
                            <MenuItem key={option.id} value={option.id} description={option.descripcion}>
                                {option.nombre}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <TextField
                                fullWidth
                                name='costo'
                                label="Costo de material"
                                margin="normal"
                                variant='standard'
                                onChange={handleAddTable}
                                value={materialSelect ? materialSelect.costo : ''}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                fullWidth
                                name='precio'
                                label="Precio"
                                margin="normal"
                                variant='standard'
                                onChange={handleAddTable}
                                value={materialSelect ? materialSelect.precio : ''}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} variant="outlined" color="success" onClick={updateMaterial}>Actualizar</Button>
                    <Button startIcon={<CloseIcon />} variant="outlined" color="error" onClick={handleClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDelete} onClose={handleClose}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Eliminar Material</Typography>
                    ¿Esta seguro de eliminar el material?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} variant="contained" color="error" onClick={deleteMaterialServicio}>Aceptar</Button>
                    <Button startIcon={<CloseIcon />} variant="contained" color="primary" onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
