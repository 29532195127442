import React from 'react'
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
// import Logo from '../../../img/logo.png';
import Logo from '../../../img/logo-guzman.png';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    // textAlign: 'center',
    // fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 10,
    marginHorizontal: 50,
    width: '45px',
    height: '45px'
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 40,
    textAlign: 'right',
    color: 'grey',
  },
});

export default function PdfReporteG({ tipo = "repGen", datosEmpresa, datosGeneral, dataFechas, tabGeneralID = [], profesionales = [], trabajadores = [], pacientes = [] }) {
  const estilosTablaPdf = { padding: "4px 0", bgHeader: "#f57c00" }
  return (
    <Document>
      {tipo === "repGen" &&
        <Page style={styles.body} orientation="landscape">
          <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
            <View style={{ display: 1 }}>
              <Image
                style={{ width: '55px', height: '50px' }}
                src={Logo}
              />
            </View>
            <View style={{ display: 2, marginHorizontal: '15px', width: '45%' }} >
              <Text style={{ fontSize: '18px' }}>{datosEmpresa.razon_social}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text>
            </View>
            <View style={{ display: 3 }}>
              <Text style={{ fontSize: '18px' }}>REPORTE ORDEN DE SERV.</Text>
              <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                <View style={{ display: 1, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>DESDE:  {dataFechas.desde}</Text>
                </View>
                <View style={{ display: 2, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>HASTA:  {dataFechas.hasta}</Text>
                </View>
              </View>
            </View>
          </View>
          <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '12px' }}>ORDENES GENERALES</Text>
          <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: estilosTablaPdf.bgHeader, padding: "4px 0" }} fixed>
            <View style={{ display: 1, width: '50px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "center" }}>ID</Text>
            </View>
            <View style={{ display: 2, width: '270px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>PROFESIONAL</Text>
            </View>
            <View style={{ display: 3, width: '250px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>SERVICIO</Text>
            </View>
            <View style={{ display: 4, width: '250px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>DESCRIPCIÓN</Text>
            </View>
            <View style={{ display: 5, width: '250px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>PACIENTE</Text>
            </View>
            <View style={{ display: 6, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>ESTADO PAGO</Text>
            </View>
            <View style={{ display: 7, width: '150px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>F. INGRESO</Text>
            </View>
            <View style={{ display: 8, width: '150px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>F.E. PROGRAMADA</Text>
            </View>
          </View>

          {datosGeneral.map((m, index) => (

            <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
              <View style={{ display: 1, width: '50px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'center' }}>{m.numero}</Text>
              </View>
              <View style={{ display: 2, width: '270px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}>{m.profesional}</Text>
              </View>
              <View style={{ display: 3, width: '250px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}>{m.servicio}</Text>
              </View>
              <View style={{ display: 4, width: '250px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}>{m.descripcion}</Text>
              </View>
              <View style={{ display: 5, width: '250px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}>{m.paciente}</Text>
              </View>
              <View style={{ display: 6, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}>{m.estado_pago === "G" ? "PAGADO" : m.estado_pago === "A" ? "A CUENTA" : m.estado_pago === "P" ? "PENDIENTE" : "..."}</Text>
              </View>
              <View style={{ display: 7, width: '150px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}>{m.fecha_ingreso}</Text>
              </View>
              <View style={{ display: 8, width: '150px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}>{m.fecha_entrega}</Text>
              </View>
              <br />
            </View>
          ))}

          <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
          <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Pag ${pageNumber}`
          )} fixed />
        </Page>
      }
      {tipo === "repProf" &&
        // <Page style={styles.body} orientation="landscape">
        <Page style={styles.body} orientation="portrait">
          <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
            <View style={{ display: 1 }}>
              <Image
                style={{ width: '55px', height: '50px' }}
                src={Logo}
              />
            </View>
            <View style={{ display: 2, marginHorizontal: '15px', width: '45%' }} >
              <Text style={{ fontSize: '16px' }}>{datosEmpresa.razon_social}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text>
            </View>
            <View style={{ display: 3 }}>
              <Text style={{ fontSize: '16px' }}>REPORTE ORDEN DE SERV.</Text>
              <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                <View style={{ display: 1, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>DESDE:  {dataFechas.desde}</Text>
                </View>
                <View style={{ display: 2, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>HASTA:  {dataFechas.hasta}</Text>
                </View>
              </View>
            </View>
          </View>

          {
            tabGeneralID.map(el => (
              profesionales.filter(f => el === f.id).map((mp, index) => (
                <div key={index}>

                  <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '10px' }}>PROFESIONAL: {mp.apellidos_nombres}</Text>
                  <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: estilosTablaPdf.bgHeader, padding: "4px 0" }} fixed>
                    <View style={{ display: 1, width: '50px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>ID</Text>
                    </View>
                    <View style={{ display: 2, width: '250px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>SERVICIO</Text>
                    </View>
                    <View style={{ display: 3, width: '310px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>DESCRIPCIÓN</Text>
                    </View>
                    <View style={{ display: 4, width: '250px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>PACIENTE</Text>
                    </View>
                    <View style={{ display: 5, width: '150px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>ESTADO PAGO</Text>
                    </View>
                    <View style={{ display: 6, width: '150px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>F. INGRESO</Text>
                    </View>
                    <View style={{ display: 7, width: '150px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>F.E. PROGRAMADA</Text>
                    </View>
                  </View>
                  {datosGeneral.filter(f => f.id_profesional === mp.id).map((m, index) => (
                    <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                      <View style={{ display: 1, width: '50px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.numero}</Text>
                      </View>
                      <View style={{ display: 2, width: '250px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.servicio}</Text>
                      </View>
                      <View style={{ display: 3, width: '310px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.descripcion}</Text>
                      </View>
                      <View style={{ display: 4, width: '250px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.paciente}</Text>
                      </View>
                      <View style={{ display: 5, width: '150px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.estado_pago === "G" ? "PAGADO" : m.estado_pago === "A" ? "A CUENTA" : m.estado_pago === "P" ? "PENDIENTE" : ""}</Text>
                      </View>
                      <View style={{ display: 6, width: '150px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.fecha_ingreso}</Text>
                      </View>
                      <View style={{ display: 7, width: '150px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.fecha_entrega}</Text>
                      </View>
                      <br />
                    </View>
                  ))}
                </div>
              ))
            ))
          }



          <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
          <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Pag ${pageNumber}`
          )} fixed />
        </Page>
      }
      {tipo === "repPac" &&
        <Page style={styles.body} orientation="portrait">
          <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
            <View style={{ display: 1 }}>
              <Image
                style={{ width: '55px', height: '50px' }}
                src={Logo}
              />
            </View>
            <View style={{ display: 2, marginHorizontal: '15px', width: '45%' }} >
              <Text style={{ fontSize: '16px' }}>{datosEmpresa.razon_social}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text>
            </View>
            <View style={{ display: 3 }}>
              <Text style={{ fontSize: '16px' }}>REPORTE ORDEN DE SERV.</Text>
              <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                <View style={{ display: 1, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>DESDE:  {dataFechas.desde}</Text>
                </View>
                <View style={{ display: 2, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>HASTA:  {dataFechas.hasta}</Text>
                </View>
              </View>

            </View>
          </View>
          {
            tabGeneralID.map(el => (
              pacientes.filter(f => el === f.id).map((mp, index) => (
                <div key={index}>

                  <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '10px' }}>PACIENTE: {mp.apellidos_nombres}</Text>
                  <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: estilosTablaPdf.bgHeader, padding: "4px 0" }} fixed>
                    <View style={{ display: 1, width: '50px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>ID</Text>
                    </View>
                    <View style={{ display: 2, width: '200px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>SERVICIO</Text>
                    </View>
                    <View style={{ display: 3, width: '270px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>DESCRIPCIÓN</Text>
                    </View>
                    <View style={{ display: 4, width: '270px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>PROFESIONAL</Text>
                    </View>
                    <View style={{ display: 5, width: '150px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>F. INGRESO</Text>
                    </View>
                    <View style={{ display: 6, width: '150px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>F.E. PROGRAMADA</Text>
                    </View>
                  </View>
                  {datosGeneral.filter(f => f.id_paciente === mp.id).map((m, index) => (
                    <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                      <View style={{ display: 1, width: '50px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.numero}</Text>
                      </View>
                      <View style={{ display: 2, width: '200px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.servicio}</Text>
                      </View>
                      <View style={{ display: 3, width: '270px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.descripcion}</Text>
                      </View>
                      <View style={{ display: 4, width: '270px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.profesional}</Text>
                      </View>
                      <View style={{ display: 5, width: '150px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.fecha_ingreso}</Text>
                      </View>
                      <View style={{ display: 6, width: '150px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.fecha_entrega}</Text>
                      </View>
                      <br />
                    </View>
                  ))}
                </div>
              ))
            ))
          }
          <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
          <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Pag ${pageNumber}`
          )} fixed />
        </Page>
      }

      {tipo === "repGenTrab" &&
        <Page style={styles.body} orientation="landscape">
          <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
            <View style={{ display: 1 }}>
              <Image
                style={{ width: '55px', height: '50px' }}
                src={Logo}
              />
            </View>
            <View style={{ display: 2, marginHorizontal: '15px', width: '45%' }} >
              <Text style={{ fontSize: '16px' }}>{datosEmpresa.razon_social}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text>
            </View>
            <View style={{ display: 3 }}>
              <Text style={{ fontSize: '16px' }}>REPORTE ORDEN DE SERVICIO</Text>
              <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                <View style={{ display: 1, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>DESDE:  {dataFechas.desde}</Text>
                </View>
                <View style={{ display: 2, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>HASTA:  {dataFechas.hasta}</Text>
                </View>
              </View>
            </View>
          </View>
          <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '12px' }}>TRABAJOS GENERALES</Text>
          <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: estilosTablaPdf.bgHeader, padding: "4px 0" }} fixed>
            <View style={{ display: 1, width: '180px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>CODIGO</Text>
            </View>
            <View style={{ display: 2, width: '270px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>TRABAJADOR</Text>
            </View>
            <View style={{ display: 3, width: '200px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>SERVICIO</Text>
            </View>
            <View style={{ display: 4, width: '250px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>DESCRIPCIÓN</Text>
            </View>
            <View style={{ display: 5, width: '200px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>MATERIAL</Text>
            </View>
            <View style={{ display: 6, width: '150px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>ESTADO</Text>
            </View>
            <View style={{ display: 7, width: '150px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>F. REGISTRO</Text>
            </View>
            <View style={{ display: 8, width: '150px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>F.E. PROGRAMADA</Text>
            </View>
          </View>
          {datosGeneral.map((m, index) => (
            <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
              <View style={{ display: 1, width: '180px', margin: '2px' }}>
                <Text style={{ fontSize: '8pt' }}>{m.fk_ven_orden}</Text>
              </View>
              <View style={{ display: 2, width: '270px', margin: '2px' }}>
                <Text style={{ fontSize: '8pt' }}>{m.trabajador}</Text>
              </View>
              <View style={{ display: 3, width: '200px', margin: '2px' }}>
                <Text style={{ fontSize: '8pt' }}>{m.servicio}</Text>
              </View>
              <View style={{ display: 4, width: '250px', margin: '2px' }}>
                <Text style={{ fontSize: '8pt' }}>{m.descripcion}</Text>
              </View>
              <View style={{ display: 5, width: '200px', margin: '2px' }}>
                <Text style={{ fontSize: '8pt' }}>{m.material}</Text>
              </View>
              <View style={{ display: 6, width: '150px', margin: '2px' }}>
                <Text style={{ fontSize: '8pt' }}>
                  {/* {m.f_registro_prueba != null && m.fecha_entrega === null && m.fecha_conclusion === null ? "REGISTRADO" :
                    m.f_registro_prueba != null && m.fecha_entrega != null && m.fecha_conclusion === null ? "ENTREGADO" :
                      m.f_registro_prueba != null && m.fecha_entrega != null && m.fecha_conclusion != null ? "CONCLUIDO" : ""} */}

                  {m.f_registro_prueba != null && m.fecha_conclusion === null && m.fecha_entrega === null ? "REGISTRADO" :
                    m.f_registro_prueba != null && m.fecha_conclusion != null && m.fecha_entrega === null ? "ENTREGADO" :
                      m.f_registro_prueba != null && m.fecha_conclusion != null && m.fecha_entrega != null ? "CONCLUIDO" : ""}
                </Text>
              </View>
              <View style={{ display: 7, width: '150px', margin: '2px' }}>
                <Text style={{ fontSize: '8pt' }}>{m.f_registro_prueba}</Text>
              </View>
              <View style={{ display: 8, width: '150px', margin: '2px' }}>
                <Text style={{ fontSize: '8pt' }}>{m.fecha_programada + ' ' + m.hora_programada}</Text>
              </View>
              <br />
            </View>
          ))}

          <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
          <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Pag ${pageNumber}`
          )} fixed />
        </Page>
      }
      {tipo === "repTrab" &&
        <Page style={styles.body} orientation="landscape">
          <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
            <View style={{ display: 1 }}>
              <Image
                style={{ width: '55px', height: '50px' }}
                src={Logo}
              />
            </View>
            <View style={{ display: 2, marginHorizontal: '15px', width: '45%' }} >
              <Text style={{ fontSize: '16px' }}>{datosEmpresa.razon_social}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text>
            </View>
            <View style={{ display: 3 }}>
              <Text style={{ fontSize: '16px' }}>REPORTE ORDEN DE SERV.</Text>
              <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                <View style={{ display: 1, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>DESDE:  {dataFechas.desde}</Text>
                </View>
                <View style={{ display: 2, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>HASTA:  {dataFechas.hasta}</Text>
                </View>
              </View>

            </View>
          </View>
          {
            tabGeneralID.map(el => (
              trabajadores.filter(f => el === f.id).map((mp, index) => (
                <div key={index}>
                  <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '10px' }}>TRABAJADOR: {mp.nombres_apellidos}</Text>
                  <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: estilosTablaPdf.bgHeader, padding: "4px 0" }} fixed>
                    <View style={{ display: 1, width: '180px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>CODIGO</Text>
                    </View>
                    <View style={{ display: 2, width: '270px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>SERVICIO</Text>
                    </View>
                    <View style={{ display: 3, width: '270px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>DESCRIPCIÓN</Text>
                    </View>
                    <View style={{ display: 4, width: '150px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>MATERIAL</Text>
                    </View>
                    <View style={{ display: 5, width: '150px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>ESTADO</Text>
                    </View>
                    <View style={{ display: 6, width: '150px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>F. REGISTRO</Text>
                    </View>
                    <View style={{ display: 7, width: '150px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}>F.E. PROGRAMADA</Text>
                    </View>
                  </View>
                  {datosGeneral.filter(f => f.id_trabajador === mp.id).map((m, index) => (
                    <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                      <View style={{ display: 1, width: '180px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.fk_ven_orden}</Text>
                      </View>
                      <View style={{ display: 2, width: '270px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.servicio}</Text>
                      </View>
                      <View style={{ display: 3, width: '270px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.descripcion}</Text>
                      </View>
                      <View style={{ display: 4, width: '150px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.material}</Text>
                      </View>
                      <View style={{ display: 5, width: '150px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>
                          {m.f_registro_prueba != null && m.fecha_conclusion === null && m.fecha_entrega === null ? "REGISTRADO" :
                            m.f_registro_prueba != null && m.fecha_conclusion != null && m.fecha_entrega === null ? "ENTREGADO" :
                              m.f_registro_prueba != null && m.fecha_conclusion != null && m.fecha_entrega != null ? "CONCLUIDO" : ""}
                        </Text>
                      </View>
                      <View style={{ display: 6, width: '150px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.f_registro_prueba}</Text>
                      </View>
                      <View style={{ display: 7, width: '150px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>{m.fecha_programada + ' ' + m.hora_programada}</Text>
                      </View>
                      <br />
                    </View>
                  ))}
                </div>
              ))
            ))
          }
          <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
          <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Pag ${pageNumber}`
          )} fixed />
        </Page>
      }
    </Document >
  )
}
