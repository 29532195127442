import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { Button } from '@mui/material';
import excel from '../../../img/excel.png';

export default function ExportExcel(props) {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const [pagosCaja, setPagosCaja] = React.useState([]);
    const exportToCSV = () => {
        const csvData = props.data;
        const registro = 'registro';
        let pagosC = [].concat(pagosCaja);
        csvData.forEach((r) => {
            pagosC.push({
                'CODIGO': r.codigo,
                'FECHA': r.fecha,
                'REFERENCIA': r.referencia,
                'TRABAJADOR': r.nombres_apellidos,
                'GASTO': r.gasto,
                'CANTIDAD': r.cantidad,
                'PRECIO U.': r.precio_unitario,
                'TOTAL': r.total
            })
        });
        pagosC.push({
            'CODIGO': 'TOTAL',
            'FECHA': '',
            'REFERENCIA': '',
            'TRABAJADOR': '',
            'GASTO': '',
            'CANTIDAD': '',
            'PRECIO U.': 'S/' + csvData.reduce((total, item) => parseFloat(total) + parseFloat(item.precio_unitario), 0).toFixed(2),
            'TOTAL': 'S/' + csvData.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)
        });
        setPagosCaja(pagosC);
        const ws = XLSX.utils.json_to_sheet(pagosC, { skipHeader: false });
        var wscols = [
            { wch: 20, },
            { wch: 25 },
            { wch: 25 },
            { wch: 40 },
            { wch: 40 },
            { wch: 10 },
            { wch: 20 },
            { wch: 20 },
        ];
        ws['!cols'] = wscols;
        const wb = { Sheets: { registro: ws }, SheetNames: ['registro'] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'RPTE-RESUMEN-REGISTRO-GASTOS' + fileExtension);
    }
    return (
        <Button startIcon={<img style={{ width: '20px', height: '20px' }} src={excel} />} variant='contained' color="success" onClick={exportToCSV}>EXCEL</Button>

    )
}
