import { Button, Chip, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import img404 from '../img/404.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SendIcon from '@mui/icons-material/Send';
export default function Error404() {
  const navigate = useNavigate();
  return (
    <Grid container style={{ justifyContent: 'center', height: '100vh' }}>
      <Grid item md={5} style={{ textAlign: 'center', margin: 'auto' }} xs={12} className="noneLogin">
        <img src={img404} style={{ maxWidth: '80%', width: '500px' }} />
      </Grid>
      <Grid item md={6} style={{ textAlign: 'center', margin: 'auto', padding: '0.5em' }} xs={12} >
        <Typography className='error404' variant='h1'>404</Typography>
        <Typography variant='h5' className='btn404'>Lo sentimos, pero no se encontró la página solicitada</Typography>
        <Button startIcon={<ArrowBackIosIcon />} onClick={() => navigate("/")} style={{ marginTop: '20px', fontSize: '15px' }} variant="contained" color="error" className='btn404'>REGRESAR</Button>
        <Button endIcon={<SendIcon />} style={{ marginTop: '20px', marginLeft: '6px', fontSize: '15px' }} variant="outlined" color='error' className='btn404'>CONTACTAR</Button>
      </Grid>
    </Grid>
  )
}
