import React from 'react';
import { Button, Autocomplete, TextField, Switch, FormControlLabel, IconButton, Alert, Collapse, MenuItem, Grid, FormControl, InputLabel, Input, InputAdornment } from '@mui/material';
import UrlsConst from '../../../config/config';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';

export default function DialogUsuario(props) {
  const [alerta, setAlerta] = React.useState(false);
  const [alertaS, setAlertaS] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // const [value, setValue] = React.useState(perfiles[0]);
  const [trabajadores, setTrabajadores] = React.useState([{
    id: 0,
    numero_documento: '',
    nombres_apellidos: '',
    es_activo: '',
    cargo: '',
    fk_con_empresa: ''
  }]);
  const [perfiles, setPerfiles] = React.useState([{
    codigo: '',
    nombre: '',
    descripcion: '',
    es_activo: ''
  }]);
  const [usuarioSubmit, setUsuarioSubmit] = React.useState({
    codigo: '',
    fk_log_perfil: '',
    clave: '',
    fk_rh_trabajador: '',
    es_activo: 0
  });
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  // validaciones
  const [codigoUsuario, setCodigoUsuario] = React.useState({
    error: false,
    text: ''
  });
  const [perfilUsuario, setPerfilUsuario] = React.useState({
    error: false,
    text: ''
  });
  const [trabajorUsuario, setTrabajadorUsuario] = React.useState({
    error: false,
    text: ''
  });
  // const [] = React.useState({
  //   error: false,
  //   text: ''
  // });
  // const [] = React.useState({
  //   error: false,
  //   text: ''
  // });
  // handles
  const handlePerfil = (e, values) => {
    setPerfilUsuario({ ...perfilUsuario, error: false, text: '' });
    setUsuarioSubmit({ ...usuarioSubmit, fk_log_perfil: values.props.value });
  }
  const handleTrabajador = (e, values) => {
    setTrabajadorUsuario({ ...trabajorUsuario, error: false, text: '' });
    setUsuarioSubmit({ ...usuarioSubmit, fk_rh_trabajador: values.props.value });
  }
  const handleCheck = (e, values) => {
    const { name } = e.target;
    if (e.target.checked != false) {
      setUsuarioSubmit({ ...usuarioSubmit, [name]: 1 })
    } else {
      setUsuarioSubmit({ ...usuarioSubmit, [name]: 0 })
    }
  }
  const handleChangePassword = (prop) => (event) => {
    const { name } = event.target
    setValues({ ...values, [prop]: event.target.value });

    const clave = document.getElementById('clave');
    const claveConfirmar = document.getElementById('confirmarClave');

    if (clave.value != "" && claveConfirmar.value != "") {
      if (clave.value != claveConfirmar.value) {
        setTextAlert('!ERROR¡ LAS CLAVES NO COINCIDEN');
        setAlerta(true);
        setTimeout(() => { setAlerta(false) }, 2500);
        setUsuarioSubmit({ ...usuarioSubmit, clave: '' });
      } else {
        setTextAlert('LAS CLAVES COINCIDEN');
        setAlerta(false);
        setAlertaS(true);
        setTimeout(() => { setAlertaS(false) }, 2500);
        setUsuarioSubmit({ ...usuarioSubmit, clave: claveConfirmar.value });
      }
    }
  };

  const handleCodigo = (e) => {
    let valor = e.target.value;
    let valorF = valor.replace(/ /g, "");
    setUsuarioSubmit({ ...usuarioSubmit, codigo: valorF.toUpperCase() });
  }
  const handleClickShowPassword = (e, value) => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    listarPerfiles();
    listarTrabajadores();
    if (props.fntIdUsuario != undefined) {
      recuperarUsuario();
    }
  }, []);
  // funciones
  const listarPerfiles = async () => {
    const perfiles = await fetch(`${UrlsConst.URL_API}/sistema/perfil/listarPerfil.php`, {
      method: "GET"
    });
    const response = await perfiles.json();
    setPerfiles(response);
  }
  const listarTrabajadores = async () => {
    const trabajadores = await fetch(`${UrlsConst.URL_API}/sistema/trabajador/listarTrabajador.php`, {
      method: "GET"
    });
    const response = await trabajadores.json();
    setTrabajadores(response);
  }
  const recuperarUsuario = async () => {
    const usuarioGet = await fetch(`${UrlsConst.URL_API}/sistema/usuario/recuperarUsuario.php?codigo=` + props.fntIdUsuario, {
      method: 'GET'
    });
    const response = await usuarioGet.json();
    setUsuarioSubmit(response);
    perfiles.forEach((item) => {
      if (item.codigo === response.fk_log_perfil) {
        const po = perfiles.indexOf(item);
        // setValue(perfiles[po]);
      }
    });
  }
  const setUsuarios = async () => {
    if (usuarioSubmit.codigo === "" || usuarioSubmit.fk_log_perfil === "" || usuarioSubmit.fk_rh_trabajador === "") {
      if (usuarioSubmit.codigo === "") {
        setCodigoUsuario({ ...codigoUsuario, error: true, text: 'Codigo obligatorio' });
      }
      if (usuarioSubmit.fk_log_perfil === "") {
        setPerfilUsuario({ ...perfilUsuario, error: true, text: 'Perfil obligatorio' });
      }
      if (usuarioSubmit.fk_rh_trabajador === "") {
        setTrabajadorUsuario({ ...trabajorUsuario, error: true, text: 'Trabajador obligatorio' });
      }
    } else {
      const usuarios = props.usuarios;
      const validar = usuarios.filter((item) => item.codigo === usuarioSubmit.codigo);
      if (validar.length === 0) {
        setLoading(true);
        const saveUsuario = await fetch(`${UrlsConst.URL_API}/sistema/usuario/guardarUsuario.php`, {
          method: 'POST',
          body: JSON.stringify(usuarioSubmit)
        });
        const response = await saveUsuario.json();
        props.fntUsuarios();
        props.fntClose();
      } else {
        setLoading(false);
        console.log('ya se encuentra un usario con ese codigo');
      }
    }
  }
  return (
    <>
      <Collapse in={alerta}>
        <Alert severity='error'
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlerta(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {textAlert}
        </Alert>
      </Collapse>
      <Collapse in={alertaS}>
        <Alert severity='success'
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertaS(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {textAlert}
        </Alert>
      </Collapse>
      <Grid container spacing={3}>
        <Grid item md={9}>
          <TextField
            fullWidth
            margin="normal"
            label="CODIGO"
            name='codigo'
            variant='standard'
            error={codigoUsuario.error}
            helperText={codigoUsuario.text}
            value={usuarioSubmit ? usuarioSubmit.codigo : ''}
            disabled={props.fntIdUsuario != undefined ? true : false}
            onChange={
              (e) => {
                const { value } = e.target;
                if (value != "") {
                  setUsuarioSubmit({ ...usuarioSubmit, codigo: value.replace(/ /g, "").toUpperCase() });
                  setCodigoUsuario({ ...codigoUsuario, error: false, text: '' });
                } else {
                  setUsuarioSubmit({ ...usuarioSubmit, codigo: '' });
                  setCodigoUsuario({ ...codigoUsuario, error: true, text: 'Codigo obligatorio' });
                }
              }
            }
          />
        </Grid>
        <Grid item md={3} style={{ marginTop: '30px' }}>
          <FormControlLabel labelPlacement='start'
            control={
              <Switch color="success" name='es_activo'
                onChange={handleCheck} checked={usuarioSubmit.es_activo != 0} />
            }
            label="ESTADO" />
        </Grid>
        <Grid item md={props.fntIdUsuario === undefined ? 6 : 12} xs={12}>
          <TextField
            style={{ textAlign: 'start' }}
            select
            margin='normal'
            fullWidth
            value={usuarioSubmit ? usuarioSubmit.fk_log_perfil : 0}
            label="PERFILES"
            variant='standard'
            error={perfilUsuario.error}
            helperText={perfilUsuario.text}
            onChange={handlePerfil}
          >
            {perfiles.map((option) => (
              <MenuItem key={option.codigo} value={option.codigo}>
                {option.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={props.fntIdUsuario === undefined ? 6 : 12} xs={12}>
          <TextField
            style={{ textAlign: 'start' }}
            select
            margin='normal'
            fullWidth
            value={usuarioSubmit ? usuarioSubmit.fk_rh_trabajador : ''}
            label="TRABAJADORES"
            variant='standard'
            error={trabajorUsuario.error}
            helperText={trabajorUsuario.text}
            onChange={handleTrabajador}
          >
            {trabajadores.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.nombres_apellidos}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      {props.fntIdUsuario === undefined &&
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <FormControl sx={{ mt: 3, width: '100%' }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">CLAVE *</InputLabel>
              <Input
                required
                id="clave"
                type={values.showPassword ? 'text' : 'password'}
                name="clave"
                onChange={handleChangePassword('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl sx={{ mt: 3, mb: 3, width: '100%' }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">CONFIRMAR CLAVE *</InputLabel>
              <Input
                required
                id="confirmarClave"
                type={values.showPassword ? 'text' : 'password'}
                name="clave"
                onChange={handleChangePassword('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      }
      <LoadingButton
        onClick={setUsuarios}
        startIcon={<CheckIcon />}
        loading={loading}
        loadingPosition="start"
        variant="outlined"
        color="success"
      >
        {usuarioSubmit.guardar != undefined ? 'GUARDAR' : 'ACTUALIZAR'}
      </LoadingButton>
    </>
  )
}
