import * as React from 'react';
import { Grid, Button, TextField, MenuItem, Alert, IconButton, Collapse, FormControlLabel, Switch, InputAdornment } from '@mui/material';
import UrlsConst from '../../../config/config';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LoadingButton } from '@mui/lab';

const tipo_parametro = [
    { id: 'S', value: 'TEXTO' },
    { id: 'N', value: 'NUMERO' },
    { id: 'D', value: 'FECHA' },
    { id: 'B', value: 'VERDADERO O FALSO' },
];
export default function DialogPamarametro(props) {
    const [date, setDate] = React.useState('');
    const [alert, setAlert] = React.useState(false);
    const [textAlert, setTextAlert] = React.useState('');
    const [typeAlert, setTypeAlert] = React.useState('error');
    const [loading, setLoading] = React.useState(false);
    const [parametroSubmit, setParametroSubmit] = React.useState({
        codigo: '',
        nombre: '',
        descripcion: '',
        tipo: 'S',
        valor_string: '',
        valor_number: '',
        valor_date: '',
        valor_bool: null
    });
    // validar parametros
    const [codigoParametro, setCodigoParametro] = React.useState({
        error: false,
        text: ''
    });
    const [nombreParametro, setNombreParametro] = React.useState({
        error: false,
        text: ''
    });
    const [valorString, setValorString] = React.useState({
        error: false,
        text: ''
    });
    const [valorNumber, setValorNumber] = React.useState({
        error: false,
        text: ''
    });
    const [valorDate, setValorDate] = React.useState({
        error: false,
        text: ''
    });

    const handleParametro = (e) => {
        const { name, value } = e.target;
        setParametroSubmit({ ...parametroSubmit, [name]: value.toUpperCase() });
    }
    const handleTipo = (e, values) => {
        let valor = e.target.value;
        setParametroSubmit({
            ...parametroSubmit,
            tipo: valor,
            valor_number: "",
            valor_date: "",
            valor_bool: null,
            valor_string: "",
        });
        setValorString({ ...valorString, error: false, text: '' });
        setValorNumber({ ...valorDate, error: false, text: '' });
        setValorDate({ ...valorDate, error: false, text: '' });
    }
    const handleBool = (e) => {
        const { name } = e.target;
        if (e.target.checked != false) {
            setParametroSubmit({ ...parametroSubmit, [name]: 1 });
        } else {
            setParametroSubmit({ ...parametroSubmit, [name]: null });
        }
    }
    const recuperarParametro = async () => {
        const getParametro = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=` + props.fntIdParametro, {
            method: 'GET'
        });
        const response = await getParametro.json();
        if (response.valor_date != null) {
            setDate(response.valor_date);
        } else {
            setDate('');
        }
        setParametroSubmit(response);
    }
        ;
    const setParametro = async () => {
        if (parametroSubmit.codigo === "" || parametroSubmit.nombre === "" || (parametroSubmit.tipo === "S" && parametroSubmit.valor_string === "")
            || (parametroSubmit.tipo === "N" && parametroSubmit.valor_number === "") || (parametroSubmit.tipo === "D" && parametroSubmit.valor_date === "")) {
            if (parametroSubmit.codigo === "") {
                setCodigoParametro({ ...codigoParametro, error: true, text: 'Codigo obligatorio' });
            }
            if (parametroSubmit.nombre === "") {
                setNombreParametro({ ...nombreParametro, error: true, text: '' });
            }
            if (parametroSubmit.tipo === "S" && parametroSubmit.valor_string === "") {
                setValorString({ ...valorString, error: true, text: 'Valor texto obligatorio' });
            }
            if (parametroSubmit.tipo === "N" && parametroSubmit.valor_number === "") {
                setValorNumber({ ...valorNumber, error: true, text: 'Valor numero obligatorio' });
            }
            if (parametroSubmit.tipo === "D" && parametroSubmit.valor_date === "") {
                setValorDate({ ...valorDate, error: true, text: 'Valor date obligatorio' });
            }
        } else {
            const parametros = props.parametros;
            const validar = parametros.filter((item) => item.codigo === parametroSubmit.codigo);
            if (validar.length === 0) {
                setLoading(true);
                const set_parametro = await fetch(`${UrlsConst.URL_API}/sistema/parametro/guardarParametro.php`, {
                    method: 'POST',
                    body: JSON.stringify(parametroSubmit)
                });
                const response = await set_parametro.json();
                setDate('');
                props.fntClose();
                if (parametroSubmit.id != 0) {
                    props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'Parametro actualizado correctamente', open: true });
                    setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
                } else {
                    props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'Parametro guardo correctamente', open: true });
                    setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
                }
                props.fntParametros();
            } else {
                setLoading(false);
                props.setAlertGeneral({ ...props.alertGeneral, type: 'error', text: 'Codigo de parametro ya se encuentra registrado', open: true });
                setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
            }

        }
    }
    React.useEffect(() => {
        if (props.fntIdParametro != undefined) {
            recuperarParametro();
        }
    }, []);
    return (
        <>
            <Collapse in={alert}>
                <Alert variant="outlined" severity={typeAlert}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlert(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {textAlert}
                </Alert>
            </Collapse>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField
                        label="CODIGO"
                        fullWidth
                        margin='normal'
                        variant='standard'
                        inputProps={{ maxLength: 20 }}
                        name="codigo"
                        disabled={props.fntIdParametro != undefined ? true : false}
                        value={parametroSubmit ? parametroSubmit.codigo : ''}
                        error={codigoParametro.error}
                        helperText={codigoParametro.text}
                        onChange={
                            (e) => {
                                const { value } = e.target;
                                if (value != "") {
                                    setParametroSubmit({ ...parametroSubmit, codigo: value.replace(/ /g, "").toUpperCase() });
                                    setCodigoParametro({ ...codigoParametro, error: false, text: '' });
                                } else {
                                    setParametroSubmit({ ...parametroSubmit, codigo: '' });
                                    setCodigoParametro({ ...codigoParametro, error: true, text: 'Codigo obligatorio' });
                                }
                            }
                        }
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        label="NOMBRE"
                        fullWidth
                        margin='normal'
                        variant='standard'
                        inputProps={{ maxLength: 50 }}
                        name="nombre"
                        value={parametroSubmit ? parametroSubmit.nombre : ''}
                        error={nombreParametro.error}
                        helperText={nombreParametro.text}
                        onChange={
                            (e) => {
                                const { value } = e.target;
                                if (value != "") {
                                    setNombreParametro({ ...nombreParametro, error: false, text: '' });
                                    setParametroSubmit({ ...parametroSubmit, nombre: value.toUpperCase() });
                                } else {
                                    setNombreParametro({ ...nombreParametro, error: true, text: 'Nombre obligatorio' });
                                    setParametroSubmit({ ...parametroSubmit, nombre: '' });
                                }
                            }
                        }
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <TextField
                        label="DESCRIPCION"
                        fullWidth
                        margin='normal'
                        multiline
                        rows={2}
                        inputProps={{ maxLength: 250 }}
                        name="descripcion"
                        value={parametroSubmit ? parametroSubmit.descripcion : ''}
                        onChange={handleParametro}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        style={{ textAlign: 'start' }}
                        select
                        margin='normal'
                        fullWidth
                        value={parametroSubmit.parametroSubmit != '' ? parametroSubmit.tipo : ''}
                        label="TIPO"
                        variant='standard'
                        onChange={handleTipo}
                    >
                        {tipo_parametro.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {parametroSubmit.tipo === "S" &&
                    <Grid item md={6} xs={12}>
                        <TextField
                            label="VALOR DE TEXTO"
                            fullWidth
                            margin='normal'
                            variant='standard'
                            inputProps={{ maxLength: 250 }}
                            name="valor_string"
                            value={parametroSubmit.valor_string != undefined ? parametroSubmit.valor_string : ''}
                            error={valorString.error}
                            helperText={valorString.text}
                            onChange={
                                (e) => {
                                    const { value } = e.target;
                                    if (value != "") {
                                        setParametroSubmit({ ...parametroSubmit, valor_string: value.toUpperCase() });
                                        setValorString({ ...valorString, error: false, text: '' });
                                    } else {
                                        setParametroSubmit({ ...parametroSubmit, valor_string: '' });
                                        setValorString({ ...valorString, error: true, text: 'Valor string obligatorio' });
                                    }
                                }
                            }
                        />
                    </Grid>}
                {parametroSubmit.tipo === "N" &&
                    <Grid item md={6} xs={12} >
                        <TextField
                            label="VALOR DE NUMERO"
                            fullWidth
                            margin='normal'
                            variant='standard'
                            inputProps={{ maxLength: 250 }}
                            name="valor_number"
                            error={valorNumber.error}
                            helperText={valorNumber.text}
                            value={parametroSubmit.valor_number != undefined ? parametroSubmit.valor_number : ''}
                            onChange={
                                (e) => {
                                    const { value } = e.target;
                                    if (value != "") {
                                        if (isNaN(value) === false) {
                                            setParametroSubmit({ ...parametroSubmit, valor_number: value });
                                            setValorNumber({ ...valorNumber, error: false, text: '' });
                                        } else {
                                            setValorNumber({ ...valorNumber, error: true, text: 'Valor numero no puede ser texto' });
                                        }
                                    } else {
                                        setParametroSubmit({ ...parametroSubmit, valor_number: '' });
                                        setValorNumber({ ...valorNumber, error: true, text: 'Valor numero obligatorio' });
                                    }
                                }
                            }
                        />
                    </Grid>}
                {parametroSubmit.tipo === 'D' &&
                    <Grid item md={6} xs={12} >
                        <TextField
                            label="VALOR DE FECHA"
                            fullWidth
                            margin='normal'
                            variant='standard'
                            type="date"
                            error={valorDate.error}
                            helperText={valorDate.text}
                            value={parametroSubmit ? parametroSubmit.valor_date : ''}
                            onChange={(e) => {
                                const fechaI = e.target.value;
                                if (fechaI != "") {
                                    setValorDate({ ...valorDate, error: false, text: '' });
                                    setParametroSubmit({ ...parametroSubmit, valor_date: fechaI });
                                } else {
                                    setValorDate({ ...valorDate, error: true, text: 'Valor fecha obligatoria' });
                                    setParametroSubmit({ ...parametroSubmit, valor_date: '' });
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CalendarMonthIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                }
                {parametroSubmit.tipo === 'B' &&
                    <Grid id="valor_bool" item md={6} xs={12} >
                        <FormControlLabel
                            labelPlacement='start'
                            label="VALOR BOLEANO"
                            control={
                                <Switch
                                    name='valor_bool'
                                    onChange={handleBool}
                                    checked={parametroSubmit.valor_bool != null}
                                />
                            }

                        />
                    </Grid>
                }
            </Grid>
            <LoadingButton
                onClick={setParametro}
                startIcon={<CheckIcon />}
                loading={loading}
                loadingPosition="start"
                variant="outlined"
                color="success"
            >
                {props.fntIdParametro != undefined ? 'ACTUALIZAR' : 'GUARDAR'}
            </LoadingButton>
        </>
    )
}
