import React from 'react'
import { Grid, Paper, TextField, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, InputAdornment, Chip, Typography, TablePagination } from '@mui/material';
import UrlsConst from '../../../config/config';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PdfReporteG from '../pdf/PdfReporteG';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

export default function RepGeneral(props) {
    const datenow = new Date().toJSON().slice(0, 10);
    const [datosProfGeneral, setDatosProfGeneral] = React.useState([]);
    const [dataFechas, setDataFechas] = React.useState({ desde: datenow, hasta: datenow });
    const typogFuente = { fuente: "", size: 15 };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const cargarProfGeneralDatos = async (desde, hasta) => {
        if (desde !== "" && hasta !== "") {
            if (desde <= hasta) {
                const search = await fetch(`${UrlsConst.URL_API}/reportes/RPordenServicioGeneral.php?desde=${desde}&hasta=${hasta}`, {
                    method: "GET",
                });
                const response = await search.json();
                response.sort((a, b) => new Date(a.fecha_entrega).getTime() < new Date(b.fecha_entrega).getTime())
                setDatosProfGeneral(response);
            }
        }
    }

    const alertPdf = () => {
        props.alertPdf();
    }
    const [datosEmpresa, setDatosEmpresa] = React.useState({
        ruc: '',
        razon_social: '',
        direccion: ''
    });
    React.useEffect(() => {
        const getDatosEmpresa = async () => {
            const getRuc = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RUC_EMPRESA`, {
                method: 'GET'
            });
            const response_getRuc = await getRuc.json();
            const getRazon = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RAZON_SOCIAL`, {
                method: 'GET'
            });
            const response_getRazon = await getRazon.json();
            const getDireccion = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=DIRECCION_EMPRESA`, {
                method: 'GET'
            });
            const response_getDireccion = await getDireccion.json();
            setDatosEmpresa({ ruc: response_getRuc.valor_string, razon_social: response_getRazon.valor_string, direccion: response_getDireccion.valor_string });
        }
        getDatosEmpresa();
    }, []);
    return (
        <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
                <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={2} sx={{ justifyContent: "start" }}>
                            <Grid item md={5} xs={12}>
                                <TextField
                                    id="filtro_desde"
                                    label="FILTRO DESDE"
                                    type="date"
                                    fullWidth
                                    variant='standard'
                                    margin='normal'
                                    value={dataFechas ? dataFechas.desde : ''}
                                    onChange={(e) => {
                                        const fechaFiltroD = e.target.value;
                                        if (fechaFiltroD != null) {
                                            setDataFechas({ ...dataFechas, desde: fechaFiltroD });
                                        } else {
                                            setDataFechas({ ...dataFechas, desde: '' });
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CalendarMonthIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item md={5} xs={12}>
                                <TextField
                                    id="filtro_hasta"
                                    label="HASTA"
                                    type="date"
                                    fullWidth
                                    variant='standard'
                                    margin='normal'
                                    value={dataFechas ? dataFechas.hasta : ''}
                                    onChange={(e) => {
                                        const fechaFiltroS = e.target.value;
                                        if (fechaFiltroS != null) {
                                            setDataFechas({ ...dataFechas, hasta: fechaFiltroS });
                                        } else {
                                            setDataFechas({ ...dataFechas, hasta: '' });
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CalendarMonthIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={2} sx={{ justifyContent: "end" }}>
                            <Grid item md={3.5} xs={12} >
                                <Grid container style={{ paddingTop: "1.5em" }} spacing={0.3}>
                                    <Grid item md={6} xs={6}>
                                        <Tooltip title="Buscar" placement='top'>
                                            <Button fullWidth sx={{ alignItems: "center" }} variant="contained" color="warning"
                                                onClick={() => cargarProfGeneralDatos(dataFechas.desde, dataFechas.hasta)}
                                            ><SearchIcon /></Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        {datosProfGeneral.length !== 0 ?
                                            <PDFDownloadLink document={<PdfReporteG datosGeneral={datosProfGeneral} dataFechas={dataFechas} datosEmpresa={datosEmpresa} />} fileName="REPORTE-GENERAL">
                                                {({ loading }) => (loading ? <Button fullWidth variant="contained" color="error"><PictureAsPdfIcon /></Button> : <Button fullWidth variant="contained" color="error"><PictureAsPdfIcon /></Button>)}
                                            </PDFDownloadLink> :
                                            <Button fullWidth variant="contained" color="error" onClick={alertPdf}><PictureAsPdfIcon /></Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
                <Paper>
                    <TableContainer sx={{ maxWidth: '310px', minWidth: '100%', height: 400 }}  >
                        <Table aria-label="simple table" >
                            <TableHead sx={{ backgroundColor: "#bde0fe" }}>
                                <TableRow >
                                    <TableCell style={{ width: '2%' }}>ID</TableCell>
                                    <TableCell>PROFESIONAL</TableCell>
                                    {/* <TableCell>DOCUMENTO</TableCell> */}
                                    <TableCell>SERVICIO</TableCell>
                                    <TableCell>DESCRIPCION</TableCell>
                                    <TableCell>PACIENTE</TableCell>
                                    <TableCell>ESTADO PAGO</TableCell>
                                    <TableCell>F. INGRESO</TableCell>
                                    <TableCell>F.E PROGRAMADA </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {datosProfGeneral.length !== 0 &&
                                    (rowsPerPage > 0
                                        ? datosProfGeneral.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : datosProfGeneral
                                    ).map((m) => (
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={m.id_orden}>
                                            <TableCell component="th" scope="row">
                                                {m.numero}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.profesional}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.servicio}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.descripcion}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.paciente}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.estado_pago === "G" ? <Chip label="PAGADO" color="success" sx={{ fontSize: "14px", height: "25px" }} /> :
                                                    m.estado_pago === 'A' ? <Chip label="A CUENTA" color="warning" sx={{ fontSize: "14px", height: "25px" }} /> :
                                                        m.estado_pago === "P" ? <Chip label="PENDIENTE" color="error" sx={{ fontSize: "14px", height: "25px" }} /> : ""}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.fecha_ingreso}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.fecha_entrega}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }

                            </TableBody>
                        </Table>
                        {datosProfGeneral.length === 0 &&
                            <Grid sx={{ justifyItems: "center", textAlign: "center ", padding: "5em 0" }}>
                                <Typography gutterBottom align="center" color="text.secondary" sx={{ fontSize: typogFuente.size, fontFamily: typogFuente.fuente }}>
                                    NO SE ENCONTRARON DATOS
                                </Typography>
                            </Grid>
                        }
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        labelRowsPerPage="Filas por columnas"
                        count={datosProfGeneral.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
            <Grid item md={12}>
                {/* <PDFViewer width="100%" height={600}>
                    <PdfReporte />
                </PDFViewer> */}
                {/* {datosProfGeneral.length !== 0 &&
                    <PDFViewer width="100%" height={600}>
                        <PdfReporteG datosGeneral={datosProfGeneral} dataFechas={dataFechas} datosEmpresa={datosEmpresa} />
                    </PDFViewer>
                } */}
            </Grid>
        </Grid>
    )
}

