import { Grid, MenuItem, TextField } from '@mui/material';
import * as React from 'react';
import { LoadingButton } from '@mui/lab';
// iconos
import UrlsConst from '../../../config/config';
import CheckIcon from '@mui/icons-material/Check';

export default function ProfesionalDialog(props) {
  const [loading, setLoading] = React.useState(false);
  // validar
  const [apellidosProfesional, setApellidosProfesional] = React.useState({
    error: false,
    text: ''
  });
  const [documentoProfesional, setDocumentoProfesional] = React.useState({
    error: false,
    text: ''
  });

  const [telefonoProfesional, setTelefonoProfesional] = React.useState({
    error: false,
    text: ''
  });

  const [profesionalSubmit, setProfesionalSubmit] = React.useState(
    {
      id: 0,
      colegiatura: '',
      apellidos_nombres: '',
      numero_telefono: '',
      direccion: '',
      observaciones: '',
      fk_com_tipo_documento: '00',
      numero_documento: ''
    }
  );
  const [documento, setDocumento] = React.useState([{
    codigo: '',
    descripcion_corta: '',
    descripcion_larga: '',
    longitud: 0,
    tipo: '',
    contribuyente: '',
    longitud_exacta: 0
  }]);
  const handleChangeProfesional = (e) => {
    const { name, value } = e.target;
    setProfesionalSubmit({ ...profesionalSubmit, [name]: value.toUpperCase() });
  }
  const [maxlength, setMaxlength] = React.useState(0);

  React.useEffect(() => {
    tipoDocumento();
    recuperarProfesional();
  }, []);
  const recuperarProfesional = async () => {
    const profesionalGet = await fetch(`${UrlsConst.URL_API}/ventas/profesional/recuperarProfesional.php?id=` + props.fntIdprofesional, {
      method: "GET"
    });
    const response = await profesionalGet.json();
    setMaxlength(response.numero_documento.length);
    setProfesionalSubmit(response);
  }
  const tipoDocumento = async () => {
    const documentos_tipo = await fetch(`${UrlsConst.URL_API}/sistema/listarDocumento.php`, {
      method: "POST"
    });
    const json = await documentos_tipo.json();
    setDocumento(json);
  }
  const documentSelectProfesional = (e, values) => {
    setMaxlength(values.props.loguitud);
    setProfesionalSubmit({ ...profesionalSubmit, fk_com_tipo_documento: values.props.value, numero_documento: '' });
  }
  const fntEditProfesional = async () => {
    if (profesionalSubmit.apellidos_nombres === "" || profesionalSubmit.numero_documento === "") {
      if (profesionalSubmit.apellidos_nombres === "") {
        setApellidosProfesional({ ...apellidosProfesional, error: true, text: 'Nombres y apellidos obligatorio' });
      }
      if (profesionalSubmit.numero_documento === "") {
        setDocumentoProfesional({ ...documentoProfesional, error: true, text: 'Documento obligatorio' });
      }
    } else {
      setLoading(true);
      const profesional = await fetch(`${UrlsConst.URL_API}/ventas/profesional/guardarProfesional.php`, {
        method: "POST",
        body: JSON.stringify({
          id: profesionalSubmit.id,
          colegiatura: profesionalSubmit.colegiatura,
          apellidos_nombres: profesionalSubmit.apellidos_nombres,
          numero_telefono: profesionalSubmit.numero_documento,
          direccion: profesionalSubmit.direccion,
          observaciones: profesionalSubmit.observaciones,
          fk_com_tipo_documento: profesionalSubmit.fk_com_tipo_documento,
          numero_documento: profesionalSubmit.numero_documento
        }),
      });
      const response = await profesional.json();
      props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'Se actualizo correctamente el profesional', open: true });
      setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
      if (props.setProfesional != undefined) {
        props.setProfesional(profesionalSubmit);
      }
      props.fntClose();
      if (props.fntProfesionales != undefined) {
        props.fntProfesionales();
      }
    }
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <TextField
            error={apellidosProfesional.error}
            fullWidth
            required
            label="NOMBRES Y APELLIDOS"
            id="apellidos_nombres_prof"
            name='apellidos_nombres'
            variant="standard"
            margin='normal'
            value={profesionalSubmit ? profesionalSubmit.apellidos_nombres : ''}
            helperText={apellidosProfesional.text}
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={
              (e) => {
                const { value } = e.target;
                if (value != "") {
                  setProfesionalSubmit({ ...profesionalSubmit, apellidos_nombres: value });
                  setApellidosProfesional({ ...apellidosProfesional, error: false, text: '' });
                } else {
                  setProfesionalSubmit({ ...profesionalSubmit, apellidos_nombres: '' });
                  setApellidosProfesional({ ...apellidosProfesional, error: true, text: 'Nombres y apellidos obligatorio' });
                }
              }
            }
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            margin="normal"
            fullWidth
            name="colegiatura"
            variant="standard"
            label="COLEGIATURA"
            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 20 }}
            value={profesionalSubmit ? profesionalSubmit.colegiatura : ''}
            onChange={handleChangeProfesional}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            style={{ textAlign: 'start' }}
            select
            margin='normal'
            fullWidth
            value={profesionalSubmit ? profesionalSubmit.fk_com_tipo_documento : ''}
            label="TIPO DE DOCUMENTO"
            variant='standard'
            onChange={documentSelectProfesional}
          >
            {documento.map((option) => (
              <MenuItem key={option.codigo} value={option.codigo} loguitud={option.longitud}>
                {option.descripcion_larga}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            required
            fullWidth
            name='numero_documento'
            variant="standard"
            margin='normal'
            label="DOCUMENTO"
            inputProps={{ style: { textTransform: "uppercase" }, maxLength: maxlength }}
            error={documentoProfesional.error}
            helperText={documentoProfesional.text}
            value={profesionalSubmit ? profesionalSubmit.numero_documento : ''}
            onChange={
              (e) => {
                const { value } = e.target;
                if (value != "") {
                  setProfesionalSubmit({ ...profesionalSubmit, numero_documento: value });
                  setDocumentoProfesional({ ...documentoProfesional, error: false, text: '' });
                } else {
                  setProfesionalSubmit({ ...profesionalSubmit, numero_documento: '' });
                  setDocumentoProfesional({ ...documentoProfesional, error: true, text: 'Documento obligatorio' });
                }
              }
            }
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            error={telefonoProfesional.error}
            fullWidth
            margin='normal'
            variant="standard"
            name='numero_telefono'
            label="TELEFONO"
            helperText={telefonoProfesional.text}
            inputProps={{ maxLength: 20 }}
            value={profesionalSubmit ? profesionalSubmit.numero_telefono : ''}
            onChange={(e) => {
              if (e.target.value != "") {
                if (isNaN(e.target.value) != true) {
                  setProfesionalSubmit({ ...profesionalSubmit, numero_telefono: e.target.value });
                } else {
                  setTelefonoProfesional({ ...telefonoProfesional, error: true, text: 'Telefono no puede ser un texto' });
                }
              } else {
                setProfesionalSubmit({ ...profesionalSubmit, numero_telefono: '' });
                setTelefonoProfesional({ ...telefonoProfesional, error: false, text: '' });
              }

            }}
          />
        </Grid>
      </Grid>
      <TextField
        fullWidth
        variant='standard'
        name="direccion"
        label="DIRECCION"
        margin='normal'
        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 250 }}
        value={profesionalSubmit ? profesionalSubmit.direccion : ''}
        onChange={handleChangeProfesional}
      />
      <TextField
        fullWidth
        name="observaciones"
        margin='normal'
        multiline
        label="OBSERVACIONES"
        rows={2}
        maxRows={4}
        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 200 }}
        onChange={handleChangeProfesional}
        value={profesionalSubmit ? profesionalSubmit.observaciones : ''}
      />
      <LoadingButton
        loading={loading}
        startIcon={<CheckIcon />} variant='outlined' color='success' onClick={fntEditProfesional}>
        Actualizar
      </LoadingButton>
    </>
  );
}
