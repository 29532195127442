import React, { useState } from 'react';
import { Alert, Avatar, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, MenuItem, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import Datatable from '../../components/datatable';
import SearchIcon from '@mui/icons-material/Search';
import UrlsConst from '../../config/config';
import AddIcon from '@mui/icons-material/Add';
import imgProfesional from '../../img/imgProfesional.png';
import CloseIcon from '@mui/icons-material/Close';
import BadgeIcon from '@mui/icons-material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import { Call, House } from '@mui/icons-material';

const filtro = [
  {
    value: 1,
    label: 'CONCLUIDAS'
  },
  {
    value: 2,
    label: 'SIN ENTREGAR'
  },
  {
    value: 3,
    label: 'ENTREGADAS'
  },
  {
    value: 4,
    label: 'TODAS'
  }
];
export default function TrabajosProfesional() {
  const [alertGeneral, setAlertGeneral] = React.useState({
    show: false,
    severity: 'warning',
    message: '',
    vertical: "top",
    horizontal: "right"
  });
  // table 
  const [profesionalSearch, setProfesionalSearch] = React.useState({
    apellidos_nombres: '',
    numero_documento: ''
  });
  const [searchCodigoBarra, setSearchCodigoBarra] = React.useState({
    searchCodBarra: ''
  });
  const [profesionales, setProfesionales] = React.useState([]);
  const [profesional, setProfesional] = React.useState({
    id: 0,
    apellidos_nombres: '',
    direccion: '',
    numero_documento: ''
  });
  const [pruebasProfesional, setPruebasProfesional] = React.useState([]);
  const [pruebaProfesional, setPruebaProfesional] = React.useState([]);
  const [openSearch, setOpenSearch] = React.useState(false);
  const uploadProfesional = async (id) => {
    const search = await fetch(`${UrlsConst.URL_API}/ventas/profesional/recuperarProfesional.php?id=` + id, {
      method: 'GET'
    });
    const response = await search.json();
    setProfesional(response);
    ListarPruebasProfesional(id);
    setOpenSearch(false);
    setProfesionalSearch({ apellidos_nombres: '', numero_documento: '' });
    setProfesionales([]);
    setSearchCodigoBarra({ searchCodBarra: "" });
  }
  const handleProfesional = (e) => {
    const { name, value } = e.target;
    if (value != "") {
      setProfesionalSearch({ ...profesionalSearch, [name]: value.toUpperCase() });
    } else {
      setProfesionalSearch({ ...profesionalSearch, [name]: '' });
    }
  }
  const searchOrdenProfesional = async () => {
    const search = await fetch(`${UrlsConst.URL_API}/ventas/profesional/buscarProfesional.php`, {
      method: "POST",
      body: JSON.stringify(profesionalSearch),
    });
    const response = await search.json();
    setProfesionales(response);
  }

  const ListarPruebasProfesional = async (id) => {
    const pruebasProfesional = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarPruebaProfesional.php?id=` + id, {
      method: "GET"
    });
    const response = await pruebasProfesional.json();
    setPruebaProfesional(response);
    let resultado = [];
    if (filtroPrueba === 1) {
      response.forEach((item) => {
        if (item.fecha_registro !== null && item.fecha_conclusion !== null && item.fecha_entrega === null) {
          resultado.push(item);
        }
      });
    }
    if (filtroPrueba === 2) {
      response.forEach((item) => {
        if ((item.fecha_registro !== null && item.fecha_conclusion !== null && item.fecha_entrega === null) || (item.fecha_registro !== null && item.fecha_conclusion === null && item.fecha_entrega === null)) {
          resultado.push(item);
        }
      });
    }
    if (filtroPrueba === 3) {
      response.forEach((item) => {
        if (item.fecha_registro !== null && item.fecha_entrega !== null && item.fecha_entrega !== null) {
          resultado.push(item);
        }
      });
    }
    if (filtroPrueba === 4) {
      response.forEach((item) => {
        resultado.push(item);
      });
    }
    setPruebasProfesional(resultado);
  }
  const [filtroPrueba, setFiltroPrueba] = React.useState(4);
  const handleChange = (e) => {
    setFiltroPrueba(e.target.value);
    let resultado = [];
    if (e.target.value === 1) {
      pruebaProfesional.forEach((item) => {
        if (item.fecha_registro !== null && item.fecha_conclusion !== null && item.fecha_entrega === null) {
          resultado.push(item);
          setSearchCodigoBarra({ searchCodBarra: "" });
        }
      });
    }
    if (e.target.value === 2) {
      pruebaProfesional.forEach((item) => {
        if ((item.fecha_registro !== null && item.fecha_conclusion !== null && item.fecha_entrega === null) || (item.fecha_registro !== null && item.fecha_conclusion === null && item.fecha_entrega === null)) {
          resultado.push(item);
          setSearchCodigoBarra({ searchCodBarra: "" });
        }
      });
    }
    if (e.target.value === 3) {
      pruebaProfesional.forEach((item) => {
        if (item.fecha_registro !== null && item.fecha_conclusion !== null && item.fecha_entrega !== null) {
          resultado.push(item);
          setSearchCodigoBarra({ searchCodBarra: "" });
        }
      });
    }
    if (e.target.value === 4) {
      pruebaProfesional.forEach((item) => {
        resultado.push(item);
        setSearchCodigoBarra({ searchCodBarra: "" });
      });
    }
    setPruebasProfesional(resultado);
  }
  const handleClickOpen = () => {
    setOpenSearch(true);
  }
  const handleClose = () => {
    setOpenSearch(false);
    setProfesionales([]);
    setProfesionalSearch({ apellidos_nombres: '', numero_documento: '' });
  }
  const cambiarButton = (e) => {
    if (e.which == 13) {
      searchOrdenProfesional();
    }
  }
  React.useEffect(() => {
    getColor()
  }, []);
  const [color, setColor] = React.useState('');
  function getColor() {
    setColor('#' + ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'].sort(() => .5 - Math.random()).slice(0, 6).join(''))
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [page2, setPage2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
  const handleChangePage2 = (newPage) => {
    setRowsPerPage2(newPage);
  };
  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };
  //buscar por codigo de barra
  const cambiarBtnCodBarra = (e) => {
    var input = document.getElementById('searchCodBarra').value;
    if (input != "" && e.which == 13) {
      document.getElementById('btnSearchCodBarra').click();
    }
  }
  const searchPruebasXCodBarra = async () => {
    if (searchCodigoBarra.searchCodBarra !== "") {
      const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenUI.php?paciente=&profesional=&id=${searchCodigoBarra.searchCodBarra}`, {
        method: 'GET'
      });
      const response = await getOrden.json();
      // console.log(response);
      if (response.length > 0) {
        getPruebasXCodBarra(response[0].fk_ven_profesional);
      } else {
        console.log("no se encontraron datos");
        setPintarPruebaXcodigoBarra(false);
        setAlertGeneral({ show: true, message: 'No se encontraron datos para el codigo', severity: 'warning', vertical: "top", horizontal: "center" });
        setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning', vertical: "top", horizontal: "right" }) }, 3500);
      }
    }
  }
  const [pintarPruebaXcodigoBarra, setPintarPruebaXcodigoBarra] = useState(false);
  const getPruebasXCodBarra = async (id) => {
    const search = await fetch(`${UrlsConst.URL_API}/ventas/profesional/recuperarProfesional.php?id=` + id, {
      method: 'GET'
    });
    const response = await search.json();
    if (response) {
      setProfesional(response);
      ListarPruebasProfesional(id);
      setPintarPruebaXcodigoBarra(true);
    }
  }
  const quitarColorPruebaBarra = () => {
    setPintarPruebaXcodigoBarra(false);
    setSearchCodigoBarra({ searchCodBarra: "" });
    setFiltroPrueba(4);
  }
  return (
    <>
      <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignContent: 'center' }}>
        <Snackbar open={alertGeneral.show} autoHideDuration={6000} onClose={() => setAlertGeneral({ show: false, message: "", severity: "warning", vertical: "top", horizontal: "right" })} anchorOrigin={{ vertical: alertGeneral.vertical, horizontal: alertGeneral.horizontal }}>
          <Alert onClose={() => setAlertGeneral({ show: false, message: "", severity: "warning", vertical: "top", horizontal: "right" })} severity={alertGeneral.severity} sx={{ width: '100%' }}>
            {alertGeneral.message}
          </Alert>
        </Snackbar>
        <Grid item md={11} xs={11}>
          <Paper elevation={1} style={{ padding: '2.5em' }}>
            <Grid container sx={{ justifyContent: "space-between", pb: 2 }}>
              <Grid item md={4} xs={12} style={{ textAlign: 'center' }}>
                <Typography variant='h5'>TRABAJOS POR PROFESIONAL</Typography>
              </Grid>
              <Grid item md={4}>
                <TextField
                  id="searchCodBarra"
                  fullWidth
                  size='small'
                  variant='outlined'
                  label="BUSCAR POR CODIGO DE BARRA"
                  name="searchCodBarra"
                  value={searchCodigoBarra.searchCodBarra}
                  onChange={
                    (e) => {
                      if (e.target.value !== "") {
                        const { name, value } = e.target;
                        setSearchCodigoBarra({ ...searchCodigoBarra, [name]: value.replace(/\s+/g, '').toUpperCase() });
                      } else {
                        setSearchCodigoBarra({ ...searchCodigoBarra, searchCodBarra: '' });
                        // getPruebas();
                      }
                    }
                  }
                  onKeyUp={cambiarBtnCodBarra}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={searchPruebasXCodBarra} id="btnSearchCodBarra">
                          <SearchIcon color="warning" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={4} xs={12} style={{ textAlign: 'center' }}>
                <Button onClick={handleClickOpen} variant="contained" color="warning"><SearchIcon />Buscar PROFESIONAL</Button>
              </Grid>
            </Grid>
            {profesional.id === 0 &&
              <div style={{ textAlign: 'center' }}>
                <Typography variant='h5' color="text.secondary" style={{ margin: 'auto' }}>No se realizo ninguna busqueda</Typography>
              </div>
            }
            {profesional.id != 0 &&
              <Grid container style={{ marginTop: '20px' }} spacing={2}>
                <Grid item md={5} xs={12} style={{ marginBottom: '10px' }}>
                  <Card style={{ textAlign: 'center' }}>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={6}>
                          <Avatar sx={{ bgcolor: color, width: '60px', height: '60px', fontSize: '30px', margin: 'auto' }}>
                            {profesional ? profesional.apellidos_nombres.charAt(0) : 0}
                          </Avatar>
                          <Typography gutterBottom component="div" style={{ marginTop: '15px', fontSize: '18px' }}>
                            {profesional ? profesional.apellidos_nombres.toUpperCase() : 'Default'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={6} style={{ marginTop: '5%' }}>
                          <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                            <BadgeIcon style={{ fontSize: '20px' }} />
                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{profesional.numero_documento.toUpperCase()}</span>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                            <SchoolIcon style={{ fontSize: '20px' }} />
                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{profesional.colegiatura.toUpperCase()}</span>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                            <House style={{ fontSize: '20px' }} />
                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{profesional.direccion.toUpperCase()}</span>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                            <Call style={{ fontSize: '20px' }} />
                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{profesional.numero_telefono}</span>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={4}></Grid>
                <Grid item md={3} xs={12}>
                  <Paper elevation={3} style={{ marginTop: '20px', padding: '10px' }}>
                    <TextField
                      fullWidth
                      select
                      id="selectFiltro"
                      label="FILTRO"
                      value={filtroPrueba}
                      variant="standard"
                      margin="normal"
                      onChange={handleChange}
                    >
                      {filtro.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Paper>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Paper>
                    <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}  >
                      <Table aria-label="simple table" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>N° ORDEN</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>SERVICIO</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>MATERIAL</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>LINEA</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>FECHA REGISTRO</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>FECHA PROGRAMADA</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>ESTADO</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            (rowsPerPage2 > 0
                              ? pruebasProfesional.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                              : pruebasProfesional
                            ).map((prueba) => (
                              <TableRow
                                key={prueba.fecha_registro + " " + prueba.hora_registro}
                                onClick={() => quitarColorPruebaBarra()}
                                sx={pintarPruebaXcodigoBarra && prueba.fk_ven_orden === searchCodigoBarra.searchCodBarra ?
                                  { backgroundColor: "#C2EAFF", '&:last-child td, &:last-child th': { border: 0 } } : { backgroundColor: "#fff" }}
                              >
                                <TableCell>{prueba.fk_ven_orden}</TableCell>
                                <TableCell>{prueba.servicio}</TableCell>
                                <TableCell>{prueba.material}</TableCell>
                                <TableCell>{prueba.descripcion}</TableCell>
                                <TableCell>{prueba.linea}</TableCell>
                                <TableCell>{prueba.fecha_registro + ' ' + prueba.hora_registro}</TableCell>
                                <TableCell>{prueba.fecha_programada + ' ' + prueba.hora_programada}</TableCell>
                                <TableCell>
                                  {
                                    prueba.fecha_registro != null && prueba.fecha_conclusion === null && prueba.fecha_entrega === null &&
                                    <Chip label="REGISTRADO" color="error" sx={{ fontSize: "14px", height: "25px" }} />
                                  }
                                  {
                                    prueba.fecha_registro != null && prueba.fecha_conclusion !== null && prueba.fecha_entrega === null &&
                                    <Chip label="CONCLUIDO" color="warning" sx={{ fontSize: "14px", height: "25px" }} />
                                  }
                                  {
                                    prueba.fecha_registro != null && prueba.fecha_conclusion !== null && prueba.fecha_entrega !== null &&
                                    <Chip label="ENTREGADO" color="success" sx={{ fontSize: "14px", height: "25px" }} />
                                  }
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      component="div"
                      labelRowsPerPage="Filas por columnas"
                      count={profesionales.length}
                      rowsPerPage={rowsPerPage2}
                      page={page2}
                      onPageChange={handleChangePage2}
                      onRowsPerPageChange={handleChangeRowsPerPage2}
                    />
                  </Paper>
                </Grid>
              </Grid>
            }

          </Paper>
        </Grid>
      </Grid >
      <Dialog open={openSearch} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle>
          BUSCAR PROFESIONAL
          <Button sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: "#000",
          }} startIcon={<CloseIcon />} onClick={handleClose} ></Button>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item md={5} xs={12}>
              <TextField
                fullWidth
                variant='standard'
                label="NOMBRES"
                name='apellidos_nombres'
                margin='normal'
                value={profesionalSearch.apellidos_nombres}
                onKeyUp={cambiarButton}
                onChange={handleProfesional}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                fullWidth
                variant='standard'
                label="DOCUMENTO"
                name='numero_documento'
                value={profesionalSearch.numero_documento}
                margin='normal'
                onKeyUp={cambiarButton}
                onChange={handleProfesional}
              />
            </Grid>
            <Grid item md={2} xs={12} style={{ textAlign: 'center' }}>
              <Button variant='contained' color="warning" onClick={searchOrdenProfesional} style={{ marginTop: '25px' }} startIcon={<SearchIcon />}>
                BUSCAR
              </Button>
            </Grid>
            <Grid item md={12} xs={12}>
              <Paper>
                <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 300 }}  >
                  <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>N°</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>PROFESIONAL</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>DOCUMENTO</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>DIRECCION</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        (rowsPerPage > 0
                          ? profesionales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : profesionales
                        ).map((profesional) => (
                          <TableRow onClick={() => uploadProfesional(profesional.id)}
                            key={profesional.id}
                            style={{ cursor: 'pointer' }}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {profesional.id}
                            </TableCell>
                            <TableCell>{profesional.apellidos_nombres}</TableCell>
                            <TableCell>{profesional.numero_documento}</TableCell>
                            <TableCell>{profesional.direccion}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  labelRowsPerPage="Filas por columnas"
                  count={profesionales.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}
