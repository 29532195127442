import { Alert, Button, Card, Collapse, Grid, IconButton, TextField, Typography } from '@mui/material';
import * as React from 'react';
import UrlsConst from '../../../config/config';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import { SwatchesPicker } from 'react-color';

export default function MaterialDialog(props) {
    // const and var
    const [loading, setLoading] = React.useState(false);
    const [materialSubmit, setMaterialSubmit] = React.useState({
        id: 0,
        nombre: '',
        descripcion: '',
        color: '#009688'
    });
    const [nombreMaterial, setNombreMaterial] = React.useState({
        error: false,
        text: ''
    });
    const [descripcionMaterial, setDescripcionMaterial] = React.useState({
        error: false,
        text: ''
    });
    // fnt servicio
    const getMaterial = async () => {
        if (props.fntEdit != null) {
            const getmarial = await fetch(`${UrlsConst.URL_API}/compras/material/recuperarMaterial.php?id=` + props.fntEdit, {
                method: 'POST'
            });
            const response = await getmarial.json();
            setMaterialSubmit(response);
        }
    }
    React.useState(() => {
        getMaterial()
    }, []);
    // fetch 
    const setMaterial = async () => {
        if (materialSubmit.nombre === "" || materialSubmit.descripcion === "") {
            if (materialSubmit.nombre === "") {
                setNombreMaterial({ ...nombreMaterial, error: true, text: 'Nombre obligatorio' });
            }
            if (materialSubmit.descripcion === "") {
                setDescripcionMaterial({ ...descripcionMaterial, error: true, text: 'Descripcion obligatoria' });
            }
        } else {
            setLoading(true);
            const setmaterial = await fetch(`${UrlsConst.URL_API}/compras/material/guardarMaterial.php`, {
                method: 'POST',
                body: JSON.stringify(materialSubmit)
            });
            const response = await setmaterial.json();
            if (props.fntEdit != undefined) {
                props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'El material se actualizo correctamente', open: true });

            } else {
                props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'El material se guardo correctamente', open: true });
                setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
            }
            if (props.setAlert != undefined) {
                props.setTextAlert('El material agregado correctamente');
                props.setTypeAlert('success');
                props.setAlert(true);
                setTimeout(() => { props.setAlert(false) }, 2500);
            }
            setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
            props.fntClose(false);
            props.fntMateriales();
        }
    }
    const handleChangeColor = (color, e) => {
        setMaterialSubmit({ ...materialSubmit, color: color.hex });
    }
    return (
        <Grid container spacing={3}>
            <Grid item md={4} xs={12} sm={4}>
                <TextField
                    fullWidth
                    name="nombre"
                    label="NOMBRE DE MATERIAL"
                    margin='normal'
                    variant="standard"
                    value={materialSubmit ? materialSubmit.nombre : ''}
                    error={nombreMaterial.error}
                    helperText={nombreMaterial.text}
                    inputProps={{ maxLength: 100 }}
                    onChange={
                        (e) => {
                            const { value } = e.target;
                            if (value != "") {
                                setMaterialSubmit({ ...materialSubmit, nombre: value.toUpperCase() });
                                setNombreMaterial({ ...nombreMaterial, error: false, text: '' });
                            } else {
                                setMaterialSubmit({ ...materialSubmit, nombre: '' });
                                setNombreMaterial({ ...nombreMaterial, error: true, text: 'Nombre obligatorio' });
                            }
                        }
                    }
                />
                <TextField
                    fullWidth
                    name="descripcion"
                    margin='normal'
                    label="DESCRIPCION"
                    multiline
                    rows={5}
                    maxRows={5}
                    inputProps={{ maxLength: 250 }}
                    value={materialSubmit ? materialSubmit.descripcion : ''}
                    error={descripcionMaterial.error}
                    helperText={descripcionMaterial.text}
                    onChange={
                        (e) => {
                            const { value } = e.target;
                            if (value != "") {
                                setMaterialSubmit({ ...materialSubmit, descripcion: value.toUpperCase() });
                                setDescripcionMaterial({ ...descripcionMaterial, error: false, text: '' });
                            } else {
                                setMaterialSubmit({ ...materialSubmit, descripcion: '' });
                                setDescripcionMaterial({ ...descripcionMaterial, error: true, text: 'Descripcion obligatoria' });
                            }
                        }
                    }
                />
            </Grid>
            <Grid item md={8} sx={12} sm={8}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ mb: 1, mt: 2 }}>COLOR</Typography>
                    <div style={{ background: materialSubmit.color, width: '30%', height: '20px', marginTop: '17px' }}></div>
                </div>

                {/* <Card sx={{ p: 1, mb: 1 }}>
                    
                </Card> */}
                <SwatchesPicker width="100%" height="185px" onChange={handleChangeColor} />
            </Grid>
            <Grid container sx={{ justifyContent: 'center', mt: 2 }}>
                <Grid item md={3}>
                    <LoadingButton
                        onClick={setMaterial}
                        startIcon={<CheckIcon />}
                        loading={loading}
                        loadingPosition="start"
                        variant="outlined"
                        color="success"
                    >
                        {materialSubmit.id != 0 ? 'ACTUALIZAR' : 'GUARDAR'}
                    </LoadingButton>
                </Grid>
                <Grid item md={3}>
                    <Button style={{ marginLeft: '8px' }} startIcon={<CloseIcon />} variant='outlined' color="error" onClick={() => props.fntClose(false)}>Cancelar</Button>
                </Grid>
            </Grid>

        </Grid>
    )
}
