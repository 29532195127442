import React from 'react'
import { Grid, Paper, TextField, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, InputAdornment, Chip, Typography, Snackbar, Alert, TablePagination } from '@mui/material';
import UrlsConst from '../../../config/config';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PdfReporteG from '../pdf/PdfReporteG';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

export default function RepGeneralTrab(props) {
    const datenow = new Date().toJSON().slice(0, 10);
    const [datosTrabGeneral, setDatosTrabGeneral] = React.useState([]);
    const [dataFechas, setDataFechas] = React.useState({ desde: datenow, hasta: datenow });
    const typogFuente = { fuente: "", size: 15 };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const cargarTrabGeneralDatos = async (desde, hasta) => {
        if (desde !== "" && hasta !== "") {
            if (desde <= hasta) {
                const fetchRes = await fetch(`${UrlsConst.URL_API}/reportes/RPpruebaGeneral.php?desde=${desde}&hasta=${hasta}`, {
                    method: "GET",
                });
                const response = await fetchRes.json();
                response.sort((a, b) => new Date(a.fecha_entrega).getTime() < new Date(b.fecha_entrega).getTime())
                setDatosTrabGeneral(response);
            }
        }
    }
    const [datosEmpresa, setDatosEmpresa] = React.useState({
        ruc: '',
        razon_social: '',
        direccion: ''
    });

    const alertPdf = () => {
        props.alertPdf();
    }
    React.useEffect(() => {
        const getDatosEmpresa = async () => {
            const getRuc = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RUC_EMPRESA`, {
                method: 'GET'
            });
            const response_getRuc = await getRuc.json();
            const getRazon = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RAZON_SOCIAL`, {
                method: 'GET'
            });
            const response_getRazon = await getRazon.json();
            const getDireccion = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=DIRECCION_EMPRESA`, {
                method: 'GET'
            });
            const response_getDireccion = await getDireccion.json();
            setDatosEmpresa({ ruc: response_getRuc.valor_string, razon_social: response_getRazon.valor_string, direccion: response_getDireccion.valor_string });
        }
        getDatosEmpresa();
    }, []);
    return (
        <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
                <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
                    <Grid item md={6} xs={12} >
                        <Grid container spacing={2} sx={{ justifyContent: "start" }}>
                            <Grid item md={5} xs={12}>
                                <TextField
                                    id="filtro_desde"
                                    label="FILTRO DESDE"
                                    type="date"
                                    fullWidth
                                    variant='standard'
                                    margin='normal'
                                    value={dataFechas ? dataFechas.desde : ''}
                                    onChange={(e) => {
                                        const fechaFiltroD = e.target.value;
                                        if (fechaFiltroD != null) {
                                            setDataFechas({ ...dataFechas, desde: fechaFiltroD });
                                        } else {
                                            setDataFechas({ ...dataFechas, desde: '' });
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CalendarMonthIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item md={5} xs={12}>
                                <TextField
                                    id="filtro_hasta"
                                    label="HASTA"
                                    type="date"
                                    fullWidth
                                    variant='standard'
                                    margin='normal'
                                    value={dataFechas ? dataFechas.hasta : ''}
                                    onChange={(e) => {
                                        const fechaFiltroS = e.target.value;
                                        if (fechaFiltroS != null) {
                                            setDataFechas({ ...dataFechas, hasta: fechaFiltroS });
                                        } else {
                                            setDataFechas({ ...dataFechas, hasta: '' });
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CalendarMonthIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={2} sx={{ justifyContent: "end" }}>
                            <Grid item md={3.5} xs={12} >
                                <Grid container style={{ paddingTop: "1.5em" }} spacing={0.3}>
                                    <Grid item xs={6}>
                                        <Tooltip title="Buscar" placement='top'>
                                            <Button fullWidth sx={{ alignItems: "center" }} variant="contained" color="warning"
                                                onClick={() => cargarTrabGeneralDatos(dataFechas.desde, dataFechas.hasta)}
                                            ><SearchIcon /></Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {datosTrabGeneral.length !== 0 ?
                                            <PDFDownloadLink document={<PdfReporteG datosEmpresa={datosEmpresa} tipo={"repGenTrab"} datosGeneral={datosTrabGeneral} dataFechas={dataFechas} />} fileName="REPORT-TRAB-GENERAL">
                                                {({ loading }) => (loading ? <Button fullWidth variant="contained" color="error"><PictureAsPdfIcon /></Button> : <Button fullWidth variant="contained" color="error"><PictureAsPdfIcon /></Button>)}
                                            </PDFDownloadLink> :
                                            <Button fullWidth variant="contained" color="error" onClick={alertPdf}><PictureAsPdfIcon /></Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
                <Paper>
                    <TableContainer sx={{ maxWidth: '310px', minWidth: '100%', height: 400 }}  >
                        <Table aria-label="simple table" >
                            <TableHead sx={{ backgroundColor: "#bde0fe" }}>
                                <TableRow >
                                    <TableCell style={{ width: '2%' }}>CODIGO</TableCell>
                                    <TableCell>TRABAJADOR</TableCell>
                                    <TableCell>SERVICIO</TableCell>
                                    <TableCell>DESCRIPCIÓN</TableCell>
                                    <TableCell>MATERIAL</TableCell>
                                    <TableCell>ESTADO</TableCell>
                                    <TableCell>F. REGISTRO</TableCell>
                                    <TableCell>F.E PROGRAMADA </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {datosTrabGeneral.length !== 0 &&
                                    (rowsPerPage > 0
                                        ? datosTrabGeneral.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : datosTrabGeneral
                                    ).map((m, index) => (
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index} >
                                            <TableCell component="th" scope="row">
                                                {m.fk_ven_orden}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.trabajador}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.servicio}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.descripcion}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.material}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.f_registro_prueba != null && m.fecha_conclusion === null && m.fecha_entrega === null ?
                                                    <Chip label="REGISTRADO" color="error" sx={{ fontSize: "14px", height: "25px" }} /> :
                                                    m.f_registro_prueba != null && m.fecha_conclusion != null && m.fecha_entrega === null ?
                                                        <Chip label="ENTREGADO" color="warning" sx={{ fontSize: "14px", height: "25px" }} /> :
                                                        m.f_registro_prueba != null && m.fecha_conclusion != null && m.fecha_entrega != null ?
                                                            <Chip label="CONCLUIDO" color="success" sx={{ fontSize: "14px", height: "25px" }} /> : ""}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.f_registro_prueba}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {m.fecha_programada + ' ' + m.hora_programada}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                        {datosTrabGeneral.length === 0 &&
                            <Grid sx={{ justifyItems: "center", textAlign: "center ", padding: "5em 0" }}>
                                <Typography gutterBottom align="center" color="text.secondary" sx={{ fontSize: typogFuente.size, fontFamily: typogFuente.fuente }}>
                                    NO SE ENCONTRARON DATOS
                                </Typography>
                            </Grid>
                        }
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        labelRowsPerPage="Filas por columnas"
                        count={datosTrabGeneral.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
            <Grid item md={12}>
                {/* <PDFViewer width="100%" height={600}>
                    <PdfReporteG datosEmpresa={datosEmpresa} tipo={"repGenTrab"} datosGeneral={datosTrabGeneral} dataFechas={dataFechas} />
                </PDFViewer> */}
                {/* {datosTrabGeneral.length !== 0 &&
            <PDFViewer width="100%" height={600}>
                <PdfReporteG datosTrabGeneral={datosTrabGeneral} dataFechas={dataFechas}  />
            </PDFViewer>
        } */}
            </Grid>
        </Grid>
    )
}
