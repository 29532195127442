import { Grid, IconButton, Paper, Typography, Button, TextField, Dialog, DialogContent, DialogActions, DialogTitle, InputAdornment, Alert, Collapse, MenuItem, Tooltip, Autocomplete, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Snackbar } from '@mui/material';
import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UrlsConst from '../../config/config';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PacienteDialog from './dialogs/pacienteDialog';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export default function Paciente() {
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [pacienteId, setPacienteId] = React.useState(0);
    const [openProfesional, setOpenProfesional] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [alertGeneral, setAlertGeneral] = React.useState({
        open: false,
        type: 'success',
        text: ''
    });
    const [profesionales, setProfesionales] = React.useState([{
        id: 0,
        colegiatura: '',
        apellidos_nombres: '',
        direccion: '',
        observaciones: '',
        fk_com_tipo_documento: '00',
        numero_documento: ''
    }]);
    /// pacientes list
    const [pacientes, setPacientes] = React.useState([{
        id: 0,
        apellidos_nombres: '',
        numero_documento: '',
        fecha_nacimiento: '',
        observaciones: ''
    }]);
    const [paciente, setPaciente] = React.useState(0);
    const [profesional, setProfesional] = React.useState(0);
    const handleClose = () => {
        setOpen(false);
        setOpenDelete(false);
        setOpenProfesional(false);
    };
    const listaPacientes = async () => {
        const pacientes = await fetch(`${UrlsConst.URL_API}/ventas/paciente/listarPaciente.php`, {
            method: "POST"
        });
        const response = await pacientes.json();
        setPacientes(response);
    }
    const profesionalAjax = async () => {
        const profesionales = await fetch(`${UrlsConst.URL_API}/ventas/profesional/listarProfesional.php`, {
            method: "POST"
        });
        const response = await profesionales.json();
        setProfesionales(response);
    }

    const [search, setSearch] = React.useState({
        string_search: ''
    });
    const searchPaciente = async () => {
        if (search.string_search != "") {
            const profesionales = await fetch(`${UrlsConst.URL_API}/ventas/profesional/listarProfesional.php`, {
                method: "POST"
            });
            const response = await profesionales.json();
            const filtrar = search.string_search;
            const buscado = response.filter((paciente) => paciente.apellidos_nombres.includes(filtrar) || paciente.numero_documento.includes(filtrar) || paciente.fecha_nacimiento.includes(filtrar));
            setPacientes(buscado);
        }
    }
    const cambiarBtn = (e) => {
        var input = document.getElementById('search').value;
        if (input != "" && e.which == 13) {
            document.getElementById('btnSearch').click();
        }
    }
    const handleProfesional = (e, values) => {
        if (values != null) {
            setProfesional(values.id);
        }
    }

    const fetchSavePacienteProfesional = async () => {
        const pacienteProfInsert = await fetch(`${UrlsConst.URL_API}/ventas/profesional/guardarPacienteProfesional.php`, {
            method: "POST",
            body: JSON.stringify({ fk_ven_profesional: profesional, fk_ven_paciente: pacienteId }),
        });
        const responseSavePacxProf = await pacienteProfInsert.json();
        setOpenProfesional(false);
        setAlertGeneral({ ...alertGeneral, text: 'Paciente vinculado correctamente', type: 'success', open: true });
        setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
        // setTextAlert('!COMPLETADO¡ Se agrego el profesional al paciente');
        setAlert(true);
        setTimeout(() => { setAlert(false) }, 2500);
    }
    const fntDeletePaciente = async () => {
        const deletePaciente = await fetch(`${UrlsConst.URL_API}/ventas/paciente/eliminarPaciente.php?id=` + pacienteId, {
            method: 'POST'
        }).then(function (response) {
            if (response.ok === true) {
                setOpenDelete(false);
                setAlertGeneral({ ...alertGeneral, text: 'El paciente se elimino correctamente', type: 'success', open: true });
                setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
                listaPacientes();
            } else {
                setOpenDelete(false);
                setAlertGeneral({ ...alertGeneral, text: 'El paciente esta asociado a un profesional', type: 'warning', open: true });
                setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
            }
        })
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // effect
    React.useEffect(() => {
        listaPacientes();
    }, []);
    const [pacientePro, setPacientePro] = React.useState(false);
    return (
        <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignContent: 'center' }}>
            <Snackbar open={alertGeneral.open} autoHideDuration={6000} onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity={alertGeneral.type} sx={{ width: '100%' }}>
                    {alertGeneral.text}
                </Alert>
            </Snackbar>
            <Grid item md={11} xs={12}>
                <Paper elevation={1} style={{ padding: '2.5em' }}>
                    <Typography variant='h5'>PACIENTES</Typography>
                    <Grid container spacing={1}>
                        <Grid item md={5} xs={12}>
                            <TextField
                                id="search"
                                name="string_search"
                                fullWidth label="BUSCAR"
                                margin='normal' variant="standard"
                                value={search.string_search}
                                onChange={
                                    (e) => {
                                        if (e.target.value != "") {
                                            const { name, value } = e.target;
                                            setSearch({ ...search, [name]: value.toUpperCase() });
                                        } else {
                                            setSearch({ ...search, string_search: '' });
                                            listaPacientes();
                                        }
                                    }
                                }
                                onKeyUp={cambiarBtn}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={searchPaciente} id="btnSearch">
                                                <SearchIcon color="warning" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                        </Grid>
                        <Grid item md={6}></Grid>
                        <Grid item md={1} xs={12} style={{ marginTop: '20px', textAlign: 'center' }}>
                        </Grid>
                    </Grid>
                    <Grid container style={{ justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                        <Grid item md={12} xs={12}>
                            <Paper>
                                <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}>
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: '5%', fontWeight: 'bold' }}>N°</TableCell>
                                                <TableCell style={{ width: '35%', fontWeight: 'bold' }}>NOMBRES Y APELLIDOS</TableCell>
                                                <TableCell style={{ width: '15%', fontWeight: 'bold' }}>DOCUMENTO</TableCell>
                                                <TableCell style={{ width: '35%', fontWeight: 'bold' }}>OBSERVACIONES</TableCell>
                                                <TableCell style={{ width: '10%' }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                (rowsPerPage > 0
                                                    ? pacientes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : pacientes
                                                ).map((paciente) => (
                                                    <TableRow
                                                        key={paciente.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {paciente.id}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {paciente.apellidos_nombres}
                                                        </TableCell>
                                                        <TableCell>{paciente.numero_documento}</TableCell>
                                                        <TableCell>{paciente.observaciones}</TableCell>
                                                        <TableCell>
                                                            {
                                                                paciente.id === 1 ? '' :
                                                                    <div>
                                                                        <Tooltip title="EDITAR PACIENTE">
                                                                            <IconButton aria-label="delete" onClick={
                                                                                () => {
                                                                                    setOpen(true);
                                                                                    setPacienteId(paciente.id);
                                                                                }}
                                                                                size="small">
                                                                                <EditIcon color='primary' fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="ELIMINAR PACIENTE">
                                                                            <IconButton aria-label="delete" onClick={
                                                                                () => {
                                                                                    setOpenDelete(true);
                                                                                    setPacienteId(paciente.id);
                                                                                }} size="small">
                                                                                <DeleteIcon color='error' fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="VINCULAR PROFESIONAL">
                                                                            <IconButton aria-label="delete" onClick={
                                                                                () => {
                                                                                    profesionalAjax()
                                                                                    setPacienteId(paciente.id);
                                                                                    setOpenProfesional(true);
                                                                                }} size="small">
                                                                                <PersonAddAlt1Icon color='success' fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        {/* <Tooltip title="PROFESIONALES VINCULADOS">
                                                                            <IconButton aria-label="delete" onClick={
                                                                                () => {
                                                                                    setPacientePro(true);
                                                                                    setPacienteId(paciente.id);
                                                                                }} size="small">
                                                                                <ManageAccountsIcon color='sencudary' fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip> */}
                                                                    </div>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component="div"
                                    labelRowsPerPage="Filas por columnas"
                                    count={pacientes.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
                <DialogTitle>EDITAR PACIENTE</DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <PacienteDialog
                        fntPaciente={pacienteId}
                        alertGeneral={alertGeneral}
                        setAlertGeneral={setAlertGeneral}
                        fntPacientes={listaPacientes}
                        fntClose={handleClose}
                    />
                    <Button style={{ marginLeft: '6px' }} startIcon={<CloseIcon />} variant='outlined' color="error" onClick={handleClose}>Cancel</Button>
                </DialogContent>
            </Dialog>
            <Dialog open={openDelete} onClose={handleClose}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Eliminar Paciente</Typography>
                    ¿Esta seguro de eliminar el paciente?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button variant="contained" color="error" onClick={fntDeletePaciente}>Aceptar</Button>
                    <Button variant="contained" onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openProfesional} onClose={handleClose} fullWidth={true} maxWidth="sm">
                <DialogTitle>VINCULAR PROFESIONAL</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item md={12} xs={12}>
                            <Autocomplete
                                onChange={handleProfesional}
                                options={profesionales}
                                getOptionLabel={(option) => option.apellidos_nombres}
                                renderInput={(params) => <TextField {...params} fullWidth variant="standard" margin="normal" label="PROFESIONAL" />}
                            />
                        </Grid>
                        {/* <Grid item md={4}>
                        </Grid> */}
                        {/* <Grid item md={12}>
                            <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 300 }}>
                                <Table aria-label="simple table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '5%', fontWeight: 'bold' }}>N°</TableCell>
                                            <TableCell style={{ width: '85%', fontWeight: 'bold' }}>NOMBRES Y APELLIDOS</TableCell>
                                            <TableCell style={{ width: '10%' }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            (rowsPerPage > 0
                                                ? pacientes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : pacientes
                                            ).map((paciente) => (
                                                <TableRow
                                                    key={paciente.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {paciente.id}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {paciente.apellidos_nombres}
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            paciente.id === 1 ? '' :
                                                                <Tooltip title="ELIMINAR PACIENTE">
                                                                    <IconButton aria-label="delete" onClick={
                                                                        () => {
                                                                            setOpenDelete(true);
                                                                            setPacienteId(paciente.id);
                                                                        }} size="small">
                                                                        <DeleteIcon color='error' fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            labelRowsPerPage="Filas por columnas"
                            count={pacientes.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
                        {/* </Paper> */}
                        {/* </Grid>  */}
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} variant="outlined" color="success" onClick={fetchSavePacienteProfesional}>Aceptar</Button>

                    <Button startIcon={<CloseIcon />} variant="outlined" color="error" onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            {/* <Dialog open={pacientePro} onClose={() => setPacientePro(false)}>
                <DialogTitle>VINCULAR PROFESIONAL</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        onChange={handleProfesional}
                        id="free-solo-demo"
                        freeSolo
                        options={profesionales}
                        getOptionLabel={(option) => option.apellidos_nombres}
                        renderInput={(params) => <TextField {...params} variant="standard" margin="normal" label="PROFESIONAL" />}
                    />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CloseIcon />} variant="outlined" color="error" onClick={() => setPacientePro(false)}>Cancelar</Button>
                </DialogActions>
            </Dialog> */}

        </Grid >
    );
}
