import * as React from 'react';
import { Alert, Autocomplete, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Snackbar, TextField, Tooltip } from '@mui/material';
import UrlsConst from '../../../config/config';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import MaterialDialog from './materialDialog';
import CloseIcon from '@mui/icons-material/Close';

export default function MaterialServicio(props) {
    const [materiales, setMateriales] = React.useState([]);
    const [dialogMaterial, setDialogMaterial] = React.useState(false);
    const [materialSelect, setMaterialSelect] = React.useState({
        fk_cmp_material: 0,
        nombre: '',
        descripcion: '',
        costo: '',
        precio: ''
    });
    const [materialS, setMaterialS] = React.useState({
        error: false,
        text: ''
    });
    const [costoMaterial, setCostoMaterial] = React.useState({
        error: false,
        text: ''
    });
    const [precioMaterial, setPrecioMaterial] = React.useState({
        error: false,
        text: ''
    });
    const [alertGeneral, setAlertGeneral] = React.useState(false);
    const [alertD, setAlertD] = React.useState({
        severity: 'error',
        textAlert: ''
    });
    const addMaterialServicio = async () => {
        if (materialSelect.fk_cmp_material === 0 || materialSelect.costo === "" || materialSelect.precio === "") {
            if (materialSelect.fk_cmp_material === 0) {
                setMaterialS({ ...materialS, error: true, text: 'Material obligatorio' });
            }
            if (materialSelect.costo === "") {
                setCostoMaterial({ ...costoMaterial, error: true, text: 'Costo obligatorio' });
            }
            if (materialSelect.precio === "") {
                setPrecioMaterial({ ...precioMaterial, error: true, text: 'Precio obligatorio' });
            }
        } else {
            const getServioMateriales = await fetch(`${UrlsConst.URL_API}/ventas/servicio/listarServicioMaterial.php?id=` + props.servicioMaterialId, {
                method: 'POST'
            });
            const response = await getServioMateriales.json();
            const validar = response.filter((item) => item.fk_cmp_material === materialSelect.fk_cmp_material);
            if (validar.length > 0) {
                setAlertD({ ...alertD, severity: 'error', textAlert: 'Material ya se encuentra agregado' });
                setAlertGeneral(true);
                setTimeout(() => { setAlertGeneral(false) }, 3000);
            } else {
                if (Number(materialSelect.precio) >= Number(materialSelect.costo)) {
                    const setSM = await fetch(`${UrlsConst.URL_API}/ventas/servicio/guardarServicioMaterial.php`, {
                        method: 'POST',
                        body: JSON.stringify({
                            fk_ven_servicio: props.servicioMaterialId,
                            fk_cmp_material: materialSelect.fk_cmp_material,
                            costo: materialSelect.costo,
                            precio: materialSelect.precio
                        })
                    });
                    const responseSM = await setSM.json();
                    props.fntServicioMaterial(props.servicioMaterialId);
                    props.handleClose(false);
                    props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'Material agregado correctamente', open: true });
                    setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
                }
            }
        }
    }
    // handles 
    const handleMateriales = (e, values) => {
        setMaterialS({ ...materialS, error: false, text: '' });
        setMaterialSelect({ ...materialSelect, fk_cmp_material: values.id, nombre: values.nombre, descripcion: values.descripcion });
    }
    // funciones 
    const getMateriales = async () => {
        const getmateriales = await fetch(`${UrlsConst.URL_API}/compras/material/listarMaterial.php`, {
            method: 'POST'
        });
        const response = await getmateriales.json();
        setMateriales(response);
    }
    const addMaterial = () => {
        if (materialSelect.fk_cmp_material === 0 || materialSelect.costo === "" || materialSelect.precio === "") {
            if (materialSelect.fk_cmp_material === 0) {
                setMaterialS({ ...materialS, error: true, text: 'Material obligatorio' });
            }
            if (materialSelect.costo === "") {
                setCostoMaterial({ ...costoMaterial, error: true, text: 'Costo obligatorio' });
            }
            if (materialSelect.precio === "") {
                setPrecioMaterial({ ...precioMaterial, error: true, text: 'Precio obligatorio' });
            }
        } else {
            if (Number(materialSelect.precio) >= Number(materialSelect.costo)) {
                if (props.rowMaterial.length === 0) {
                    const rowsMateriales = [].concat(props.rowMaterial);
                    rowsMateriales.push(materialSelect);
                    props.setRowMaterial(rowsMateriales);
                    props.setAlertD({ ...props.alertD, severity: 'success', textAlert: 'Material agregado correctamente' });
                    props.setAlertGeneral(true);
                    setTimeout(() => { props.setAlertGeneral(false) }, 3000);
                    console.log(1)
                } else {
                    let validar = props.rowMaterial.filter((material) => material.fk_cmp_material === materialSelect.fk_cmp_material);
                    console.log(validar);
                    if (validar.length !== 0) {
                        props.setAlertD({ ...props.alertD, severity: 'error', textAlert: 'Ya se encuentra un material agregado' });
                        props.setAlertGeneral(true);
                        setTimeout(() => { props.setAlertGeneral(false) }, 3000);
                    } else {
                        const rowsMateriales = [].concat(props.rowMaterial);
                        rowsMateriales.push(materialSelect);
                        props.setRowMaterial(rowsMateriales);
                        validar.length = 0;
                        props.setAlertD({ ...props.alertD, severity: 'success', textAlert: 'Material agregado correctamente' });
                        props.setAlertGeneral(true);
                        setTimeout(() => { props.setAlertGeneral(false) }, 3000);
                    }
                }
                props.handleClose(false);
            }
        }
    }

    React.useEffect(() => {
        getMateriales();
        // setMaterialSelect({ ...materialSelect, fk_cmp_material: 0 });
    }, []);

    return (
        <>
            <Grid container spacing={2} style={{ justifyContent: 'center', marginBottom: '10px' }}>
                <Snackbar open={alertGeneral} autoHideDuration={6000} onClose={() => setAlertGeneral(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={() => { setAlertGeneral(false) }} severity={alertD.severity} sx={{ width: '100%' }}>
                        {alertD.textAlert}
                    </Alert>
                </Snackbar>
                <Grid item md={10} xs={10}>
                    <Autocomplete
                        disablePortal
                        options={materiales}
                        onChange={handleMateriales}
                        getOptionLabel={(option) => option.nombre}
                        renderInput={(params) => <TextField {...params}
                            label="MATERIAL" variant='standard'
                            fullWidth
                        />}
                    />
                </Grid>
                <Grid item md={2} xs={2}>
                    <Tooltip title="NUEVO MATERIAL">
                        <IconButton variant='contained' color="success" style={{ marginTop: '6px' }} onClick={() => setDialogMaterial(true)}>
                            <AddCircleIcon style={{ fontSize: '30px' }} />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        fullWidth
                        error={costoMaterial.error}
                        helperText={costoMaterial.text}
                        name='costo'
                        label="COSTO DE MATERIAL"
                        margin="normal"
                        variant='standard'
                        value={materialSelect.costo}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (value != "") {
                                if (isNaN(value) === false) {
                                    if (materialSelect.precio != "") {
                                        if (Number(value) < Number(materialSelect.precio)) {
                                            setCostoMaterial({ ...costoMaterial, error: false, text: '' });
                                        } else {
                                            setCostoMaterial({ ...costoMaterial, error: true, text: 'Costo no puede ser mayor al precio' });
                                        }
                                        setMaterialSelect({ ...materialSelect, costo: value });
                                    } else {
                                        setCostoMaterial({ ...costoMaterial, error: false, text: '' });
                                        setMaterialSelect({ ...materialSelect, costo: value });
                                    }
                                } else {
                                    setCostoMaterial({ ...costoMaterial, error: true, text: 'Costo no puede ser un texto' });
                                }
                            } else {
                                setMaterialSelect({ ...materialSelect, costo: '' });
                                setCostoMaterial({ ...costoMaterial, error: true, text: 'Costo obligatorio' });
                            }
                        }}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        fullWidth
                        name='precio'
                        label="PRECIO DE MATERIAL"
                        margin="normal"
                        variant='standard'
                        error={precioMaterial.error}
                        helperText={precioMaterial.text}
                        value={materialSelect.precio}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (value != "") {
                                if (isNaN(value) === false) {
                                    if (materialSelect.costo != "") {
                                        if (Number(materialSelect.costo) > Number(value)) {
                                            setPrecioMaterial({ ...precioMaterial, error: true, text: 'Precio no puede ser menor al costo' });
                                            setMaterialSelect({ ...materialSelect, precio: value });
                                        } else {
                                            setPrecioMaterial({ ...precioMaterial, error: false, text: '' });
                                            setMaterialSelect({ ...materialSelect, precio: value });
                                        }
                                    } else {
                                        setMaterialSelect({ ...materialSelect, precio: value });
                                        setPrecioMaterial({ ...precioMaterial, error: false, text: '' });
                                    }
                                } else {
                                    setPrecioMaterial({ ...precioMaterial, error: true, text: 'Precio no puede ser un texto' });
                                }
                            } else {
                                setMaterialSelect({ ...materialSelect, precio: '' });
                                setPrecioMaterial({ ...precioMaterial, error: true, text: 'Precio obligatorio' });
                            }
                        }}
                    />
                </Grid>
            </Grid>
            {
                props.servicioMaterialId != undefined &&
                <Button startIcon={<CheckIcon />} variant="outlined" color="success" onClick={addMaterialServicio} >Agregar</Button>
            }
            {
                props.rowMaterial != undefined &&
                <Button startIcon={<CheckIcon />} variant="outlined" color="success" onClick={addMaterial}>Agregar</Button>
            }
            <Dialog open={dialogMaterial} onClose={() => setDialogMaterial(false)} fullWidth={true} maxWidth="sm">
                <DialogTitle>NUEVO MATERIAL</DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <MaterialDialog
                        fntMateriales={getMateriales}
                        fntClose={setDialogMaterial}
                        setAlertGeneral={setAlertGeneral}
                        alertGeneral={alertGeneral}
                        setAlert={props.setAlert}
                        alertD={props.alertD}
                        setAlertD={props.setAlertD}
                    />
                    <Button style={{ marginLeft: '6px' }} startIcon={<CloseIcon />} variant='outlined' color="error" onClick={() => setDialogMaterial(false)}>Cancelar</Button>
                </DialogContent>
            </Dialog>
        </>
    )
}
