import React from 'react'
import { Grid, Typography, TextField, Autocomplete, Button, Dialog, DialogContent, DialogActions, Collapse, Alert, IconButton, DialogTitle, InputAdornment, MenuItem, Switch, FormControlLabel, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Snackbar, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dentadura from '../../../components/dentadura';
import UrlsConst from '../../../config/config';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
// icons
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PacienteDialog from '../../mantenimiento/dialogs/pacienteDialog';
import NewOdontograma from '../../../components/newodontograma';

export default function DialogOrdenServicio(props) {
    const profesional = props.profesional;
    const [detalleOrden, setDetalleOrden] = React.useState([]);
    const [openPrueba, setOpenPrueba] = React.useState(false);
    const [cantidadPrueba, setCantidadPrueba] = React.useState(1);
    const [pruebas, setPruebas] = React.useState([]);
    const [textPrueba, setTextPrueba] = React.useState('');
    const [lineaPrueba, setLineaPrueba] = React.useState(0);
    const [newPaciente, setNewPaciente] = React.useState(false);
    const [material, setMaterial] = React.useState({
        fk_cmp_material: 0,
        nombre: '',
        descripcion: '',
        precio: 0
    });
    const [ordenSubmit, setOrdenSubmmit] = React.useState({
        id: '',
        descripcion: '',
        observaciones: '',
        fecha_ingreso: '',
        fecha_entrega: '',
        cubeta: 0,
        esta_entregado: 0,
        fk_ven_profesional: 0,
        fk_ven_paciente: 0,
        esta_facturado: 0,
        fk_log_usuario: '',
        fk_cmp_material: 0,
        fk_ven_servicio: 0,
        presupuesto: 0,
        estado_pago: 'P',
        prueba_mordida: 0,
        antagonista: 0,
        color_tercio_incisal: '',
        color_tercio_medial: '',
        color_tercio_cervical: '',
        numero: 0
    });
    const [trabajadores, setTrabajadores] = React.useState([{
        id: 0,
        nombres_apellidos: '',
    }]);
    const [pacientes, setPacientes] = React.useState([]);
    const [servicio, setServicio] = React.useState({
        id: 0,
        nombre: '',
        descripcion: '',
        observaciones: '',
        color: '',
        multipieza: 0
    });
    const [serviciosList, setServiciosList] = React.useState([]);
    const [materiales, setMateriales] = React.useState([]);
    const [pruebaSubmit, setPruebaSubmit] = React.useState({
        fk_ven_orden: 0,
        linea: 0,
        fk_rh_trabajador: 0,
        fecha_registro: '',
        hora_registro: '',
        fecha_conclusion: '',
        hora_conclusion: '',
        fecha_entrega: '',
        hora_entrega: '',
        fecha_programada: '',
        hora_programada: '',
        descripcion: '',
        nombre_trabajador: ''
    });
    const [alertGeneral, setAlertGeneral] = React.useState({
        open: false,
        text: '',
        type: 'success'
    });
    //  validaciones orden
    const [pacienteOrden, setPacienteOrden] = React.useState({
        error: false,
        text: ''
    });
    const [serviciOrden, setServiciOrden] = React.useState({
        error: false,
        text: ''
    });
    const [materialOrden, setMaterialOrden] = React.useState({
        error: false,
        text: ''
    });
    const [presupuestOrden, setPresupuestOrden] = React.useState({
        error: false,
        text: ''
    });
    const [fechaOrden, setFechaOrden] = React.useState({
        error: false,
        text: ''
    });
    const [descripcionOrden, setDescripcionOrden] = React.useState({
        error: false,
        text: ''
    });
    // validar prueba
    const [trabajadorPrueba, setTrabajadorPrueba] = React.useState({
        error: false,
        text: ''
    });
    const [fechaPrueba, setFechaPrueba] = React.useState({
        error: false,
        text: ''
    });
    const [horaPrueba, setHoraPrueba] = React.useState({
        error: false,
        text: ''
    });
    const [descripcionPrueba, setDescripcionPrueba] = React.useState({
        error: false,
        text: ''
    });

    const [opendeletePrueba, setOpenDeletePrueba] = React.useState(false);
    React.useEffect(() => {
        listPacientes(profesional.id);
        servicios();
        fntTrabajadores();
        if (props.fntOrden !== undefined) {
            setOrdenSubmmit(props.fntOrden);
            // setOrdenSubmmit2(props.fntOrden);
            setServicio(props.fntServicio);
            setMateriales(props.fntMaterial);
            setMaterial({
                ...material,
                fk_cmp_material: props.material.fk_cmp_material,
                nombre: props.material.nombre,
                precio: props.material.precio,
                color: props.material.color
            });
            setPruebaSubmit({ ...pruebaSubmit, fk_ven_orden: props.fntOrden.id });
            setPruebas(props.fntPruebas);
            props.fntPruebas.forEach((item) => {
                setCantidadPrueba(item.linea);
            });
        } else {
            const dateNow = new Date()
            const agregarCero = (n) => {
                if (n <= 9) { return "0" + n; }
                return n
            }
            const getRandomNum = () => {
                let min = Math.ceil(1);
                let max = Math.floor(9999);
                var numGenerado = Math.floor(Math.random() * (max - min + 1) + min);
                if (numGenerado <= 9) { return "000" + numGenerado; }
                if (numGenerado <= 99) { return "00" + numGenerado; }
                if (numGenerado <= 999) { return "0" + numGenerado; }
                return numGenerado;
            }
            const genCodigo = () => {
                let dateFormateado2 = dateNow.getFullYear() + "" +
                    agregarCero(dateNow.getMonth() + 1) + "" +
                    agregarCero(dateNow.getDate()) + "" +
                    agregarCero(dateNow.getHours()) + "" +
                    agregarCero(dateNow.getMinutes()) + "" +
                    agregarCero(dateNow.getSeconds()) + getRandomNum();
                return dateFormateado2;
            }
            var obtCodigo = genCodigo();
            const UserLog = JSON.parse(sessionStorage.getItem('usuario_log'));
            setCantidadPrueba(0);
            setOrdenSubmmit({ ...ordenSubmit, id: obtCodigo, fecha_entrega: new Date().toJSON().slice(0, 10), fk_log_usuario: UserLog.usuario, fk_ven_profesional: profesional.id, fecha_ingreso: new Date().toJSON().slice(0, 10) });
        }
    }, []);
    // handles
    const handleServicio = async (e, values) => {
        const servicioInput = values.props;
        if (servicioInput.value != null) {
            setServiciOrden({ ...serviciOrden, error: false, text: '' });
            setMaterialOrden({ ...materialOrden, error: true, text: 'Material obligatorio' });
            setMaterial({ fk_cmp_material: 0, nombre: '', costo: 0, precio: 0, color: '' });
            setOrdenSubmmit({ ...ordenSubmit, fk_ven_servicio: servicioInput.value, presupuesto: 0, fk_cmp_material: 0 });
            const materiales = await fetch(`${UrlsConst.URL_API}/ventas/servicio/listarServicioMaterial.php?id=` + servicioInput.value, {
                method: 'POST'
            });
            const response = await materiales.json();
            setMateriales(response);
            setServicio(servicioInput);
        } else {
            setServicio([]);
            setMateriales([]);
        }
    }
    const handleOrden = (e) => {
        const { name, value } = e.target;
        setOrdenSubmmit({ ...ordenSubmit, [name]: value.toUpperCase() });
    }
    const handleMaterial = (e, values) => {
        const materialInput = values.props;
        if (materialInput.fk_cmp_material != 0) {
            setMaterialOrden({ ...materialOrden, error: false, text: '' });
            setMaterial({ fk_cmp_material: materialInput.value, nombre: materialInput.nombre, costo: materialInput.costo, precio: materialInput.precio, color: materialInput.color });
            setOrdenSubmmit({ ...ordenSubmit, fk_cmp_material: materialInput.value });
        } else {
            setMaterial({ fk_cmp_material: 0, nombre: '', costo: 0, precio: 0, color: '' });
        }
    }
    const handleCubeta = (e) => {
        const { name } = e.target;
        if (e.target.checked != false) {
            setOrdenSubmmit({ ...ordenSubmit, [name]: 1 });
        } else {
            setOrdenSubmmit({ ...ordenSubmit, [name]: 0 });
        }
    }
    const handlePruebaMordida = (e) => {
        const { name } = e.target;
        if (e.target.checked != false) {
            setOrdenSubmmit({ ...ordenSubmit, [name]: 1 });
        } else {
            setOrdenSubmmit({ ...ordenSubmit, [name]: 0 });
        }
    }
    const handleAntagonista = (e) => {
        const { name } = e.target;
        if (e.target.checked != false) {
            setOrdenSubmmit({ ...ordenSubmit, [name]: 1 });
        } else {
            setOrdenSubmmit({ ...ordenSubmit, [name]: 0 });
        }
    }
    const handleTrabajador = (e, values) => {
        if (values != null) {
            setTrabajadorPrueba({ ...trabajadorPrueba, error: false, text: '' });
            const trabajador = values.props;
            setPruebaSubmit({ ...pruebaSubmit, fk_rh_trabajador: trabajador.value, nombre_trabajador: trabajador.nombre });
        } else {
            setPruebaSubmit({ ...pruebaSubmit, fk_rh_trabajador: 0, nombre_trabajador: '' });
        }
    }
    const fntPruebaOpen = async () => {
        setTextPrueba('NUEVA');
        setOpenPrueba(true);
        setPruebaSubmit({ ...pruebaSubmit, linea: pruebas.length + 1, fecha_registro: new Date().toJSON().slice(0, 10), fecha_programada: new Date().toJSON().slice(0, 10) });
        setTrabajadorPrueba({ ...trabajadorPrueba, error: false, text: '' });
        setFechaPrueba({ ...fechaPrueba, error: false, text: '' });
        setHoraPrueba({ ...horaPrueba, error: false, text: '' });
        setDescripcionPrueba({ ...descripcionPrueba, error: false, text: '' });
    }
    const handleClose = () => {
        setOpenPrueba(false);
        let cantidad = cantidadPrueba;
        setCantidadPrueba(cantidad);
        setPruebaSubmit({ ...pruebaSubmit, fk_rh_trabajador: 0, fecha_programada: '', hora_programada: '', descripcion: '' });
    }
    const handleLinea = (e) => {
        setPruebaSubmit({ ...pruebaSubmit, linea: Number(e.target.value) });
    }
    // functions
    const servicios = async () => {
        const listServicios = await fetch(`${UrlsConst.URL_API}/ventas/servicio/listarServicio.php`, {
            method: 'POST'
        });
        const response = await listServicios.json();
        setServiciosList(response);
    }
    const listPacientes = async (id) => {
        const listPacientesPro = await fetch(`${UrlsConst.URL_API}/ventas/profesional/listarPacienteXProfesional.php?id=` + id, {
            method: "POST",
        });
        const response = await listPacientesPro.json();
        setPacientes(response);
    }
    const fntTrabajadores = async () => {
        const getTrabajadores = await fetch(`${UrlsConst.URL_API}/sistema/trabajador/listarTrabajador.php`, {
            method: 'POST'
        });
        const response = await getTrabajadores.json();
        setTrabajadores(response);
    }
    const setPrueba = async () => {
        if (pruebaSubmit.fk_rh_trabajador === 0 || pruebaSubmit.fecha_programada === "" || pruebaSubmit.hora_programada === "" || pruebaSubmit.descripcion === "") {
            if (pruebaSubmit.fk_rh_trabajador === 0) {
                setTrabajadorPrueba({ ...trabajadorPrueba, error: true, text: 'Trabajador obligatorio' });
            }
            if (pruebaSubmit.fecha_programada === "") {
                setFechaPrueba({ ...fechaPrueba, error: true, text: 'Fecha programada obligatoria' });
            }
            if (pruebaSubmit.hora_programada === "") {
                setHoraPrueba({ ...horaPrueba, error: true, text: 'Hora programada obligatoria' });
            }
            if (pruebaSubmit.descripcion === "") {
                setDescripcionPrueba({ ...descripcionPrueba, error: true, text: 'Descripcion obligatoria' });
            }
        } else {
            let pruebasA = [].concat(pruebas);
            if (pruebasA.length === 0) {
                let dateHora = new Date();
                pruebasA.push({ ...pruebaSubmit, hora_registro: dateHora.toLocaleTimeString() });
                setPruebas(pruebasA);
                let cantidad = cantidadPrueba + 1;
                setCantidadPrueba(cantidad);
                setPruebaSubmit({ ...pruebaSubmit, fk_rh_trabajador: 0, fecha_programada: '', hora_programada: '' });
                setOpenPrueba(false);
                setAlertGeneral({ ...alertGeneral, type: 'success', text: 'La prueba se guardo correctamente', open: true });
                setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
            } else {
                const validar = pruebasA.filter((prueba) => prueba.linea === pruebaSubmit.linea);
                let dateHora = new Date();
                if (validar.length === 0) {
                    pruebasA.push({ ...pruebaSubmit, hora_registro: dateHora.toLocaleTimeString() });
                    setPruebas(pruebasA);
                    let cantidad = cantidadPrueba + 1;
                    setCantidadPrueba(cantidad);
                    setPruebaSubmit({ ...pruebaSubmit, fk_rh_trabajador: 0, fecha_programada: '', hora_programada: '', descripcion: '' });
                    setOpenPrueba(false);
                    setAlertGeneral({ ...alertGeneral, type: 'success', text: 'La prueba se guardo correctamente', open: true });
                    setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
                } else {
                    let pruebasAE = [];
                    const newPruebas = pruebas.filter((item) => item.linea != pruebaSubmit.linea);
                    newPruebas.forEach((prueba) => {
                        pruebasAE.push(prueba);
                    });
                    pruebasAE.push(pruebaSubmit);
                    setPruebas(pruebasAE);
                    setOpenPrueba(false);
                    setPruebaSubmit({ ...pruebaSubmit, fk_rh_trabajador: 0, fecha_programada: '', hora_programada: '', descripcion: '' });
                    setAlertGeneral({ ...alertGeneral, type: 'success', text: 'La prueba se actualizo correctamente', open: true });
                    setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
                }
            }
        }
    }
    const editarPrueba = (linea) => {
        setPruebaSubmit({ ...pruebaSubmit, fk_rh_trabajador: 0, fecha_programada: '', hora_programada: '', descripcion: '' });
        setTrabajadorPrueba({ ...trabajadorPrueba, error: false, text: '' });
        setFechaPrueba({ ...fechaPrueba, error: false, text: '' });
        setHoraPrueba({ ...horaPrueba, error: false, text: '' });
        setDescripcionPrueba({ ...descripcionPrueba, error: false, text: '' });
        setTextPrueba('EDITAR');
        const pruebaGet = pruebas.filter((item) => item.linea === linea);
        pruebaGet.forEach((prueba) => {
            setPruebaSubmit(prueba);
        });
        setCantidadPrueba(pruebas.length + 1)
    }
    const deletePrueba = () => {
        const newPruebas = pruebas.filter((item) => item.linea !== lineaPrueba);
        setPruebas(newPruebas);
        setOpenDeletePrueba(false);
        setAlertGeneral({ ...alertGeneral, type: 'success', text: 'La prueba se elimino correctamente', open: true });
        setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
    };
    const saveOrden = async () => {
        if (ordenSubmit.fk_ven_paciente === 0 || ordenSubmit.fk_ven_servicio === 0 || ordenSubmit.fk_cmp_material === 0 || ordenSubmit.fecha_entrega === "" || ordenSubmit.descripcion === "" || ordenSubmit.presupuesto === 0) {
            if (ordenSubmit.fk_ven_paciente === 0) {
                setPacienteOrden({ ...pacienteOrden, error: true, text: 'Paciente obligatorio' });
            }
            if (ordenSubmit.fk_ven_servicio === 0) {
                setServiciOrden({ ...serviciOrden, error: true, text: 'Servicio obligatorio' });
            }
            if (ordenSubmit.fk_cmp_material === 0) {
                setMaterialOrden({ ...materialOrden, error: true, text: 'Material obligatorio' });
            }
            if (ordenSubmit.descripcion === "") {
                setDescripcionOrden({ ...descripcionOrden, error: true, text: 'Descripcion obligatoria' });
            }
            if (ordenSubmit.fecha_entrega === "") {
                setFechaOrden({ ...fechaOrden, error: true, text: 'Fecha de entrega obligatoria' });
            }
            if (ordenSubmit.presupuesto === 0) {
                setPresupuestOrden({ ...presupuestOrden, error: true, text: 'Presupuesto debe ser mayor a 0' });
            }
        } else {
            const verifIdOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/verificarIdOrden.php?id=${ordenSubmit.id}`, {
                method: 'POST',
                body: JSON.stringify(ordenSubmit)
            });
            const response_IdOrden = await verifIdOrden.json();
            if (response_IdOrden.resultado === 0) {
                const setorden = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarOrden.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: ordenSubmit.id,
                        descripcion: ordenSubmit.descripcion.toUpperCase(),
                        observaciones: ordenSubmit.observaciones.toUpperCase(),
                        fecha_ingreso: ordenSubmit.fecha_ingreso,
                        fecha_entrega: ordenSubmit.fecha_entrega,
                        cubeta: ordenSubmit.cubeta,
                        esta_entregado: ordenSubmit.esta_entregado,
                        fk_ven_profesional: ordenSubmit.fk_ven_profesional,
                        fk_ven_paciente: ordenSubmit.fk_ven_paciente,
                        esta_facturado: ordenSubmit.esta_facturado,
                        fk_log_usuario: ordenSubmit.fk_log_usuario,
                        fk_cmp_material: ordenSubmit.fk_cmp_material,
                        fk_ven_servicio: ordenSubmit.fk_ven_servicio,
                        presupuesto: ordenSubmit.presupuesto,
                        estado_pago: ordenSubmit.estado_pago,
                        prueba_mordida: ordenSubmit.prueba_mordida,
                        antagonista: ordenSubmit.antagonista,
                        color_tercio_incisal: ordenSubmit.color_tercio_incisal.toUpperCase(),
                        color_tercio_medial: ordenSubmit.color_tercio_medial.toUpperCase(),
                        color_tercio_cervical: ordenSubmit.color_tercio_cervical.toUpperCase(),
                        numero: ordenSubmit.numero
                    })
                });
                const response = await setorden.json();
                const deleteDetalleOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/eliminarTodoDetalle.php?id=` + response.id, {
                    method: 'GET'
                });
                const response_delete = await deleteDetalleOrden.json();
                let toothPintados = detalleOrden.filter(tp => tp.background !== undefined && tp.background !== '#fff');
                if (toothPintados.length > 0) {
                    toothPintados.forEach(async tp => {
                        const setOrdenDetalle = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarOrdenDetalle.php`, {
                            method: 'POST',
                            body: JSON.stringify({
                                pieza: tp.id,
                                fk_ven_orden: response.id,
                                es_pontico: tp.pontico
                            })
                        });
                        const response_orden_detalle = await setOrdenDetalle.json();
                    });
                }
                pruebas.forEach(async (item) => {
                    const deletePruebas = await fetch(`${UrlsConst.URL_API}/ventas/orden/eliminarTodoPrueba.php?id=` + response.id, {
                        method: 'GET'
                    });
                    const response_eliminar = await deletePruebas.json();
                    const ordenPrueba = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarPrueba.php`, {
                        method: 'POST',
                        body: JSON.stringify({
                            fk_ven_orden: response.id,
                            linea: item.linea,
                            fk_rh_trabajador: item.fk_rh_trabajador,
                            fecha_registro: item.fecha_registro,
                            hora_registro: item.hora_registro,
                            fecha_conclusion: '',
                            hora_conclusion: '',
                            fecha_entrega: '',
                            hora_entrega: '',
                            fecha_programada: item.fecha_programada,
                            hora_programada: item.hora_programada,
                            descripcion: item.descripcion,
                        })
                    });
                    const response_orden = ordenPrueba.json();
                });
            } else {
                const setorden = await fetch(`${UrlsConst.URL_API}/ventas/orden/actualizarOrden.php?orden=${ordenSubmit.id}`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: ordenSubmit.id,
                        descripcion: ordenSubmit.descripcion.toUpperCase(),
                        observaciones: ordenSubmit.observaciones.toUpperCase(),
                        fecha_ingreso: ordenSubmit.fecha_ingreso,
                        fecha_entrega: ordenSubmit.fecha_entrega,
                        cubeta: ordenSubmit.cubeta,
                        esta_entregado: ordenSubmit.esta_entregado,
                        fk_ven_profesional: ordenSubmit.fk_ven_profesional,
                        fk_ven_paciente: ordenSubmit.fk_ven_paciente,
                        esta_facturado: ordenSubmit.esta_facturado,
                        fk_log_usuario: ordenSubmit.fk_log_usuario,
                        fk_cmp_material: ordenSubmit.fk_cmp_material,
                        fk_ven_servicio: ordenSubmit.fk_ven_servicio,
                        presupuesto: ordenSubmit.presupuesto,
                        estado_pago: ordenSubmit.estado_pago,
                        prueba_mordida: ordenSubmit.prueba_mordida,
                        antagonista: ordenSubmit.antagonista,
                        color_tercio_incisal:ordenSubmit.color_tercio_incisal!==null&&ordenSubmit.color_tercio_incisal!==""? ordenSubmit.color_tercio_incisal.toUpperCase():"",
                        color_tercio_medial: ordenSubmit.color_tercio_medial!==null&&ordenSubmit.color_tercio_medial!==""? ordenSubmit.color_tercio_medial.toUpperCase():"",
                        color_tercio_cervical: ordenSubmit.color_tercio_cervical!==null&&ordenSubmit.color_tercio_cervical!==""?  ordenSubmit.color_tercio_cervical.toUpperCase():"",
                        numero: ordenSubmit.numero
                    })
                });
                const response = await setorden.json();
                const deleteDetalleOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/eliminarTodoDetalle.php?id=` + response.id, {
                    method: 'GET'
                });
                const response_delete = await deleteDetalleOrden.json();
                let toothPintados = detalleOrden.filter(tp => tp.background !== undefined && tp.background !== '#fff');
                if (toothPintados.length > 0) {
                    toothPintados.forEach(async tp => {
                        const setOrdenDetalle = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarOrdenDetalle.php`, {
                            method: 'POST',
                            body: JSON.stringify({
                                pieza: tp.id,
                                fk_ven_orden: response.id,
                                es_pontico: tp.pontico
                            })
                        });
                        const response_orden_detalle = await setOrdenDetalle.json();
                    });
                }
                pruebas.forEach(async (item) => {
                    const deletePruebas = await fetch(`${UrlsConst.URL_API}/ventas/orden/eliminarTodoPrueba.php?id=` + response.id, {
                        method: 'GET'
                    });
                    const response_eliminar = await deletePruebas.json();
                    const ordenPrueba = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarPrueba.php`, {
                        method: 'POST',
                        body: JSON.stringify({
                            fk_ven_orden: response.id,
                            linea: item.linea,
                            fk_rh_trabajador: item.fk_rh_trabajador,
                            fecha_registro: item.fecha_registro,
                            hora_registro: item.hora_registro,
                            fecha_conclusion: '',
                            hora_conclusion: '',
                            fecha_entrega: '',
                            hora_entrega: '',
                            fecha_programada: item.fecha_programada,
                            hora_programada: item.hora_programada,
                            descripcion: item.descripcion,
                        })
                    });
                    const response_orden = ordenPrueba.json();
                });
                props.ordenes();
            }
            if (props.setNewOrden != undefined) {
                props.setNewOrden(ordenSubmit);
                props.ordenes();
            }
            if (props.fntClose != undefined) {
                props.fntClose();
            }
        }
    }
    const handlePacientes = (e, values) => {
        const paciente = values.props;
        setOrdenSubmmit({ ...ordenSubmit, fk_ven_paciente: paciente.value });
    }
    // console.log(materiales)
    return (
        <Grid container style={{ minHeight: '100%', alignContent: 'center', justifyContent: 'center' }} spacing={2}>
            <Snackbar open={alertGeneral.open} autoHideDuration={6000} onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} severity={alertGeneral.type} sx={{ width: '100%' }}>
                    {alertGeneral.text}
                </Alert>
            </Snackbar>
            <Grid item xl={5} lg={6} md={8} sm={8} xs={12}>
                <Paper style={{ padding: '1em', alignContent: 'center' }}>
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="PROFESIONAL"
                                margin='normal'
                                variant="standard"
                                value={profesional ? profesional.apellidos_nombres : ''}
                                disabled
                            />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <TextField
                                style={{ textAlign: 'start' }}
                                select
                                margin='normal'
                                fullWidth
                                value={ordenSubmit ? ordenSubmit.fk_ven_paciente : 0}
                                error={pacienteOrden.error}
                                helperText={pacienteOrden.text}
                                label="PACIENTE"
                                variant='standard'
                                onChange={handlePacientes}
                            >
                                {pacientes.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.apellidos_nombres}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item md={1} style={{ marginTop: '20px' }}>
                            <IconButton color="success" variant='contained' size="large" onClick={() => setNewPaciente(true)}>
                                <Tooltip title="NUEVO PACIENTE">
                                    <AddCircleIcon />
                                </Tooltip>
                            </IconButton>
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <TextField
                                style={{ textAlign: 'start' }}
                                select
                                margin='normal'
                                fullWidth
                                value={ordenSubmit.fk_ven_servicio}
                                error={serviciOrden.error}
                                helperText={serviciOrden.text}
                                label="SERVICIO"
                                variant='standard'
                                onChange={handleServicio}
                            >
                                {serviciosList.map((option) => (
                                    <MenuItem key={option.id} value={option.id} color={option.color} multipieza={option.multipieza}>
                                        {option.nombre}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item md={7} xs={12}>
                            <TextField
                                style={{ textAlign: 'start' }}
                                select
                                margin='normal'
                                fullWidth
                                value={ordenSubmit.fk_cmp_material}
                                label="MATERIAL"
                                variant='standard'
                                error={materialOrden.error}
                                helperText={materialOrden.text}
                                onChange={handleMaterial}
                            >
                                {materiales.map((option) => (
                                    <MenuItem key={option.fk_cmp_material} value={option.fk_cmp_material} nombre={option.descripcion} precio={option.precio} costo={option.costo} color={option.color}>
                                        {option.nombre}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="FECHA DE ENTREGA"
                                type="date"
                                fullWidth
                                variant='standard'
                                margin='normal'
                                value={ordenSubmit ? ordenSubmit.fecha_entrega : ''}
                                error={fechaOrden.error}
                                helperText={fechaOrden.text}
                                onChange={(e) => {
                                    const fechaI = e.target.value;
                                    if (fechaI != "") {
                                        setOrdenSubmmit({ ...ordenSubmit, fecha_entrega: fechaI });
                                        setFechaOrden({ ...fechaOrden, error: false, text: '' });
                                    } else {
                                        setFechaOrden({ ...fechaOrden, error: true, text: 'Fecha de entrega obligatoria' });
                                        setOrdenSubmmit({ ...ordenSubmit, fecha_entrega: '' });
                                    }
                                }}
                                InputProps={{
                                    inputProps: {
                                        min: new Date().toISOString().split('T')[0]
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CalendarMonthIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                margin='normal'
                                name="presupuesto"
                                variant="standard"
                                label="PRESUPUESTO"
                                error={presupuestOrden.error}
                                helperText={presupuestOrden.text}
                                value={ordenSubmit ? ordenSubmit.presupuesto : ''}
                                onChange={
                                    (e) => {
                                        const { value } = e.target;
                                        if (value !== "") {
                                            if (isNaN(value) != true) {
                                                if (value > 0) {
                                                    setOrdenSubmmit({ ...ordenSubmit, presupuesto: value });
                                                    setPresupuestOrden({ ...presupuestOrden, error: false, text: '' });
                                                } else {
                                                    setPresupuestOrden({ ...presupuestOrden, error: true, text: 'Presupuesto debe ser mayor a cero' });
                                                    setOrdenSubmmit({ ...ordenSubmit, presupuesto: value });
                                                }
                                            }
                                        } else {
                                            setOrdenSubmmit({ ...ordenSubmit, presupuesto: '' });
                                            setPresupuestOrden({ ...presupuestOrden, error: true, text: 'Presupuesto obligatorio' });
                                        }
                                    }
                                }
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">S/.</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin='normal'
                                label="DESCRIPCION"
                                name="descripcion"
                                multiline
                                rows={2}
                                maxRows={4}
                                error={descripcionOrden.error}
                                helperText={descripcionOrden.text}
                                value={ordenSubmit ? ordenSubmit.descripcion : ''}
                                onChange={
                                    (e) => {
                                        const { value } = e.target;
                                        if (value != "") {
                                            setOrdenSubmmit({ ...ordenSubmit, descripcion: value.toUpperCase() });
                                            setDescripcionOrden({ ...descripcionOrden, error: false, text: '' });
                                        } else {
                                            setOrdenSubmmit({ ...ordenSubmit, descripcion: '' });
                                            setDescripcionOrden({ ...descripcionOrden, error: true, text: 'Descripcion obligatoria' });
                                        }
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin='normal'
                                label="OBSERVACIONES"
                                name="observaciones"
                                onChange={handleOrden}
                                multiline
                                rows={2}
                                maxRows={4}
                                value={ordenSubmit ? ordenSubmit.observaciones : ''}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FormControlLabel labelPlacement="start" onChange={handlePruebaMordida} control={<Switch name="prueba_mordida" checked={ordenSubmit.prueba_mordida != 0} color="success" />} label="PRUEBA MORDIDA" />
                            <FormControlLabel labelPlacement="start" onChange={handleAntagonista} control={<Switch name="antagonista" checked={ordenSubmit.antagonista != 0} color="success" />} label="ANTAGONISTA" />
                            <FormControlLabel labelPlacement="start" onChange={handleCubeta} control={<Switch name="cubeta" color="success" checked={ordenSubmit.cubeta != 0} />} label="CUBETA" />
                        </Grid>
                        <Grid item md={6}>
                            <FormControlLabel labelPlacement="start" control={<Switch color="success" checked={ordenSubmit.estado_pago === 'G'} />} label="PAGADO" disabled />
                            <FormControlLabel labelPlacement="start" control={<Switch color="success" checked={ordenSubmit.esta_entregado != 0} />} label="ENTREGAGO" disabled />
                            <FormControlLabel labelPlacement="start" control={<Switch color="success" checked={ordenSubmit.esta_facturado != 0} />} label="FACTURADO" disabled />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button variant='contained' color="success" onClick={saveOrden} startIcon={<CheckIcon />}>Guardar Orden</Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper style={{ padding: '1em', marginTop: '10px' }}>
                    <Grid container style={{ justifyContent: 'space-between', textAlign: 'center' }}>
                        <Typography variant='h6'>TRABAJOS</Typography>
                        <Button style={{ marginTop: '6px' }} variant='contained' color="success" onClick={fntPruebaOpen} startIcon={<AddIcon />}>AGREGAR TRABAJO</Button>
                    </Grid>
                    {
                        pruebas.length === 0 &&
                        <Typography style={{ marginTop: '20px', textAlign: 'center' }}>Sin trabajos registrados</Typography>
                    }
                    {
                        pruebas.length != 0 &&
                        <TableContainer style={{ marginTop: '20px', height: 200 }} component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '5%', fontWeight: 'bold' }}>N°</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>TRABAJADOR</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>F. PROGRAMADA</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pruebas.map((prueba) => (

                                        <TableRow
                                            key={prueba.linea}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {prueba.linea}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    prueba.nombre_trabajador === undefined ? prueba.trabajador_asignado : prueba.nombre_trabajador
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {prueba.fecha_programada + " " + prueba.hora_programada}
                                            </TableCell>
                                            <TableCell>
                                                {prueba.descripcion}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton aria-label="delete" onClick={() => { setOpenPrueba(true); editarPrueba(prueba.linea) }} size="small">
                                                    <EditIcon color="primary" fontSize="small" />
                                                </IconButton>
                                                <IconButton aria-label="delete" onClick={() => { setOpenDeletePrueba(true); setLineaPrueba(prueba.linea) }} size="small">
                                                    <DeleteIcon color="error" fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Paper>
            </Grid >
            <Grid item xl={4} lg={6} md={4} sm={4} xs={12}>
                <Paper elevation={1}>
                    <NewOdontograma
                        view={0}
                        orden={ordenSubmit}
                        setOrden={setOrdenSubmmit}
                        serviciOrden={servicio}
                        material={material}
                        alertGeneral={alertGeneral}
                        setAlertGeneral={setAlertGeneral}
                        setDetalleOrden={setDetalleOrden}
                        recuperarOrden={props.fntOrden}
                    />
                </Paper>
            </Grid>
            <Dialog open={openPrueba} onClose={handleClose} fullWidth={true} maxWidth="sm">
                <DialogTitle>{textPrueba} TRABAJO</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item md={2} xs={12}>
                            <TextField
                                label="N° TRABAJO"
                                fullWidth
                                margin='normal'
                                name='linea'
                                value={pruebaSubmit.linea}
                                disabled
                                variant='standard'
                                onChange={handleLinea}
                            />
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <TextField
                                style={{ textAlign: 'start' }}
                                select
                                margin='normal'
                                fullWidth
                                error={trabajadorPrueba.error}
                                helperText={trabajadorPrueba.text}
                                value={pruebaSubmit ? pruebaSubmit.fk_rh_trabajador : 0}
                                label="TRABAJADOR"
                                variant='standard'
                                onChange={handleTrabajador}
                            >
                                {trabajadores.map((option) => (
                                    <MenuItem key={option.id} value={option.id} nombre={option.nombres_apellidos}>
                                        {option.nombres_apellidos}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                type="date"
                                name="fecha_programada"
                                label="FECHA PROGRAMADA"
                                fullWidth
                                variant='standard'
                                margin='normal'
                                error={fechaPrueba.error}
                                helperText={fechaPrueba.text}
                                value={pruebaSubmit ? pruebaSubmit.fecha_programada : ''}
                                onChange={(e) => {
                                    const fechaI = e.target.value;
                                    if (fechaI != "") {
                                        setPruebaSubmit({ ...pruebaSubmit, fecha_programada: fechaI });
                                        setFechaPrueba({ ...fechaPrueba, error: false, text: '' });
                                    } else {
                                        setPruebaSubmit({ ...pruebaSubmit, fecha_programada: '' });
                                        setFechaPrueba({ ...fechaPrueba, error: true, text: 'Fecha programada obligatoria' });
                                    }
                                }}
                                InputProps={{
                                    inputProps: {
                                        min: new Date().toISOString().split('T')[0]
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CalendarMonthIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                label="HORA PROGRAMADA"
                                type="time"
                                fullWidth
                                name='hora_programada'
                                margin='normal'
                                variant='standard'
                                error={horaPrueba.error}
                                helperText={horaPrueba.text}
                                value={pruebaSubmit ? pruebaSubmit.hora_programada : ''}
                                onChange={(e) => {
                                    const fechaI = e.target.value;
                                    if (fechaI != "") {
                                        setPruebaSubmit({ ...pruebaSubmit, hora_programada: fechaI });
                                        setHoraPrueba({ ...horaPrueba, error: false, text: '' });
                                    } else {
                                        setPruebaSubmit({ ...pruebaSubmit, hora_programada: '' });
                                        setHoraPrueba({ ...horaPrueba, error: true, text: 'Hora programada obligatoria' });
                                    }
                                }}
                                InputProps={{
                                    inputProps: {
                                        min: new Date().toISOString().split('T')[0]
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <ScheduleIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        label="DESCRIPCION"
                        fullWidth
                        margin='normal'
                        name='descripcion'
                        multiline
                        rows={3}
                        error={descripcionPrueba.error}
                        helperText={descripcionPrueba.text}
                        value={pruebaSubmit ? pruebaSubmit.descripcion : ''}
                        inputProps={{ maxLength: 400 }}
                        onChange={
                            (e) => {
                                const { value } = e.target;
                                if (value != "") {
                                    setPruebaSubmit({ ...pruebaSubmit, descripcion: value.toUpperCase() });
                                    setDescripcionPrueba({ ...descripcionPrueba, error: false, text: '' });
                                } else {
                                    setPruebaSubmit({ ...pruebaSubmit, descripcion: '' });
                                    setDescripcionPrueba({ ...descripcionPrueba, error: true, text: 'Descripcion obligatoria' });
                                }
                            }
                        }
                    />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        onClick={setPrueba} variant="outlined" color="success" startIcon={<CheckIcon />}>
                        {textPrueba === "EDITAR" ? 'ACTUALIZAR' : 'GUARDAR'}
                    </Button>
                    <Button
                        onClick={handleClose} variant="outlined" color="error" startIcon={<CloseIcon />}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={opendeletePrueba} onClose={() => setOpenDeletePrueba(false)}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Eliminar Trabajo</Typography>
                    ¿Esta seguro de eliminar el trabajo?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} variant="contained" color="error" onClick={deletePrueba}>Aceptar</Button>
                    <Button startIcon={<CloseIcon />} variant="contained" onClick={() => setOpenDeletePrueba(false)}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={newPaciente} onClose={() => setNewPaciente(false)} fullWidth={true} maxWidth="md">
                <DialogTitle>NUEVO PACIENTE</DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <PacienteDialog
                        fntIdprofesional={profesional.id}
                        setNewPaciente={() => setNewPaciente(false)}
                        listPacientes={listPacientes}
                        alertGeneral={alertGeneral}
                        setAlertGeneral={setAlertGeneral}
                    />
                    <Button startIcon={<CloseIcon />} style={{ marginLeft: '6px' }} variant="outlined" color="error" className="btnClose" onClick={() => setNewPaciente(false)}>Cancelar</Button>
                </DialogContent>
            </Dialog>
        </Grid >
    )
}
