import { Check, Close, Paid, PersonAdd } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, MenuItem, TextField } from '@mui/material'
import React from 'react'
import UrlsConst from '../../../config/config';
import DialogGasto from './dialogGasto';
import DialogTrabajadores from '../../sistema/Dialogs/dialogTrabajadores';
export default function DialogRegistroGasto(props) {
    let date = new Date();
    let año = date.getFullYear();
    let mes = date.getMonth() + 1;
    let dia = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    let fecha_actual = año + "-" + mes + "-" + dia;
    const [gastos, setGastos] = React.useState([]);
    const [dialogTrabajador, setDialogTrabajador] = React.useState(false);
    const [dialogGasto, setDialogGasto] = React.useState({
        show: false,
        textDialog: 'NUEVO',
        codigo: ''
    });
    const [trabajadores, setTrabajadores] = React.useState([]);
    const [registroSubmit, setRegistroSubmit] = React.useState({
        codigo: '',
        fecha: fecha_actual,
        fecha_registro: '',
        referencia: '',
        cantidad: 0,
        precio_unitario: 0,
        total: 0,
        fk_cmp_gasto: '',
        fk_rh_trabajador_responsable: 0,
        fk_log_usuario_creado_por: '',
    });
    // handles
    const handleRegistro = (e) => {
        const { name, value } = e.target;
        setRegistroSubmit({ ...registroSubmit, [name]: value });
    }
    const handleTrabajador = (e, values) => {
        setRegistroSubmit({ ...registroSubmit, fk_rh_trabajador_responsable: values.props.value });
    }
    const handleGasto = (e, values) => {
        setRegistroSubmit({ ...registroSubmit, fk_cmp_gasto: values.props.value });
    }
    const handleTotal = (e) => {
        const { value, name } = e.target;
        if (name === "cantidad") {
            if (!isNaN(value)) {
                if (registroSubmit.precio_unitario !== "") {
                    setRegistroSubmit({ ...registroSubmit, cantidad: value, total: Number(registroSubmit.precio_unitario) * value });
                } else {
                    setRegistroSubmit({ ...registroSubmit, cantidad: value, total: 0 });
                }
            } else {
                setRegistroSubmit({ ...registroSubmit, cantidad: 0, total: 0 });
            }
        }
        if (name === "precio_unitario") {
            if (!isNaN(value)) {
                if (registroSubmit.cantidad !== "") {
                    setRegistroSubmit({ ...registroSubmit, precio_unitario: value, total: Number(registroSubmit.cantidad) * value });
                } else {
                    setRegistroSubmit({ ...registroSubmit, precio_unitario: value, total: 0 });
                }
            } else {
                setRegistroSubmit({ ...registroSubmit, cantidad: 0, total: 0 });
            }
        }
    }
    // functions
    const dateNow = new Date()
    const agregarCero = (n) => {
        if (n <= 9) { return "0" + n; }
        return n
    }
    const getRandomNum = () => {
        let min = Math.ceil(1);
        let max = Math.floor(9999);
        var numGenerado = Math.floor(Math.random() * (max - min + 1) + min);
        if (numGenerado <= 9) { return "000" + numGenerado; }
        if (numGenerado <= 99) { return "00" + numGenerado; }
        if (numGenerado <= 999) { return "0" + numGenerado; }
        return numGenerado;
    }
    const genCodigo = () => {
        let dateFormateado2 = dateNow.getFullYear() + "" +
            agregarCero(dateNow.getMonth() + 1) + "" +
            agregarCero(dateNow.getDate()) + "" +
            agregarCero(dateNow.getHours()) + "" +
            agregarCero(dateNow.getMinutes()) + "" +
            agregarCero(dateNow.getSeconds()) + getRandomNum();
        return dateFormateado2;
    }
    const saveRegistroGasto = async () => {
        const userSession = JSON.parse(sessionStorage.getItem('usuario_log'));
        const guardarRegistro = await fetch(`${UrlsConst.URL_API}/compras/gastos/guardarRegistroGasto.php`, {
            method: 'POST',
            body: JSON.stringify({
                codigo: registroSubmit.codigo === "" ? genCodigo() : registroSubmit.codigo,
                fecha: registroSubmit.fecha,
                fecha_registro: registroSubmit.fecha_registro === "" ? new Date().toJSON().slice(0, 10) : registroSubmit.fecha_registro,
                referencia: registroSubmit.referencia === "" ? '' : registroSubmit.referencia.toUpperCase(),
                cantidad: registroSubmit.cantidad,
                precio_unitario: registroSubmit.precio_unitario,
                total: registroSubmit.total,
                fk_cmp_gasto: registroSubmit.fk_cmp_gasto,
                fk_rh_trabajador_responsable: registroSubmit.fk_rh_trabajador_responsable,
                fk_log_usuario_creado_por: userSession.usuario
            })
        });
        const response = await guardarRegistro.json();
        if (response) {
            if (registroSubmit.codigo === "") {
                props.setAlertGeneral({ show: true, message: 'Registro guardado correctamente', severity: 'success' });
            } else {
                props.setAlertGeneral({ show: true, message: 'Registro actualizado correctamente', severity: 'success' });
            }
        } else {
            props.setAlertGeneral({ show: true, message: 'Ocurrio un error', severity: 'error' });
        }
        setTimeout(() => props.setAlertGeneral({ show: false, severity: 'success', message: '' }), 2500);
        props.listarRegistroGastoFecha();
        props.dialogClose();
    }
    const recuperarRegistro = async (codigo) => {
        const recuperarRegistroGasto = await fetch(`${UrlsConst.URL_API}/compras/gastos/recuperarGastoRegistro.php?codigo=${codigo}`);
        const response = await recuperarRegistroGasto.json();
        setRegistroSubmit(response);
    }
    const listarTrabajadores = async () => {
        const trabajadores = await fetch(`${UrlsConst.URL_API}/sistema/trabajador/listarTrabajador.php`);
        const response = await trabajadores.json();
        setTrabajadores(response);
    }
    const listarGastos = async () => {
        const gastos_list = await fetch(`${UrlsConst.URL_API}/compras/gastos/listarGastos.php`);
        const response = await gastos_list.json();
        setGastos(response);
    }
    React.useEffect(() => {
        if (props.codigo !== "") {
            recuperarRegistro(props.codigo);
        }
        listarTrabajadores();
        listarGastos();
    }, []);
    return (
        <Grid container spacing={2}>
            <Dialog open={dialogTrabajador} maxWidth="sm">
                <DialogTitle>NUEVO TRABAJADOR</DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <DialogTrabajadores fntTrabajadores={listarTrabajadores} />
                    <Button sx={{ ml: 2 }} variant='outlined' color="error" onClick={() => setDialogTrabajador(false)} startIcon={<Close />}>CANCELAR</Button>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogGasto.show} maxWidth="xs">
                <DialogTitle>{dialogGasto.textDialog} GASTO</DialogTitle>
                <DialogContent>
                    <DialogGasto closeDialog={() => setDialogGasto({ show: false, textDialog: 'NUEVO', codigo: '' })} listarGatos={listarGastos} codigo={dialogGasto.codigo} />
                </DialogContent>
            </Dialog>
            <Grid item md={4}>
                <TextField margin='normal' name="fecha" fullWidth label="FECHA" type="date" InputLabelProps={{ shrink: true }} value={registroSubmit.fecha} onChange={handleRegistro} />
            </Grid>
            <Grid item md={8}>
                <TextField margin='normal' name="referencia" fullWidth label="REFERENCIA" onChange={handleRegistro} value={registroSubmit.referencia} inputProps={{ style: { textTransform: 'uppercase' } }} />
            </Grid>
            <Grid item md={10.5}>
                <TextField
                    style={{ textAlign: 'start' }}
                    select
                    margin='normal'
                    fullWidth
                    value={registroSubmit ? registroSubmit.fk_rh_trabajador_responsable : 0}
                    label="TRABAJADOR"
                    onChange={handleTrabajador}
                >
                    {trabajadores.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.nombres_apellidos}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item md={1.5} sx={{ mt: 2 }}>
                <IconButton size="large" onClick={() => setDialogTrabajador(true)}>
                    <PersonAdd color="success" fontSize="inherit" />
                </IconButton>
            </Grid>
            <Grid item md={10.5}>
                <TextField
                    style={{ textAlign: 'start' }}
                    select
                    margin='normal'
                    fullWidth
                    value={registroSubmit ? registroSubmit.fk_cmp_gasto : ''}
                    label="GASTO"
                    onChange={handleGasto}
                >
                    {gastos.map((option) => (
                        <MenuItem key={option.codigo} value={option.codigo}>
                            {option.nombre}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item md={1.5} sx={{ mt: 2 }}>
                <IconButton size="large" onClick={() => setDialogGasto({ show: true, textDialog: 'NUEVO', codigo: '' })}>
                    <Paid color="success" fontSize="inherit" />
                </IconButton>
            </Grid>
            <Grid item md={4}>
                <TextField margin='normal' fullWidth name='cantidad' label="CANTIDAD" value={registroSubmit.cantidad} onChange={handleTotal} />
            </Grid>
            <Grid item md={4}>
                <TextField margin='normal' fullWidth name='precio_unitario' label="PRECIO UNITARIO" value={registroSubmit.precio_unitario} onChange={handleTotal} InputProps={{ startAdornment: (<InputAdornment position="start">S/.</InputAdornment>) }} />
            </Grid>
            <Grid item md={4}>
                <TextField margin='normal' fullWidth label="TOTAL" value={registroSubmit.total} InputProps={{ startAdornment: (<InputAdornment position="start">S/.</InputAdornment>) }} disabled />
            </Grid>
            <Grid container sx={{ justifyContent: 'center', mt: 3 }}>
                <Grid item md={2}>
                    <Button startIcon={<Check />} variant='outlined' color="success" onClick={saveRegistroGasto}>GUARDAR</Button>
                </Grid>
                <Grid item md={2}>
                    <Button startIcon={<Close />} variant='outlined' color="error" onClick={() => props.dialogClose()}>CERRAR</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}
