import { Check, CleaningServices, Close, RailwayAlert } from '@mui/icons-material';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Paper, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import UrlsConst from '../config/config';
import Tooth from './tooth'

export default function NewOdontograma(props) {
    // constans
    const [toothSelects, setToothSelects] = React.useState([]);
    const [widthC, setWidthC] = React.useState(0);
    let toothSelectA = [].concat(toothSelects);
    // props 
    let serviciOrden = props.serviciOrden;
    let material = props.material;
    let setAlertGeneral = props.setAlertGeneral;
    let sizeTooth = widthC / 20;
    // position tooth
    let position = {
        x: 0,
        y: 0
    };
    let radio = widthC / 3;
    let cx = widthC / 2;
    let cy = 250;
    let f = Math.PI / 17;
    const [toothsOdontograma, setToothsOdontograma] = React.useState([
        // parte inferior
        { id: 0 },
        { id: 38, pontico: 0, background: '#fff', position: 'I' },
        { id: 37, pontico: 0, background: '#fff', position: 'I' },
        { id: 36, pontico: 0, background: '#fff', position: 'I' },
        { id: 35, pontico: 0, background: '#fff', position: 'I' },
        { id: 34, pontico: 0, background: '#fff', position: 'I' },
        { id: 33, pontico: 0, background: '#fff', position: 'I' },
        { id: 32, pontico: 0, background: '#fff', position: 'I' },
        { id: 31, pontico: 0, background: '#fff', position: 'I' },
        { id: 41, pontico: 0, background: '#fff', position: 'I' },
        { id: 42, pontico: 0, background: '#fff', position: 'I' },
        { id: 43, pontico: 0, background: '#fff', position: 'I' },
        { id: 44, pontico: 0, background: '#fff', position: 'I' },
        { id: 45, pontico: 0, background: '#fff', position: 'I' },
        { id: 46, pontico: 0, background: '#fff', position: 'I' },
        { id: 47, pontico: 0, background: '#fff', position: 'I' },
        { id: 48, pontico: 0, background: '#fff', position: 'I' },
        { id: 1 },
        { id: 18, pontico: 0, background: '#fff', position: 'S' },
        { id: 17, pontico: 0, background: '#fff', position: 'S' },
        { id: 16, pontico: 0, background: '#fff', position: 'S' },
        { id: 15, pontico: 0, background: '#fff', position: 'S' },
        { id: 14, pontico: 0, background: '#fff', position: 'S' },
        { id: 13, pontico: 0, background: '#fff', position: 'S' },
        { id: 12, pontico: 0, background: '#fff', position: 'S' },
        { id: 11, pontico: 0, background: '#fff', position: 'S' },
        { id: 21, pontico: 0, background: '#fff', position: 'S' },
        { id: 22, pontico: 0, background: '#fff', position: 'S' },
        { id: 23, pontico: 0, background: '#fff', position: 'S' },
        { id: 24, pontico: 0, background: '#fff', position: 'S' },
        { id: 25, pontico: 0, background: '#fff', position: 'S' },
        { id: 26, pontico: 0, background: '#fff', position: 'S' },
        { id: 27, pontico: 0, background: '#fff', position: 'S' },
        { id: 28, pontico: 0, background: '#fff', position: 'S' },

        // parte superior

    ]);
    // const containerOdonto = document.querySelector('.contenedor_odontograma').offsetWidth;
    // console.log(containerOdonto);

    // console.log(window.size.width)
    const toothClick = (id) => {
        if (props.view === 0) {
            if (serviciOrden.value !== 0 && material.fk_cmp_material !== 0) {
                if (serviciOrden.multipieza !== undefined && serviciOrden.multipieza === 1) {
                    toothSelectA.push(id);
                    setToothSelects(toothSelectA);
                    if (toothSelectA.length === 1) {
                        let recuperar = toothsOdontograma.filter(tp => tp.id === id && tp.background !== "#fff");
                        if (recuperar.length > 0) {
                            let newOdontograma = [].concat(toothsOdontograma);
                            recuperar.forEach(tp => {
                                let indice = toothsOdontograma.findIndex((t, indice) => {
                                    if (t.id === tp.id) {
                                        return true;
                                    }
                                });
                                newOdontograma[indice] = {
                                    id: tp.id,
                                    pontico: tp.pontico === 0 ? 1 : 0,
                                    background: material.color,
                                    position: tp.position,
                                    color_tercio_incisal: tp.color_tercio_incisal,
                                    color_tercio_medial: tp.color_tercio_medial,
                                    color_tercio_cervical: tp.color_tercio_cervical
                                };
                            });
                            toothSelectA.length = 0;
                            recuperar.length = 0;
                            props.setDetalleOrden(newOdontograma);
                            setToothsOdontograma(newOdontograma);
                        }
                    }
                    if (toothSelectA.length === 2) {
                        let tooth1Obj = {};
                        let tooth2Obj = {};
                        let tooth1 = toothsOdontograma.filter(t => t.id === toothSelectA[0]);
                        let tooth2 = toothsOdontograma.filter(t => t.id === toothSelectA[1]);
                        tooth1.forEach(tr => tooth1Obj = tr);
                        tooth2.forEach(tr => tooth2Obj = tr);
                        if (tooth1Obj.position === tooth2Obj.position) {
                            let indice1 = toothsOdontograma.findIndex((e, indice) => {
                                if (e.id === toothSelectA[0]) {
                                    return true;
                                }
                            });
                            let indice2 = toothsOdontograma.findIndex((e, indice) => {
                                if (e.id === toothSelectA[1]) {
                                    return true;
                                }
                            });
                            let newOdontograma = [].concat(toothsOdontograma);
                            if (indice1 > indice2) {
                                let pintarTooths = toothsOdontograma.slice(indice2, indice1 + 1);
                                pintarTooths.forEach(tp => {
                                    let indice = toothsOdontograma.findIndex((t, indice) => {
                                        if (t.id === tp.id) {
                                            return true;
                                        }
                                    });
                                    if (pintarTooths[0].id === tp.id || pintarTooths[pintarTooths.length - 1].id === tp.id) {
                                        newOdontograma[indice] = {
                                            id: tp.id,
                                            pontico: 0,
                                            background: material.color,
                                            position: tp.position,
                                            color_tercio_incisal: tp.color_tercio_incisal,
                                            color_tercio_medial: tp.color_tercio_medial,
                                            color_tercio_cervical: tp.color_tercio_cervical
                                        };
                                    } else {
                                        newOdontograma[indice] = {
                                            id: tp.id,
                                            pontico: 1,
                                            background: material.color,
                                            position: tp.position,
                                            color_tercio_incisal: tp.color_tercio_incisal,
                                            color_tercio_medial: tp.color_tercio_medial,
                                            color_tercio_cervical: tp.color_tercio_cervical
                                        };
                                    }
                                });
                                let pintadosPresupuesto = newOdontograma.filter(tp => tp.background !== undefined && tp.background !== "#fff");
                                props.setOrden({ ...props.orden, presupuesto: Number(pintadosPresupuesto.length) * Number(material.precio) });
                                props.setDetalleOrden(newOdontograma);
                                setToothsOdontograma(newOdontograma);
                                toothSelectA.length = 0;
                            } else {
                                let pintarTooths = toothsOdontograma.slice(indice1, indice2 + 1);
                                pintarTooths.forEach(tp => {
                                    let indice = toothsOdontograma.findIndex((t, indice) => {
                                        if (t.id === tp.id) {
                                            return true;
                                        }
                                    });
                                    if (pintarTooths[0].id === tp.id || pintarTooths[pintarTooths.length - 1].id === tp.id) {
                                        newOdontograma[indice] = {
                                            id: tp.id,
                                            pontico: 0,
                                            background: material.color,
                                            position: tp.position,
                                            color_tercio_incisal: tp.color_tercio_incisal,
                                            color_tercio_medial: tp.color_tercio_medial,
                                            color_tercio_cervical: tp.color_tercio_cervical
                                        };
                                    } else {
                                        newOdontograma[indice] = {
                                            id: tp.id,
                                            pontico: 1,
                                            background: material.color,
                                            position: tp.position,
                                            color_tercio_incisal: tp.color_tercio_incisal,
                                            color_tercio_medial: tp.color_tercio_medial,
                                            color_tercio_cervical: tp.color_tercio_cervical
                                        };
                                    }
                                });
                                let pintadosPresupuesto = newOdontograma.filter(tp => tp.background !== undefined && tp.background !== "#fff");
                                props.setOrden({ ...props.orden, presupuesto: Number(pintadosPresupuesto.length) * Number(material.precio) });
                                toothSelectA.length = 0;
                                props.setDetalleOrden(newOdontograma);
                                setToothsOdontograma(newOdontograma);
                            }
                        } else {
                            toothSelectA.length = 0;
                            console.log('accion no permitida')
                        }
                    }
                } else {
                    let newOdontograma = [].concat(toothsOdontograma);
                    let validar = toothsOdontograma.filter(t => t.id === id && t.background === '#fff');
                    if (validar.length > 0) {
                        let indice = toothsOdontograma.findIndex((t, indice) => {
                            if (t.id === id) {
                                return true;
                            }
                        });
                        validar.forEach(tp => {
                            newOdontograma[indice] = {
                                id: tp.id,
                                pontico: tp.pontico,
                                background: material.color,
                                color_tercio_incisal: tp.color_tercio_incisal,
                                color_tercio_medial: tp.color_tercio_medial,
                                color_tercio_cervical: tp.color_tercio_cervical,
                                position: tp.position, positionPintado: tp.positionPintado
                            };
                        });
                        props.setDetalleOrden(newOdontograma);
                        setToothsOdontograma(newOdontograma);
                    }
                }
            } else {
                setAlertGeneral({ type: 'warning', text: 'Debe seleccionar un servicio y material', open: true });
                setTimeout(() => setAlertGeneral({ type: 'success', text: '', open: false }), 3000);
            }
        }
    }
    const clearOdontograma = () => {
        let newOdontograma = [].concat(toothsOdontograma);
        let pintadosTooth = newOdontograma.filter(tp => tp.background !== undefined && tp.background !== "#fff" || tp.color_tercio_incisal !== undefined && tp.color_tercio_incisal !== "" || tp.color_tercio_medial !== undefined && tp.color_tercio_medial !== "" || tp.color_tercio_cervical !== undefined && tp.color_tercio_cervical !== "");
        pintadosTooth.forEach(tp => {
            let indice = toothsOdontograma.findIndex((t, indice) => {
                if (t.id === tp.id) {
                    return true;
                }
            });
            newOdontograma[indice] = {
                id: tp.id,
                pontico: 0,
                background: "#fff",
                position: tp.position,
                color_tercio_incisal: '',
                color_tercio_medial: '',
                color_tercio_cervical: '',
            };
        });
        props.setOrden({ ...props.orden, presupuesto: 0 });
        props.setDetalleOrden(newOdontograma);
        setToothsOdontograma(newOdontograma);

    }
    const dibujar = (t, index) => {
        position = { x: radio * Math.cos(index * f) + cx, y: radio * 1.25 * Math.sin(index * f) + cy };
        return <Tooth key={index} radio={radio} clickTooth={() => toothClick(t.id)} toothProperty={t} position={position} sizeTooth={sizeTooth} />;

    }

    const recuperarDetalle = async (orden) => {
        const getOrdenesDetalle = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenDetalle.php?id=${orden.id}`);
        const response = await getOrdenesDetalle.json();
        let newOdontograma = [].concat(toothsOdontograma);
        const getmarial = await fetch(`${UrlsConst.URL_API}/compras/material/recuperarMaterial.php?id=` + orden.fk_cmp_material);
        const response_material = await getmarial.json();
        // let toothDetalle
        response.forEach(tp => {
            let indice = toothsOdontograma.findIndex((t, indice) => {
                if (t.id === Number(tp.pieza)) {
                    return true;
                }
            });
            newOdontograma[indice] = {
                id: Number(tp.pieza),
                background: response_material.color,
                pontico: tp.es_pontico,
                position: newOdontograma[indice].position
            };
        })
        props.setDetalleOrden(newOdontograma);
        setToothsOdontograma(newOdontograma);
    }
    const handleColorTooth = (e) => {
        const { value, name } = e.target;
        props.setOrden({ ...props.orden, [name]: value });
    }
    const resizeWindow = () => {
        if (props.detallePintar !== undefined || props.recuperarOrden !== undefined || props.view !== undefined) {
            setWidthC(document.querySelector('.contenedor_odontograma').offsetWidth);
        }
    }
    window.addEventListener('resize', resizeWindow);

    React.useEffect(() => {
        if (props.recuperarOrden !== undefined) {
            recuperarDetalle(props.recuperarOrden);
        }
        resizeWindow();
        pintarTooth();
    }, [props.detallePintar]);
    const pintarTooth = async () => {
        if (props.detallePintar !== undefined) {
            let newOdontograma = [].concat(toothsOdontograma);
            const detalle = props.detallePintar;
            if (detalle.length === 0) {
                setToothsOdontograma([
                    { id: 0 },
                    { id: 38, pontico: 0, background: '#fff', position: 'I' },
                    { id: 37, pontico: 0, background: '#fff', position: 'I' },
                    { id: 36, pontico: 0, background: '#fff', position: 'I' },
                    { id: 35, pontico: 0, background: '#fff', position: 'I' },
                    { id: 34, pontico: 0, background: '#fff', position: 'I' },
                    { id: 33, pontico: 0, background: '#fff', position: 'I' },
                    { id: 32, pontico: 0, background: '#fff', position: 'I' },
                    { id: 31, pontico: 0, background: '#fff', position: 'I' },
                    { id: 41, pontico: 0, background: '#fff', position: 'I' },
                    { id: 42, pontico: 0, background: '#fff', position: 'I' },
                    { id: 43, pontico: 0, background: '#fff', position: 'I' },
                    { id: 44, pontico: 0, background: '#fff', position: 'I' },
                    { id: 45, pontico: 0, background: '#fff', position: 'I' },
                    { id: 46, pontico: 0, background: '#fff', position: 'I' },
                    { id: 47, pontico: 0, background: '#fff', position: 'I' },
                    { id: 48, pontico: 0, background: '#fff', position: 'I' },
                    { id: 1 },
                    { id: 18, pontico: 0, background: '#fff', position: 'S' },
                    { id: 17, pontico: 0, background: '#fff', position: 'S' },
                    { id: 16, pontico: 0, background: '#fff', position: 'S' },
                    { id: 15, pontico: 0, background: '#fff', position: 'S' },
                    { id: 14, pontico: 0, background: '#fff', position: 'S' },
                    { id: 13, pontico: 0, background: '#fff', position: 'S' },
                    { id: 12, pontico: 0, background: '#fff', position: 'S' },
                    { id: 11, pontico: 0, background: '#fff', position: 'S' },
                    { id: 21, pontico: 0, background: '#fff', position: 'S' },
                    { id: 22, pontico: 0, background: '#fff', position: 'S' },
                    { id: 23, pontico: 0, background: '#fff', position: 'S' },
                    { id: 24, pontico: 0, background: '#fff', position: 'S' },
                    { id: 25, pontico: 0, background: '#fff', position: 'S' },
                    { id: 26, pontico: 0, background: '#fff', position: 'S' },
                    { id: 27, pontico: 0, background: '#fff', position: 'S' },
                    { id: 28, pontico: 0, background: '#fff', position: 'S' }
                ]);
            } else {
                let pintados = toothsOdontograma.filter(t => t.background !== undefined);
                pintados.forEach(p => {
                    let indice = toothsOdontograma.findIndex((t, indice) => {
                        if (t.id === p.id) {
                            return true;
                        }
                    });
                    newOdontograma[indice].background = "#fff";
                });
                detalle.forEach(d => {
                    let indice = toothsOdontograma.findIndex((t, indice) => {
                        if (t.id === Number(d.pieza)) {
                            return true;
                        }
                    });
                    newOdontograma[indice].background = d.color;
                })
                setToothsOdontograma(newOdontograma);
            }
        }
    }
    return (
        <div>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                    <Typography variant='h6'>ODONTOGRAMA</Typography>
                </Grid>
                <Grid item xs={12} className='contenedor_odontograma'>
                    {
                        toothsOdontograma.map((t, index) => dibujar(t, index))
                    }
                </Grid>
            </Grid>

            {
                props.view === 0 &&
                <Grid item xs={12} sx={{ textAlign: 'center', mb: 2, mt: 2 }}>
                    <Button variant="contained" color="error" startIcon={<CleaningServices />} onClick={clearOdontograma}>limpiar</Button>
                </Grid>
            }
            {
                props.view === 0 &&
                <Grid item md={12} xs={12} sx={{ p: 2 }}>
                    <div style={{ width: '200px', height: '190px', margin: 'auto' }}>
                        <div style={{ display: 'flex', width: '100%', height: '60px' }}>
                            <div style={{ borderBottom: '2px dashed #000', borderTop: '2px solid #000', borderLeft: '2px solid #000', width: '30px', borderTopLeftRadius: '100%' }}>
                            </div>
                            <div style={{ borderRight: '2px dashed #000', borderLeft: '2px dashed #000', borderBottom: '2px dashed #000', borderTop: '2px solid #000', width: '130px' }}>
                                <TextField name='color_tercio_incisal' label="C.T. INCISAL" size="small" sx={{ m: 1 }} inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 3 }} InputLabelProps={{
                                    shrink: true,
                                }} onChange={handleColorTooth} value={props.orden ? props.orden.color_tercio_incisal : ''} />
                            </div>
                            <div style={{ borderBottom: '2px dashed #000', borderTop: '2px solid #000', borderRight: '2px solid #000', width: '30px', borderTopRightRadius: '100%' }}>
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', height: '60px' }}>
                            <div style={{ borderBottom: '2px dashed #000', borderLeft: '2px solid #000', width: '30px' }}>
                            </div>
                            <div style={{ borderLeft: '2px dashed #000', borderBottom: '2px dashed #000', width: '130px' }}>
                                <TextField name='color_tercio_medial' label="C.T. MEDIAL" size="small" sx={{ m: 1 }} inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 3 }} InputLabelProps={{
                                    shrink: true,
                                }} onChange={handleColorTooth} value={props.orden ? props.orden.color_tercio_medial : ''} />
                            </div>
                            <div style={{ borderLeft: '2px dashed #000', borderBottom: '2px dashed #000', borderRight: '2px solid #000', width: '30px' }}>
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', height: '70px' }}>
                            <div style={{ borderRight: '0px solid #000', borderLeft: '2px solid #000', borderBottom: '2px solid #000', width: '30px', borderBottomLeftRadius: '100%' }}>
                            </div>
                            <div style={{ borderLeft: '2px dashed #000', borderBottom: '2px solid #000', width: '130px' }}>
                                <TextField name='color_tercio_cervical' label="C.T. CERVICAL" size="small" sx={{ m: 1, mt: 2 }} inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 3 }} InputLabelProps={{
                                    shrink: true,
                                }} onChange={handleColorTooth} value={props.orden ? props.orden.color_tercio_cervical : ''} />
                            </div>
                            <div style={{ borderLeft: '2px dashed #000', borderBottom: '2px solid #000', borderRight: '2px solid #000', width: '30px', borderBottomRightRadius: '100%' }}>
                            </div>
                        </div>
                    </div>
                </Grid>
            }
        </div>
    )
}
