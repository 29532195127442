import { Check, Close, Delete, Edit, HelpOutline, Paid, Search } from '@mui/icons-material';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import React from 'react';
import UrlsConst from '../../../config/config';
import DialogGasto from './dialogGasto';

export default function DialogManGastos() {
    const [search, setSearch] = React.useState('');
    const [dialogDelete, setDialogDelete] = React.useState(false);
    const [gastos, setGastos] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPage, setRowsPage] = React.useState(10);
    const [dialogGasto, setDialogGasto] = React.useState({
        show: false,
        textDialog: 'NUEVO',
        codigo: ''
    });
    const [alertGeneral, setAlertGeneral] = React.useState({
        show: false,
        message: '',
        severity: 'success'
    });
    //    handles
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowPage = (event) => {
        setRowsPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleSearch = (e) => {
        if (e.target.value !== "") {
            setSearch(e.target.value);
        } else {
            setSearch('');
            listarGastos();
        }
    }
    const handlekeyup = (e) => {
        if (search !== "" && e.which == 13) {
            searchGasto();
        }
    }
    // functions
    const listarGastos = async () => {
        const gastos_list = await fetch(`${UrlsConst.URL_API}/compras/gastos/listarGastos.php`);
        const response = await gastos_list.json();
        setGastos(response);
    }
    const eliminarGasto = async () => {
        const deltegasto = await fetch(`${UrlsConst.URL_API}/compras/gastos/eliminarGasto.php?codigo=${dialogGasto.codigo}`);
        const response = await deltegasto.json();
        if(response.executed){
            setAlertGeneral({ show: true, message: 'Gasto eliminado correctamente', severity: 'success' });
            setTimeout(() => setAlertGeneral({ show: false, severity: 'success', message: '' }), 2500);
        }else{
            setAlertGeneral({ show: true, message: 'ocurrio un error', severity: 'error' });
            setTimeout(() => setAlertGeneral({ show: false, severity: 'success', message: '' }), 2500);
        }
        setDialogDelete(false);
        listarGastos();
        setDialogGasto({
            show: false,
            textDialog: 'NUEVO',
            codigo: ''
        });
    
    }
    const searchGasto = async () => {
        if (search !== "") {
            const searchGasto = await fetch(`${UrlsConst.URL_API}/compras/gastos/listarGastosBusqueda.php?search=${search.toUpperCase()}`);
            const response = await searchGasto.json();
            setGastos(response);
        } else {
            listarGastos();
        }
    }
    React.useEffect(() => {
        listarGastos();
    }, []);
    return (
        <Grid container spacing={1}>
            <Snackbar open={alertGeneral.show} autoHideDuration={6000} onClose={() => setAlertGeneral({ ...alertGeneral, show: false, severity: 'success', message: '' })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={() => setAlertGeneral({ ...alertGeneral, show: false, severity: 'success', message: '' })} severity={alertGeneral.severity} sx={{ width: '100%' }}>
                    {alertGeneral.message}
                </Alert>
            </Snackbar>
            <Dialog open={dialogDelete} maxWidth="xs">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <HelpOutline style={{ color: '#fdb924', fontSize: '80px' }} />
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Eliminar Gasto</Typography>
                    ¿Esta seguro de eliminar el gasto?
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color="success" startIcon={<Check />} onClick={eliminarGasto}>ACEPTAR</Button>
                    <Button variant='contained' color="error" startIcon={<Close />} onClick={() => setDialogDelete(false)}>ACEPTAR</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialogGasto.show} maxWidth="xs">
                <DialogTitle>{dialogGasto.textDialog} GASTO</DialogTitle>
                <DialogContent>
                    <DialogGasto closeDialog={() => setDialogGasto({ ...dialogGasto, show: false, textDialog: 'NUEVO', codigo: '' })} listarGatos={listarGastos} codigo={dialogGasto.codigo} setAlertGeneral={setAlertGeneral} />
                </DialogContent>
            </Dialog>
            <Grid item md={6}>
                <TextField label="BUSCAR GASTO" fullWidth margin='normal' inputProps={{ style: { textTransform: 'uppercase' } }} onChange={handleSearch} onKeyUp={handlekeyup} />
            </Grid>
            <Grid item md={2} sx={{ mt: 3 }}>
                <Button fullWidth variant='contained' color="primary" startIcon={<Search />} onClick={searchGasto}>BUSCAR</Button>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={3} sx={{ mt: 3 }}>
                <Button fullWidth variant='contained' color="success" startIcon={<Paid />} onClick={() => setDialogGasto({ ...dialogGasto, show: true, textDialog: 'NUEVO', codigo: '' })}>NUEVO GASTO</Button>
            </Grid>
            <Grid item md={12}>
                <Paper>
                    <TableContainer>
                        <Table aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>CODIGO</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>NOMBRE</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (rowsPage > 0
                                        ? gastos.slice(page * rowsPage, page * rowsPage + rowsPage)
                                        : gastos
                                    ).map((gasto) => (
                                        <TableRow
                                            key={gasto.codigo}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {gasto.codigo}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {gasto.nombre}
                                            </TableCell>
                                            <TableCell>{gasto.descripcion}</TableCell>
                                            <TableCell>
                                                <Tooltip title="EDITAR">
                                                    <IconButton aria-label="delete" size="small" onClick={() => setDialogGasto({ ...dialogGasto, show: true, textDialog: 'EDITAR', codigo: gasto.codigo })}>
                                                        <Edit color="primary" fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="ELIMINAR">
                                                    <IconButton aria-label="delete" size="small" onClick={() => { setDialogGasto({ ...dialogGasto, show: false, textDialog: 'NUEVO', codigo: gasto.codigo }); setDialogDelete(true) }}>
                                                        <Delete color="error" fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        labelRowsPerPage="Filas por columnas"
                        count={gastos.length}
                        rowsPerPage={rowsPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowPage}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}
