import * as React from 'react';
import {
    Alert, Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Link,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import UrlsConst from '../../../config/config';

import { LoadingButton } from '@mui/lab';
// iconos 
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

export default function ProfesionalPaciente(props) {
    // constantes
    const [maxlength, setMaxlength] = React.useState(0);
    const [rowsT, setRowsT] = React.useState([]);
    const [openPaciente, setOpenPaciente] = React.useState(false);
    const [openValidation, setOpenVError] = React.useState(false);
    const [openVS, setOpenVS] = React.useState(false);
    const [openW, setOpenW] = React.useState(false);
    const [idPacienteR, setIdpacienteR] = React.useState(0);
    const [textAlert, setTextAlert] = React.useState('');
    const [openVP, setOpenVP] = React.useState(false);
    const [validarDocumento, setValidarDocumento] = React.useState(0);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [alertProfesionalE, setAlertProfesionalE] = React.useState(false);
    const [numeroPaciente, setNumeroPaciente] = React.useState({
        id: 0,
        apellidos_nombres: '',
        fk_com_tipo_documento: '01',
        numero_documento: '',
        observaciones: ''
    });
    // const validaciones profesional
    const [apellidosProfesional, setApellidosProfesional] = React.useState({
        error: false,
        text: ''
    });
    const [documentoProfesional, setDocumentoProfesional] = React.useState({
        error: false,
        text: ''
    });
    const [telefonoProfesional, setTelefonoProfesional] = React.useState({
        error: false,
        text: ''
    });
    // validaciones paciente
    const [apellidosPaciente, setApellidosPaciente] = React.useState({
        error: false,
        text: ''
    });
    const [documentoPaciente, setDocumentoPaciente] = React.useState({
        error: false,
        text: ''
    });
    // alerta
    const [alertGeneral, setAlertGeneral] = React.useState({
        open: false,
        text: '',
        type: 'error'
    });
    // document obj
    const [documento, setDocumento] = React.useState([{
        codigo: '',
        descripcion_corta: '',
        descripcion_larga: '',
        longitud: 0,
        tipo: '',
        contribuyente: '',
        longitud_exacta: 0
    }]);
    // profesional function
    const [profesionalSubmit, setProfesionalSubmit] = React.useState(
        {
            id: 0,
            colegiatura: '',
            apellidos_nombres: '',
            numero_telefono: '',
            direccion: '',
            observaciones: '',
            fk_com_tipo_documento: '01',
            numero_documento: ''
        }
    );
    const [pacienteSubmit, setPacienteSubmit] = React.useState(
        {
            id: 0,
            apellidos_nombres: '',
            fk_com_tipo_documento: '01',
            numero_documento: '',
            observaciones: ''
        }
    );
    React.useEffect(() => {
        tipoDocumento();
        if (props.fntIdprofesional != undefined) {
            recuperarProfesional();
            setMaxlength(15);
        } else {
            setMaxlength(8);
            recuperarPacienteInicial();
        }
    }, []);
    const recuperarPacienteInicial = async () => {
        const paciente = await fetch(`${UrlsConst.URL_API}/ventas/paciente/recuperarPaciente.php?id=1`, {
            method: "GET"
        });
        const response = await paciente.json();
        setRowsT([response]);
    }
    const recuperarProfesional = async () => {
        const profesionalGet = await fetch(`${UrlsConst.URL_API}/ventas/profesional/recuperarProfesional.php?id=` + props.fntIdprofesional, {
            method: "GET"
        });
        const response = await profesionalGet.json();
        if (response.fk_com_tipo_documento === "00") {
            setMaxlength(15);
        }
        if (response.fk_com_tipo_documento === "01") {
            setMaxlength(8);
        }
        if (response.fk_com_tipo_documento === "04") {
            setMaxlength(12);
        }
        if (response.fk_com_tipo_documento === "06") {
            setMaxlength(11);
        }
        if (response.fk_com_tipo_documento === "07") {
            setMaxlength(12);
        }
        if (response.fk_com_tipo_documento === "11") {
            setMaxlength(15);
        }
        const pacientesGet = await fetch(`${UrlsConst.URL_API}/ventas/profesional/listarPacienteXProfesional.php?id=` + props.fntIdprofesional, {
            method: "GET"
        });
        const response_pacientes = await pacientesGet.json();
        setProfesionalSubmit(response);
        setRowsT(response_pacientes);
    }
    console.log(maxlength);
    const tipoDocumento = async () => {
        const documentos_tipo = await fetch(`${UrlsConst.URL_API}/sistema/listarDocumento.php`, {
            method: "POST"
        });
        const json = await documentos_tipo.json();
        setDocumento(json);
    }
    const documentSelectProfesional = (e, values) => {
        if (values.props.value === "00") {
            setDocumentoProfesional({ ...documentoProfesional, error: false, text: '' });
        }
        setMaxlength(values.props.loguitud);
        setProfesionalSubmit({ ...profesionalSubmit, fk_com_tipo_documento: values.props.value, numero_documento: '' });
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const pacienteAddProfesional = () => {
        setOpenPaciente(true);
        setMaxlength(8);
        setPacienteSubmit({ ...pacienteSubmit, id: 0 });
        setApellidosPaciente({ ...pacienteAddProfesional, error: false, text: '' });
        setDocumentoPaciente({ ...documentoPaciente, error: false, text: '' });
    };
    const handleChangePaciente = (e) => {
        const { name, value } = e.target;
        setPacienteSubmit({ ...pacienteSubmit, [name]: value });
    }
    const handleClose = () => {
        setOpenPaciente(false);
        setPacienteSubmit({
            id: 0,
            apellidos_nombres: '',
            fk_com_tipo_documento: '01',
            numero_documento: '',
            observaciones: ''
        });
    }
    const documentSelectPaciente = (e, values) => {
        const documento = values.props;
        if (documento.value === "00") {
            setValidarDocumento(1);
            setDocumentoPaciente({ ...documentoPaciente, error: false, text: '' });
        } else {
            setValidarDocumento(0);
        }
        setMaxlength(documento.loguitud);
        setPacienteSubmit({ ...pacienteSubmit, fk_com_tipo_documento: documento.value, numero_documento: '' });
    }

    const validarDocumentoPaciente = async () => {
        if (pacienteSubmit.tipoDocumento != "" && pacienteSubmit.numero_documento != "") {
            const validarD = await fetch(`${UrlsConst.URL_API}/ventas/paciente/validarPacienteUI.php`, {
                method: "POST",
                body: JSON.stringify(pacienteSubmit),
            });
            const response = await validarD.json();
            if (response.existe !== false) {
                setIdpacienteR(response.id);
                setOpenVError(true);
                setTimeout(() => { setOpenVError(false) }, 3000);
                setValidarDocumento(0);
            } else {
                setOpenW(false);
                setTextAlert('Este documento no se encuentra registrado para un paciente');
                setOpenVS(true);
                setTimeout(() => { setOpenVS(false) }, 2500);
                setValidarDocumento(1);
                setDocumentoPaciente({ ...documentoPaciente, error: false, text: '' });
            }
        } else {
            setTextAlert('Deberia ingresar primero un paciente');
            setOpenW(true);
            setTimeout(() => { setOpenW(false) }, 2500);
        }
    }
    const recuperarPaciente = async () => {
        const pacienteRecuperado = await fetch(`${UrlsConst.URL_API}/ventas/paciente/recuperarPaciente.php?id=` + idPacienteR, {
            method: "POST"
        });
        const response = await pacienteRecuperado.json();
        setPacienteSubmit(response);
        setAlertGeneral({ ...alertGeneral, type: 'success', text: 'Datos cargados correctamente', open: true });
        setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
        setValidarDocumento(1);
    }
    // validaciones formulario 
    const addTablePaciente = () => {
        if (pacienteSubmit.apellidos_nombres === "" || (pacienteSubmit.numero_documento === "" && pacienteSubmit.fk_com_tipo_documento !== "00")) {
            if (pacienteSubmit.apellidos_nombres === "") {
                setApellidosPaciente({ ...apellidosPaciente, error: true, text: 'Nombres y apellidos obligatorio' });
            }
            if (pacienteSubmit.numero_documento === "" && pacienteSubmit.fk_com_tipo_documento !== "00") {
                setDocumentoPaciente({ ...documentoPaciente, error: true, text: 'Documento obligatorio' });
            }
        } else {
            if (validarDocumento !== 0) {
                if (rowsT.length === 0) {
                    const rows = [].concat(rowsT);
                    rows.push({
                        id: pacienteSubmit.id,
                        apellidos_nombres: pacienteSubmit.apellidos_nombres.toUpperCase(),
                        fk_com_tipo_documento: pacienteSubmit.fk_com_tipo_documento.toUpperCase(),
                        numero_documento: pacienteSubmit.numero_documento.toUpperCase(),
                        observaciones: pacienteSubmit.observaciones.toUpperCase()
                    });
                    setRowsT(rows);
                } else {
                    if (pacienteSubmit.fk_com_tipo_documento === "00") {
                        const rows = [].concat(rowsT);
                        rows.push({
                            id: pacienteSubmit.id,
                            apellidos_nombres: pacienteSubmit.apellidos_nombres.toUpperCase(),
                            fk_com_tipo_documento: pacienteSubmit.fk_com_tipo_documento.toUpperCase(),
                            numero_documento: pacienteSubmit.numero_documento.toUpperCase(),
                            observaciones: pacienteSubmit.observaciones.toUpperCase()
                        });
                        setRowsT(rows);
                        setAlertGeneral({ ...alertGeneral, type: 'success', text: 'El paciente agregado correctamente', open: true });
                        setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
                    } else {
                        let validar = rowsT.filter((paciente) => paciente.numero_documento === pacienteSubmit.numero_documento);
                        if (validar.length > 0) {
                            setAlertGeneral({ ...alertGeneral, type: 'error', text: 'El paciente ya fue agregado', open: true });
                            setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
                        } else {
                            const rows = [].concat(rowsT);
                            rows.push({
                                id: pacienteSubmit.id,
                                apellidos_nombres: pacienteSubmit.apellidos_nombres.toUpperCase(),
                                fk_com_tipo_documento: pacienteSubmit.fk_com_tipo_documento.toUpperCase(),
                                numero_documento: pacienteSubmit.numero_documento.toUpperCase(),
                                observaciones: pacienteSubmit.observaciones.toUpperCase()
                            });
                            setRowsT(rows);
                            setAlertGeneral({ ...alertGeneral, type: 'success', text: 'El paciente agregado correctamente', open: true });
                            setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
                        }
                    }
                }
                setOpenPaciente(false);
                setValidarDocumento(0);
                setPacienteSubmit({
                    id: 0,
                    apellidos_nombres: '',
                    fk_com_tipo_documento: '01',
                    numero_documento: '',
                    observaciones: ''
                });
            } else {
                setTextAlert('Primero deberia validar el documento');
                setOpenW(true);
                setTimeout(() => { setOpenW(false) }, 2500);
            }
        }
    }
    const setFetchPacientexProfesional = async () => {
        if (profesionalSubmit.apellidos_nombres === "" || (profesionalSubmit.numero_documento === "" && profesionalSubmit.fk_com_tipo_documento !== "00")) {
            if (profesionalSubmit.apellidos_nombres === "") {
                setApellidosProfesional({ ...apellidosProfesional, error: true, text: 'Nombres y apellidos obligatorio' });
            }
            if (profesionalSubmit.numero_documento === "" && profesionalSubmit.fk_com_tipo_documento !== "00") {
                setDocumentoProfesional({ ...documentoProfesional, error: true, text: 'Documento obligatorio' });
            }
        } else {
            setLoading(true);
            const profesional = await fetch(`${UrlsConst.URL_API}/ventas/profesional/guardarProfesional.php`, {
                method: "POST",
                body: JSON.stringify({
                    id: profesionalSubmit.id,
                    colegiatura: profesionalSubmit.colegiatura.toUpperCase(),
                    apellidos_nombres: profesionalSubmit.apellidos_nombres.toUpperCase(),
                    numero_telefono: profesionalSubmit.numero_telefono,
                    direccion: profesionalSubmit.direccion.toUpperCase(),
                    observaciones: profesionalSubmit.observaciones.toUpperCase(),
                    fk_com_tipo_documento: profesionalSubmit.fk_com_tipo_documento,
                    numero_documento: profesionalSubmit.numero_documento.toUpperCase()
                }),
            });
            const response = await profesional.json();
            let responsePacientes = [];
            if (profesionalSubmit.id === 0) {
                const deletePacientesProfesional = await fetch(`${UrlsConst.URL_API}/ventas/profesional/eliminarTodosPacientes.php?id=` + response.id, {
                    method: "POST",
                });
                const pacixProfDelete = await deletePacientesProfesional.json();
            }
            const pacientesGet = await fetch(`${UrlsConst.URL_API}/ventas/profesional/listarPacienteXProfesional.php?id=` + response.id, {
                method: "GET"
            });
            const response_pacientes = await pacientesGet.json();
            if (response_pacientes.length > 0) {
                const idsNoPermitidos = response_pacientes.map(doc => doc.id);
                const datosFiltrados = rowsT.filter(doc => !idsNoPermitidos.includes(doc.id))
                datosFiltrados.forEach(async (pac) => {
                    const pacientesFetch = await fetch(`${UrlsConst.URL_API}/ventas/paciente/guardarPaciente.php`, {
                        method: "POST",
                        body: JSON.stringify(pac),
                    });
                    const responsePac = await pacientesFetch.json();
                    const pacienteProfInsert = await fetch(`${UrlsConst.URL_API}/ventas/profesional/guardarPacienteProfesional.php`, {
                        method: "POST",
                        body: JSON.stringify({ fk_ven_profesional: response.id, fk_ven_paciente: responsePac.id }),
                    });
                    const responseSavePacxProf = await pacienteProfInsert.json();
                });
                const eliminados = rowsT.map(doc => doc.id);
                const datosEliminados = response_pacientes.filter(doc => !eliminados.includes(doc.id));
                datosEliminados.forEach(async (item) => {
                    const pacienteProfInsert = await fetch(`${UrlsConst.URL_API}/ventas/profesional/eliminarProfesionalPaciente.php?fk_ven_profesional=${response.id}&fk_ven_paciente=${item.id}`, {
                        method: "GET"
                    });
                    const responseSavePacxProf = await pacienteProfInsert.json();
                });
            } else {
                if (rowsT.length > 0) {
                    for (let i = 0; i < rowsT.length; i++) {
                        if (rowsT[i].id === 0) {
                            const pacientesFetch = await fetch(`${UrlsConst.URL_API}/ventas/paciente/guardarPaciente.php`, {
                                method: "POST",
                                body: JSON.stringify(rowsT[i]),
                            });
                            const response = await pacientesFetch.json();
                            responsePacientes.push(response.id);
                        } else {
                            responsePacientes.push(rowsT[i].id);
                        }
                    }
                    let pacientePro = [];
                    for (let i = 0; i < responsePacientes.length; i++) {
                        pacientePro.push({ fk_ven_profesional: response.id, fk_ven_paciente: responsePacientes[i] });
                    }
                    pacientePro.forEach(async (pacienteProf) => {
                        const pacienteProfInsert = await fetch(`${UrlsConst.URL_API}/ventas/profesional/guardarPacienteProfesional.php`, {
                            method: "POST",
                            body: JSON.stringify(pacienteProf),
                        });
                        const responseSavePacxProf = await pacienteProfInsert.json();
                    });
                }
            }
            if (props.setNewProfesionalPaci != undefined) {
                props.setNewProfesionalPaci(response.id);
                const profesionalGet = await fetch(`${UrlsConst.URL_API}/ventas/profesional/recuperarProfesional.php?id=` + response.id, {
                    method: "GET"
                });
                const response_pro = await profesionalGet.json();
                props.setProfesional(response_pro);
                props.listarPaciXProfesional(response_pro.id);
            }
            if (props.fntIdprofesional != undefined) {
                props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'Se actualizo correctamente el profesional', open: true });
                setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
            } else {
                props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'Se guardo correctamente el profesional', open: true });
                setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
            }
            if (props.fntClose != undefined) {
                props.fntClose();
                props.fntProfesionales();
            }
        }
    }
    const deletePaciente = () => {
        if (numeroPaciente.fk_com_tipo_documento === "00") {
            const newRowPaciente = rowsT.filter((item) => item.apellidos_nombres !== numeroPaciente.apellidos_nombres);
            setRowsT(newRowPaciente);
        } else {
            const newRowPaciente = rowsT.filter((item) => item.numero_documento !== numeroPaciente.numero_documento);
            setRowsT(newRowPaciente);
        }
        setAlertGeneral({ ...alertGeneral, type: 'success', text: 'Paciente eliminado correctamente', open: true });
        setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
        setOpenDelete(false)
    };
    const handleProfesional = (e) => {
        const { value, name } = e.target;
        setProfesionalSubmit({ ...profesionalSubmit, [name]: value });
    }

    return (
        <>
            <Snackbar open={alertGeneral.open} autoHideDuration={6000} onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity={alertGeneral.type} sx={{ width: '100%' }}>
                    {alertGeneral.text}
                </Alert>
            </Snackbar>
            <Collapse in={alertProfesionalE}>
                <Alert variant="outlined" severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertProfesionalE(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {textAlert}
                </Alert>
            </Collapse>
            <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                    <TextField
                        fullWidth
                        required
                        id="apellidos_nombres_prof"
                        error={apellidosProfesional.error}
                        name='apellidos_nombres'
                        variant="standard"
                        label="NOMBRES Y APELLIDOS"
                        inputProps={{ maxLength: 400, style: { textTransform: "uppercase" } }}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (value != "") {
                                setApellidosProfesional({ ...apellidosProfesional, error: false, text: '' });
                                setProfesionalSubmit({ ...profesionalSubmit, apellidos_nombres: value });
                            } else {
                                setApellidosProfesional({ ...apellidosProfesional, error: true, text: 'Nombres y apellidos obligatorio' });
                                setProfesionalSubmit({ ...profesionalSubmit, apellidos_nombres: '' });
                            }
                        }}
                        value={profesionalSubmit ? profesionalSubmit.apellidos_nombres : ''}
                        helperText={apellidosProfesional.text}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextField
                        fullWidth
                        name="colegiatura"
                        variant="standard"
                        label="COLEGIATURA"
                        className='textfield'
                        inputProps={{ maxLength: 20, style: { textTransform: "uppercase" } }}
                        value={profesionalSubmit ? profesionalSubmit.colegiatura : ''}
                        onChange={handleProfesional}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextField
                        style={{ textAlign: 'start' }}
                        select
                        fullWidth
                        value={profesionalSubmit ? profesionalSubmit.fk_com_tipo_documento : ''}
                        label="TIPO DE DOCUMENTO"
                        variant='standard'
                        onChange={documentSelectProfesional}
                    >
                        {documento.map((option) => (
                            <MenuItem key={option.codigo} value={option.codigo} loguitud={option.longitud}>
                                {option.descripcion_larga}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextField
                        required
                        error={documentoProfesional.error}
                        fullWidth
                        variant="standard"
                        name='numero_documento'
                        label="DOCUMENTO"
                        inputProps={{ maxLength: maxlength, style: { textTransform: "uppercase" } }}
                        value={profesionalSubmit ? profesionalSubmit.numero_documento : ''}
                        helperText={documentoProfesional.text}
                        onChange={(e) => {
                            const { value } = e.target;
                            if ((value !== "" && profesionalSubmit.fk_com_tipo_documento === "00") || (value !== "" && profesionalSubmit.fk_com_tipo_documento !== "00")) {
                                if (value !== "" && pacienteSubmit.fk_com_tipo_documento !== "00") {
                                    setProfesionalSubmit({ ...profesionalSubmit, numero_documento: value });
                                    setDocumentoProfesional({ ...documentoProfesional, error: false, text: '' });
                                }
                                if (value !== "" && profesionalSubmit.fk_com_tipo_documento === "00") {
                                    setProfesionalSubmit({ ...profesionalSubmit, numero_documento: value });
                                    setDocumentoProfesional({ ...documentoProfesional, error: false, text: '' });
                                }
                            } else {
                                if (value === "" && profesionalSubmit.fk_com_tipo_documento !== "00") {
                                    setProfesionalSubmit({ ...profesionalSubmit, numero_documento: '' });
                                    setDocumentoProfesional({ ...documentoProfesional, error: true, text: 'Documento obligatorio' });
                                }
                                if (value === "" && profesionalSubmit.fk_com_tipo_documento === "00") {
                                    setProfesionalSubmit({ ...profesionalSubmit, numero_documento: '' });
                                    setDocumentoProfesional({ ...documentoProfesional, error: false, text: '' });
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextField
                        fullWidth
                        variant="standard"
                        name='numero_telefono'
                        label="TELEFONO"
                        inputProps={{ maxLength: 20 }}
                        value={profesionalSubmit ? profesionalSubmit.numero_telefono : ''}
                        onChange={(e) => {
                            if (e.target.value !== "") {
                                if (isNaN(e.target.value) != true) {
                                    setProfesionalSubmit({ ...profesionalSubmit, numero_telefono: e.target.value });
                                } else {
                                    setTelefonoProfesional({ ...telefonoProfesional, error: true, text: 'Telefono no puede ser un texto' });
                                }
                            } else {
                                setProfesionalSubmit({ ...profesionalSubmit, numero_telefono: '' });
                                setTelefonoProfesional({ ...telefonoProfesional, error: false, text: '' });
                            }

                        }}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        fullWidth
                        required
                        multiline
                        rows={2}
                        name="direccion"
                        label="DIRECCION"
                        inputProps={{ maxLength: 250, style: { textTransform: "uppercase" } }}
                        value={profesionalSubmit ? profesionalSubmit.direccion : ''}
                        onChange={handleProfesional}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        fullWidth
                        name="observaciones"
                        label="OBSERVACIONES"
                        multiline
                        rows={2}
                        inputProps={{ maxLength: 200, style: { textTransform: "uppercase" } }}
                        onChange={handleProfesional}
                        value={profesionalSubmit ? profesionalSubmit.observaciones : ''}
                    />
                </Grid>
            </Grid>
            <div style={{ textAlign: 'center', margin: '12px' }}>
                <Button startIcon={<AddIcon />} variant="outlined" color="primary" onClick={pacienteAddProfesional}>
                    Agregar Paciente
                </Button>
            </div>
            <div style={{ marginBottom: '8px' }} className="noneDatable">
                <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 350 }}  >
                    <Table aria-label="simple table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>PACIENTE</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>DOCUMENTO</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>OBSERVACIONES</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (rowsPerPage > 0
                                    ? rowsT.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rowsT
                                ).map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>
                                            {row.apellidos_nombres}
                                        </TableCell>
                                        <TableCell >
                                            {row.numero_documento}
                                        </TableCell>
                                        <TableCell>{row.observaciones}</TableCell>
                                        <TableCell>
                                            {
                                                row.id === 1 ? '' :
                                                    <IconButton aria-label="delete" size="small" onClick={() => { setOpenDelete(true); setNumeroPaciente(row) }}>
                                                        <DeleteIcon color="error" fontSize="small" />
                                                    </IconButton>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    labelRowsPerPage="Filas por columnas"
                    count={rowsT.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
            <LoadingButton
                onClick={setFetchPacientexProfesional}
                startIcon={<CheckIcon />}
                loading={loading}
                loadingPosition="start"
                variant="outlined"
                color="success"
            >
                {props.fntIdprofesional != undefined ? 'ACTUALIZAR' : 'GUARDAR'}
            </LoadingButton>
            <Dialog open={openPaciente} onClose={handleClose} fullWidth={true} maxWidth="md">
                <DialogTitle>NUEVO PACIENTE</DialogTitle>
                <DialogContent>
                    <Collapse in={openW}>
                        <Alert variant="outlined" severity="warning"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenW(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {textAlert}
                        </Alert>
                    </Collapse>
                    <Collapse in={openVP}>
                        <Alert variant="outlined" severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenVP(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {textAlert}
                        </Alert>
                    </Collapse>
                    <Collapse in={openVS}>
                        <Alert variant="outlined"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenVS(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {textAlert}
                        </Alert>
                    </Collapse>
                    <Collapse in={openValidation}>
                        <Alert variant="outlined" severity="warning"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenVError(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            Ya se encuentra agregado un paciente con ese documento <Link component="button" variant="body2" onClick={recuperarPaciente}>Agregar Paciente</Link>
                        </Alert>
                    </Collapse>
                    <TextField
                        label="NOMBRES Y APELLIDOS"
                        error={apellidosPaciente.error}
                        required
                        fullWidth
                        variant="standard"
                        id="apellidos_nombres_p"
                        margin='normal'
                        name='apellidos_nombres'
                        value={pacienteSubmit ? pacienteSubmit.apellidos_nombres : ''}
                        helperText={apellidosPaciente.text}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (value != "") {
                                setPacienteSubmit({ ...pacienteSubmit, apellidos_nombres: value });
                                setApellidosPaciente({ ...apellidosPaciente, error: false, text: '' });
                            } else {
                                setPacienteSubmit({ ...pacienteSubmit, apellidos_nombres: '' });
                                setApellidosPaciente({ ...apellidosPaciente, error: true, text: 'Nombres  y apellidos obligatorio' });
                            }
                        }}
                    />
                    <Grid container spacing={1}>
                        <Grid item md={5} xs={12}>
                            <TextField
                                style={{ textAlign: 'start' }}
                                select
                                margin='normal'
                                fullWidth
                                value={pacienteSubmit.fk_com_tipo_documento}
                                label="TIPO DE DOCUMENTO"
                                variant='standard'
                                onChange={documentSelectPaciente}
                            >
                                {documento.map((option) => (
                                    <MenuItem key={option.codigo} value={option.codigo} loguitud={option.longitud}>
                                        {option.descripcion_larga}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item md={pacienteSubmit.fk_com_tipo_documento != "00" ? 5 : 7} xs={12}>
                            <TextField
                                fullWidth
                                required
                                error={documentoPaciente.error}
                                name="numero_documento"
                                margin='normal'
                                label="DOCUMENTO"
                                variant="standard"
                                value={pacienteSubmit ? pacienteSubmit.numero_documento : ''}
                                inputProps={{ maxLength: maxlength, style: { textTransform: "uppercase" } }}
                                helperText={documentoPaciente.text}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if ((value !== "" && pacienteSubmit.fk_com_tipo_documento === "00") || (value !== "" && pacienteSubmit.fk_com_tipo_documento !== "00")) {
                                        if (value !== "" && pacienteSubmit.fk_com_tipo_documento !== "00") {
                                            setPacienteSubmit({ ...pacienteSubmit, numero_documento: value });
                                            setDocumentoPaciente({ ...documentoPaciente, error: false, text: '' });
                                        }
                                        if (value !== "" && pacienteSubmit.fk_com_tipo_documento === "00") {
                                            setPacienteSubmit({ ...pacienteSubmit, numero_documento: value });
                                            setDocumentoPaciente({ ...documentoPaciente, error: false, text: '' });
                                        }
                                    } else {
                                        if (value === "" && pacienteSubmit.fk_com_tipo_documento !== "00") {

                                            setPacienteSubmit({ ...pacienteSubmit, numero_documento: '' });
                                            setDocumentoPaciente({ ...documentoPaciente, error: true, text: 'Documento obligatorio' });
                                        }
                                        if (value === "" && pacienteSubmit.fk_com_tipo_documento === "00") {
                                            setPacienteSubmit({ ...pacienteSubmit, numero_documento: '' });
                                            setDocumentoPaciente({ ...documentoPaciente, error: false, text: '' });
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        {
                            pacienteSubmit.fk_com_tipo_documento != "00" &&
                            <Grid item md={2} xs={12} style={{ marginTop: '25px' }}>
                                <Button
                                    onClick={validarDocumentoPaciente}
                                    variant="outlined"
                                    color={validarDocumento != 0 ? 'success' : 'error'}
                                    startIcon={validarDocumento != 0 ? <CheckCircleOutlineIcon /> : <CloseIcon />}>
                                    {validarDocumento != 0 ? ' Verificado' : ' Verificar'}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                    <TextField
                        fullWidth
                        label="OBSERVACIONES"
                        name='observaciones'
                        margin='normal'
                        multiline
                        rows={2}
                        maxRows={4}
                        inputProps={{ maxLength: 200, style: { textTransform: "uppercase" } }}
                        value={pacienteSubmit ? pacienteSubmit.observaciones : ''}
                        onChange={handleChangePaciente}
                    />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} variant="outlined" color="success" onClick={addTablePaciente}>Agregar paciente</Button>
                    <Button startIcon={<CloseIcon />} variant="outlined" color="error" onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDelete} onClose={() => setOpenDelete(false)} maxWidth="md">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Eliminar Paciente</Typography>
                    ¿Esta seguro de eliminar el paciente?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button variant="contained" color="error" onClick={deletePaciente}>Aceptar</Button>
                    <Button variant="contained" color="primary" onClick={() => setOpenDelete(false)}>CANCELAR</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
