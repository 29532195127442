import * as React from 'react';
// mui
import { Alert, Avatar, Button, Card, Checkbox, CardContent, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, MenuItem, Paper, TextField, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Tooltip, Snackbar, Chip, FormControlLabel, BottomNavigationAction } from '@mui/material';
// react pdf
import { Page, Text, Image, Document, StyleSheet, View, PDFDownloadLink, PDFViewer, Font } from "@react-pdf/renderer";
import pdf from '../../img/pdf.png'
import DeleteIcon from '@mui/icons-material/Delete';
// url base 
import UrlsConst from '../../config/config';
import Logo from '../../img/logoReporte.png';
// componente de table 
import LockIcon from '@mui/icons-material/Lock';
// iconos 
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import BadgeIcon from '@mui/icons-material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import { LoadingButton } from '@mui/lab';
import PaymentsIcon from '@mui/icons-material/Payments';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ExportExcel from './reportesCaja/export-excel';
import ExportPdf from './reportesCaja/export-pdf';
import { AccountCircle, AttachMoney, CalendarMonth, Check, ViewWeek, Close, DoDisturb, LocationOn, Person, PersonPinCircle, Search, SwitchAccount, Undo } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { blue } from '@mui/material/colors';
import DialogCajaChica from './dialogCajaChica';
export default function Cajas() {
    const fecha = new Date();
    const mes = fecha.getMonth() + 1;
    const mesActual = mes <= 9 ? '0' + mes : mes;
    const dia = fecha.getDate() <= 9 ? '0' + fecha.getDate() : fecha.getDate();
    const fechahoy = `${fecha.getFullYear()}-${mesActual}-${dia}`;
    // array tipo de pago
    const tipo_pagos = [
        { id: 'D', name: 'DEPOSITO' },
        { id: 'T', name: 'TARJETA' },
        { id: 'E', name: 'EFECTIVO' },
        { id: 'Y', name: 'YAPE' }
    ];
    //const
    const [dialogCajaChica, setDialogCajaChica] = React.useState(false);
    const [dialogCajaCerrada, setDialogCajaCerrada] = React.useState(false);
    const [openCaja, setOpenCaja] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [alertPago, setAlertPago] = React.useState(false);
    const [openCajaCerrar, setOpenCajaCerrar] = React.useState(false);
    const [alerta, setAlerta] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [typeAlert, setTypeAlert] = React.useState('error');
    const [textAlert, setTextAlert] = React.useState('');
    const [openProfesional, setOpenProfesional] = React.useState(false);
    const [profesionales, setProfesionales] = React.useState([]);
    const [pagoOpen, setPagoOpen] = React.useState(false);
    const [ordenes, setOrdenes] = React.useState([]);
    const [totalPago, setTotalPago] = React.useState(0);
    const [ordenesPagadas, setOrdenesPagadas] = React.useState([]);
    const [ordenesPagos, setOrdenesPagos] = React.useState(false);
    const [pagar, setPagar] = React.useState([]);
    const [color, setColor] = React.useState('');
    const [dialogCajaAbierta, setDialogCajaAbierta] = React.useState(false);
    const [cajaRecuperada, setCajaRecuperada] = React.useState({
        id: 0,
        fecha_apertura: '',
        hora_apertura: '',
        fecha_cierre: '',
        hora_cierre: '',
        fk_log_usuario_creado_por: ''
    });
    const [cajaCobranzaSubmit, setCajaCobranzaSubmit] = React.useState({
        id: 0,
        fk_log_usuario_creado_por: '',
        fecha_apertura: '',
        hora_apertura: '',
        fecha_cierre: '',
        hora_cierre: ''
    });
    const [cajAbierta, setCajAbierta] = React.useState({
        id: 0,
        fk_log_usuario_creado_por: '',
        fecha_apertura: '',
        hora_apertura: '',
        fecha_cierre: '',
        hora_cierre: ''
    });
    const [pagoSubmit, setPagoSubmit] = React.useState({
        id: 0,
        fk_ven_caja_cobranza: 0,
        fk_ven_orden: 0,
        fecha_registro: new Date().toJSON().slice(0, 10),
        hora_registro: '',
        monto: 0,
        tipo: 'E',
        referencia: ''
    });
    const [dlgSearchCodigoBarra, setDlgSearchCodigoBarra] = React.useState(false);
    const [searchCodigoBarra, setSearchCodigoBarra] = React.useState({ id: 0 });
    const [profesional, setProfesional] = React.useState({
        id: 0,
        apellidos_nombres: '',
        colegiatura: '',
        direccion: '',
        numero_documento: ''
    });
    const [profesionalSearch, setProfesionalSearch] = React.useState({
        numero_documento: '',
        apellidos_nombres: ''
    });
    const [pagado, setPagado] = React.useState(0);
    const [totalCajaChica, setTotalCajaChica] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [alertGeneral, setAlertGeneral] = React.useState(false);
    const [alertGeneralNew, setAlertGeneralNew] = React.useState({
        open: false,
        type: "warning",
        text: "",
    });
    const [cajaChicaRecuperada, setCajaChicaRecuperada] = React.useState({
        id: 0,
        fk_ven_caja_cobranza: '',
        saldo_inicial: 0,
        total_recarga: 0,
        saldo_anterior: 0,
        saldo_al_cierre: 0,
        esta_cerrada: 0
    });
    const [loagindAbrirCerrada, setLoagindAbrirCerrada] = React.useState(false);
    const [loadingAbrirCaja, setLoadingAbrirCaja] = React.useState(false);
    const [cajaCerrarText, setCajaCerrarText] = React.useState({
        text: '',
        title: ''
    });
    // table 1
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // table 2
    const [page2, setPage2] = React.useState(0);
    const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
    const handleChangePage2 = (event, newPage) => {
        setPage2(newPage);
    };
    const handleChangeRowsPerPage2 = (event) => {
        setRowsPerPage2(parseInt(event.target.value, 10));
        setPage2(0);
    };
    // table 3
    const [page3, setPage3] = React.useState(0);
    const [rowsPerPage3, setRowsPerPage3] = React.useState(10);
    const handleChangePage3 = (event, newPage) => {
        setPage3(newPage);
    };
    const handleChangeRowsPerPage3 = (event) => {
        setRowsPerPage3(parseInt(event.target.value, 10));
        setPage3(0);
    };
    // handles
    const handleProfesional = (e) => {
        const { name, value } = e.target;
        if (value !== "") {
            setProfesionalSearch({ ...profesionalSearch, [name]: value.toUpperCase() });
        } else {
            setProfesionalSearch({ ...profesionalSearch, [name]: '' });
        }
    }
    const handleCaja = async () => {
        const caja_abierta = await fetch(`${UrlsConst.URL_API}/ventas/pago/listarCajasAbiertas.php`, {
            method: 'GET'
        });
        const response = await caja_abierta.json();
        if (response.length !== 0) {
            let caja = {};
            response.forEach(c => caja = c);
            // setCajaAbiertaR(caja)
            setDialogCajaAbierta(true);
        } else {
            const caja_recuperar = await fetch(`${UrlsConst.URL_API}/ventas/pago/recuperarCajaCobranzaFecha.php?fecha=${cajaCobranzaSubmit.fecha_apertura}`, {
                method: 'GET'
            });
            const response_caja = await caja_recuperar.json();
            if (response_caja !== false) {
                setTypeAlert('warning');
                setTextAlert('Ya se encuentra una caja abierta para esta fecha');
                setAlertGeneral(true);
                setTimeout(() => { setAlertGeneral(false) }, 2500);
            } else {
                setOpenCaja(true);
            }
        }
    }
    const handleFecha = (e) => {
        const { value } = e.target;
        setCajaCobranzaSubmit({ ...cajaCobranzaSubmit, fecha_apertura: value });
        recuperarCajaFecha(value);
        setOrdenesPagadas([]);
    }
    const recuperarCajaFecha = async (fecha) => {
        const obtenerCaja = await fetch(`${UrlsConst.URL_API}/ventas/pago/recuperarCajaCobranzaFecha.php?fecha=${fecha}`, {
            method: 'GET'
        });
        const response = await obtenerCaja.json();
        if (response !== false) {
            const caja_chica_recuperar = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${response.id}`);
            const response_cajaChica = await caja_chica_recuperar.json();
            const registros_caja = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/listarCajaChicaRegistro.php?id=${response_cajaChica.id}`);
            const response_registros = await registros_caja.json();
            const rendido = response_registros.filter(r => Number(r.total_rendido) !== 0);
            const sinrendido = response_registros.filter(r => Number(r.total_rendido) === 0);
            let gastado_rendido = 0;
            let gastado_sinrendir = 0;
            rendido.forEach(p => {
                gastado_rendido += Number(p.total_rendido)
            });
            sinrendido.forEach(p => {
                gastado_sinrendir += Number(p.total_entregado)
            });
            let saldoCierre = Number(response_cajaChica.saldo_inicial) - (gastado_rendido + gastado_sinrendir);
            setTotalRegistro(saldoCierre);

            if (response.fecha_cierre === null && response.hora_cierre === null) {
                setCajAbierta(response);
            }
            setCajaRecuperada(response);
            listarPagosCaja(response.id);
        } else {
            const caja_abierta = await fetch(`${UrlsConst.URL_API}/ventas/pago/listarCajasAbiertas.php`);
            const response = await caja_abierta.json();
            if (response.length > 0) {
                let caja_abierta = {};
                response.forEach(c => caja_abierta = c);
                setCajAbierta(caja_abierta);
            }
            setCajaRecuperada({
                id: 0,
                fecha_apertura: '',
                hora_apertura: '',
                fecha_cierre: '',
                hora_cierre: '',
                fk_log_usuario_creado_por: ''
            });
            listarPagosCaja(0);
        }
    }
    const handleButton = (e) => {
        if (e.which === 13) {
            searchProfesional();
        }
    }
    const handleCajaCerrar = async () => {
        setCajaCobranzaSubmit({
            id: cajaRecuperada.id,
            fecha_apertura: cajaRecuperada.fecha_apertura,
            hora_apertura: cajaRecuperada.hora_apertura,
            fecha_cierre: new Date().toJSON().slice(0, 10),
            hora_cierre: '',
            fk_log_usuario_creado_por: cajaRecuperada.fk_log_usuario_creado_por
        });
        const caja_chica_recuperar = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${cajaRecuperada.id}`);
        const response_cajaChica = await caja_chica_recuperar.json();
        const registros_caja = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/listarCajaChicaRegistro.php?id=${response_cajaChica.id}`);
        const response_registros = await registros_caja.json();
        const sinrendido = response_registros.filter(r => Number(r.total_rendido) === 0);
        if (sinrendido.length > 0) {
            setCajaCerrarText({ title: 'Registros sin rendir', text: '¿Estas seguro de cerrar la caja cobranza?' });
        } else {
            setCajaCerrarText({ title: 'Cerrar Caja Abierta', text: '¿Estas seguro de cerrar la caja cobranza?' });
        }
        setOpenCajaCerrar(true);
    }
    const handleClose = () => {
        setOpenCaja(false);
    }
    const handleTipoPago = (e, values) => {
        const tipo_pago = values.props;
        if (tipo_pago.value === "E" || tipo_pago.value === "Y") {
            setReferenciaPago({ ...referenciaPago, error: false, text: '' });
            setPagoSubmit({ ...pagoSubmit, tipo: tipo_pago.value });
        } else {
            setPagoSubmit({ ...pagoSubmit, tipo: tipo_pago.value });
        }
    }
    const handlePago = () => {
        if (profesional.id === 0) {
            setTextAlert('Primero debe buscar un profesional');
            setTypeAlert('warning');
        } else {
            if (totalPago > 0) {
                setPagoSubmit({ ...pagoSubmit, fecha_registro: new Date().toJSON().slice(0, 10), monto: totalPago });
                setPagoOpen(true);
            } else {
                setTextAlert('Debe selecionar una o mas ordenes para realizar un pago');
                setTypeAlert('warning');
                setAlertGeneral(true);
                setTimeout(() => { setAlertGeneral(false) }, 3000);
            }
        }
    }
    const cerrarCajaRecuperada = async () => {
        setLoadingAbrirCaja(true);
        const saveCaja = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarCajaCobranza.php`, {
            method: "POST",
            body: JSON.stringify({
                id: cajAbierta.id,
                fk_log_usuario_creado_por: cajAbierta.fk_log_usuario_creado_por,
                fecha_apertura: cajAbierta.fecha_apertura,
                hora_apertura: cajAbierta.hora_apertura,
                fecha_cierre: new Date().toJSON().slice(0, 10),
                hora_cierre: new Date().toLocaleTimeString()
            })
        });
        const response = await saveCaja.json();
        const caja_chica_recuperar = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${cajAbierta.id}`);
        const response_cajaChica = await caja_chica_recuperar.json();
        setLoadingAbrirCaja(false);
        if (response_cajaChica !== false) {
            const registros_caja = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/listarCajaChicaRegistro.php?id=${response_cajaChica.id}`);
            const response_registros = await registros_caja.json();
            const rendido = response_registros.filter(r => Number(r.total_rendido) !== 0);
            const sinrendido = response_registros.filter(r => Number(r.total_rendido) === 0);
            let gastado_rendido = 0;
            let gastado_sinrendir = 0;
            rendido.forEach(p => {
                gastado_rendido += Number(p.total_rendido)
            });
            sinrendido.forEach(p => {
                gastado_sinrendir += Number(p.total_entregado)
            });
            let saldoCierre = Number(response_cajaChica.saldo_inicial) - (gastado_rendido + gastado_sinrendir);
            setTotalRegistro(saldoCierre);
            const caja_chica = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/guardarcajachica.php`, {
                method: 'POST',
                body: JSON.stringify({
                    id: response_cajaChica.id,
                    fk_ven_caja_cobranza: response_cajaChica.fk_ven_caja_cobranza,
                    saldo_inicial: response_cajaChica.saldo_inicial,
                    total_recarga: response_cajaChica.total_recarga,
                    saldo_anterior: response_cajaChica.saldo_anterior,
                    saldo_al_cierre: saldoCierre < 0 && gastado_sinrendir === 0 ? 0 : saldoCierre,
                    esta_cerrada: 1
                })
            });
            const response_caja_cerrada = await caja_chica.json();
        }
        if (response.guardado) {
            setTypeAlert('success');
            setTextAlert('Caja cerrada exitosamente');
            setAlertGeneral(true);
            setTimeout(() => { setAlertGeneral(false) }, 2500);
            setDialogCajaAbierta(false);
            abrirNewCaja();
        }
    }
    // functiones
    const abrirNewCaja = async () => {
        const usuarioL = JSON.parse(sessionStorage.getItem('usuario_log'));
        if (cajAbierta.id === 0) {
            const cajas = await fetch(`${UrlsConst.URL_API}/ventas/pago/listarCajaCobranza.php`);
            const response = await cajas.json();
            if (response.length > 0) {
                const cajaUltima = response[response.length - 1];
                const caja_chica_recuperar = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${cajaUltima.id}`);
                const response_cajaChica = await caja_chica_recuperar.json();
                setCajaChicaRecuperada(response_cajaChica);
                const saveCaja = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarCajaCobranza.php`, {
                    method: "POST",
                    body: JSON.stringify({
                        id: 0,
                        fk_log_usuario_creado_por: usuarioL.usuario,
                        fecha_apertura: cajaCobranzaSubmit.fecha_apertura,
                        hora_apertura: new Date().toLocaleTimeString(),
                        fecha_cierre: '',
                        hora_cierre: ''
                    })
                });
                const response_caja = await saveCaja.json();
                setOpenCaja(false);
                setCajaRecuperada({
                    id: response_caja.id,
                    fecha_apertura: cajaCobranzaSubmit.fecha_apertura,
                    hora_apertura: new Date().toLocaleTimeString(),
                    fecha_cierre: null,
                    hora_cierre: null,
                    fk_log_usuario_creado_por: usuarioL.usuario
                });
                setCajAbierta({
                    id: response_caja.id,
                    fecha_apertura: cajaCobranzaSubmit.fecha_apertura,
                    hora_apertura: new Date().toLocaleTimeString(),
                    fecha_cierre: null,
                    hora_cierre: null,
                    fk_log_usuario_creado_por: usuarioL.usuario
                });
                setDialogCajaChica(true);
            } else {
                const saveCaja = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarCajaCobranza.php`, {
                    method: "POST",
                    body: JSON.stringify({
                        id: 0,
                        fk_log_usuario_creado_por: usuarioL.usuario,
                        fecha_apertura: cajaCobranzaSubmit.fecha_apertura,
                        hora_apertura: new Date().toLocaleTimeString(),
                        fecha_cierre: '',
                        hora_cierre: ''
                    })
                });
                const response_caja = await saveCaja.json();
                setOpenCaja(false);
                setCajaRecuperada({
                    id: response_caja.id,
                    fecha_apertura: cajaCobranzaSubmit.fecha_apertura,
                    hora_apertura: new Date().toLocaleTimeString(),
                    fecha_cierre: null,
                    hora_cierre: null,
                    fk_log_usuario_creado_por: usuarioL.usuario
                });
                setDialogCajaChica(true);
            }
        } else {
            const saveCaja = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarCajaCobranza.php`, {
                method: "POST",
                body: JSON.stringify({
                    id: 0,
                    fk_log_usuario_creado_por: usuarioL.usuario,
                    fecha_apertura: cajaCobranzaSubmit.fecha_apertura,
                    hora_apertura: new Date().toLocaleTimeString(),
                    fecha_cierre: '',
                    hora_cierre: ''
                })
            });
            const response_caja = await saveCaja.json();
            const caja_chica_recuperar = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${cajAbierta.id}`);
            const response_cajaChica = await caja_chica_recuperar.json();
            setCajaChicaRecuperada(response_cajaChica);
            setOpenCaja(false);
            setCajaRecuperada({
                id: response_caja.id,
                fecha_apertura: cajaCobranzaSubmit.fecha_apertura,
                hora_apertura: new Date().toLocaleTimeString(),
                fecha_cierre: null,
                hora_cierre: null,
                fk_log_usuario_creado_por: usuarioL.usuario
            });
            setCajAbierta({
                id: response_caja.id,
                fecha_apertura: cajaCobranzaSubmit.fecha_apertura,
                hora_apertura: new Date().toLocaleTimeString(),
                fecha_cierre: null,
                hora_cierre: null,
                fk_log_usuario_creado_por: usuarioL.usuario
            });
            setDialogCajaChica(true);
        }
    }

    const abrirCaja = async () => {
        setLoagindAbrirCerrada(true);
        const caja_abierta = await fetch(`${UrlsConst.URL_API}/ventas/pago/listarCajasAbiertas.php`);
        const response = await caja_abierta.json();
        if (response.length > 0) {
            let caja_abierta = {};
            response.forEach(c => caja_abierta = c);
            const cerrar_caja = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarCajaCobranza.php`, {
                method: "POST",
                body: JSON.stringify({
                    id: caja_abierta.id,
                    fk_log_usuario_creado_por: caja_abierta.fk_log_usuario_creado_por,
                    fecha_apertura: caja_abierta.fecha_apertura,
                    hora_apertura: caja_abierta.hora_apertura,
                    fecha_cierre: fechahoy,
                    hora_cierre: new Date().toLocaleTimeString()
                })
            });
            const response_cerrar = await cerrar_caja.json();
            const caja_chica_recuperar = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${caja_abierta.id}`);
            const response_cajaChica = await caja_chica_recuperar.json();
            if (response_cajaChica !== false) {
                const caja_chica = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/guardarcajachica.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: response_cajaChica.id,
                        fk_ven_caja_cobranza: response_cajaChica.fk_ven_caja_cobranza,
                        saldo_inicial: response_cajaChica.saldo_inicial,
                        total_recarga: response_cajaChica.total_recarga,
                        saldo_anterior: response_cajaChica.saldo_anterior,
                        saldo_al_cierre: response_cajaChica.saldo_al_cierre,
                        esta_cerrada: 1
                    })
                });
                const response = await caja_chica.json();
            }
            const saveCaja = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarCajaCobranza.php`, {
                method: "POST",
                body: JSON.stringify({
                    id: cajaRecuperada.id,
                    fk_log_usuario_creado_por: cajaRecuperada.fk_log_usuario_creado_por,
                    fecha_apertura: cajaRecuperada.fecha_apertura,
                    hora_apertura: cajaRecuperada.hora_apertura,
                    fecha_cierre: '',
                    hora_cierre: ''
                })
            });
            const response_abrir = await saveCaja.json();
            const caja_chica_recuperar_abrir = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${response_abrir.id}`);
            const response_cajaChica_abrir = await caja_chica_recuperar_abrir.json();
            if (response_cajaChica_abrir !== false) {
                const caja_chica = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/guardarcajachica.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: response_cajaChica_abrir.id,
                        fk_ven_caja_cobranza: response_cajaChica_abrir.fk_ven_caja_cobranza,
                        saldo_inicial: response_cajaChica_abrir.saldo_inicial,
                        total_recarga: response_cajaChica_abrir.total_recarga,
                        saldo_anterior: response_cajaChica_abrir.saldo_anterior,
                        saldo_al_cierre: response_cajaChica_abrir.saldo_al_cierre,
                        esta_cerrada: 0
                    })
                });
                const response = await caja_chica.json();
            }
            setCajaRecuperada({
                id: cajaRecuperada.id,
                fk_log_usuario_creado_por: cajaRecuperada.fk_log_usuario_creado_por,
                fecha_apertura: cajaRecuperada.fecha_apertura,
                hora_apertura: cajaRecuperada.hora_apertura,
                fecha_cierre: null,
                hora_cierre: null
            });
            setCajAbierta({
                id: cajaRecuperada.id,
                fk_log_usuario_creado_por: cajaRecuperada.fk_log_usuario_creado_por,
                fecha_apertura: cajaRecuperada.fecha_apertura,
                hora_apertura: cajaRecuperada.hora_apertura,
                fecha_cierre: null,
                hora_cierre: null
            });
            setDialogCajaCerrada(false);
        } else {
            const saveCaja = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarCajaCobranza.php`, {
                method: "POST",
                body: JSON.stringify({
                    id: cajaRecuperada.id,
                    fk_log_usuario_creado_por: cajaRecuperada.fk_log_usuario_creado_por,
                    fecha_apertura: cajaRecuperada.fecha_apertura,
                    hora_apertura: cajaRecuperada.hora_apertura,
                    fecha_cierre: '',
                    hora_cierre: ''
                })
            });
            const response = await saveCaja.json();
            const caja_chica_recuperar_abrir = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${response.id}`);
            const response_cajaChica_abrir = await caja_chica_recuperar_abrir.json();
            if (response_cajaChica_abrir !== false) {
                const caja_chica = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/guardarcajachica.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: response_cajaChica_abrir.id,
                        fk_ven_caja_cobranza: response_cajaChica_abrir.fk_ven_caja_cobranza,
                        saldo_inicial: response_cajaChica_abrir.saldo_inicial,
                        total_recarga: response_cajaChica_abrir.total_recarga,
                        saldo_anterior: response_cajaChica_abrir.saldo_anterior,
                        saldo_al_cierre: response_cajaChica_abrir.saldo_al_cierre,
                        esta_cerrada: 0
                    })
                });
                const response = await caja_chica.json();
            }
            setCajaRecuperada({
                id: cajaRecuperada.id,
                fk_log_usuario_creado_por: cajaRecuperada.fk_log_usuario_creado_por,
                fecha_apertura: cajaRecuperada.fecha_apertura,
                hora_apertura: cajaRecuperada.hora_apertura,
                fecha_cierre: null,
                hora_cierre: null
            });
            setCajAbierta({
                id: cajaRecuperada.id,
                fk_log_usuario_creado_por: cajaRecuperada.fk_log_usuario_creado_por,
                fecha_apertura: cajaRecuperada.fecha_apertura,
                hora_apertura: cajaRecuperada.hora_apertura,
                fecha_cierre: null,
                hora_cierre: null
            });
            setDialogCajaCerrada(false);
        }
        setLoagindAbrirCerrada(false);
    }
    const [loadingCerrarCaja, setLoadingCerrarCaja] = React.useState(false);
    const [totalRegistro, setTotalRegistro] = React.useState(0);
    const cerrarCaja = async () => {
        setLoadingCerrarCaja(true);
        const usuarioL = JSON.parse(sessionStorage.getItem('usuario_log'));
        const saveCaja = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarCajaCobranza.php`, {
            method: "POST",
            body: JSON.stringify({
                id: cajaCobranzaSubmit.id,
                fk_log_usuario_creado_por: usuarioL.usuario,
                fecha_apertura: cajaCobranzaSubmit.fecha_apertura,
                hora_apertura: cajaCobranzaSubmit.hora_apertura,
                fecha_cierre: new Date().toJSON().slice(0, 10),
                hora_cierre: new Date().toLocaleTimeString()
            })
        });
        const response = await saveCaja.json();
        const caja_chica_recuperar = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${cajaCobranzaSubmit.id}`);
        const response_cajaChica = await caja_chica_recuperar.json();
        if (response_cajaChica) {
            const registros_caja = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/listarCajaChicaRegistro.php?id=${response_cajaChica.id}`);
            const response_registros = await registros_caja.json();
            if (response_registros.length > 0) {
                let saldoRendido = 0;
                let saldoSinRendir = 0;
                const totalRendido = response_registros.filter(r => Number(r.total_rendido) !== 0);
                const totalSinRendir = response_registros.filter(r => Number(r.total_rendido) === 0);
                totalRendido.forEach(r => saldoRendido += Number(r.total_rendido));
                totalSinRendir.forEach(r => saldoSinRendir += Number(r.total_entregado));
                let saldoCierre = Number(response_cajaChica.saldo_inicial) - (saldoRendido + saldoSinRendir);
                setTotalRegistro(saldoCierre);
                const caja_chica = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/guardarcajachica.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: response_cajaChica.id,
                        fk_ven_caja_cobranza: response_cajaChica.fk_ven_caja_cobranza,
                        saldo_inicial: response_cajaChica.saldo_inicial,
                        total_recarga: response_cajaChica.total_recarga,
                        saldo_anterior: saldoCierre < 0 && saldoSinRendir === 0 ? 0 : saldoCierre,
                        saldo_al_cierre: saldoCierre < 0 && saldoSinRendir === 0 ? 0 : saldoCierre,
                        esta_cerrada: 1
                    })
                });
                const response_caja_cerrada = await caja_chica.json();
            } else {
                const caja_chica = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/guardarcajachica.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: response_cajaChica.id,
                        fk_ven_caja_cobranza: response_cajaChica.fk_ven_caja_cobranza,
                        saldo_inicial: response_cajaChica.saldo_inicial,
                        total_recarga: response_cajaChica.total_recarga,
                        saldo_anterior: response_cajaChica.total_recarga,
                        saldo_al_cierre: response_cajaChica.total_recarga,
                        esta_cerrada: 1
                    })
                });
                const response_caja_cerrada = await caja_chica.json();
            }
        }
        setLoadingCerrarCaja(false);
        setOpenCajaCerrar(false);
        setCajaRecuperada({
            id: response.id,
            fk_log_usuario_creado_por: usuarioL.usuario,
            fecha_apertura: cajaCobranzaSubmit.fecha_apertura,
            hora_apertura: cajaCobranzaSubmit.hora_apertura,
            fecha_cierre: new Date().toJSON().slice(0, 10),
            hora_cierre: new Date().toLocaleTimeString(),
        });
    }
    const searchProfesional = async () => {
        const search = await fetch(`${UrlsConst.URL_API}/ventas/profesional/buscarProfesional.php`, {
            method: "POST",
            body: JSON.stringify(profesionalSearch)
        });
        const response = await search.json();
        if (response.length > 0) {
            setProfesionales(response);
        } else {
            setTextAlert('!ERROR¡ EL PROFESIONAL QUE ESTA BUSCANDO NO EXISTE');
            setTypeAlert('error');
            setAlertGeneral(true);
            setTimeout(() => { setAlertGeneral(false) }, 2500);
        }
    }
    const uploadProfesional = (profesional) => {
        setPagoSubmit({
            id: 0,
            fk_ven_caja_cobranza: 0,
            fk_ven_orden: 0,
            fecha_registro: new Date().toJSON().slice(0, 10),
            hora_registro: '',
            monto: 0,
            tipo: 'E',
            referencia: ''
        });
        setPagar([]);
        // setTotalPago(0);
        setOpenProfesional(false);
        setProfesional(profesional);
        setProfesionalSearch({ ...profesionalSearch, apellidos_nombres: '', numero_documento: '' });
        setProfesionales([]);
        listarPagos(profesional.id);
    }
    const listarPagos = async (id) => {
        const search = await fetch(`${UrlsConst.URL_API}/ventas/pago/listarPagoMain.php?id=` + id, {
            method: "GET"
        });
        const response = await search.json();
        if (response.length > 0) {
            const sin_pagar = response.reverse().filter((orden) => orden.estado_pago !== 'G');
            if (sin_pagar.length > 0) {
                setPagado(1);
                setOrdenes(sin_pagar);
                setSelected([]);
            } else {
                setTextAlert('El profesional no cuenta con ordenes disponibles ahora');
                setTypeAlert('warning');
                setAlertGeneral(true);
                setTimeout(() => { setAlertGeneral(false) }, 2500);
                setSelected([]);
                setOrdenes([]);
            }
            setOpenProfesional(false);
        } else {
            setTextAlert('El profesional no cuenta con ordenes disponibles ahora');
            setTypeAlert('warning');
            setAlertGeneral(true);
            setTimeout(() => { setAlertGeneral(false) }, 2500);
        }
    }
    const getColor = () => {
        setColor('#' + ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'].sort(() => .5 - Math.random()).slice(0, 6).join(''))
    }
    // validaciones
    const [montoPago, setMontoPago] = React.useState({
        error: false,
        text: ''
    });
    const [referenciaPago, setReferenciaPago] = React.useState({
        error: false,
        text: ''
    });
    const setPagarCaja = async () => {
        if (pagoSubmit.referencia === "") {
            if (pagoSubmit.tipo === "E" || pagoSubmit.tipo === "Y") {
                setReferenciaPago({ ...referenciaPago, error: false, text: '' });
                let pagos_orden = 0;
                let deuda = 0;
                let estadoPago = "";
                let monto_ingresado = Number(pagoSubmit.monto);
                for (let i = 0; i < pagar.length; i++) {
                    if (Number(pagar[i].total_pagado) > 0) {
                        deuda = Number(pagar[i].presupuesto) - Number(pagar[i].total_pagado);
                        if (monto_ingresado >= deuda) {
                            const cajaSet = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarPago.php`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    id: pagar[i].id,
                                    fk_ven_caja_cobranza: pagar[i].fk_ven_caja_cobranza,
                                    fk_ven_orden: pagar[i].fk_ven_orden,
                                    fecha_registro: pagar[i].fecha_registro,
                                    hora_registro: new Date().toLocaleTimeString(),
                                    monto: deuda,
                                    tipo: pagoSubmit.tipo,
                                    referencia: pagoSubmit.referencia
                                })
                            });
                        } else {
                            const cajaSet = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarPago.php`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    id: pagar[i].id,
                                    fk_ven_caja_cobranza: pagar[i].fk_ven_caja_cobranza,
                                    fk_ven_orden: pagar[i].fk_ven_orden,
                                    fecha_registro: pagar[i].fecha_registro,
                                    hora_registro: new Date().toLocaleTimeString(),
                                    monto: monto_ingresado,
                                    tipo: pagoSubmit.tipo,
                                    referencia: pagoSubmit.referencia
                                })
                            });
                            monto_ingresado -= deuda;
                        }
                        const recuperarPago = await fetch(`${UrlsConst.URL_API}/ventas/pago/listarPagosOrden.php?fk_ven_orden=${pagar[i].fk_ven_orden}`, {
                            method: 'GET'
                        });
                        const respago = await recuperarPago.json();
                        respago.forEach((item) => {
                            pagos_orden += Number(item.monto);
                        });
                        if (pagos_orden === Number(pagar[i].presupuesto)) {
                            estadoPago = "G";
                        } else {
                            estadoPago = "A";
                        }
                        const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + pagar[i].fk_ven_orden, {
                            method: 'GET'
                        });
                        const response_orden = await getOrden.json();
                        const setorden = await fetch(`${UrlsConst.URL_API}/ventas/orden/actualizarOrden.php?orden=${response_orden.id}`, {
                            method: 'POST',
                            body: JSON.stringify({
                                id: response_orden.id,
                                fk_ven_servicio: response_orden.fk_ven_servicio,
                                fk_cmp_material: response_orden.fk_cmp_material,
                                fk_log_usuario: response_orden.fk_log_usuario,
                                fk_ven_paciente: response_orden.fk_ven_paciente,
                                fk_ven_profesional: response_orden.fk_ven_profesional,
                                fecha_ingreso: response_orden.fecha_ingreso,
                                fecha_entrega: response_orden.fecha_entrega,
                                descripcion: response_orden.descripcion,
                                observaciones: response_orden.observaciones,
                                cubeta: response_orden.cubeta,
                                esta_entregado: response_orden.esta_entregado,
                                esta_facturado: response_orden.esta_facturado,
                                estado_pago: estadoPago,
                                presupuesto: response_orden.presupuesto
                            })
                        });
                        if (monto_ingresado <= 0) break;
                    } else {
                        if (monto_ingresado >= Number(pagar[i].presupuesto)) {
                            monto_ingresado -= Number(pagar[i].presupuesto);
                            const cajaSet = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarPago.php`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    id: pagar[i].id,
                                    fk_ven_caja_cobranza: pagar[i].fk_ven_caja_cobranza,
                                    fk_ven_orden: pagar[i].fk_ven_orden,
                                    fecha_registro: pagar[i].fecha_registro,
                                    hora_registro: new Date().toLocaleTimeString(),
                                    monto: pagar[i].presupuesto,
                                    tipo: pagoSubmit.tipo,
                                    referencia: pagoSubmit.referencia
                                })
                            });
                            const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + pagar[i].fk_ven_orden, {
                                method: 'GET'
                            });
                            const response_orden = await getOrden.json();
                            const setorden = await fetch(`${UrlsConst.URL_API}/ventas/orden/actualizarOrden.php?orden=${response_orden.id}`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    id: response_orden.id,
                                    fk_ven_servicio: response_orden.fk_ven_servicio,
                                    fk_cmp_material: response_orden.fk_cmp_material,
                                    fk_log_usuario: response_orden.fk_log_usuario,
                                    fk_ven_paciente: response_orden.fk_ven_paciente,
                                    fk_ven_profesional: response_orden.fk_ven_profesional,
                                    fecha_ingreso: response_orden.fecha_ingreso,
                                    fecha_entrega: response_orden.fecha_entrega,
                                    descripcion: response_orden.descripcion,
                                    observaciones: response_orden.observaciones,
                                    cubeta: response_orden.cubeta,
                                    esta_entregado: response_orden.esta_entregado,
                                    esta_facturado: response_orden.esta_facturado,
                                    estado_pago: "G",
                                    presupuesto: response_orden.presupuesto
                                })
                            });
                        } else {
                            const cajaSet = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarPago.php`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    id: pagar[i].id,
                                    fk_ven_caja_cobranza: pagar[i].fk_ven_caja_cobranza,
                                    fk_ven_orden: pagar[i].fk_ven_orden,
                                    fecha_registro: pagar[i].fecha_registro,
                                    hora_registro: new Date().toLocaleTimeString(),
                                    monto: monto_ingresado,
                                    tipo: pagoSubmit.tipo,
                                    referencia: pagoSubmit.referencia
                                })
                            });
                            monto_ingresado -= Number(pagar[i].presupuesto);
                            const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + pagar[i].fk_ven_orden, {
                                method: 'GET'
                            });
                            const response_orden = await getOrden.json();
                            const setorden = await fetch(`${UrlsConst.URL_API}/ventas/orden/actualizarOrden.php?orden=${response_orden.id}`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    id: response_orden.id,
                                    fk_ven_servicio: response_orden.fk_ven_servicio,
                                    fk_cmp_material: response_orden.fk_cmp_material,
                                    fk_log_usuario: response_orden.fk_log_usuario,
                                    fk_ven_paciente: response_orden.fk_ven_paciente,
                                    fk_ven_profesional: response_orden.fk_ven_profesional,
                                    fecha_ingreso: response_orden.fecha_ingreso,
                                    fecha_entrega: response_orden.fecha_entrega,
                                    descripcion: response_orden.descripcion,
                                    observaciones: response_orden.observaciones,
                                    cubeta: response_orden.cubeta,
                                    esta_entregado: response_orden.esta_entregado,
                                    esta_facturado: response_orden.esta_facturado,
                                    estado_pago: "A",
                                    presupuesto: response_orden.presupuesto
                                })
                            });
                        }
                        if (monto_ingresado <= 0) break;
                    }
                }
                setPagoOpen(false);
                setPagoSubmit({
                    id: 0,
                    fk_ven_caja_cobranza: 0,
                    fk_ven_orden: 0,
                    fecha_registro: new Date().toJSON().slice(0, 10),
                    hora_registro: '',
                    monto: 0,
                    tipo: 'E',
                    referencia: ''
                });
                setPagar([]);
                setTotalPago(0);
                listarPagos(profesional.id);
                listarPagosCaja(cajaRecuperada.id);
                // alert
                setTextAlert('Los pagos se realizaron satisfactoriamentte');
                setTypeAlert('success');
                setAlerta(true);
                setTimeout(() => { setAlertPago(setAlerta(false)) }, 2500);

            } else {
                setReferenciaPago({ ...referenciaPago, error: true, text: 'Referencia obligatoria' });
            }
        } else {
            let pagos_orden = 0;
            let deuda = 0;
            let estadoPago = "";
            let monto_ingresado = Number(pagoSubmit.monto);
            for (let i = 0; i < pagar.length; i++) {
                if (Number(pagar[i].total_pagado) > 0) {
                    deuda = Number(pagar[i].presupuesto) - Number(pagar[i].total_pagado);
                    if (monto_ingresado >= deuda) {
                        const cajaSet = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarPago.php`, {
                            method: 'POST',
                            body: JSON.stringify({
                                id: pagar[i].id,
                                fk_ven_caja_cobranza: pagar[i].fk_ven_caja_cobranza,
                                fk_ven_orden: pagar[i].fk_ven_orden,
                                fecha_registro: pagar[i].fecha_registro,
                                hora_registro: new Date().toLocaleTimeString(),
                                monto: deuda,
                                tipo: pagoSubmit.tipo,
                                referencia: pagoSubmit.referencia
                            })
                        });
                    } else {
                        const cajaSet = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarPago.php`, {
                            method: 'POST',
                            body: JSON.stringify({
                                id: pagar[i].id,
                                fk_ven_caja_cobranza: pagar[i].fk_ven_caja_cobranza,
                                fk_ven_orden: pagar[i].fk_ven_orden,
                                fecha_registro: pagar[i].fecha_registro,
                                hora_registro: new Date().toLocaleTimeString(),
                                monto: monto_ingresado,
                                tipo: pagoSubmit.tipo,
                                referencia: pagoSubmit.referencia
                            })
                        });
                        monto_ingresado -= deuda;
                    }
                    const recuperarPago = await fetch(`${UrlsConst.URL_API}/ventas/pago/listarPagosOrden.php?fk_ven_orden=${pagar[i].fk_ven_orden}`, {
                        method: 'GET'
                    });
                    const respago = await recuperarPago.json();
                    respago.forEach((item) => {
                        pagos_orden += Number(item.monto);
                    });
                    if (pagos_orden === Number(pagar[i].presupuesto)) {
                        estadoPago = "G";
                    } else {
                        estadoPago = "A";
                    }
                    const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + pagar[i].fk_ven_orden, {
                        method: 'GET'
                    });
                    const response_orden = await getOrden.json();
                    const setorden = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarOrden.php`, {
                        method: 'POST',
                        body: JSON.stringify({
                            id: response_orden.id,
                            fk_ven_servicio: response_orden.fk_ven_servicio,
                            fk_cmp_material: response_orden.fk_cmp_material,
                            fk_log_usuario: response_orden.fk_log_usuario,
                            fk_ven_paciente: response_orden.fk_ven_paciente,
                            fk_ven_profesional: response_orden.fk_ven_profesional,
                            fecha_ingreso: response_orden.fecha_ingreso,
                            fecha_entrega: response_orden.fecha_entrega,
                            descripcion: response_orden.descripcion,
                            observaciones: response_orden.observaciones,
                            cubeta: response_orden.cubeta,
                            esta_entregado: response_orden.esta_entregado,
                            esta_facturado: response_orden.esta_facturado,
                            estado_pago: estadoPago,
                            presupuesto: response_orden.presupuesto
                        })
                    });
                    if (monto_ingresado <= 0) break;
                } else {
                    if (monto_ingresado >= Number(pagar[i].presupuesto)) {
                        monto_ingresado -= Number(pagar[i].presupuesto);
                        const cajaSet = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarPago.php`, {
                            method: 'POST',
                            body: JSON.stringify({
                                id: pagar[i].id,
                                fk_ven_caja_cobranza: pagar[i].fk_ven_caja_cobranza,
                                fk_ven_orden: pagar[i].fk_ven_orden,
                                fecha_registro: pagar[i].fecha_registro,
                                hora_registro: new Date().toLocaleTimeString(),
                                monto: pagar[i].presupuesto,
                                tipo: pagoSubmit.tipo,
                                referencia: pagoSubmit.referencia
                            })
                        });
                        const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + pagar[i].fk_ven_orden, {
                            method: 'GET'
                        });
                        const response_orden = await getOrden.json();
                        const setorden = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarOrden.php`, {
                            method: 'POST',
                            body: JSON.stringify({
                                id: response_orden.id,
                                fk_ven_servicio: response_orden.fk_ven_servicio,
                                fk_cmp_material: response_orden.fk_cmp_material,
                                fk_log_usuario: response_orden.fk_log_usuario,
                                fk_ven_paciente: response_orden.fk_ven_paciente,
                                fk_ven_profesional: response_orden.fk_ven_profesional,
                                fecha_ingreso: response_orden.fecha_ingreso,
                                fecha_entrega: response_orden.fecha_entrega,
                                descripcion: response_orden.descripcion,
                                observaciones: response_orden.observaciones,
                                cubeta: response_orden.cubeta,
                                esta_entregado: response_orden.esta_entregado,
                                esta_facturado: response_orden.esta_facturado,
                                estado_pago: "G",
                                presupuesto: response_orden.presupuesto
                            })
                        });
                    } else {
                        const cajaSet = await fetch(`${UrlsConst.URL_API}/ventas/pago/guardarPago.php`, {
                            method: 'POST',
                            body: JSON.stringify({
                                id: pagar[i].id,
                                fk_ven_caja_cobranza: pagar[i].fk_ven_caja_cobranza,
                                fk_ven_orden: pagar[i].fk_ven_orden,
                                fecha_registro: pagar[i].fecha_registro,
                                hora_registro: new Date().toLocaleTimeString(),
                                monto: monto_ingresado,
                                tipo: pagoSubmit.tipo,
                                referencia: pagoSubmit.referencia
                            })
                        });
                        monto_ingresado -= Number(pagar[i].presupuesto);
                        const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + pagar[i].fk_ven_orden, {
                            method: 'GET'
                        });
                        const response_orden = await getOrden.json();
                        const setorden = await fetch(`${UrlsConst.URL_API}/ventas/orden/guardarOrden.php`, {
                            method: 'POST',
                            body: JSON.stringify({
                                id: response_orden.id,
                                fk_ven_servicio: response_orden.fk_ven_servicio,
                                fk_cmp_material: response_orden.fk_cmp_material,
                                fk_log_usuario: response_orden.fk_log_usuario,
                                fk_ven_paciente: response_orden.fk_ven_paciente,
                                fk_ven_profesional: response_orden.fk_ven_profesional,
                                fecha_ingreso: response_orden.fecha_ingreso,
                                fecha_entrega: response_orden.fecha_entrega,
                                descripcion: response_orden.descripcion,
                                observaciones: response_orden.observaciones,
                                cubeta: response_orden.cubeta,
                                esta_entregado: response_orden.esta_entregado,
                                esta_facturado: response_orden.esta_facturado,
                                estado_pago: "A",
                                presupuesto: response_orden.presupuesto
                            })
                        });
                    }
                    if (monto_ingresado <= 0) break;
                }
            }
            setPagoOpen(false);
            setPagoSubmit({
                id: 0,
                fk_ven_caja_cobranza: 0,
                fk_ven_orden: 0,
                fecha_registro: new Date().toJSON().slice(0, 10),
                hora_registro: '',
                monto: 0,
                tipo: 'E',
                referencia: ''
            });
            setPagar([]);
            setTotalPago(0);
            listarPagos(profesional.id);
            listarPagosCaja(cajaRecuperada.id);
            // alert
            setTextAlert('Los pagos se realizaron satisfactoriamentte');
            setTypeAlert('success');
            setAlerta(true);
            setTimeout(() => { setAlertPago(setAlerta(false)) }, 2500);
        }
    }
    const btnBuscarPorCodigo = async () => {
        // console.log("click en buscar");
        const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenUI.php?paciente=&profesional=&id=${searchCodigoBarra.id}`, {
            method: 'GET'
        });
        const response = await getOrden.json();
        if (response.length > 0) {
            const search = await fetch(`${UrlsConst.URL_API}/ventas/pago/listarPagoMain.php?id=${response[0].fk_ven_profesional}`, {
                method: "GET"
            });
            const dataResponse = await search.json();
            const profesionalGet = await fetch(`${UrlsConst.URL_API}/ventas/profesional/recuperarProfesional.php?id=` + response[0].fk_ven_profesional, {
                method: "GET"
            });
            const responseProf = await profesionalGet.json();
            setProfesional(responseProf);
            // const dataResponse = response2.filter(f => f.id === searchCodigoBarra.id);
            if (dataResponse.length > 0) {
                const sin_pagar = dataResponse.reverse().filter((orden) => orden.estado_pago !== 'G');
                if (sin_pagar.length > 0) {
                    // console.log({ sin_pagar });
                    setPagado(1);
                    setOrdenes(sin_pagar);
                    setSelected([]);
                    setSearchCodigoBarra({ id: "" });
                    setDlgSearchCodigoBarra(false);
                } else {
                    setTextAlert('El profesional no cuenta con ordenes disponibles ahora');
                    setTypeAlert('warning');
                    setAlertGeneral(true);
                    setTimeout(() => { setAlertGeneral(false) }, 2500);
                    setSelected([]);
                    setOrdenes([]);
                }
                setOpenProfesional(false);
            } else {
                setTextAlert('El profesional no cuenta con ordenes disponibles ahora');
                setTypeAlert('warning');
                setAlertGeneral(true);
                setTimeout(() => { setAlertGeneral(false) }, 2500);
            }
        } else {
            // console.log("id no encontrado");
            setSearchCodigoBarra({ id: "" });
            setDlgSearchCodigoBarra(false);
            //mejorar alerta a un solo obj
            setTextAlert('No existen ordenes para el codigo ingresado');
            setTypeAlert('warning');
            setAlertGeneral(true);
            setTimeout(() => { setAlertGeneral(false) }, 3000);
        }
    }
    const onchSearchCodigoBarra = (e) => {
        setSearchCodigoBarra({ ...searchCodigoBarra, id: e.target.value });
    }
    const handleSearchProfesional = () => {
        if (cajaRecuperada.id !== 0 && cajaRecuperada.fecha_cierre === null) {
            setOpenProfesional(true);
            listarPagosCaja(cajaRecuperada.id);
        } else {
            setTextAlert('Deberia buscar una caja abierta para realizar esta accion');
            setTypeAlert('warning');
            setAlertGeneral(true);
            setTimeout(() => { setAlertGeneral(false) }, 3000);
        }
    }
    const listarPagosCaja = async (id) => {
        const pagosCaja = await fetch(`${UrlsConst.URL_API}/ventas/pago/listarPagosCajaCobranza.php?id=${id}`, {
            method: 'GET'
        });
        const response = await pagosCaja.json();
        if (response.length > 0) {
            setOrdenesPagadas(response);
        } else {
            setOrdenesPagadas([]);
        }
    }
    const [datosEmpresa, setDatosEmpresa] = React.useState({
        ruc: '',
        razon_social: '',
        direccion: ''
    });
    // use effect
    React.useEffect(() => {
        setPagoSubmit({
            id: 0,
            fk_ven_caja_cobranza: 0,
            fk_ven_orden: 0,
            fecha_registro: new Date().toJSON().slice(0, 10),
            hora_registro: '',
            monto: 0,
            tipo: 'E',
            referencia: ''
        });
        setPagar([]);
        setTotalPago(0);
        // listarPagos(profesional.id);
        // listarPagosCaja(cajaRecuperada.id);
        setCajaCobranzaSubmit({ ...cajaCobranzaSubmit, fecha_apertura: new Date().toJSON().slice(0, 10) });
        recuperarCajaFecha(new Date().toJSON().slice(0, 10));
        getColor();
        const getDatosEmpresa = async () => {
            const getRuc = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RUC_EMPRESA`, {
                method: 'GET'
            });
            const response_getRuc = await getRuc.json();
            const getRazon = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RAZON_SOCIAL`, {
                method: 'GET'
            });
            const response_getRazon = await getRazon.json();
            const getDireccion = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=DIRECCION_EMPRESA`, {
                method: 'GET'
            });
            const response_getDireccion = await getDireccion.json();
            setDatosEmpresa({ ruc: response_getRuc.valor_string, razon_social: response_getRazon.valor_string, direccion: response_getDireccion.valor_string });
        }
        getDatosEmpresa();
    }, []);
    const handleSelectAllClick = async (event) => {
        if (event.target.checked === true) {
            const newSelected = ordenes.map((n) => n.id);
            const sin_pagar = ordenes.filter((orden) => orden.estado_pago !== 'G');
            if (sin_pagar.length > 0) {
                let sumaPendientes = 0;
                let sumaParciales = 0;
                let pagarA = [].concat(pagar);
                sin_pagar.forEach((item) => {
                    sumaPendientes += Number(item.presupuesto);
                    pagarA.push({
                        id: 0,
                        fk_ven_caja_cobranza: cajaRecuperada.id,
                        fk_ven_orden: item.id,
                        servicio: item.servicio,
                        fecha_registro: new Date().toJSON().slice(0, 10),
                        presupuesto: item.presupuesto,
                        total_pagado: item.total_pagado
                    });
                });
                const casi_pagado = ordenes.filter((orden) => orden.estado_pago === 'A');
                casi_pagado.forEach((item) => {
                    sumaParciales += Number(item.total_pagado);
                });
                if (casi_pagado.length > 0) {
                    setTotalPago((sumaPendientes - sumaParciales).toFixed(2));
                } else {
                    setTotalPago(sumaPendientes.toFixed(2));
                }
                setPagar(pagarA);
            }
            setSelected(newSelected);
            return;
        } else {
            setPagar([]);
            setSelected([]);
            setTotalPago(0);
        }
    };
    const handleClick = async (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;
    const [pagoDelete, setPagoDelete] = React.useState(false);
    const [pagoRecuperado, setPagoRecuperado] = React.useState({});
    const deletePago = async () => {
        const deletePago = await fetch(`${UrlsConst.URL_API}/ventas/pago/eliminarPago.php?id=${pagoRecuperado.id}`, {
            method: 'GET'
        });
        const response = await deletePago.json();
        const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + pagoRecuperado.fk_ven_orden, {
            method: 'GET'
        });
        const response_orden = await getOrden.json();
        const setorden = await fetch(`${UrlsConst.URL_API}/ventas/orden/actualizarOrden.php?orden=${response_orden.id}`, {
            method: 'POST',
            body: JSON.stringify({
                id: response_orden.id,
                fk_ven_servicio: response_orden.fk_ven_servicio,
                fk_cmp_material: response_orden.fk_cmp_material,
                fk_log_usuario: response_orden.fk_log_usuario,
                fk_ven_paciente: response_orden.fk_ven_paciente,
                fk_ven_profesional: response_orden.fk_ven_profesional,
                fecha_ingreso: response_orden.fecha_ingreso,
                fecha_entrega: response_orden.fecha_entrega,
                descripcion: response_orden.descripcion,
                observaciones: response_orden.observaciones,
                cubeta: response_orden.cubeta,
                esta_entregado: response_orden.esta_entregado,
                esta_facturado: response_orden.esta_facturado,
                estado_pago: 'A',
                presupuesto: response_orden.presupuesto
            })
        });
        listarPagosCaja(cajaRecuperada.id);
        setPagoDelete(false);
    }
    const irCajaAbierta = async () => {
        const caja_abierta = await fetch(`${UrlsConst.URL_API}/ventas/pago/listarCajasAbiertas.php`, {
            method: 'GET'
        });
        const response = await caja_abierta.json();
        if (response.length > 0) {
            let caja = {};
            response.forEach(c => caja = c);
            setCajaCobranzaSubmit(caja);
            setCajaRecuperada(caja);
            listarPagosCaja(caja.id);
        } else {
            setTextAlert('No existe una caja abierta');
            setTypeAlert('warning');
            setAlertGeneral(true);
            setTimeout(() => { setAlertGeneral(false) }, 3000);
        }
    }
    const handleSearchCodigoBarra = () => {
        // setDlgSearchCodigoBarra(true);
        if (cajaRecuperada.id !== 0 && cajaRecuperada.fecha_cierre === null) {
            // setOpenProfesional(true);
            setDlgSearchCodigoBarra(true);
            setSearchCodigoBarra({ id: "" });
            listarPagosCaja(cajaRecuperada.id);
            // console.log({ ordenesPagadas });
        } else {
            setTextAlert('Deberia buscar una caja abierta para realizar esta accion');
            setTypeAlert('warning');
            setAlertGeneral(true);
            setTimeout(() => { setAlertGeneral(false) }, 3000);
        }
    }
    const validResumCaja = async () => {
        if (cajaRecuperada.id !== 0) {
            const pagosCaja = await fetch(`${UrlsConst.URL_API}/ventas/pago/listarPagosCajaCobranza.php?id=${cajaRecuperada.id}`, {
                method: 'GET'
            });
            const response = await pagosCaja.json();
            if (response.length > 0) {
                setOrdenesPagadas(response);
                const caja_chica_recuperar = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${cajaRecuperada.id}`);
                const response_cajaChica = await caja_chica_recuperar.json();
                const registros_caja = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/listarCajaChicaRegistro.php?id=${response_cajaChica.id}`);
                const response_registros = await registros_caja.json();
                let rendidos = 0;
                let sin_rendir = 0;
                const rendido = response_registros.filter(r => Number(r.total_rendido) !== 0);
                const sinrendido = response_registros.filter(r => Number(r.total_rendido) === 0);
                rendido.forEach(p => {
                    rendidos += Number(p.total_rendido)
                });
                sinrendido.forEach(p => {
                    sin_rendir += Number(p.total_entregado)
                });
                setTotalCajaChica(rendidos + sin_rendir);
                setOrdenesPagos(true);
            } else {
                setOrdenesPagadas([]);
                setAlertGeneralNew({ ...alertGeneralNew, text: 'Datos no encontrados', type: 'warning', open: true });
                setTimeout(() => { setAlertGeneralNew({ ...alertGeneralNew, open: false, text: "", type: "warning" }) }, 3500);
            }
        } else {
            setAlertGeneralNew({ ...alertGeneralNew, text: 'Datos no encontrados para la fecha', type: 'warning', open: true });
            setTimeout(() => { setAlertGeneralNew({ ...alertGeneralNew, open: false, text: "", type: "warning" }) }, 3500);
        }
    }
    const colorTable = {
        header: blue[700],
        footer: "",
        fondo: "#EBF8FF",
        subtotal: blue[500],
        total: "#1abd9c",
        color: "#fff"
    }
    return (
        <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignContent: 'center' }} className="contained">
            <Snackbar open={alertGeneralNew.open} autoHideDuration={6000} onClose={() => setAlertGeneralNew({ ...alertGeneralNew, open: false, text: "", type: "warning" })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity={alertGeneralNew.type} sx={{ width: '100%' }}>
                    {alertGeneralNew.text}
                </Alert>
            </Snackbar>
            <Snackbar open={alertGeneral} autoHideDuration={6000} onClose={() => setAlertGeneral(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={() => setAlertGeneral(false)} severity={typeAlert} sx={{ width: '100%' }}>
                    {textAlert}
                </Alert>
            </Snackbar>
            {/* hasta aquiiiii */}
            <Grid item md={11} xs={12} sx={{ pt: 5 }}>
                <Paper elevation={2} style={{ padding: '1em', }}>
                    <Grid container item xs={12} md={12} sx={{ justifyContent: "space-between" }}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h6'>CAJA COBRANZA</Typography>
                        </Grid>
                        <Grid container item xs={12} md={4} sx={{ pt: { xs: 1, md: 0 } }}>
                            <Grid item xs={6} md={6}>
                                <Stack spacing={1} direction="row">
                                    <Typography style={{ marginRight: '10px', fontSize: '16px', fontWeight: 'bold' }} >USUARIO:</Typography>
                                    {JSON.parse(sessionStorage.getItem('usuario_log')).usuario}
                                </Stack>
                            </Grid>
                            <Grid item xs={6} md={6} >
                                <Stack spacing={1} direction="row" sx={{ justifyContent: { xs: "end", sm: "center" } }}>
                                    <Typography variant='h7' style={{ marginRight: '10px', fontSize: '16px', fontWeight: 'bold' }}>ESTADO:</Typography>
                                    {cajaRecuperada.fecha_apertura === '' && cajaRecuperada.fecha_cierre === '' ?
                                        <Chip color="warning" size='small' label="NO EXISTE"></Chip> : ''}
                                    {cajaRecuperada.fecha_apertura !== '' && cajaRecuperada.fecha_cierre === null ?
                                        <Chip color="success" size='small' label="ABIERTA"></Chip> : ''}
                                    {cajaRecuperada.fecha_apertura !== '' && cajaRecuperada.fecha_cierre !== null ?
                                        <Chip color="error" size='small' label="CERRADA"></Chip> : ''}
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={4} sx={{ justifyContent: { xs: "center", md: "end" }, pt: { xs: 1, md: 0 } }}>
                            <Stack spacing={0.2} direction="row">
                                <Button variant='contained' color="primary" startIcon={<SearchIcon />} onClick={handleSearchProfesional}>Buscar Profesional</Button>
                                <Tooltip title="BUSCAR POR CODIGO DE BARRAS">
                                    <Button variant='contained' color="primary" onClick={handleSearchCodigoBarra}><ViewWeek /></Button>
                                </Tooltip>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ paddingTop: "1em" }}>
                        <Grid item xs={12} md={9.5} sx={{ pt: 2 }}>
                            <Paper elevation={1} style={{ padding: '1em', minHeight: 135 }}>
                                <Typography color="common.black" sx={{ fontSize: "18px" }}>ACCIONES DE CAJA</Typography>
                                <Grid container spacing={2}>
                                    <Grid container item md={5} xs={12} spacing={1} >
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                margin='normal'
                                                label="FECHA APERTURA"
                                                fullWidth
                                                variant='standard'
                                                type="date"
                                                name="fecha_apertura"
                                                value={cajaCobranzaSubmit ? cajaCobranzaSubmit.fecha_apertura : ''}
                                                onChange={handleFecha}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position='start'>
                                                            <CalendarMonthIcon />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                disabled
                                                margin='normal'
                                                id="fecha_cierre"
                                                label="FECHA CIERRE"
                                                value={cajaRecuperada.fecha_cierre !== null ? cajaRecuperada.fecha_cierre : "SIN CERRAR"}
                                                type="text"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <CalendarMonth />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                variant="standard"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item md={7} xs={12} >
                                        <Grid container spacing={0.3} sx={{ justifyContent: "center", pt: 3 }}>
                                            {cajaRecuperada.id === 0 &&
                                                <Grid item xs={10} sm={4} md={4}>
                                                    <Button fullWidth variant='contained' color="primary" startIcon={<Undo />} onClick={irCajaAbierta}>Ir Caja abierta</Button>
                                                </Grid>
                                            }
                                            {cajaRecuperada.id !== 0 && cajaRecuperada.fecha_cierre !== null &&
                                                <Grid item xs={10} sm={4} md={4}>
                                                    <Button fullWidth variant='contained' color="primary" startIcon={<Undo />} onClick={irCajaAbierta}>Ir Caja abierta</Button>
                                                </Grid>
                                            }
                                            {cajaRecuperada.id === 0 &&
                                                <Grid item xs={10} sm={4} md={4}>
                                                    <Button fullWidth variant='contained' color="success" startIcon={<LockOpenIcon />} onClick={handleCaja}>Abrir CAJA</Button>
                                                </Grid>}
                                            {cajaRecuperada.id !== 0 && cajaRecuperada.fecha_cierre != null &&
                                                <Grid item xs={10} sm={4} md={4}>
                                                    <Button fullWidth variant='contained' color="success" startIcon={<LockOpenIcon />} onClick={() => setDialogCajaCerrada(true)}>ABRIR CAJA</Button>
                                                </Grid>}
                                            {cajaRecuperada.id !== 0 && cajaRecuperada.fecha_cierre === null &&
                                                <Grid item xs={10} sm={4} md={4}>
                                                    <Button fullWidth variant='contained' color="warning" startIcon={<LockIcon />} onClick={handleCajaCerrar}>CERRAR CAJA</Button>
                                                </Grid>}
                                            {cajaRecuperada.fecha_apertura !== "" && cajaRecuperada.fecha_cierre === null &&
                                                <Grid item xs={10} sm={4} md={4}>
                                                    <Button fullWidth style={{ background: '#17806D', color: '#fff' }} startIcon={<PaymentsIcon />} onClick={handlePago} >REALIZAR PAGO</Button>
                                                </Grid>}
                                            <Grid item xs={10} sm={4} md={4}>
                                                <Button fullWidth style={{ background: '#17806D', color: '#fff' }} startIcon={<AttachMoney />} onClick={validResumCaja}>RESUMEN DE CAJA</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={2.5}>
                            <Card style={{ padding: '1em', minHeight: 135 }}>
                                <Grid container>
                                    <Grid item md={12} xs={12}>
                                        <Typography color="common.black" sx={{ fontSize: "18px" }}>DATOS</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                                            <SwitchAccount style={{ fontSize: '20px' }} />
                                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{profesional.apellidos_nombres !== "" ? profesional.apellidos_nombres : 'DEFAULT'}</span>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                                            <BadgeIcon style={{ fontSize: '20px' }} />
                                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{profesional.numero_documento !== "" ? profesional.numero_documento : '0'}</span>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                                            <SchoolIcon style={{ fontSize: '20px' }} />
                                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{profesional.colegiatura !== "" ? profesional.colegiatura : 'SIN COLEGIATURA'}</span>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'left', margin: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>
                                            <LocationOn style={{ fontSize: '20px' }} />
                                            <span style={{ marginLeft: '0.4em', marginTop: '0.01em', fontSize: '15px' }}>{profesional.direccion !== "" ? profesional.direccion : 'SIN DIRECCION'}</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>

                    </Grid>
                    <Grid container item xs={12} md={12}>
                        {cajaRecuperada.fecha_apertura !== "" && cajaRecuperada.fecha_cierre === null &&
                            <Grid container style={{ marginTop: '10px' }} spacing={2}>
                                {profesional.id !== 0 &&
                                    <Grid item md={12} xs={12}>
                                        <Paper>
                                            <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 400 }}>
                                                <Table aria-label="simple table" stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', }}>N°</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>SERVICIO</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>PACIENTE</TableCell>
                                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>PRESUPUESTO</TableCell>
                                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>A CUENTA</TableCell>
                                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>SALDO</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>ESTADO</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                <Checkbox
                                                                    indeterminate={selected.length > 0 && selected.length < ordenes.length}
                                                                    checked={ordenes.length > 0 && selected.length === ordenes.length}
                                                                    onChange={handleSelectAllClick}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            (rowsPerPage2 > 0
                                                                ? ordenes.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                                                                : ordenes
                                                            ).map((orden) => {
                                                                const isItemSelected = isSelected(orden.id);
                                                                return (
                                                                    <TableRow
                                                                        key={orden.id}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell component="th" scope="row">
                                                                            {orden.id}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {orden.servicio}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {orden.descripcion}
                                                                        </TableCell>
                                                                        <TableCell>{orden.paciente}</TableCell>
                                                                        <TableCell align="right">S/ {orden.presupuesto}</TableCell>
                                                                        <TableCell align="right">S/ {orden.total_pagado}</TableCell>
                                                                        <TableCell align="right">S/ {orden.presupuesto - orden.total_pagado}</TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                orden.estado_pago === 'A' &&
                                                                                <Chip label="A CUENTA" color="warning" variant="contained" />
                                                                            }
                                                                            {
                                                                                orden.estado_pago === 'P' &&
                                                                                <Chip label="PENDIENTE" color="error" variant="contained" />
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Checkbox
                                                                                checked={isItemSelected}
                                                                                onClick={(event) => {
                                                                                    handleClick(event, orden.id);
                                                                                    if (event.target.checked != false) {
                                                                                        if (Number(orden.total_pagado) > 0) {
                                                                                            let contador = Number(orden.presupuesto) - Number(orden.total_pagado);
                                                                                            let total = Number(totalPago) + contador;
                                                                                            setTotalPago(total.toFixed(2));
                                                                                            const add = [].concat(pagar);
                                                                                            add.push({
                                                                                                id: 0,
                                                                                                fk_ven_caja_cobranza: cajaRecuperada.id,
                                                                                                fk_ven_orden: orden.id,
                                                                                                fecha_registro: new Date().toJSON().slice(0, 10),
                                                                                                presupuesto: orden.presupuesto,
                                                                                                total_pagado: orden.total_pagado
                                                                                            });
                                                                                            setPagar(add);
                                                                                        } else {
                                                                                            let contador = Number(totalPago) + Number(orden.presupuesto);
                                                                                            setTotalPago(contador.toFixed(2))
                                                                                            const add = [].concat(pagar);
                                                                                            add.push({
                                                                                                id: 0,
                                                                                                fk_ven_caja_cobranza: cajaRecuperada.id,
                                                                                                fk_ven_orden: orden.id,
                                                                                                fecha_registro: new Date().toJSON().slice(0, 10),
                                                                                                presupuesto: orden.presupuesto,
                                                                                                total_pagado: orden.total_pagado
                                                                                            });
                                                                                            setPagar(add);
                                                                                        }
                                                                                    } else {
                                                                                        if (Number(orden.total_pagado) > 0) {
                                                                                            let monto = Number(orden.presupuesto) - Number(orden.total_pagado);
                                                                                            let contador = Number(totalPago) - monto;
                                                                                            setTotalPago(contador.toFixed(2))
                                                                                        } else {
                                                                                            let contador = Number(totalPago) - Number(orden.presupuesto);
                                                                                            setTotalPago(contador.toFixed(2))
                                                                                        }
                                                                                        const remove = pagar.filter((item) => item.fk_ven_orden !== orden.id);
                                                                                        setPagar(remove);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                component="div"
                                                labelRowsPerPage="Filas por columnas"
                                                count={ordenes.length}
                                                rowsPerPage={rowsPerPage2}
                                                page={page2}
                                                onPageChange={handleChangePage2}
                                                onRowsPerPageChange={handleChangeRowsPerPage2}
                                            />
                                        </Paper>
                                    </Grid>
                                }
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Grid>
            <Dialog open={openCaja} onClose={handleClose} maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Abrir Caja</Typography>
                    <Typography variant='secundary'>¿Estas seguro de abrir caja?</Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    {
                        cajaRecuperada.id === 0 &&
                        <Button variant='contained' color='success' startIcon={<LockOpenIcon />} onClick={abrirNewCaja}>ABRIR CAJA</Button>
                    }
                    {
                        cajaRecuperada.id !== 0 && cajaRecuperada.fecha_cierre != null &&
                        <Button variant='contained' color='success' startIcon={<LockOpenIcon />} onClick={abrirCaja}>ABRIR CAJA</Button>
                    }
                    <Button onClick={handleClose} startIcon={<CloseIcon />} variant='contained' color="error">cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openCajaCerrar} onClose={handleClose} maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">{cajaCerrarText.title}</Typography>
                    <Typography variant='secundary'>{cajaCerrarText.text}</Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <LoadingButton loading={loadingCerrarCaja} variant='contained' color='warning' startIcon={<LockIcon />} onClick={cerrarCaja}>CERRAR CAJA</LoadingButton>
                    <Button onClick={() => setOpenCajaCerrar(false)} startIcon={<CloseIcon />} variant='contained' color="error">cancelar</Button>
                </DialogActions>
            </Dialog>
            {/* buscar paciente dialog */}
            <Dialog open={openProfesional} onClose={() => { setOpenProfesional(false); setProfesionales([]) }} fullWidth={true} maxWidth="md">
                <Button sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000",
                }} startIcon={<CloseIcon />} onClick={() => { setOpenProfesional(false); setProfesionales([]) }}  ></Button>
                <DialogTitle>BUSCAR PROFESIONAL</DialogTitle>
                <DialogContent>
                    <Collapse in={alert}>
                        <Alert variant='outlined' severity={typeAlert}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlert(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {textAlert}
                        </Alert>
                    </Collapse>
                    <Grid container spacing={2}>
                        <Grid item md={5} xs={12}>
                            <TextField
                                fullWidth
                                label="NOMBRE"
                                name="apellidos_nombres"
                                margin='normal'
                                variant='standard'
                                onKeyUp={handleButton}
                                onChange={handleProfesional}
                                value={profesionalSearch.apellidos_nombres}
                            />
                        </Grid>
                        <Grid item md={5} xs={12} >
                            <TextField
                                fullWidth
                                label="DOCUMENTO"
                                name="numero_documento"
                                margin='normal'
                                variant='standard'
                                onKeyUp={handleButton}
                                onChange={handleProfesional}
                                value={profesionalSearch.numero_documento}
                            />
                        </Grid>
                        <Grid item md={2} xs={12} style={{ marginTop: '25px', textAlign: 'center' }} >
                            <Button onClick={searchProfesional} variant="contained" color="warning" startIcon={<SearchIcon />}>BUSCAR</Button>
                        </Grid>
                        <Grid item md={12}>
                            <Paper>
                                <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 350 }}  >
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>N°</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>PROFESIONAL</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>DOCUMENTO</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>DIRECCION</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                (rowsPerPage > 0
                                                    ? profesionales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : profesionales
                                                ).map((profesional) => (
                                                    <TableRow onClick={() => uploadProfesional(profesional)}
                                                        key={profesional.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {profesional.id}
                                                        </TableCell>
                                                        <TableCell>{profesional.apellidos_nombres}</TableCell>
                                                        <TableCell>{profesional.numero_documento}</TableCell>
                                                        <TableCell>{profesional.direccion}</TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component="div"
                                    labelRowsPerPage="Filas por columnas"
                                    count={profesionales.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog open={pagoOpen} onClose={() => { setPagoOpen(false); setReferenciaPago({ ...referenciaPago, error: false, text: '' }) }} fullWidth={true} maxWidth="sm"   >
                <DialogTitle>NUEVO PAGO</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                style={{ textAlign: 'start' }}
                                select
                                margin='normal'
                                fullWidth
                                value={pagoSubmit.tipo}
                                label="TIPO DE PAGO"
                                variant='standard'
                                onChange={handleTipoPago}
                            >
                                {tipo_pagos.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                name="monto"
                                label="MONTO"
                                margin='normal'
                                variant='standard'
                                fullWidth
                                error={montoPago.error}
                                helperText={montoPago.text}
                                value={pagoSubmit ? pagoSubmit.monto : 0}
                                onChange={
                                    (e) => {
                                        const { value } = e.target;
                                        if (value !== "") {
                                            if (isNaN(value) === false) {
                                                if (Number(value) > totalPago) {
                                                    setLoading(true)
                                                    setMontoPago({ ...montoPago, error: true, text: 'Monto no puede ser mayor al monto ingresado' });
                                                    setPagoSubmit({ ...pagoSubmit, monto: value });
                                                } else {
                                                    setLoading(false);
                                                    setMontoPago({ ...montoPago, error: false, text: '' });
                                                    setPagoSubmit({ ...pagoSubmit, monto: value });
                                                }
                                            } else {
                                                setMontoPago({ ...montoPago, error: true, text: 'Monto no puede ser un texto' });
                                            }
                                        } else {
                                            setPagoSubmit({ ...pagoSubmit, monto: '' });
                                            setMontoPago({ ...montoPago, error: true, text: 'Monto obligatorio' });
                                        }

                                    }
                                }
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <TextField
                                name='referencia'
                                label="REFERENCIA"
                                margin='normal'
                                variant='standard'
                                fullWidth
                                inputProps={{ maxLength: 100 }}
                                error={referenciaPago.error}
                                helperText={referenciaPago.text}
                                value={pagoSubmit ? pagoSubmit.referencia : ''}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value !== "") {
                                        setPagoSubmit({ ...pagoSubmit, referencia: value.toUpperCase() });
                                        setReferenciaPago({ ...referenciaPago, error: false, text: '' });
                                    } else if (value === "" && pagoSubmit.tipo === "E" || pagoSubmit.tipo === "Y") {
                                        setPagoSubmit({ ...pagoSubmit, referencia: '' });
                                        // setReferenciaPago({ ...referenciaPago, error: true, text: '' });
                                    } else {
                                        setPagoSubmit({ ...pagoSubmit, referencia: '' });
                                        setReferenciaPago({ ...referenciaPago, error: true, text: 'Referencia obligatoria' });
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <LoadingButton
                        variant='outlined' startIcon={<CheckIcon />}
                        onClick={setPagarCaja}
                        loading={loading}
                        color="success"
                        loadingIndicator="Comprobando...">
                        GUARDAR
                    </LoadingButton>
                    <Button onClick={() => { setPagoOpen(false); setReferenciaPago({ ...referenciaPago, error: false, text: '' }) }} startIcon={<CloseIcon />} variant='outlined' color="error">CERRAR</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={ordenesPagos} onClose={() => setOrdenesPagos(false)} fullWidth={true} maxWidth="xl">
                <Button sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000",
                }} startIcon={<CloseIcon />} onClick={() => setOrdenesPagos(false)} ></Button>
                <DialogTitle>
                    RESUMEN DE PAGOS DE CAJA {cajaRecuperada.fecha_apertura}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <PDFDownloadLink document={<ExportPdf datosEmpresa={datosEmpresa} ordenesPagadas={ordenesPagadas} cajaRecuperada={cajaRecuperada} totalCajaChica={totalCajaChica} totalRegistro={totalRegistro} />} fileName={"RPTE-RESUMEN-CAJA-" + cajaRecuperada.fecha_apertura} style={{ marginLeft: '10px' }}>
                                <Button variant='contained' color="error" startIcon={<img style={{ width: '20px', height: '20px' }} src={pdf} />} >PDF</Button>
                            </PDFDownloadLink>
                            <ExportExcel data={ordenesPagadas} caja={cajaRecuperada} />
                            <Paper style={{ padding: '1em', marginTop: '10px' }}>
                                <TableContainer id="table" sx={{ maxWidth: '275px', minWidth: '100%', height: 450 }}>
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>N°</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>PROFESIONAL</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>ORDEN</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>SERVICIO</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>T. PAGO</TableCell>
                                                <TableCell align='right' style={{ fontWeight: 'bold' }}>PRESUPUESTO</TableCell>
                                                <TableCell align='right' style={{ fontWeight: 'bold' }}>PAGADO</TableCell>
                                                <TableCell align='right' style={{ fontWeight: 'bold' }}>SALDO</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>REFERENCIA</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>ESTADO</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                (rowsPerPage3 > 0
                                                    ? ordenesPagadas.slice(page3 * rowsPerPage3, page3 * rowsPerPage3 + rowsPerPage3)
                                                    : ordenesPagadas
                                                ).map((orden) => (
                                                    <TableRow
                                                        key={orden.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {orden.id}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {orden.profesional}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {orden.descOrden}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {orden.servicio}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {orden.tipo === 'E' ? 'EFECTIVO' : orden.tipo === 'T' ? 'TARJETA' : orden.tipo === 'D' ? 'DEPOSITO' : orden.tipo === 'Y' ? 'YAPE' : ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" align='right'>
                                                            S/ {orden.presupuesto}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" align='right'>
                                                            S/ {orden.monto}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" align='right'>
                                                            S/ {orden.presupuesto - orden.monto}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {orden.referencia}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {orden.estado_pago === "G" ? <Chip label="PAGADO" color="success" /> : orden.estado_pago === 'A' ? <Chip label="A CUENTA" color="warning" /> : ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Tooltip title="ELIMINAR PAGO">
                                                                {/* deletePago(orden) */}
                                                                <IconButton size="small" onClick={() => { setPagoDelete(true); setPagoRecuperado(orden) }}>
                                                                    <DeleteIcon color='error' fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer >
                                <TableContainer sx={{ maxHeight: 80 }}>
                                    <Table size='small' stickyHeader>
                                        <TableBody >
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: colorTable.total }}>
                                                <TableCell component="th" scope="row" sx={{ color: "#fff", width: "51%" }}>TOTAL</TableCell>
                                                <TableCell component="th" scope="row" sx={{ color: "#fff", width: "10%" }} align='right'>S/ {ordenesPagadas.reduce((total, item) => parseFloat(total) + parseFloat(item.presupuesto), 0).toFixed(2)}</TableCell>
                                                <TableCell component="th" scope="row" sx={{ color: "#fff", width: "8%" }} align='right'>S/ {ordenesPagadas.reduce((total, item) => parseFloat(total) + parseFloat(item.monto), 0).toFixed(2)}</TableCell>
                                                <TableCell component="th" scope="row" sx={{ color: "#fff", width: "8%" }} align='right'>S/ {ordenesPagadas.reduce((total, item) => parseFloat(total) + parseFloat(item.presupuesto) - parseFloat(item.monto), 0).toFixed(2)}</TableCell>
                                                <TableCell component="th" scope="row" sx={{ color: "#fff" }} colSpan={2}></TableCell>

                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component="div"
                                    labelRowsPerPage="Filas por columnas"
                                    count={ordenesPagadas.length}
                                    rowsPerPage={rowsPerPage3}
                                    page={page3}
                                    onPageChange={handleChangePage3}
                                    onRowsPerPageChange={handleChangeRowsPerPage3}
                                />
                            </Paper >
                        </Grid >
                    </Grid >
                    {/* {
                        <PDFViewer width="100%" height={600}>
                            <ExportPdf datosEmpresa={datosEmpresa} ordenesPagadas={ordenesPagadas} cajaRecuperada={cajaRecuperada} />
                        </PDFViewer>
                    } */}
                </DialogContent >
            </Dialog >
            <Dialog open={dialogCajaAbierta} onClose={() => setDialogCajaAbierta(false)} maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Caja Abierta {cajAbierta.fecha_apertura}</Typography>
                    <Typography variant='secundary'>¿Estas seguro de cerrar la caja abierta?</Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <LoadingButton loading={loadingAbrirCaja} variant='contained' startIcon={<LockIcon />} color='success' onClick={cerrarCajaRecuperada}>CERRAR CAJA</LoadingButton>
                    <Button onClick={() => setDialogCajaAbierta(false)} startIcon={<CloseIcon />} variant='contained' color="error">CANCELAR</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialogCajaCerrada} onClose={() => setDialogCajaCerrada(false)} maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Abrir Caja Cerrada</Typography>
                    <Typography variant='secundary'>¿Estas seguro de abrir una caja cerrada?</Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <LoadingButton loading={loagindAbrirCerrada} variant='contained' startIcon={<LockIcon />} color='success' onClick={abrirCaja}>ABRIR CAJA</LoadingButton>
                    <Button onClick={() => setDialogCajaCerrada(false)} startIcon={<CloseIcon />} variant='contained' color="error">CANCELAR</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={pagoDelete} onClose={() => setPagoDelete(false)} maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Eliminar Pago</Typography>
                    {/* <Typography variant=''>del </Typography> */}
                    <Typography variant='secundary'>¿Estas seguro de eliminar el pago?</Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button variant='contained' startIcon={<CheckIcon />} color='error' onClick={deletePago}>Aceptar</Button>
                    <Button onClick={() => setPagoDelete(false)} startIcon={<CloseIcon />} variant='contained' color="primary">CANCELAR</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dlgSearchCodigoBarra} onClose={() => setDlgSearchCodigoBarra(false)} maxWidth="xs" fullWidth>
                <DialogTitle>
                    <Button sx={{
                        position: 'absolute',
                        right: 4,
                        top: 8,
                        color: "#000",
                    }} startIcon={<CloseIcon />} onClick={() => setDlgSearchCodigoBarra(false)} ></Button>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                        <Grid item md={12}>
                            <TextField
                                id="searchCodigoBarra"
                                label="BUSCAR POR CODIGO DE BARRA"
                                type="search"
                                variant="standard"
                                fullWidth
                                onChange={onchSearchCodigoBarra}
                                // value={searchCodigoBarra.id === 0 ? NaN : searchCodigoBarra.id}
                                value={searchCodigoBarra.id}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <Button fullWidth variant="contained" color="primary" onClick={btnBuscarPorCodigo} startIcon={<SearchIcon />}>BUSCAR</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <DialogCajaChica dialogCajaChica={dialogCajaChica} setDialogCajaChica={setDialogCajaChica} cajaRecuperada={cajaRecuperada} cajaChicaRecuperada={cajaChicaRecuperada} />
        </Grid >
    )
}
