import React from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { Button, Tooltip } from '@mui/material';
import excel from '../../../img/excel.png'

export default function Excel(props) {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const [pagosCaja, setPagosCaja] = React.useState([]);
    const exportToCSV = () => {
        const csvData = props.data;
        let pagosC = [].concat(pagosCaja);
        csvData.forEach((pagos) => {
            pagosC.push({
                'N CAJA°': pagos.id_caja,
                'FECHA APERTURA CAJA': pagos.fecha_apertura,
                'FECHA CIERRE CAJA': pagos.fecha_cierre,
                'N° PAGO': pagos.id_pago,
                'FECHA REGISTRO PAGO': pagos.fecha_pago,
                'PROFESIONAL': pagos.profesional,
                'ORDEN': pagos.orden_descripcion,
                'SERVICIO': pagos.servicio,
                'TIPO DE PAGO': pagos.tipo === "E" ? 'EFECTIVO' : pagos.tipo === "D" ? 'DEPOSITO' : pagos.tipo === "T" ? 'TARJETA' : pagos.tipo === 'Y' ? 'YAPE' : '',
                'PRESUPUESTO': pagos.presupuesto,
                'PAGADO': pagos.total_pagado,
                'REFERENCIA': pagos.referencia,
                'ESTADO PAGO': pagos.estado_pago === "A" ? 'A CUENTA' : pagos.estado_pago === "G" ? 'PAGADO' : ''
            });
        });
        setPagosCaja(pagosC);
        // console.log(pagosC)
        const ws = XLSX.utils.json_to_sheet(pagosC, { skipHeader: false });
        var wscols = [
            { wch: 10, },
            { wch: 25 },
            { wch: 25 },
            { wch: 10 },
            { wch: 25 },
            { wch: 40 },
            { wch: 40 },
            { wch: 40 },
            { wch: 25 },
            { wch: 15 },
            { wch: 10 },
            { wch: 40 },
            { wch: 10 },
        ];
        ws['!cols'] = wscols;
        const wb = { Sheets: { caja: ws }, SheetNames: ['caja'] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'reporteExcel' + fileExtension);

    };
    return (
        <Tooltip title="EXPORTAR EXCEL" placement='top'>
            <Button fullWidth startIcon={<img style={{ width: '20px', height: '20px' }} src={excel} />} variant='contained' color="success" onClick={exportToCSV}>EXCEL</Button>
        </Tooltip>
    )
}
