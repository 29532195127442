import {
    Alert,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import * as React from 'react';
import UrlsConst from '../../config/config';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OrdenTrabajo from './dialogs/dialogOrdenServicio';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckIcon from '@mui/icons-material/Check';


export default function OrdenServicio() {
    // contantes
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [orden, setOrden] = React.useState({});
    const [ordenID, setOrdenID] = React.useState(0);
    const [servicio, setServicio] = React.useState({});
    const [materiales, setMateriales] = React.useState([]);
    const [material, setMaterial] = React.useState({});
    const [pruebas, setPruebas] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [profesional, setProfesional] = React.useState({});
    const [searchOrden, setSearchOrden] = React.useState({
        paciente: '',
        profesional: '',
        id: "",
    });
    const [ordenes, setOrdenes] = React.useState([]);
    const [alertGeneral, setAlertGeneral] = React.useState({
        open: false,
        text: '',
        type: 'error'
    });
    // handle
    const handleClose = () => {
        setOpenEdit(false);
        setOpenDelete(false);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // funciones
    const buscarOrden = async () => {
        const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenUI.php?paciente=${searchOrden.paciente}&profesional=${searchOrden.profesional}&id=${searchOrden.id}`, {
            method: 'GET'
        });
        const response = await getOrden.json();
        setOrdenes(response);
    }
    const cambiarBtn = (e) => {
        if (e.which == 13) {
            buscarOrden();
        }
    }
    const listarOrden = async () => {
        const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenUI.php?paciente=&&profesional=`, {
            method: 'GET'
        });
        const response = await getOrden.json();
        setOrdenes(response);
    }

    const ordenEdit = async (id) => {
        const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + id, {
            method: 'GET'
        });
        const response_orden = await getOrden.json();
        setOrden(response_orden);
        const getProfesional = await fetch(`${UrlsConst.URL_API}/ventas/profesional/recuperarProfesional.php?id=` + response_orden.fk_ven_profesional, {
            method: "GET"
        });
        const response = await getProfesional.json();
        setProfesional(response);
        const getServicio = await fetch(`${UrlsConst.URL_API}/ventas/servicio/recuperarServicio.php?id=` + response_orden.fk_ven_servicio, {
            method: 'GET'
        });
        const response_servicio = await getServicio.json();
        setServicio(response_servicio);
        const getMaterial = await fetch(`${UrlsConst.URL_API}/ventas/servicio/listarServicioMaterial.php?id=` + response_orden.fk_ven_servicio, {
            method: 'GET'
        });
        const response_materiales = await getMaterial.json();
        console.log(response_materiales)
        setMateriales(response_materiales);
        const getmaterial = response_materiales.filter((item) => item.fk_cmp_material === response_orden.fk_cmp_material);
        getmaterial.forEach(item => {
            setMaterial(item);
        });
        const getPruebasOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarPruebaOrden.php?id=` + id, {
            method: 'GET'
        });
        const response_pruebas = await getPruebasOrden.json();
        setPruebas(response_pruebas);
        setOpenEdit(true);
    }
    const deleteOrden = async () => {
        // const eliminarOrdenDetalle = await fetch(`${UrlsConst.URL_API}/ventas/orden/eliminarTodoDetalle.php?id=` + ordenID, {
        //     method: 'POST'
        // });
        // const response_delete_detalle = await eliminarOrdenDetalle.json();
        // const eliminarOrdenPrueba = await fetch(`${UrlsConst.URL_API}/ventas/orden/eliminarTodoPrueba.php?id=` + ordenID, {
        //     method: 'POST'
        // });
        // const response_delete_prueba = await eliminarOrdenPrueba.json();
        // const eliminar_orden = await fetch(`${UrlsConst.URL_API}/ventas/orden/eliminarOrden.php?id=` + ordenID, {
        //     method: 'POST'
        // });
        // const response = await eliminar_orden.json();
        buscarOrden();
        const getOrden = await fetch(`${UrlsConst.URL_API}/ventas/orden/recuperarOrden.php?id=` + ordenID, {
            method: 'GET'
        });
        const response_orden = await getOrden.json();
        if (response_orden.estado_pago === "P") {
            const eliminarOrdenDetalle = await fetch(`${UrlsConst.URL_API}/ventas/orden/eliminarTodoDetalle.php?id=` + ordenID, {
                method: 'POST'
            });
            const response_delete_detalle = await eliminarOrdenDetalle.json();
            const eliminarOrdenPrueba = await fetch(`${UrlsConst.URL_API}/ventas/orden/eliminarTodoPrueba.php?id=` + ordenID, {
                method: 'POST'
            });
            const response_delete_prueba = await eliminarOrdenPrueba.json();
            const eliminar_orden = await fetch(`${UrlsConst.URL_API}/ventas/orden/eliminarOrden.php?id=` + ordenID, {
                method: 'POST'
            });
            const response = await eliminar_orden.json();
            buscarOrden();
        } else {
            setAlertGeneral({ ...alertGeneral, type: 'error', text: 'No se pudo elimnar', open: true });
            setTimeout(() => { setAlertGeneral({ ...alertGeneral, open: false }) }, 3000);
        }
        setOpenDelete(false);
    }
    React.useEffect(() => {
        buscarOrden();
    }, []);
    const onchgbuscarOrdCodigo = (e) => {
        const { value } = e.target;
        if (value !== "") {
            setSearchOrden({ ...searchOrden, id: value.toUpperCase() });
        } else {
            setSearchOrden({ ...searchOrden, id: '' });
            listarOrden();
        }
    }
    return (
        <>
            <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignContent: 'center' }} className="contained">
                <Snackbar open={alertGeneral.open} autoHideDuration={6000} onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={handleClose} severity={alertGeneral.type} sx={{ width: '100%' }}>
                        {alertGeneral.text}
                    </Alert>
                </Snackbar>
                <Grid item md={11.5} xs={11.5} style={{ marginTop: window.screen.width < 500 ? '40px' : '0' }}>
                    <Paper elevation={1} style={{ padding: '1em' }}>
                        <Grid container style={{ justifyContent: 'space-between', textAlign: 'center' }}>
                            <Typography variant='h5'>ORDENES DE SERVICIO</Typography>
                        </Grid>
                        <Grid container style={{ justifyContent: 'center' }} spacing={1}>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    id="search"
                                    name="string_search"
                                    fullWidth label="PACIENTE"
                                    margin='normal' variant="standard"
                                    value={searchOrden.paciente}
                                    onChange={
                                        (e) => {
                                            const { value } = e.target;
                                            if (value !== "") {
                                                setSearchOrden({ ...searchOrden, paciente: value.toUpperCase() });
                                            } else {
                                                setSearchOrden({ ...searchOrden, paciente: '' });
                                                listarOrden();
                                            }
                                        }
                                    }
                                    onKeyUp={cambiarBtn}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    id="search"
                                    name="string_search"
                                    fullWidth label="PROFESIONAL"
                                    margin='normal' variant="standard"
                                    value={searchOrden.profesional}
                                    onChange={
                                        (e) => {
                                            const { value } = e.target;
                                            if (value !== "") {
                                                setSearchOrden({ ...searchOrden, profesional: value.toUpperCase() });
                                            } else {
                                                setSearchOrden({ ...searchOrden, profesional: '' });
                                                listarOrden();
                                            }
                                        }
                                    }
                                    onKeyUp={cambiarBtn}
                                />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <TextField
                                    id="search"
                                    name="string_search"
                                    fullWidth label="BUSCAR POR CODIGO DE BARRAS"
                                    margin='normal'
                                    variant="standard"
                                    value={searchOrden.id}
                                    onChange={onchgbuscarOrdCodigo}
                                    onKeyUp={cambiarBtn}
                                />
                            </Grid>

                            <Grid item md={2} xs={12} style={{ textAlign: 'center', marginTop: '25px' }}>
                                <Button color="warning" variant="contained" onClick={buscarOrden} startIcon={<SearchIcon />}>
                                    Buscar orden
                                </Button>
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <Paper elevation={1} style={{ padding: '0.8em' }}>
                                    <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>LEYENDA</Typography>
                                    <Grid container spacing={1} style={{ width: '100%', justifyContent: 'center' }}>
                                        <Grid item md={2} xs={6}>
                                            <Chip style={{ background: '#008b8b', width: '100%', color: '#fff', fontWeight: 'bold' }} label="C : Cubeta" />
                                        </Grid>
                                        <Grid item md={2} xs={6}>
                                            <Chip style={{ background: '#37b24d', color: '#fff', width: '100%', fontWeight: 'bold' }} label="E : Entregado" />
                                        </Grid>
                                        <Grid item md={2} xs={6}>
                                            <Chip style={{ background: '#0080c0', color: '#fff', width: '100%', fontWeight: 'bold' }} label="F : Facturado" />
                                        </Grid>
                                        <Grid item md={2} xs={6}>
                                            <Chip style={{ background: '#008040', color: '#fff', width: '100%', fontWeight: 'bold' }} label="G : Pagado" />
                                        </Grid>
                                        <Grid item md={2} xs={6}>
                                            <Chip style={{ background: '#f59f00', color: '#fff', width: '100%', fontWeight: 'bold' }} label="A : A cuenta" />
                                        </Grid>
                                        <Grid item md={2} xs={6}>
                                            <Chip style={{ background: '#ff3300', color: '#fff', width: '100%', fontWeight: 'bold' }} label="P : Pendiente" />
                                        </Grid>
                                        <Grid item md={2} xs={6}>
                                            <Chip style={{ background: '#7851A9', color: '#fff', width: '100%', fontWeight: 'bold' }} label="M : Prueba Mordida" />
                                        </Grid>
                                        <Grid item md={2} xs={6}>
                                            <Chip style={{ background: '#55B4B0', color: '#fff', width: '100%', fontWeight: 'bold' }} label="AN : Antagonista" />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Paper>
                                    <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}  >
                                        <Table aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ width: '2%', fontWeight: 'bold' }}>N°</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>PROFESIONAL</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>PACIENTE</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>F. INGRESO</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>F.E PROGRAMADA </TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>SERVICIO</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>ESTADO</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>ACCIONES</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {/*   (rowsPerPage2 > 0
                                                                        ? pacienteOrden.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                                                                        : pacienteOrden
                                                                    ).map((orden) => ( */}
                                                {(rowsPerPage > 0
                                                    ? ordenes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : ordenes
                                                ).map((orden) => (
                                                    <TableRow
                                                        key={orden.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {orden.numero_orden}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {orden.profesional}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {orden.descripcion}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {orden.paciente}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {orden.fecha_ingreso}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {orden.fecha_entrega}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {orden.servicio}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <div style={{ display: 'flex' }}>
                                                                {
                                                                    orden.cubeta !== 0 ?
                                                                        (<div style={{ background: '#008b8b', margin: '2px', textAlign: 'center', color: '#fff', fontSize: '14px', borderRadius: '50px', width: '30px', height: '20px', fontWeight: 'bold' }}>C</div>) : ''

                                                                }
                                                                {
                                                                    orden.esta_entregado !== 0 ?
                                                                        (<div style={{ background: '#37b24d', margin: '2px', textAlign: 'center', color: '#fff', fontSize: '14px', borderRadius: '50px', width: '30px', height: '20px', fontWeight: 'bold' }}>E</div>) : ''
                                                                }
                                                                {
                                                                    orden.esta_facturado !== 0 ?
                                                                        (<div style={{ background: '#0080c0', margin: '2px', textAlign: 'center', color: '#fff', fontSize: '14px', borderRadius: '50px', width: '30px', height: '20px', fontWeight: 'bold' }}>F</div>) : ''
                                                                }
                                                                {
                                                                    orden.prueba_mordida !== 0 ?
                                                                        (<div style={{ background: '#7851A9', margin: '2px', textAlign: 'center', color: '#fff', fontSize: '14px', borderRadius: '50px', width: '30px', height: '20px', fontWeight: 'bold' }}>M</div>) : ''
                                                                }
                                                                {
                                                                    orden.antagonista !== 0 ?
                                                                        (<div style={{ background: '#55B4B0', margin: '2px', textAlign: 'center', color: '#fff', fontSize: '14px', borderRadius: '50px', width: '30px', height: '20px', fontWeight: 'bold' }}>AN</div>) : ''
                                                                }
                                                                {
                                                                    orden.estado_pago === 'P' ?
                                                                        (<div style={{ background: '#ff3300', margin: '2px', textAlign: 'center', color: '#fff', fontSize: '14px', borderRadius: '50px', width: '30px', height: '20px', fontWeight: 'bold' }}>P</div>) : ''
                                                                }
                                                                {
                                                                    orden.estado_pago === 'A' ?
                                                                        (<div style={{ background: '#f59f00', margin: '2px', textAlign: 'center', color: '#fff', fontSize: '14px', borderRadius: '50px', width: '30px', height: '20px', fontWeight: 'bold' }}>A</div>) : ''
                                                                }
                                                                {
                                                                    orden.estado_pago === 'G' ?
                                                                        (<div style={{ background: '#008040', margin: '2px', textAlign: 'center', color: '#fff', fontSize: '14px', borderRadius: '50px', width: '30px', height: '20px', fontWeight: 'bold' }}>G</div>) : ''
                                                                }

                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {
                                                                (<div>
                                                                    <Tooltip title="EDITAR ORDEN">
                                                                        <IconButton aria-label="delete" onClick={() => {
                                                                            ordenEdit(orden.id);
                                                                        }} size="small">
                                                                            <EditIcon color="primary" fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="ELIMINAR ORDEN">
                                                                        <IconButton aria-label="delete" onClick={() => {
                                                                            setOrdenID(orden.id);
                                                                            setOpenDelete(true);
                                                                        }} size="small">
                                                                            <DeleteIcon color="error" fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>)
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        component="div"
                                        rowsPerPageOptions={[10, 25, 50]}
                                        count={ordenes.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        labelRowsPerPage="Filas por página"
                                    />
                                </Paper>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={openEdit} onClose={handleClose} fullScreen={true}>
                <Button sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000",
                }} startIcon={<CloseIcon />} onClick={handleClose} ></Button>
                <DialogTitle>EDITAR ORDEN</DialogTitle>
                <DialogContent>
                    <OrdenTrabajo
                        profesional={profesional}
                        fntOrden={orden}
                        fntServicio={servicio}
                        fntMaterial={materiales}
                        material={material}
                        fntPruebas={pruebas}
                        fntClose={handleClose}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={openDelete} onClose={handleClose}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Eliminar Orden</Typography>
                    ¿Esta seguro de eliminar la orden de servicio?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} variant="contained" style={{ background: '#ed0505' }} onClick={deleteOrden}>Aceptar</Button>
                    <Button startIcon={<CloseIcon />} variant="contained" onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}