import React from 'react'
import { Grid, Typography, Paper, TextField, Button, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, InputAdornment, Chip, TablePagination } from '@mui/material';
import UrlsConst from '../../../config/config';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PdfReporteG from '../pdf/PdfReporteG';
import { DatePicker } from '@mui/lab';

export default function PorTrabajador(props) {
    const datenow = new Date().toJSON().slice(0, 10);
    const typogFuente = { fuente: "", size: 20, sizeTypo2: 15 };
    const [trabajadores, setTrabajadores] = React.useState([]);
    const [tabGeneralTrabID, setTabGeneralTrabID] = React.useState([]);
    const [tabGeneralTrabID2, setTabGeneralTrabID2] = React.useState([]);
    const [datosBuscarTrabId, setDatosBuscarTrabId] = React.useState([]);
    const [dataFechas, setDataFechas] = React.useState({
        desde: datenow, hasta: datenow
    });
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [datosEmpresa, setDatosEmpresa] = React.useState({
        ruc: '',
        razon_social: '',
        direccion: ''
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(() => {
        const cargarTrabajadores = async () => {
            const search = await fetch(`${UrlsConst.URL_API}/sistema/trabajador/buscarTrabajador.php`, {
                method: "POST",
                body: JSON.stringify(""),
            });
            const response = await search.json();
            setTrabajadores(response);
        }
        cargarTrabajadores();
        const getDatosEmpresa = async () => {
            const getRuc = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RUC_EMPRESA`, {
                method: 'GET'
            });
            const response_getRuc = await getRuc.json();
            const getRazon = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RAZON_SOCIAL`, {
                method: 'GET'
            });
            const response_getRazon = await getRazon.json();
            const getDireccion = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=DIRECCION_EMPRESA`, {
                method: 'GET'
            });
            const response_getDireccion = await getDireccion.json();
            setDatosEmpresa({ ruc: response_getRuc.valor_string, razon_social: response_getRazon.valor_string, direccion: response_getDireccion.valor_string });
        }
        getDatosEmpresa();
    }, [dataFechas.desde, dataFechas.hasta])
    async function buscarTrabPorId() {
        if (tabGeneralTrabID.length !== 0) {
            var arrResultados = [];
            var arrResultadosID = [];
            await tabGeneralTrabID.forEach(async (ele) => {
                const fetchRes = await fetch(`${UrlsConst.URL_API}/reportes/RPpruebaTrabajador.php?id=${ele}&desde=${dataFechas.desde}&hasta=${dataFechas.hasta}`);
                const resDB = await fetchRes.json();
                if (arrResultados.length === 0) {
                    arrResultados = resDB;
                } else {
                    arrResultados = arrResultados.concat(resDB);
                }
                arrResultados.sort((a, b) => new Date(a.f_registro_prueba).getTime() < new Date(b.f_registro_prueba).getTime())
                setDatosBuscarTrabId(arrResultados);

                resDB.forEach(el => {
                    arrResultadosID.push(el.id_trabajador);
                });
                var unicos = arrResultadosID.filter((valor, indice) => {
                    return arrResultadosID.indexOf(valor) === indice;
                });
                setTabGeneralTrabID2(unicos);
            });
        } else {
            setDatosBuscarTrabId([]);
        }
    }
    const hcSelectTrab = (e, v) => {
        let filtrarRep = {};
        var arrayF = v.filter(o => filtrarRep[o.id] ? false : filtrarRep[o.id] = true).map(m => { return m.id });
        setTabGeneralTrabID(arrayF);
    };
    const alertPdf = () => {
        props.alertPdf();
    }
    return (
        <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
                <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
                    <Grid item md={5} xs={12} >
                        <Grid container spacing={2}>
                            <Grid item md={10} xs={12}>
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    limitTags={1}
                                    id="checkboxs-multiple-prof"
                                    options={trabajadores ? trabajadores : []}
                                    disableCloseOnSelect
                                    onChange={hcSelectTrab}
                                    getOptionLabel={(option) => option.nombres_apellidos}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.nombres_apellidos}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} margin='normal' variant="standard" label="Buscar Por Trabajador" placeholder="Seleccionar" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={4.5} xs={12}>
                                <TextField
                                    id="filtro_desde"
                                    label="FILTRO DESDE"
                                    type="date"
                                    fullWidth
                                    variant='standard'
                                    margin='normal'
                                    value={dataFechas ? dataFechas.desde : ''}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            setDataFechas({ ...dataFechas, desde: e.target.value });
                                        } else {
                                            setDataFechas({ ...dataFechas, desde: '' });
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CalendarMonthIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item md={4.5} xs={12}>
                                <TextField
                                    id="filtro_hasta"
                                    label="HASTA"
                                    type="date"
                                    fullWidth
                                    variant='standard'
                                    margin='normal'
                                    value={dataFechas ? dataFechas.hasta : ''}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            setDataFechas({ ...dataFechas, hasta: e.target.value });
                                        } else {
                                            setDataFechas({ ...dataFechas, hasta: '' });
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CalendarMonthIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Grid container style={{ paddingTop: "1.7em" }} spacing={0.3}>
                                    <Grid item md={6} xs={6}>
                                        <Tooltip title="Buscar" placement='top'>
                                            <Button fullWidth sx={{ alignItems: "center" }} variant="contained" color="warning"
                                                onClick={buscarTrabPorId}
                                            ><SearchIcon /></Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        {datosBuscarTrabId.length !== 0 ?
                                            <PDFDownloadLink document={<PdfReporteG datosEmpresa={datosEmpresa} tipo={"repTrab"} datosGeneral={datosBuscarTrabId} dataFechas={dataFechas} tabGeneralID={tabGeneralTrabID2} trabajadores={trabajadores} />} fileName="REPORT-POR-TRABAJADOR">
                                                {({ loading }) => (loading ? <Button fullWidth variant="contained" color="error"><PictureAsPdfIcon /></Button> : <Button fullWidth variant="contained" color="error"><PictureAsPdfIcon /></Button>)}
                                            </PDFDownloadLink> :
                                            <Button fullWidth variant="contained" color="error" onClick={alertPdf}><PictureAsPdfIcon /></Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Paper>
                            <TableContainer sx={{ maxWidth: '310px', minWidth: '100%', height: 400 }} >
                                <Table aria-label="simple table" >
                                    <TableHead sx={{ backgroundColor: "#bde0fe" }}>
                                        <TableRow >
                                            <TableCell style={{ width: '2%' }}>CODIGO</TableCell>
                                            <TableCell>TRABAJADOR</TableCell>
                                            <TableCell>SERVICIO</TableCell>
                                            <TableCell>DESCRIPCIÓN</TableCell>
                                            <TableCell>MATERIAL</TableCell>
                                            <TableCell>ESTADO</TableCell>
                                            <TableCell>F. REGISTRO</TableCell>
                                            <TableCell>F.E PROGRAMADA </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {datosBuscarTrabId.length !== 0 &&
                                            (rowsPerPage > 0
                                                ? datosBuscarTrabId.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : datosBuscarTrabId
                                            ).map((m, index) => (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index} >
                                                    <TableCell component="th" scope="row">
                                                        {m.fk_ven_orden}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {m.trabajador}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {m.servicio}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {m.descripcion}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {m.material}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {m.f_registro_prueba != null && m.fecha_conclusion === null && m.fecha_entrega === null ?
                                                            <Chip label="REGISTRADO" color="error" sx={{ fontSize: "14px", height: "25px" }} /> :
                                                            m.f_registro_prueba != null && m.fecha_conclusion != null && m.fecha_entrega === null ?
                                                                <Chip label="ENTREGADO" color="warning" sx={{ fontSize: "14px", height: "25px" }} /> :
                                                                m.f_registro_prueba != null && m.fecha_conclusion != null && m.fecha_entrega != null ?
                                                                    <Chip label="CONCLUIDO" color="success" sx={{ fontSize: "14px", height: "25px" }} /> : ""}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {m.f_registro_prueba}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {m.fecha_programada + ' ' + m.hora_programada}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                                {datosBuscarTrabId.length === 0 &&
                                    <Grid sx={{ justifyItems: "center", textAlign: "center ", padding: "5em 0" }}>
                                        <Typography gutterBottom align="center" color="text.secondary" sx={{ fontSize: typogFuente.sizeTypo2, fontFamily: typogFuente.fuente }}>
                                            NO SE ENCONTRARON DATOS
                                        </Typography>
                                    </Grid>
                                }
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                labelRowsPerPage="Filas por columnas"
                                count={datosBuscarTrabId.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                    {/* <Grid item md={12}>
                        <PDFViewer width="100%" height={600}>
                            <PdfReporteG datosEmpresa={datosEmpresa} tipo={"repTrab"} datosGeneral={datosBuscarTrabId} dataFechas={dataFechas} tabGeneralID={tabGeneralTrabID2} trabajadores={trabajadores} />
                        </PDFViewer>
                    </Grid> */}
                </Grid >
            </Grid >
        </Grid >
    )
}
