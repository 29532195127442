import * as React from 'react';
import {
    Button,
    DialogContent,
    Dialog,
    DialogActions,
    Typography,
    TextField,
    Alert,
    IconButton,
    Collapse,
    Checkbox,
    DialogTitle,
    FormControlLabel,
    Grid,
    Card,
    CardContent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import UrlsConst from '../config/config';
import CheckIcon from '@mui/icons-material/Check';
import Delete from '../img/delete.png';
import BadgeIcon from '@mui/icons-material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import CallIcon from '@mui/icons-material/Call';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { StarOutlineSharp } from '@mui/icons-material';

export default function Dentadura(props) {
    // const de id de tooth
    const [idPieza, setIdPieza] = React.useState([]);
    // const de checkbox color
    const [typeAlert, setTypeAlert] = React.useState('error');
    const [textAlert, setAlertText] = React.useState('');
    const [openAlert, setOpenAlert] = React.useState(false);
    const [openOrdenDetalle, setOpenOrdenDetalle] = React.useState(false);
    const [openDetalleHistorial, setOpenDetalleHistorial] = React.useState(false);
    const [openDeleteA, setOpenDeleteA] = React.useState(false);
    const [openDeleteB, setOpenDeleteB] = React.useState(false);
    const [piezaSelect, setPiezaSelect] = React.useState('');
    const [datosHistorialDialog, setDatosHistorialDialog] = React.useState([]);
    const [ordenDetalleSubmit, setOrdenDetalleSubmit] = React.useState({
        fk_ven_orden: 0,
        pieza: '00',
        color_tercio_incisal: '',
        color_tercio_medial: '',
        color_tercio_cervical: '',
        es_pontico: 0
    });
    let toothsaveA = [];
    let toothsaveB = [];
    React.useEffect(() => {
        if (props.fntOrden != null) {
            recuperarDetalleOrden();
        }
    }, []);

    const handleClear = () => {
        if (props.toothSelectA.length !== 0) {
            props.toothSelectA.forEach((item) => {
                let toothValue = document.querySelector(`.sp${item}`);
                document.getElementById(toothValue.id).style.fill = "#fff";
                document.querySelector('.text-visible' + toothValue.id).classList.add('none');
                props.setToothSelectA([]);
                props.setPintarDA(0);
            });
        }
        if (props.toothSelectB.length !== 0) {
            props.toothSelectB.forEach((item) => {
                let toothValue = document.querySelector(`.sb${item}`);
                document.getElementById(toothValue.id).style.fill = "#fff";
                document.querySelector('.text-visible' + toothValue.id).classList.add('none');
                props.setToothSelectB([]);
                props.setPintarDB(0);
            });
        }
        const ordenObj = props.objPresupuesto;
        props.setPresupuestOrden({ ...props.presupuestOrden, error: true, text: 'Presupuesto debe ser mayor a 0' });
        props.fntPresupuesto({ ...ordenObj, presupuesto: 0 });
        if (props.toothDetalle.length !== 0) {
            props.setToothDetalle([]);
        }

    }
    const recuperarDetalleOrden = async () => {
        const getOrdenesDetalle = await fetch(`${UrlsConst.URL_API}/ventas/orden/listarOrdenDetalle.php?id=` + props.fntOrden.id, {
            method: 'GET'
        });
        const response = await getOrdenesDetalle.json();
        if (props.viewOrden === 1 && props.viewOrden !== undefined) {
            response.forEach((item) => {
                const toothP = document.getElementById(item.pieza);
                toothP.style.fill = props.fntOrden.color;
                if (item.es_pontico !== 0) {
                    document.querySelector(`.text-visible${item.pieza}`).classList.remove('none');
                }
            });
        } else {
            let toohsA = [].concat(props.toothSelectA);
            let toohsB = [].concat(props.toothSelectB);
            response.forEach((item) => {
                const toothP = document.getElementById(item.pieza);
                toothP.style.fill = props.fntColor.color;
                if (item.es_pontico !== 0) {
                    document.querySelector(`.text-visible${item.pieza}`).classList.remove('none');
                }
                if (item.pieza < 29 && item.pieza > 10) {
                    toohsA.push(Number(toothP.getAttribute('name')));
                    props.setPintarDA(1);
                    props.setPintarDa(1);
                } else {
                    toohsB.push(Number(toothP.getAttribute('name')));
                    props.setPintarDB(1);
                    props.setPintarDb(1);
                }
            });
            props.setToothSelectA(toohsA);
            props.setToothSelectB(toohsB);
            props.setToothDetalle(response);

            // console.log("toohsA")
            // console.log(toohsA)
            // console.log("response")
            // console.log(response)
        }
    }
    // fnt piezas superiores
    const fntPiezasA = (tooth) => {
        if (props.historial !== 1) {
            if (props.fntMaterial.fk_cmp_material !== 0) {
                let toothA = [].concat(props.piezaMultipleA);
                toothA.push(tooth);
                if (props.vMultipieza !== 0) {
                    props.setPiezaMultipleA(toothA);
                    if (props.pintarDA === 0) {
                        if (toothA.length === 2) {
                            let tooths = [];
                            if (toothA[0] < toothA[1]) {
                                for (let i = toothA[0]; i <= toothA[1]; i++) {
                                    tooths.push(i);
                                }
                            } else {
                                for (let i = toothA[1]; i <= toothA[0]; i++) {
                                    tooths.push(i)
                                }
                            }
                            tooths.forEach((item) => {
                                let toothP = document.querySelector(`.sp${item}`);
                                toothP.style.fill = props.fntServicio.color;
                                toothsaveA.push(item);
                            });
                            props.setToothSelectA(toothsaveA);
                            props.setPintarDA(1);
                            toothA.length = 0;
                            const ordenObj = props.objPresupuesto;
                            const precio = Number(props.fntMaterial.precio);
                            if (props.toothSelectB.length === 0) {
                                const total = precio * toothsaveA.length;
                                props.setPresupuestOrden({ ...props.presupuestOrden, error: false, text: '' });
                                props.fntPresupuesto({ ...ordenObj, presupuesto: total.toFixed(2) });
                            } else {
                                props.setPresupuestOrden({ ...props.presupuestOrden, error: false, text: '' });
                                const total = precio * (toothsaveA.length + props.toothSelectB.length);
                                props.fntPresupuesto({ ...ordenObj, presupuesto: total.toFixed(2) });
                            }
                        }
                    } else {
                        if (toothA.length === 2) {
                            let validacion = props.toothSelectA.filter((item) => item === toothA[0] || item === toothA[1]);
                            if (validacion.length !== 0) {
                                let idTooth = [];
                                if (validacion.length === 1) {
                                    // console.log(validacion)
                                    let toothValue = document.querySelector(`.sp${validacion}`);
                                    idTooth.push(toothValue.id);
                                    setIdPieza(idTooth);
                                    setOpenOrdenDetalle(true);
                                } else {
                                    for (let i = validacion[0]; i <= validacion[1]; i++) {
                                        let toothValue = document.querySelector(`.sp${i}`);
                                        idTooth.push(toothValue.id);
                                    }
                                    setIdPieza(idTooth);
                                    setOpenOrdenDetalle(true);
                                }
                                toothA.length = 0;
                            } else {
                                setTypeAlert('warning');
                                setAlertText('Si desea cambiar de piezas deberia limpiar primero la selecion');
                                setOpenAlert(true);
                                setTimeout(() => { setOpenAlert(false) }, 2500);
                                toothA.length = 0;
                            }
                        }
                        if (toothA.length === 1) {
                            let idpieza = [];
                            props.toothDetalle.forEach((item) => {
                                let toothValue = document.querySelector(`.sp${tooth}`);
                                setPiezaSelect(toothValue.id);
                                if (item.pieza === toothValue.id) {
                                    idpieza.push(toothValue.id);
                                    setOrdenDetalleSubmit(item);
                                    setOpenOrdenDetalle(true);
                                    toothA.length = 0;
                                }
                            });
                            setIdPieza(idpieza);
                        }
                    }
                } else {
                    props.setPiezaMultipleA([tooth]);
                    // console.log(props.pintarDA);
                    if (props.pintarDa === 0) {
                        document.querySelector(`.sp${tooth}`).style.fill = props.fntServicio.color;
                        toothsaveA.push(tooth);
                        props.setToothSelectA(toothsaveA);
                        props.setPintarDa(1);
                        const ordenObj = props.objPresupuesto;
                        const precio = Number(props.fntMaterial.precio);
                        if (props.toothSelectB.length === 0) {
                            props.setPresupuestOrden({ ...props.presupuestOrden, error: false, text: '' });
                            props.fntPresupuesto({ ...ordenObj, presupuesto: precio });
                        } else {
                            props.setPresupuestOrden({ ...props.presupuestOrden, error: false, text: '' });
                            props.fntPresupuesto({ ...ordenObj, presupuesto: Number(precio) * 2 });
                        }
                    } else {
                        setOpenDeleteA(true);
                        props.toothSelectA.forEach((item) => {
                            let toothValue = document.querySelector(`.sp${tooth}`);
                            setIdPieza(toothValue.id);
                            setOrdenDetalleSubmit({ ...ordenDetalleSubmit, pieza: toothValue.id });
                            if (item === tooth) {
                                setOpenOrdenDetalle(true);
                                toothA.length = 0;
                            }
                        });
                        props.toothDetalle.forEach((item) => {
                            let toothValue = document.querySelector(`.sp${tooth}`);
                            setIdPieza(toothValue.id);
                            if (item.pieza === toothValue.id) {
                                setOrdenDetalleSubmit(item);
                            }
                        });
                    }
                }
            } else {
                setTypeAlert('warning');
                setAlertText('Primero debe selecionar un servicio y un material');
                setOpenAlert(true);
                setTimeout(() => { setOpenAlert(false) }, 2500);
            }
        }
        if (props.historial === 1) {

            setOpenDetalleHistorial(true)
            let toothValue = document.querySelector(`.sp${tooth}`);
            // console.log("toothValue A");
            // console.log(toothValue.id);

            let datosFiltroColor = [];
            props.datosColorHist.filter(f => f.pieza === toothValue.id).map(m => (
                datosFiltroColor.push(m)
            ));
            setDatosHistorialDialog(datosFiltroColor);
        }
    };
    const fntPiezasB = (tooth) => {
        if (props.historial !== 1) {
            if (props.fntMaterial.fk_cmp_material !== 0) {
                let toothB = [].concat(props.piezaMultipleB);
                toothB.push(tooth);
                if (props.vMultipieza !== 0) {
                    props.setPiezaMultipleB(toothB);
                    if (props.pintarDB === 0) {
                        if (toothB.length === 2) {
                            let tooths = [];
                            if (toothB[0] < toothB[1]) {
                                for (let i = toothB[0]; i <= toothB[1]; i++) {
                                    tooths.push(i);
                                }
                            } else {
                                for (let i = toothB[1]; i <= toothB[0]; i++) {
                                    tooths.push(i)
                                }
                            }
                            tooths.forEach((item) => {
                                let toothP = document.querySelector(`.sb${item}`);
                                toothP.style.fill = props.fntServicio.color
                                toothsaveB.push(item);
                            });
                            props.setToothSelectB(toothsaveB);
                            props.setPintarDB(1);
                            const ordenObj = props.objPresupuesto;
                            const precio = Number(props.fntMaterial.precio);
                            if (props.toothSelectA.length === 0) {
                                const total = precio * toothsaveB.length;
                                props.setPresupuestOrden({ ...props.presupuestOrden, error: false, text: '' });
                                props.fntPresupuesto({ ...ordenObj, presupuesto: total.toFixed(2) });
                            } else {
                                props.setPresupuestOrden({ ...props.presupuestOrden, error: false, text: '' });
                                const total = precio * (props.toothSelectA.length + toothsaveB.length);
                                props.fntPresupuesto({ ...ordenObj, presupuesto: total.toFixed(2) });
                            }
                            toothB.length = 0;
                        }
                    } else {
                        if (toothB.length === 2) {
                            let validacion = props.toothSelectB.filter((item) => item === toothB[0] || item === toothB[1]);
                            if (validacion.length != 0) {
                                let idTooth = [];
                                if (validacion.length === 1) {
                                    let toothValue = document.querySelector(`.sb${validacion}`);
                                    idTooth.push(toothValue.id);
                                    setIdPieza(idTooth);
                                    setOpenOrdenDetalle(true);
                                } else {
                                    for (let i = validacion[0]; i <= validacion[1]; i++) {
                                        let toothValue = document.querySelector(`.sb${i}`);
                                        idTooth.push(toothValue.id);
                                    }
                                    setIdPieza(idTooth);
                                    setOpenOrdenDetalle(true);
                                }
                                toothB.length = 0;
                            } else {
                                setTypeAlert('warning');
                                setAlertText('Si desea cambiar de piezas deberia limpiar primero la selecion');
                                setOpenAlert(true);
                                setTimeout(() => { setOpenAlert(false) }, 2500);
                                toothB.length = 0;
                            }
                        }
                        if (toothB.length === 1) {
                            let idpieza = [];
                            props.toothDetalle.forEach((item) => {
                                let toothValue = document.querySelector(`.sb${tooth}`);
                                setPiezaSelect(toothValue.id);
                                if (item.pieza === toothValue.id) {
                                    idpieza.push(toothValue.id);
                                    setOrdenDetalleSubmit(item);
                                    setOpenOrdenDetalle(true);
                                    toothB.length = 0;
                                }
                            });
                            setIdPieza(idpieza);
                        }
                    }
                } else {
                    props.setPiezaMultipleB([tooth]);
                    if (props.pintarDb === 0) {
                        document.querySelector(`.sb${tooth}`).style.fill = props.fntServicio.color;
                        toothsaveB.push(tooth);
                        props.setToothSelectB(toothsaveB);
                        props.setPintarDb(1);
                        const ordenObj = props.objPresupuesto;
                        const precio = Number(props.fntMaterial.precio);
                        if (props.toothSelectA.length === 0) {
                            props.fntPresupuesto({ ...ordenObj, presupuesto: precio });
                            props.setPresupuestOrden({ ...props.presupuestOrden, error: false, text: '' });
                        } else {
                            props.setPresupuestOrden({ ...props.presupuestOrden, error: false, text: '' });
                            props.fntPresupuesto({ ...ordenObj, presupuesto: precio * 2 });
                        }
                    } else {
                        setOpenDeleteB(true);
                        props.toothSelectB.forEach((item) => {
                            let toothValue = document.querySelector(`.sb${tooth}`);
                            setIdPieza(toothValue.id);
                            if (item === tooth) {
                                setOrdenDetalleSubmit({ ...ordenDetalleSubmit, pieza: toothValue.id });
                                setOpenOrdenDetalle(true);
                                toothB.length = 0;
                            }
                        });
                        props.toothDetalle.forEach((item) => {
                            let toothValue = document.querySelector(`.sb${tooth}`);
                            setIdPieza([toothValue.id]);
                            if (item.pieza === toothValue.id) {
                                setOrdenDetalleSubmit(item);
                            }
                        });
                    }
                }
            } else {
                setTypeAlert('warning');
                setAlertText('Primero debe selecionar un servicio y un material');
                setOpenAlert(true);
                setTimeout(() => { setOpenAlert(false) }, 2500);
            }
        }

        if (props.historial === 1) {
            setOpenDetalleHistorial(true)
            let toothValue = document.querySelector(`.sb${tooth}`);
            let datosFiltroColor = [];
            props.datosColorHist.filter(f => f.pieza === toothValue.id).map(m => (
                datosFiltroColor.push(m)
            ));
            setDatosHistorialDialog(datosFiltroColor);
        }
    }
    const handleClose = () => {
        setOpenOrdenDetalle(false);
        setOpenDeleteA(false);
        setOpenDeleteB(false);
        setOrdenDetalleSubmit({
            fk_ven_orden: 0,
            pieza: '',
            es_pontico: 0,
            color_tercio_incisal: '',
            color_tercio_medial: '',
            color_tercio_cervical: ''
        });
    };

    // fnt SavePiezas superiores
    const handleOrdenDetelleSubmit = (e) => {
        const { name, value } = e.target;
        setOrdenDetalleSubmit({ ...ordenDetalleSubmit, [name]: value.toUpperCase() });
    }
    const handlePontico = (e) => {
        if (e.target.checked !== false) {
            setOrdenDetalleSubmit({ ...ordenDetalleSubmit, es_pontico: 1 });
        } else {
            setOrdenDetalleSubmit({ ...ordenDetalleSubmit, es_pontico: 0 });
        }
    }
    const saveOrdenDetalle = () => {
        if (props.vMultipieza !== 0) {
            if (props.toothDetalle.length === 0) {
                let tooths = [].concat(props.toothDetalle);
                idPieza.forEach((item) => {
                    tooths.push({
                        fk_ven_orden: 0,
                        pieza: item,
                        es_pontico: ordenDetalleSubmit.es_pontico,
                        color_tercio_incisal: ordenDetalleSubmit.color_tercio_incisal,
                        color_tercio_medial: ordenDetalleSubmit.color_tercio_medial,
                        color_tercio_cervical: ordenDetalleSubmit.color_tercio_cervical
                    });
                    if (ordenDetalleSubmit.es_pontico !== 0) {
                        document.querySelector(`.text-visible${item}`).classList.remove('none');
                    }
                });
                props.setToothDetalle(tooths);
                props.fntSetOrden(tooths);
                setIdPieza([]);
                props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'El detalle se guardo correctamente', open: true });
                setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
            } else {
                const toothsInser = [];
                let validacion = props.toothDetalle.filter((tooth) => tooth.pieza === piezaSelect);
                if (validacion.length !== 0) {
                    let deleteP = props.toothDetalle.filter((tooth) => tooth.pieza !== piezaSelect);
                    deleteP.forEach((tooth) => {
                        toothsInser.push(tooth);
                    });
                    toothsInser.push({
                        fk_ven_orden: 0,
                        pieza: piezaSelect,
                        es_pontico: ordenDetalleSubmit.es_pontico,
                        color_tercio_incisal: ordenDetalleSubmit.color_tercio_incisal,
                        color_tercio_medial: ordenDetalleSubmit.color_tercio_medial,
                        color_tercio_cervical: ordenDetalleSubmit.color_tercio_cervical
                    });
                    if (ordenDetalleSubmit.es_pontico !== 0) {
                        document.querySelector(`.text-visible${piezaSelect}`).classList.remove('none');
                    } else {
                        document.querySelector(`.text-visible${piezaSelect}`).classList.add('none');
                    }
                    props.setToothDetalle(toothsInser);
                    props.fntSetOrden(toothsInser);
                    setIdPieza([]);
                    props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'El detalle se actualizo correctamente', open: true });
                    setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
                } else {
                    let tooths = [].concat(props.toothDetalle);
                    idPieza.forEach((item) => {
                        tooths.push({
                            fk_ven_orden: 0,
                            pieza: item,
                            es_pontico: ordenDetalleSubmit.es_pontico,
                            color_tercio_incisal: ordenDetalleSubmit.color_tercio_incisal,
                            color_tercio_medial: ordenDetalleSubmit.color_tercio_medial,
                            color_tercio_cervical: ordenDetalleSubmit.color_tercio_cervical
                        });
                        if (ordenDetalleSubmit.es_pontico !== 0) {
                            document.querySelector(`.text-visible${item}`).classList.remove('none');
                        }
                    });
                    props.setToothDetalle(tooths);
                    props.fntSetOrden(tooths);
                    setIdPieza([]);
                    props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'El detalle se guardo correctamente', open: true });
                    setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
                }
            }
        } else {
            props.setAlertGeneral({ ...props.alertGeneral, type: 'success', text: 'El detalle se guardo correctamente', open: true });
            setTimeout(() => { props.setAlertGeneral({ ...props.alertGeneral, open: false }) }, 3000);
            props.fntSetOrden([ordenDetalleSubmit]);
            props.setToothDetalle([ordenDetalleSubmit]);
        }
        setOpenDeleteB(false);
        setOrdenDetalleSubmit({ fk_ven_orden: 0, pieza: '', es_pontico: 0, color_tercio_incisal: '', color_tercio_medial: '', color_tercio_cervical: '' });
        setOpenOrdenDetalle(false);
        setOpenDeleteA(false);
        setOpenDeleteB(false);
    }
    // console.log(idPieza)
    const deletePiezasA = () => {
        props.toothSelectA.forEach((item) => {
            document.querySelector(`.sp${item}`).style.fill = "#fff";
        });
        let toothsaveA = [];
        props.setToothSelectA([]);
        props.piezaMultipleA.forEach((item) => {
            let toothP = document.querySelector(`.sp${item}`);
            toothP.style.fill = props.fntServicio.color;
            toothsaveA.push(item);
        });
        props.setToothDetalle([]);
        props.setToothSelectA(toothsaveA);
        const ordenObj = props.objPresupuesto;
        props.fntPresupuesto({ ...ordenObj, presupuesto: 0 });
        props.setPresupuestOrden({ ...props.presupuestOrden, error: true, text: 'Presupuesto debe ser mayor a 0' });
        setOpenDeleteA(false);
    }
    const deletePiezasB = () => {
        props.toothSelectB.forEach((item) => {
            document.querySelector(`.sb${item}`).style.fill = "#fff";
        });
        let toothsaveB = [];
        props.setToothSelectB([]);
        props.piezaMultipleB.forEach((item) => {
            let toothP = document.querySelector(`.sb${item}`);
            toothP.style.fill = props.fntServicio.color;
            toothsaveB.push(item);
        });
        props.setToothSelectB(toothsaveB);
        setOpenDeleteB(false);
        const ordenObj = props.objPresupuesto;
        props.fntPresupuesto({ ...ordenObj, presupuesto: 0 });
        props.setPresupuestOrden({ ...props.presupuestOrden, error: true, text: 'Presupuesto debe ser mayor a 0' });
    }
    // historial
    if (props.cargarTotal === 1) {
        if (props.fntServicio.length !== 0 && props.pruebaV !== 0) {
            props.prueba.forEach((item) => {
                props.fntServicio.forEach((orden) => {
                    if (item.id_orden === orden.id) {
                        document.getElementById(item.pieza).style.fill = orden.color;
                    }
                    if (item.es_pontico === 1) {
                        document.querySelector('.text-visible' + item.pieza).classList.remove('none');
                    }
                });
            });
        } else {
            props.prueba.forEach((item) => {
                document.getElementById(item.pieza).style.fill = '#fff';
                document.querySelector('.text-visible' + item.pieza).classList.add('none');
            });
        }
    } else if (props.cargarTotal === 2) {
        if (props.checkedOrden === true) {
            props.fntOrdenSelect.forEach((item) => {
                if (item.id === props.idOrdenS) {
                    document.getElementById(item.pieza).style.fill = item.color;
                }
            });
        } else {
            props.fntOrdenSelect.forEach((item) => {
                if (item.id === props.idOrdenS) {
                    document.getElementById(item.pieza).style.fill = "#fff";
                }
            });
        }
    }
    // console.log(checkEstadoProps)
    return (
        <>
            <Collapse in={openAlert} >
                <Alert variant="outlined"
                    severity={typeAlert}
                    action={
                        <IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpenAlert(false); }} >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    } >
                    {textAlert}
                </Alert>
            </Collapse>
            <svg id="odontograma" width="345px" height="470px" style={{ textAlign: 'center' }}>
                { /* parte superior izquierda */}
                <circle id="18" cx="20" name='1' cy="205" r="15" className="hoverCls sp1" onClick={() => fntPiezasA(1)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible18" x="15" y="210" onClick={() => fntPiezasA(1)}>P</text>
                <circle id="17" cx="25" name="2" cy="170" r="15" className="hoverCls sp2" onClick={() => fntPiezasA(2)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible17" x="20" y="175" onClick={() => fntPiezasA(2)}>P</text>
                <circle id="16" cx="30" name="3" cy="135" r="15" className="hoverCls sp3" onClick={() => fntPiezasA(3)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible16" x="25" y="140" onClick={() => fntPiezasA(3)}>P</text>
                <circle id="15" cx="40" name="4" cy="100" r="15" className="hoverCls sp4" onClick={() => fntPiezasA(4)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible15" x="35" y="105" onClick={() => fntPiezasA(4)}>P</text>
                <circle id="14" cx="55" name="5" cy="70" r="15" className="hoverCls sp5" onClick={() => fntPiezasA(5)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible14 " x="50" y="75" onClick={() => fntPiezasA(5)}>P</text>
                <circle id="13" cx="75" name="6" cy="45" r="15" className="hoverCls sp6" onClick={() => fntPiezasA(6)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible13" x="70" y="50" onClick={() => fntPiezasA(6)}>P</text>
                <circle id="12" cx="103" name="7" cy="30" r="15" className="hoverCls sp7" onClick={() => fntPiezasA(7)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible12" x="100" y="35" onClick={() => fntPiezasA(7)}>P</text>
                <circle id="11" cx="135" name='8' cy="20" r="15" className="hoverCls sp8" onClick={() => fntPiezasA(8)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible11" x="130" y="25" onClick={() => fntPiezasA(8)}>P</text>

                { /* parte superior derecha */}
                <circle id="21" cx="180" name='9' cy="20" r="15" className="hoverCls sp9" onClick={() => fntPiezasA(9)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible21" x="175" y="25" onClick={() => fntPiezasA(9)}>P</text>
                <circle id="22" cx="215" name='10' cy="30" r="15" className="hoverCls sp10" onClick={() => fntPiezasA(10)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible22" x="210" y="35" onClick={() => fntPiezasA(10)}>P</text>
                <circle id="23" cx="245" name='11' cy="45" r="15" className="hoverCls sp11" onClick={() => fntPiezasA(11)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible23" x="240" y="50" onClick={() => fntPiezasA(11)}>P</text>
                <circle id="24" cx="270" name='12' cy="70" r="15" className="hoverCls sp12" onClick={() => fntPiezasA(12)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible24" x="265" y="75" onClick={() => fntPiezasA(12)}>P</text>
                <circle id="25" cx="285" name='13' cy="100" r="15" className="hoverCls sp13" onClick={() => fntPiezasA(13)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible25" x="280" y="105" onClick={() => fntPiezasA(13)}>P</text>
                <circle id="26" cx="300" name='14' cy="135" r="15" className="hoverCls sp14" onClick={() => fntPiezasA(14)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible26" x="295" y="140" onClick={() => fntPiezasA(14)}>P</text>
                <circle id="27" cx="307" name='15' cy="170" r="15" className="hoverCls sp15" onClick={() => fntPiezasA(15)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible27" x="305" y="175" onClick={() => fntPiezasA(15)}>P</text>
                <circle id="28" cx="313" name='16' cy="205" r="15" className="hoverCls sp16" onClick={() => fntPiezasA(16)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible28" x="310" y="210" onClick={() => fntPiezasA(16)}>P</text>
                { /* Parte abajo derecha */}
                <circle id="31" cx="180" name='9' cy="450" r="15" className="hoverCls sb9" onClick={() => fntPiezasB(9)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible31" x="175" y="455" onClick={() => fntPiezasB(9)}>P</text>
                <circle id="32" cx="215" name='10' cy="445" r="15" className="hoverCls sb10" onClick={() => fntPiezasB(10)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible32" x="210" y="450" onClick={() => fntPiezasB(10)}>P</text>
                <circle id="33" cx="245" name='11' cy="425" r="15" className="hoverCls sb11" onClick={() => fntPiezasB(11)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible33" x="240" y="430" onClick={() => fntPiezasB(11)}>P</text>
                <circle id="34" cx="270" name='12' cy="395" r="15" className="hoverCls sb12" onClick={() => fntPiezasB(12)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible34" x="265" y="400" onClick={() => fntPiezasB(12)}>P</text>
                <circle id="35" cx="285" name="13" cy="365" r="15" className="hoverCls sb13" onClick={() => fntPiezasB(13)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible35" x="280" y="370" onClick={() => fntPiezasB(13)}>P</text>
                <circle id="36" cx="300" name="14" cy="330" r="15" className="hoverCls sb14" onClick={() => fntPiezasB(14)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible36" x="295" y="335" onClick={() => fntPiezasB(14)}>P</text>
                <circle id="37" cx="307" name="15" cy="295" r="15" className="hoverCls sb15" onClick={() => fntPiezasB(15)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible37" x="305" y="300" onClick={() => fntPiezasB(15)}>P</text>
                <circle id="38" cx="313 " name="16" cy="260" r="15" className="hoverCls sb16" onClick={() => fntPiezasB(16)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible38" x="310" y="265" onClick={() => fntPiezasB(16)}>P</text>
                { /* Parte abajo izquierda */}
                <circle id="41" cx="135" name="8" cy="450" r="15" className="hoverCls sb8" onClick={() => fntPiezasB(8)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible41" x="130" y="455" onClick={() => fntPiezasB(8)}>P</text>
                <circle id="42" cx="103" name="7" cy="445" r="15" className="hoverCls sb7" onClick={() => fntPiezasB(7)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible42" x="100" y="450" onClick={() => fntPiezasB(7)}>P</text>
                <circle id="43" cx="75" name="6" cy="425" r="15" className="hoverCls sb6" onClick={() => fntPiezasB(6)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible43" x="70" y="430" onClick={() => fntPiezasB(6)}>P</text>
                <circle id="44" cx="55" name='5' cy="395" r="15" className="hoverCls sb5" onClick={() => fntPiezasB(5)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible44" x="50" y="400" onClick={() => fntPiezasB(5)}>P</text>
                <circle id="45" cx="40" name='4' cy="365" r="15" className="hoverCls sb4" onClick={() => fntPiezasB(4)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible45" x="35" y="370" onClick={() => fntPiezasB(4)}>P</text>
                <circle id="46" cx="30" name='3' cy="330" r="15" className="hoverCls sb3" onClick={() => fntPiezasB(3)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible46" x="25" y="335" onClick={() => fntPiezasB(3)}>P</text>
                <circle id="47" cx="25" name='2' cy="295" r="15" className="hoverCls sb2" onClick={() => fntPiezasB(2)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible47" x="20" y="300" onClick={() => fntPiezasB(2)}>P</text>
                <circle id="48" cx="20" name='1' cy="260" r="15" className="hoverCls sb1" onClick={() => fntPiezasB(1)} fill="#fff" stroke='#000' />
                <text className="none text-odon text-visible48" x="15" y="265" onClick={() => fntPiezasB(1)}>P</text>

            </svg>
            <Dialog open={openDeleteA} onClose={handleClose}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <QuestionMarkIcon color="warning" style={{ fontSize: '50px' }} />
                </DialogTitle>
                <DialogContent>
                    ¿Ya se encuentra una pieza seleciona esta seguro de cambiar de pieza?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button variant="contained" style={{ background: '#ed0505' }} onClick={deletePiezasA}>Aceptar</Button>
                    <Button variant="contained" onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDeleteB} onClose={handleClose}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <QuestionMarkIcon color="warning" style={{ fontSize: '50px' }} />
                </DialogTitle>
                <DialogContent>
                    ¿Ya se encuentra una pieza seleciona esta seguro de cambiar de pieza?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button variant="contained" style={{ background: '#ed0505' }} onClick={deletePiezasB}>Aceptar</Button>
                    <Button variant="contained" onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openOrdenDetalle} onClose={handleClose} maxWidth="md">
                <DialogContent >
                    <Typography variant='h6' > PIEZA {idPieza + " "} </Typography>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <TextField
                                fullWidth margin="normal"
                                label="COLOR TERCIO INCISAL"
                                name='color_tercio_incisal'
                                variant='standard'
                                inputProps={
                                    { maxLength: 3 }}
                                value={ordenDetalleSubmit ? ordenDetalleSubmit.color_tercio_incisal : ''}
                                onChange={handleOrdenDetelleSubmit} />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                fullWidth margin="normal"
                                label="COLOR TERCIO MEDIAL"
                                name='color_tercio_medial'
                                variant='standard'
                                inputProps={
                                    { maxLength: 3 }}
                                value={ordenDetalleSubmit ? ordenDetalleSubmit.color_tercio_medial : ''}
                                onChange={handleOrdenDetelleSubmit}
                            />
                        </Grid>
                        <Grid item md={props.vMultipieza !== 0 ? 6 : 12}>
                            <TextField fullWidth margin="normal"
                                label="COLOR TERCIO CERVICAL"
                                name='color_tercio_cervical'
                                variant='standard'
                                inputProps={
                                    { maxLength: 3 }}
                                onChange={handleOrdenDetelleSubmit}
                                value={ordenDetalleSubmit ? ordenDetalleSubmit.color_tercio_cervical : ''}
                            />
                        </Grid>
                        {props.vMultipieza !== 0 &&
                            <Grid item md={6} style={{ marginTop: '35px' }}>
                                <FormControlLabel labelPlacement='start' label="ES PONTICO"
                                    control={
                                        <Checkbox
                                            name='es_pontico'
                                            onChange={handlePontico}
                                            checked={ordenDetalleSubmit.es_pontico != 0}
                                        />
                                    } />
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<CheckIcon />} variant="outlined" color='success' onClick={saveOrdenDetalle} > Guardar </Button>
                    <Button startIcon={<CloseIcon />} variant="outlined" color='error' onClick={handleClose}> Cancelar </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}