import { CalendarMonth, Check, Close, Delete, Help, HelpOutline, LocalAtm, Lock, LockOpen, MonetizationOn, Payments, PaymentSharp, UndoSharp } from '@mui/icons-material'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, MenuItem, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import UrlsConst from '../../config/config';
import DialogCajaChica from './dialogCajaChica';

export default function Cajachica() {
  let date = new Date();
  let año = date.getFullYear();
  let mes = date.getMonth() + 1;
  let mesActual = mes > 9 ? date.getDate() : '0' + mes;
  let dia = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
  let fecha_actual = año + "-" + mesActual + "-" + dia;
  const categorias = [
    { label: 'ADELANTO DE SUELDO', id: 'A' },
    { label: 'COMPRAS', id: 'C' },
    { label: 'GASTOS ADMINISTRATIVOS', id: 'G' },
    { label: 'OTROS', id: 'O' }
  ];
  const [trabajadores, setTrabajadores] = useState([]);
  const [alertGeneral, setAlertGeneral] = useState({
    show: false,
    severity: 'warning',
    message: ''
  });
  const [inputFecha, setInputFecha] = useState('');
  const [cajaCobranza, setCajaCobranza] = useState({
    id: 0,
    fecha_apertura: '',
    hora_apertura: '',
    fecha_cierre: '',
    hora_cierre: '',
    fk_log_usuario_creado_por: ''
  });
  const [cajaCobranzaAbierta, setCajaCobranzaAbierta] = useState({
    id: 0,
    fecha_apertura: '',
    hora_apertura: '',
    fecha_cierre: '',
    hora_cierre: '',
    fk_log_usuario_creado_por: ''
  });
  const [cajaChicaSubmit, setCajaChicaSubmit] = useState({
    id: 0,
    fk_ven_caja_cobranza: '',
    saldo_inicial: 0,
    total_recarga: 0,
    saldo_anterior: 0,
    saldo_al_cierre: 0,
    esta_cerrada: 0
  });
  const [registroSubmit, setRegistroSubmit] = useState({
    numero: 0,
    fk_ven_caja_chica: 0,
    hora_registro: '',
    categoria: '',
    descripcion: '',
    total_entregado: 0,
    referencia: '',
    total_rendido: 0,
    fk_rh_trabajador_responsable: 0
  });
  const [registroRecuperado, setRegistroRecuperado] = useState({
    id: 0,
    numero: 0
  });
  const [registrosCaja, setRegistrosCaja] = useState([]);
  const [dialogCajaChicaRegistro, setDialogCajaChicaRegistro] = useState(false);
  const [dialogRendido, setDialogRendido] = useState(false);
  const [dialogEliminarRegistro, setDialogEliminarRegistro] = useState(false);
  const [categoriaRegistro, setCategoriaRegistro] = React.useState({
    error: false,
    text: ''
  });
  const [trabajadorRegistro, setTrabajadorRegistro] = React.useState({
    error: false,
    text: ''
  });
  const [montoEntregadoRegistro, setMontoEntregadoRegistro] = React.useState({
    error: false,
    text: ''
  });
  const [montoRendidoRegistro, setMontoRendidoRegistro] = React.useState({
    error: false,
    text: ''
  });
  const [rowsPage, setRowsPage] = useState(10);
  const [page, setPage] = useState(0);

  const [totalPago, setTotalPago] = useState(0);
  // guardarcajachica.php
  // handles
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPage = (event) => {
    setRowsPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFecha = async (e) => {
    const { value } = e.target;
    setInputFecha(value);
    setCajaCobranza({ ...cajaCobranza, fecha_apertura: value });
    const obtenerCaja = await fetch(`${UrlsConst.URL_API}/ventas/pago/recuperarCajaCobranzaFecha.php?fecha=${value}`);
    const response = await obtenerCaja.json();
    if (response) {
      const caja_chica_recuperar = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${response.id}`);
      const response_caja = await caja_chica_recuperar.json();
      const registros_caja = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/listarCajaChicaRegistro.php?id=${response_caja.id}`);
      const response_registros = await registros_caja.json();
      if (response_registros.length > 0) {
        const rendido = response_registros.filter(r => Number(r.total_rendido) !== 0);
        const sinrendido = response_registros.filter(r => Number(r.total_rendido) === 0);
        let gastado_rendido = 0;
        let gastado_sinrendir = 0;
        rendido.forEach(p => {
          gastado_rendido += Number(p.total_rendido)
        });
        sinrendido.forEach(p => {
          gastado_sinrendir += Number(p.total_entregado)
        });
        const gasto = gastado_rendido + gastado_sinrendir;
        const total = Number(response_caja.saldo_inicial) - gasto;
        setTotalPago(total);
        setRegistrosCaja(response_registros);
        if (response_caja) {
          setCajaChicaSubmit(response_caja);
          setCajaCobranza(response);
        } else {
          setCajaChicaSubmit({
            id: 0,
            fk_ven_caja_cobranza: '',
            saldo_inicial: 0,
            total_recarga: 0,
            saldo_anterior: 0,
            saldo_al_cierre: 0,
            esta_cerrada: 0
          });
          setTotalPago(0);
        }
      } else {
        setCajaChicaSubmit({
          id: response_caja.id,
          fk_ven_caja_cobranza: response_caja.fk_ven_caja_cobranza,
          saldo_inicial: response_caja.saldo_inicial,
          total_recarga: response_caja.total_recarga,
          saldo_anterior: response.saldo_anterior,
          saldo_al_cierre: response_caja.saldo_al_cierre,
          esta_cerrada: response_caja.esta_cerrada
        });
        setTotalPago(response_caja.saldo_inicial);
        setRegistrosCaja([]);
      }
    } else {
      setRegistrosCaja([]);
      setCajaCobranza({
        id: 0,
        fk_ven_caja_cobranza: '',
        saldo_inicial: 0,
        total_recarga: 0,
        saldo_anterior: 0,
        saldo_al_cierre: 0,
        esta_cerrada: 0
      });
      setCajaChicaSubmit({
        id: 0,
        fk_ven_caja_cobranza: '',
        saldo_inicial: 0,
        total_recarga: 0,
        saldo_anterior: 0,
        saldo_al_cierre: 0,
        esta_cerrada: 0
      });
      setTotalPago(0);
    }
  }
  const handleCategoria = (e, values) => {
    const categoriavalor = values.props.value;
    setCategoriaRegistro({ error: false, text: '' });
    setRegistroSubmit({ ...registroSubmit, categoria: categoriavalor });
  }
  const handleTrabajador = (e, values) => {
    const trabajadorValor = values.props.value;
    setTrabajadorRegistro({ error: false, text: '' });
    setRegistroSubmit({ ...registroSubmit, fk_rh_trabajador_responsable: trabajadorValor });
  }
  const handleRegistro = (e) => {
    const { name, value } = e.target;
    if (name === "total_entregado") {
      if (value !== "") {
        if (!isNaN(value)) {
          setMontoEntregadoRegistro({ error: false, text: '' });
          setRegistroSubmit({ ...registroSubmit, total_entregado: value });
        }
      } else {
        setMontoEntregadoRegistro({ error: true, text: 'Monto obligatorio' });
        setRegistroSubmit({ ...registroSubmit, total_entregado: '' });
      }
    } else if (name === "total_rendido") {
      if (value !== "") {
        if (!isNaN(value)) {
          setMontoRendidoRegistro({ error: false, text: '' });
          setRegistroSubmit({ ...registroSubmit, total_rendido: value });
        }
      } else {
        setMontoRendidoRegistro({ error: true, text: 'Monto obligatorio' });
        setRegistroSubmit({ ...registroSubmit, total_rendido: '' });
      }
    } else {
      setRegistroSubmit({ ...registroSubmit, [name]: value });
    }
  }
  const handleClickRegisto = () => {
    trabajadoresList();
    if (cajaChicaSubmit.id !== 0 && cajaChicaSubmit.esta_cerrada !== 1) {
      setDialogCajaChicaRegistro(true);
    } else {
      setAlertGeneral({ show: true, message: 'No existe una caja chica', severity: 'warning' });
      setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning' }) }, 3000);
    }
  }
  const handleRegistroDialog = async (id, numero) => {
    const recuperar_registro = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/recuperarCajaChicaRegistro.php?id=${id}&&numero=${numero}`);
    const response = await recuperar_registro.json();
    setRegistroSubmit(response);
    setDialogRendido(true);
  }
  // functions
  const irCajaAbierta = async () => {
    const caja_abierta = await fetch(`${UrlsConst.URL_API}/ventas/pago/listarCajasAbiertas.php`);
    const response_caja = await caja_abierta.json();
    if (response_caja.length > 0) {
      let caja_abiert = {};
      response_caja.forEach(c => caja_abiert = c);
      setCajaCobranza(caja_abiert);
      setInputFecha(caja_abiert.fecha_apertura);
      const caja_chica_recuperar = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${caja_abiert.id}`);
      const response = await caja_chica_recuperar.json();
      setCajaChicaSubmit(response);
      setRegistroSubmit({ ...registroSubmit, fk_ven_caja_chica: response.id });

      const registros_caja = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/listarCajaChicaRegistro.php?id=${response.id}`);
      const response_registros = await registros_caja.json();
      if (response_registros.length > 0) {
        setRegistrosCaja(response_registros);
        const rendido = response_registros.filter(r => Number(r.total_rendido) !== 0);
        const sinrendido = response_registros.filter(r => Number(r.total_rendido) === 0);
        let gastado_rendido = 0;
        let gastado_sinrendir = 0;
        rendido.forEach(p => {
          gastado_rendido += Number(p.total_rendido)
        });
        sinrendido.forEach(p => {
          gastado_sinrendir += Number(p.total_entregado)
        });
        const gasto = gastado_rendido + gastado_sinrendir;
        const total = Number(response.saldo_inicial) - gasto;
        setTotalPago(total);
        setRegistrosCaja(response_registros);
      } else {
        setRegistrosCaja([]);
      }
    } else {
      setAlertGeneral({ show: true, message: 'No existe una caja abierta', severity: 'warning' });
      setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning' }) }, 3000);
    }
  }
  const trabajadoresList = async () => {
    const trabajadores = await fetch(`${UrlsConst.URL_API}/sistema/trabajador/listarTrabajador.php`, {
      method: "POST"
    });
    const response = await trabajadores.json();
    setTrabajadores(response);
  }

  const agregarRegistro = async () => {
    if (registroSubmit.categoria === "" || registroSubmit.fk_rh_trabajador_responsable === 0 || registroSubmit.total_entregado === "" || registroSubmit.total_rendido !== 0) {
      if (registroSubmit.categoria === "") {
        setCategoriaRegistro({ error: true, text: 'Categoria obligatoria' });
      }
      if (registroSubmit.fk_rh_trabajador_responsable === 0) {
        setTrabajadorRegistro({ error: true, text: 'Trabajador obligatorio' });
      }
      if (registroSubmit.total_rendido === 0) {
        setMontoEntregadoRegistro({ error: true, text: 'Monto no puede ser 0 o vacio' });
      }
    } else {
      console.log(cajaChicaSubmit);
      const cajaRegistro = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/guardarCajaChicaRegistro.php`, {
        method: 'POST',        
        body: JSON.stringify({
          numero: 0,
          fk_ven_caja_chica: cajaChicaSubmit.id,
          hora_registro: new Date().toLocaleTimeString(),
          categoria: registroSubmit.categoria,
          descripcion: registroSubmit.descripcion.toUpperCase(),
          total_entregado: registroSubmit.total_entregado,
          referencia: registroSubmit.referencia,
          total_rendido: registroSubmit.total_rendido,
          fk_rh_trabajador_responsable: registroSubmit.fk_rh_trabajador_responsable
        })
      });
      const response = await cajaRegistro.json();
      const registros_caja = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/listarCajaChicaRegistro.php?id=${cajaChicaSubmit.id}`);
      const response_registros = await registros_caja.json();
      const rendido = response_registros.filter(r => Number(r.total_rendido) !== 0);
      const sinrendido = response_registros.filter(r => Number(r.total_rendido) === 0);
      let gastado_rendido = 0;
      let gastado_sinrendir = 0;
      rendido.forEach(p => {
        gastado_rendido += Number(p.total_rendido);
      });
      sinrendido.forEach(p => {
        gastado_sinrendir += Number(p.total_entregado);
      });
      const gasto = gastado_rendido + gastado_sinrendir;
      const total = Number(cajaChicaSubmit.saldo_inicial) - gasto;
      setTotalPago(total);
      setRegistrosCaja(response_registros);
      setRegistroSubmit({
        numero: 0,
        fk_ven_caja_chica: 0,
        hora_registro: '',
        categoria: '',
        descripcion: '',
        total_entregado: 0,
        referencia: '',
        total_rendido: 0,
        fk_rh_trabajador_responsable: 0
      });
      if (response) {
        setDialogCajaChicaRegistro(false);
        setAlertGeneral({ show: true, message: 'Registro guardado correctamente', severity: 'success' });
        setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning' }) }, 3000);
      }
    }
  }
  const setRendir = async () => {
    if (registroSubmit.total_rendido === '') {
      if (registroSubmit.total_rendido === "") {
        setMontoRendidoRegistro({ error: true, text: 'Monto obligatorio' });
      }
    } else {
      const cajaRegistro = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/guardarCajaChicaRegistro.php`, {
        method: 'POST',
        body: JSON.stringify({
          numero: registroSubmit.numero,
          fk_ven_caja_chica: registroSubmit.fk_ven_caja_chica,
          hora_registro: registroSubmit.hora_registro,
          categoria: registroSubmit.categoria,
          descripcion: registroSubmit.descripcion,
          total_entregado: registroSubmit.total_entregado,
          referencia: registroSubmit.referencia,
          total_rendido: registroSubmit.total_rendido,
          fk_rh_trabajador_responsable: registroSubmit.fk_rh_trabajador_responsable
        })
      });
      const response = await cajaRegistro.json();
      setDialogRendido(false);
      setAlertGeneral({ show: true, message: 'Registro rendido correctamente', severity: 'success' });
      setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning' }) }, 3000);
      const registros_caja = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/listarCajaChicaRegistro.php?id=${cajaChicaSubmit.id}`);
      const response_registros = await registros_caja.json();
      if (response_registros.length > 0) {
        const rendido = response_registros.filter(r => Number(r.total_rendido) !== 0);
        const sinrendido = response_registros.filter(r => Number(r.total_rendido) === 0);
        let gastado_rendido = 0;
        let gastado_sinrendir = 0;
        rendido.forEach(p => {
          gastado_rendido += Number(p.total_rendido)
        });
        sinrendido.forEach(p => {
          gastado_sinrendir += Number(p.total_entregado)
        });
        const gasto = gastado_rendido + gastado_sinrendir;
        const total = Number(cajaChicaSubmit.saldo_inicial) - gasto;
        setTotalPago(total);
        setRegistrosCaja(response_registros);
        setRegistroSubmit({
          numero: 0,
          fk_ven_caja_chica: 0,
          hora_registro: '',
          categoria: '',
          descripcion: '',
          total_entregado: 0,
          referencia: '',
          total_rendido: 0,
          fk_rh_trabajador_responsable: 0
        });
      } else {
        setRegistrosCaja([]);
      }
    }
  }
  const eliminarRegistro = async () => {
    const eliminar_registro = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/eliminarCajaChicaRegistro.php?id=${registroRecuperado.id}&&numero=${registroRecuperado.numero}`);
    const response = await eliminar_registro.json();
    if (response) {
      setDialogEliminarRegistro(false);
      setAlertGeneral({ show: true, message: 'Registro eliminado correctamente', severity: 'success' });
      setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning' }) }, 3000);
      const registros_caja = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/listarCajaChicaRegistro.php?id=${cajaChicaSubmit.id}`);
      const response_registros = await registros_caja.json();
      if (response_registros.length > 0) {
        const rendido = response_registros.filter(r => Number(r.total_rendido) !== 0);
        const sinrendido = response_registros.filter(r => Number(r.total_rendido) === 0);
        let gastado_rendido = 0;
        let gastado_sinrendir = 0;
        rendido.forEach(p => {
          gastado_rendido += Number(p.total_rendido)
        });
        sinrendido.forEach(p => {
          gastado_sinrendir += Number(p.total_entregado)
        });
        const gasto = gastado_rendido + gastado_sinrendir;
        const total = Number(cajaChicaSubmit.saldo_inicial) - gasto;
        setTotalPago(total);
        setRegistrosCaja(response_registros);
      } else {
        setRegistrosCaja([]);
      }
    }
  }
  const recuperarCajaHoy = async () => {
    const obtenerCaja = await fetch(`${UrlsConst.URL_API}/ventas/pago/recuperarCajaCobranzaFecha.php?fecha=${fecha_actual}`);
    const response = await obtenerCaja.json();
    console.log(response);
    if (response !== false) {
      const caja_chica_recuperar = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChica/recuperarCajaChicaPorCobranza.php?fk_ven_caja_cobranza=${response.id}`);
      const response_caja = await caja_chica_recuperar.json();
      console.log(response_caja);
      setCajaCobranza(response);
      if (response_caja) {
        const registros_caja = await fetch(`${UrlsConst.URL_API}/contabilidad/cajaChicaRegistro/listarCajaChicaRegistro.php?id=${response_caja.id}`);
        const response_registros = await registros_caja.json();
        const rendido = response_registros.filter(r => Number(r.total_rendido) !== 0);
        const sinrendido = response_registros.filter(r => Number(r.total_rendido) === 0);
        let gastado_rendido = 0;
        let gastado_sinrendir = 0;
        rendido.forEach(p => {
          gastado_rendido += Number(p.total_rendido)
        });
        sinrendido.forEach(p => {
          gastado_sinrendir += Number(p.total_entregado)
        });
        const gasto = gastado_rendido + gastado_sinrendir;
        const total = Number(response_caja.saldo_inicial) - gasto;
        setTotalPago(total);
        setRegistrosCaja(response_registros);
        setCajaChicaSubmit(response_caja);
      } else {
        setCajaChicaSubmit({
          id: 0,
          fk_ven_caja_cobranza: '',
          saldo_inicial: 0,
          total_recarga: 0,
          saldo_anterior: 0,
          saldo_al_cierre: 0,
          esta_cerrada: 0
        });
      }
    }
  }
  const closeDialogRegistro = () => {
    setCategoriaRegistro({ error: false, text: '' });
    setTrabajadorRegistro({ error: false, text: '' });
    setMontoEntregadoRegistro({ error: false, text: '' });
    setDialogCajaChicaRegistro(false);
    setRegistroSubmit({
      numero: 0,
      fk_ven_caja_chica: 0,
      hora_registro: '',
      categoria: '',
      descripcion: '',
      total_entregado: 0,
      referencia: '',
      total_rendido: 0,
      fk_rh_trabajador_responsable: 0
    });
  }
  const closeDialogRendir = () => {
    setMontoRendidoRegistro({ error: false, text: '' });
    setDialogRendido(false);
    setRegistroSubmit({
      numero: 0,
      fk_ven_caja_chica: 0,
      hora_registro: '',
      categoria: '',
      descripcion: '',
      total_entregado: 0,
      referencia: '',
      total_rendido: 0,
      fk_rh_trabajador_responsable: 0
    });
  }
  useEffect(() => {
    setInputFecha(fecha_actual);
    recuperarCajaHoy();
  }, []);

  return (
    <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignContent: 'center' }} className="contained">
      <Snackbar open={alertGeneral.show} autoHideDuration={6000} onClose={() => setAlertGeneral({ show: false, message: "", severity: "warning" })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setAlertGeneral({ show: false, message: "", severity: "warning" })} severity={alertGeneral.severity} sx={{ width: '100%' }}>
          {alertGeneral.message}
        </Alert>
      </Snackbar>
      <Grid item md={11} xs={11} sx={{ pt: 5 }}>
        <Paper elevation={1} sx={{ p: 1 }}>
          <Typography variant='h6'>CAJA CHICA</Typography>
          <Grid item md={12} xs={12}>
            <Paper elevation={1} sx={{ p: 1 }}>
              <Typography>ACCIONES DE CAJA</Typography>
              <Grid container spacing={2} sx={{ textAlign: { xs: "center", sm: 'inherit' } }}>
                <Grid item md={2} sm={3} xs={12}>
                  <TextField label="FECHA APERTURA" fullWidth InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <CalendarMonth />
                      </InputAdornment>
                    )
                  }} variant="standard" margin='normal' type="date" value={inputFecha} onChange={handleFecha} />
                </Grid>
                <Grid item md={2} sm={3} xs={12}>
                  <TextField label="FECHA CIERRE" fullWidth InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <CalendarMonth />
                      </InputAdornment>
                    )
                  }} margin='normal' variant="standard" disabled value={cajaChicaSubmit.id === 0 && cajaChicaSubmit.esta_cerrada === 0 ? 'NO EXISTE' : cajaChicaSubmit.id !== 0 && cajaChicaSubmit.esta_cerrada === 0 ? 'ABIERTA' : cajaChicaSubmit.id !== 0 && cajaChicaSubmit.esta_cerrada === 1 ? 'CERRADA' : ''} />
                </Grid>
                <Grid item md={2} sm={3} xs={12}>
                  <TextField label="SALDO INICIAL" fullWidth margin='normal' variant="standard" disabled value={cajaChicaSubmit.saldo_inicial} />
                </Grid>
                <Grid item md={2} sm={3} xs={12}>
                  <TextField label="MONTO ANTERIOR" fullWidth margin='normal' variant="standard" disabled value={cajaChicaSubmit.saldo_al_cierre} />
                </Grid>
                <Grid item md={2} sm={3} xs={12}>
                  <TextField label="MONTO RECARGA" fullWidth margin='normal' variant="standard" disabled value={cajaChicaSubmit.total_recarga} />
                </Grid>
                <Grid item md={2} sm={3} xs={12}>
                  <TextField label="SALDO" fullWidth margin='normal' variant="standard" disabled value={totalPago} />
                </Grid>
                <Grid item md={0} sm={4} xs={0}></Grid>
                <Grid item md={2} sm={4} xs={12} sx={{ mt: 3 }}>
                  <Button fullWidth variant='contained' color="secondary" onClick={handleClickRegisto} startIcon={<MonetizationOn />}>Nuevo registro</Button>
                </Grid>
                {
                  cajaCobranza.fecha_cierre !== null &&
                  < Grid item md={2} sm={4} xs={12} sx={{ mt: 3 }}>
                    <Button fullWidth variant='contained' color="primary" onClick={irCajaAbierta} startIcon={<UndoSharp />}>Ir Caja abierta</Button>
                  </Grid>
                }
              </Grid>
            </Paper>
          </Grid>
        </Paper>
      </Grid>
      <Grid item md={11} xs={11} sx={{ mt: 2 }}>
        <Paper>
          <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 400 }}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold', }}>N°</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>HORA REGISTRO</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>CATEGORIA</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>TOTAL ENTREGADO</TableCell>
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>REFERENCIA</TableCell>
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>TOTAL RENDIDO</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>TRABAJADOR</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  (rowsPage > 0
                    ? registrosCaja.slice(page * rowsPage, page * rowsPage + rowsPage)
                    : registrosCaja
                  ).map((registro) => (
                    <TableRow
                      key={registro.numero}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {registro.numero}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {registro.hora_registro}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {registro.categoria === "A" && 'ADELANTO DE SUELDO'}
                        {registro.categoria === "C" && 'COMPRAS'}
                        {registro.categoria === "G" && 'GASTOS ADMINISTRATIVOS'}
                        {registro.categoria === "O" && 'OTROS'}
                      </TableCell>
                      <TableCell>{registro.descripcion}</TableCell>
                      <TableCell align="right">S/ {registro.total_entregado}</TableCell>
                      <TableCell>{registro.referencia}</TableCell>
                      <TableCell align="right">S/ {registro.total_rendido}</TableCell>
                      <TableCell>{registro.trabajador}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => {
                          handleRegistroDialog(registro.fk_ven_caja_chica, registro.numero);
                        }} >
                          <LocalAtm color="warning" />
                        </IconButton>
                        <IconButton onClick={() => {
                          setDialogEliminarRegistro(true); setRegistroRecuperado({ id: registro.fk_ven_caja_chica, numero: registro.numero })
                        }} >
                          <Delete color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            labelRowsPerPage="Filas por columnas"
            count={registrosCaja.length}
            rowsPerPage={rowsPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPage}
          />
        </Paper>
      </Grid>
      <Dialog open={dialogCajaChicaRegistro} onClose={closeDialogRegistro} maxWidth="xs">
        <DialogTitle>NUEVO REGISTRO</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={12}>
              <TextField
                select
                fullWidth
                label="CATEGORIA"
                error={categoriaRegistro.error}
                helperText={categoriaRegistro.text}
                value={registroSubmit ? registroSubmit.categoria : ''}
                onChange={handleCategoria}
                variant="standard"
              >
                {categorias.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={12}>
              <TextField
                select
                fullWidth
                label="TRABAJADOR"
                margin='normal'
                error={trabajadorRegistro.error}
                helperText={trabajadorRegistro.text}
                value={registroSubmit ? registroSubmit.fk_rh_trabajador_responsable : 0}
                onChange={handleTrabajador}
                variant="standard"
              >
                {trabajadores.map((t) => (
                  <MenuItem key={t.id} value={t.id}>
                    {t.nombres_apellidos}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={12}>
              <TextField name="total_entregado" fullWidth label="MONTO ENTREGADO" margin='normal' variant="standard" value={registroSubmit ? registroSubmit.total_entregado : 0} error={montoEntregadoRegistro.error} helperText={montoEntregadoRegistro.text} onChange={handleRegistro} />
            </Grid>
            <Grid item md={12}>
              <TextField name="descripcion" fullWidth label="DESCRIPCION" margin='normal' multiline rows={3} onChange={handleRegistro} inputProps={{ style: { textTransform: 'uppercase' } }} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant='outlined' color="success" startIcon={<Check />} onClick={agregarRegistro}>REGISTRAR</Button>
          <Button variant='outlined' color="error" startIcon={<Close />} onClick={closeDialogRegistro}>CANCELAR</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogRendido} onClose={closeDialogRendir} maxWidth="xs">
        <DialogTitle>RENDIR REGISTRO</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextField label="Monto entregado" variant='standard' margin='normal' fullWidth disabled value={registroSubmit.total_entregado} />
            </Grid>
            <Grid item md={6}>
              <TextField value={registroSubmit.total_rendido} name='total_rendido' label="Monto rendido" variant='standard' margin='normal' fullWidth onChange={handleRegistro} error={montoRendidoRegistro.error} helperText={montoRendidoRegistro.text} />
            </Grid>
            <Grid item md={12}>
              <TextField value={registroSubmit.referencia} name='referencia' label="Referencia" variant='standard' placeholder='F001-000000001' margin='normal' fullWidth inputProps={{ style: { textTransform: 'uppercase' } }} onChange={handleRegistro} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant='outlined' color='success' onClick={setRendir} startIcon={<Check />}>RENDIR</Button>
          <Button variant='outlined' color='error' onClick={closeDialogRendir} startIcon={<Close />}>CANCELAR</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogEliminarRegistro} onClose={() => setDialogEliminarRegistro(false)} maxWidth="xs">
        <DialogTitle style={{ textAlign: 'center' }}>
          <div>
            <HelpOutline style={{ color: '#fdb924', fontSize: '80px' }} />
          </div>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant="h5">Eliminar Registro</Typography>
          ¿Esta seguro de eliminar el registro?
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button startIcon={<Check />} variant='contained' color='success' onClick={eliminarRegistro}>ELIMINAR</Button>
          <Button startIcon={<Close />} variant='contained' color='error' onClick={() => setDialogEliminarRegistro(false)}>CANCELAR</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
