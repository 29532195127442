import { Check, Close, Delete, Edit, Handyman, HelpOutline, Paid, Payments, PictureAsPdf, Search } from '@mui/icons-material'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react';
import UrlsConst from '../../config/config';
import DialogManGastos from './gastos/dialogManGastos';
import DialogRegistroGasto from './gastos/dialogRegistroGasto';
import ExportPdf from './reportesGasto/exportPdf';
import { PDFDownloadLink } from "@react-pdf/renderer";
import ExportExcel from './reportesGasto/exportExcel';

export default function RegistroGastos() {
    let date = new Date();
    let año = date.getFullYear();
    let mes = date.getMonth() + 1;
    let mesActual = mes > 9 ? mes : '0' + mes;
    let dia = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    let fecha_actual = año + "-" + mesActual + "-" + dia;
    const [page, setPage] = React.useState(0);
    const [rowsPage, setRowsPage] = React.useState(10);
    const [dialogDelete, setDialogDelete] = React.useState(false);
    const [registros, setRegistros] = React.useState([]);
    const [dialogRegistro, setDialogRegistro] = React.useState({
        show: false,
        textDialog: 'NUEVO',
        codigo: ''
    });
    const [inputFechas, setInputFechas] = React.useState({
        fechaInicio: fecha_actual,
        fechaFinal: fecha_actual
    });
    const [alertGeneral, setAlertGeneral] = React.useState({
        show: false,
        message: '',
        severity: 'success'
    });
    const [datosEmpresa, setDatosEmpresa] = React.useState({
        ruc: '',
        razon_social: '',
        direccion: ''
    });
    const [dialogManGasto, setDialogManGasto] = React.useState(false);
    // handles
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowPage = (event) => {
        setRowsPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleFechaInicio = (e) => {
        const { value } = e.target;
        setInputFechas({ ...inputFechas, fechaInicio: value });
    }
    const handleFechaFinal = (e) => {
        const { value } = e.target;
        setInputFechas({ ...inputFechas, fechaFinal: value });
    }
    const searchFechas = () => {
        if (inputFechas.fechaFinal > inputFechas.fechaInicio) {
            listarRegistroGastoFecha();
        }
    }
    const listarRegistroGastoFecha = async () => {
        const listarRegistros = await fetch(`${UrlsConst.URL_API}/compras/gastos/listarRegistroGastoFecha.php?desde=${inputFechas.fechaInicio}&&hasta=${inputFechas.fechaFinal}`);
        const response = await listarRegistros.json();
        setRegistros(response);
    }
    const eliminarRegistroGasto = async () => {
        const eliminarRegistro = await fetch(`${UrlsConst.URL_API}/compras/gastos/eliminarGastoRegistro.php?codigo=${dialogRegistro.codigo}`);
        const response = await eliminarRegistro.json();
        setDialogDelete(false);
        listarRegistroGastoFecha();
        setAlertGeneral({ show: true, message: 'Registro eliminado correctamente', severity: 'success' });
        setTimeout(() => setAlertGeneral({ show: false, severity: 'success', message: '' }), 2500);
    }
    React.useEffect(() => {
        listarRegistroGastoFecha();
        const getDatosEmpresa = async () => {
            const getRuc = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RUC_EMPRESA`, {
                method: 'GET'
            });
            const response_getRuc = await getRuc.json();
            const getRazon = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=RAZON_SOCIAL`, {
                method: 'GET'
            });
            const response_getRazon = await getRazon.json();
            const getDireccion = await fetch(`${UrlsConst.URL_API}/sistema/parametro/recuperarParametro.php?codigo=DIRECCION_EMPRESA`, {
                method: 'GET'
            });
            const response_getDireccion = await getDireccion.json();
            setDatosEmpresa({ ruc: response_getRuc.valor_string, razon_social: response_getRazon.valor_string, direccion: response_getDireccion.valor_string });
        }
        getDatosEmpresa();
    }, []);

    return (
        <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignContent: 'center' }}>
            <Dialog open={dialogDelete} onClose={() => setDialogDelete(false)}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutline style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Eliminar Registro Gasto</Typography>
                    ¿Esta seguro de eliminar el registro?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button variant='contained' color="success" startIcon={<Check />} onClick={eliminarRegistroGasto}>ACEPTAR</Button>
                    <Button variant='contained' color="error" startIcon={<Close />} onClick={() => setDialogDelete(false)}>CANCELAR</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialogRegistro.show} onClose={() => setDialogRegistro({ show: false, textDialog: 'NUEVO', codigo: '' })} maxWidth="md">
                <DialogTitle>{dialogRegistro.textDialog} REGISTRO</DialogTitle>
                <DialogContent>
                    <DialogRegistroGasto dialogClose={() => setDialogRegistro({ show: false, textDialog: 'NUEVO', codigo: '' })} codigo={dialogRegistro.codigo} listarRegistroGastoFecha={listarRegistroGastoFecha} setAlertGeneral={setAlertGeneral} />
                </DialogContent>
            </Dialog>
            <Dialog open={dialogManGasto} onClose={() => setDialogManGasto(false)} fullWidth={true} maxWidth="md">
                <DialogTitle>
                    GASTOS
                    <Button sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "#000",
                    }} startIcon={<Close />} onClick={() => setDialogManGasto(false)} ></Button>
                </DialogTitle>
                <DialogContent>
                    <DialogManGastos />
                </DialogContent>
            </Dialog>
            <Grid item md={11}>
                <Snackbar open={alertGeneral.show} autoHideDuration={6000} onClose={() => setAlertGeneral({ ...alertGeneral, show: false, severity: 'success', message: '' })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={() => setAlertGeneral({ ...alertGeneral, show: false, severity: 'success', message: '' })} severity={alertGeneral.severity} sx={{ width: '100%' }}>
                        {alertGeneral.message}
                    </Alert>
                </Snackbar>
                <Paper elevation={1} sx={{ p: 1 }}>
                    <Typography variant='h6'>REGISTROS DE GASTOS</Typography>
                    <Grid item xs={12}>
                        <Paper elevation={1} sx={{ p: 1 }}>
                            <Typography>ACCIONES</Typography>
                            <Grid container spacing={1}>
                                <Grid item md={1.5}>
                                    <TextField fullWidth label="DESDE" type="date" InputLabelProps={{ shrink: true }} margin="normal" value={inputFechas.fechaInicio} onChange={handleFechaInicio} />
                                </Grid>
                                <Grid item md={1.5}>
                                    <TextField fullWidth label="HASTA" type="date" InputLabelProps={{ shrink: true }} margin="normal" value={inputFechas.fechaFinal} onChange={handleFechaFinal} />
                                </Grid>
                                <Grid item md={1} sx={{ mt: 3 }}>
                                    <Button fullWidth variant='contained' color="primary" startIcon={<Search />} onClick={searchFechas}>BUSCAR</Button>
                                </Grid>
                                <Grid item md={1} sx={{ mt: 3 }}>
                                    {
                                        registros.length > 0 ?
                                            <PDFDownloadLink document={<ExportPdf datosEmpresa={datosEmpresa} fechas={inputFechas} registros={registros} />} fileName={"RPTE-RESUMEN-REGISTROS-GASTOS"}>
                                                <Button variant='contained' color="error" startIcon={<PictureAsPdf />} >PDF</Button>
                                            </PDFDownloadLink>
                                            :
                                            <Button startIcon={<PictureAsPdf />} variant='contained' color="error">PDF</Button>
                                    }
                                </Grid>
                                <Grid item md={1} sx={{ mt: 3 }}>
                                    {/* <Button variant='contained' color='success'>EXCEL</Button> */}
                                    <ExportExcel data={registros} />
                                </Grid>
                                <Grid item md={2.5}></Grid>
                                <Grid item md={1.5} sx={{ mt: 3 }}>
                                    <Button fullWidth variant='contained' color='success' startIcon={<Payments />} onClick={() => setDialogRegistro({ show: true, codigo: '', textDialog: 'NUEVO' })}>NUEVO REGISTRO</Button>
                                </Grid>
                                <Grid item md={2} sx={{ mt: 3 }}>
                                    <Button fullWidth variant='contained' color='primary' startIcon={<Handyman />} onClick={() => setDialogManGasto(true)}>MANTENIMIENTO GASTOS</Button>
                                </Grid>
                                <Grid item md={12}>
                                    <TableContainer>
                                        <Table aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }}>CODIGO</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>FECHA</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>REFERENCIA</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>TRABAJADOR</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>GASTO</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>CANTIDAD</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>PRECIO U.</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>TOTAL</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    (rowsPage > 0
                                                        ? registros.slice(page * rowsPage, page * rowsPage + rowsPage)
                                                        : registros
                                                    ).map((registro) => (
                                                        <TableRow
                                                            key={registro.codigo}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {registro.codigo}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {registro.fecha}
                                                            </TableCell>
                                                            <TableCell>{registro.referencia}</TableCell>
                                                            <TableCell>{registro.nombres_apellidos}</TableCell>
                                                            <TableCell>{registro.gasto}</TableCell>
                                                            <TableCell>{registro.cantidad}</TableCell>
                                                            <TableCell>{registro.precio_unitario}</TableCell>
                                                            <TableCell>{registro.total}</TableCell>
                                                            <TableCell>
                                                                <Tooltip title="EDITAR" onClick={() => setDialogRegistro({ show: true, textDialog: 'EDITAR', codigo: registro.codigo })}>
                                                                    <IconButton aria-label="delete" size="small">
                                                                        <Edit color="primary" fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="ELIMINAR" onClick={() => { setDialogRegistro({ show: false, textDialog: 'NUEVO', codigo: registro.codigo }); setDialogDelete(true); }}>
                                                                    <IconButton aria-label="delete" size="small">
                                                                        <Delete color="error" fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50]}
                                        component="div"
                                        labelRowsPerPage="Filas por columnas"
                                        count={registros.length}
                                        rowsPerPage={rowsPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowPage}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}
