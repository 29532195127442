import { Button } from '@mui/material'
import React from 'react'

export default function Tooth(props) {
    let toothProperty = props.toothProperty;
    let position = props.position;
    return (
        <>
            {
                (toothProperty.id !== 0 && toothProperty.id !== 1) &&
                <div onClick={props.clickTooth} id={toothProperty.id}
                    className="tooth"
                    style={{
                        background: toothProperty.background,
                        borderRadius: '50%', border: props.id !== 0 ? '2px solid #000' : 'none',
                        width: props.sizeTooth, height: props.sizeTooth, textAlign: 'center',
                        top: position.y, left: position.x, cursor: 'pointer',
                    }}
                >
                    {toothProperty.pontico !== 0 ? <span style={{ color: '#fff' }}>P</span> : ''}
                </div>
            }
            {
                toothProperty.id === 0 &&
                <div>
                    <div style={{ top: position.y, left: position.x - props.radio, position: 'absolute', width: props.radio + 20, height: '20px', borderBottom: '2px solid #000', }}>
                    </div>
                    <div style={{ top: position.y + 20, left: position.x - props.radio, position: 'absolute', width: props.radio, height: '20px', borderLeft: '2px solid #000', }}>
                    </div>
                </div>

            }
            {
                toothProperty.id === 1 &&
                <div>
                    <div style={{ top: position.y + 20, left: position.x + 5, position: 'absolute', width: props.radio, height: '20px', borderTop: '2px solid #000' }}>
                    </div>
                    <div style={{ top: position.y, left: position.x, position: 'absolute', width: props.radio, height: '20px', borderRight: '2px solid #000' }}>
                    </div>
                </div>
            }
        </>
    )
}
