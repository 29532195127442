import { Check, Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { Alert, Button, Collapse, Dialog, DialogActions, DialogTitle, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import UrlsConst from '../../../config/config';

function DialogPasswordUpdate(props) {
    //cambio de contraseña JFGASTI
    const [userUpdate, setUserUpdate] = React.useState({
        codigo: "",
        clave: "",
        claveConf: "",
        showPassword: false,
    })
    useEffect(() => {
        const userlog = JSON.parse(sessionStorage.getItem("usuario_log"));
        setUserUpdate({
            codigo: userlog.usuario,
            clave: "",
            claveConf: "",
            showPassword: false,
        });
    }, [])

    const [verifAlert, setVerifAlert] = React.useState({
        show: false,
        severity: "warning",
        message: "",
    })
    const [passwordUpdate, setPasswordUpdate] = React.useState({
        show: false,
        severity: "warning",
        message: "",
    })
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const handlechangeUser = (e) => {
        setUserUpdate({ ...userUpdate, [e.target.id]: e.target.value });

    }
    const saveChange = async () => {
        if (userUpdate.clave === userUpdate.claveConf) {
            const saveUsuario = await fetch(`${UrlsConst.URL_API}/sistema/usuario/actualizarUsuarioContrasena.php`, {
                method: 'POST',
                body: JSON.stringify({ codigo: userUpdate.codigo, clave: userUpdate.clave })
            });
            const response = await saveUsuario.json();
            if (response) {
                setPasswordUpdate({
                    show: true,
                    severity: "success",
                    message: "Contraseña actualizada Correctamente",
                })
            } else {
                setPasswordUpdate({
                    show: true,
                    severity: "error",
                    message: "No se pudo actualziar la contraseña, intente de nuevo.",
                })
            }
            setTimeout(() => {
                setPasswordUpdate({
                    show: false,
                    severity: "warning",
                    message: "",
                })
                props.parentClose();
            }, 2700);
        } else {
            setPasswordUpdate({
                show: true,
                severity: "error",
                message: "Las contraseñas deben ser iguales",
            })
            setTimeout(() => {
                setPasswordUpdate({
                    show: false,
                    severity: "error",
                    message: "",
                })
            }, 2700);
        }
    }

    return (
        <Grid container spacing={1}>
            <Dialog open={showConfirmation} onClose={() => { setShowConfirmation(false) }}>
                <DialogTitle style={{ textAlign: 'center' }}>¿Esta seguro de cambiar la contraseña?</DialogTitle>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<Check />} onClick={saveChange} variant="outlined" color="success">ACEPTAR</Button>
                    <Button startIcon={<Close />} style={{ marginLeft: '6px' }} variant="outlined" color="error" onClick={() => { setShowConfirmation(false) }}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Collapse in={passwordUpdate.show}>
                <Alert severity={passwordUpdate.severity}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setVerifAlert({ show: false, message: "", severity: "warning" });
                            }}
                        >
                            <Close fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {passwordUpdate.message}
                </Alert>
            </Collapse>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="clave"
                    sx={{ mt: 2 }}
                    type={userUpdate.showPassword ? 'text' : 'password'}
                    error={userUpdate.clave !== userUpdate.claveConf}
                    // value={values.password}
                    label="CONTRASEÑA"
                    variant='standard'
                    name="clave"
                    onChange={handlechangeUser}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => { setUserUpdate({ ...userUpdate, showPassword: !userUpdate.showPassword }) }}
                                >
                                    {userUpdate.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                // helperText={userUpdate.clave !== userUpdate.claveConf ? "Las contraseñas no coinciden" : ""}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="claveConf"
                    sx={{ mt: 2 }}
                    type={userUpdate.showPassword ? 'text' : 'password'}
                    error={userUpdate.clave !== userUpdate.claveConf}
                    name="claveConf"
                    onChange={handlechangeUser}
                    label="CONFIRMAR CONTRASEÑA"
                    variant='standard'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => { setUserUpdate({ ...userUpdate, showPassword: !userUpdate.showPassword }) }}
                                >
                                    {userUpdate.showPassword ? <VisibilityOff /> : <Visibility />}
                                    {/* <Visibility /> */}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    helperText={userUpdate.clave !== userUpdate.claveConf ? "Las contraseñas no coinciden" : ""}
                />
            </Grid>
            <Grid container item xs={12} spacing={1} sx={{ justifyContent: "center", mt: 3 }}>
                <Grid item>
                    <Button onClick={saveChange} variant="contained" color="success"> GUARDAR </Button>
                </Grid>
                <Grid item>
                    <Button onClick={() => { props.parentClose() }} variant="contained" color="error"> CANCELAR </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DialogPasswordUpdate