import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import React from 'react'
import Datatable from '../../components/datatable';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DialogPamarametro from './Dialogs/dialogPamarametro';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import UrlsConst from '../../config/config';
import CloseIcon from '@mui/icons-material/Close';

export default function Parametro() {
  // CONSTANTES
  const [openNewParametro, setOpenNewParametro] = React.useState(false);
  const [openEditParametro, setOpenEditParametro] = React.useState(false);
  const [openDeleteParametro, setOpenDeleteParametro] = React.useState(false);
  const [parametros, setParametros] = React.useState([]);
  const [idParametro, setIdParametro] = React.useState('');
  const [alert, setAlert] = React.useState(false);
  const [typeAlert, setTypeAlert] = React.useState('error');
  const [textAlert, setTextAlert] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [alertGeneral, setAlertGeneral] = React.useState({
    open: false,
    type: 'success',
    text: ''
  });
  const [search, setSearch] = React.useState({
    string_search: ''
  });
  React.useEffect(() => {
    recuperarParametros();
  }, []);
  // HANDLES
  const handleClickOpen = () => {
    setOpenNewParametro(true);
  }
  const handleClose = () => {
    setOpenNewParametro(false);
    setOpenEditParametro(false);
    setOpenDeleteParametro(false);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // FUNCIONES
  const recuperarParametros = async () => {
    const getParametros = await fetch(`${UrlsConst.URL_API}/sistema/parametro/listarParametro.php`, {
      method: 'GET'
    });
    const response = await getParametros.json();
    setParametros(response);
  }
  const deleteParametro = async () => {
    const eliminarParametro = await fetch(`${UrlsConst.URL_API}/sistema/parametro/eliminarParametro.php?codigo=` + idParametro, {
      method: 'POST'
    });
    const response = await eliminarParametro.json();
    recuperarParametros();
    if (response.executed !== false) {
      setOpenDeleteParametro(false);
      setTypeAlert('success');
      setTextAlert('¡COMPLETADO! Se elimino correctamente el parametro');
      setAlert(true);
      setTimeout(() => { setAlert(false) }, 2500);
    }
  }
  const cambiarBtn = (e) => {
    if (e.which === 13) {
      searchParametro();
    }
  }
  const searchParametro = async (e) => {
    if (search.string_search !== "") {
      const getParametros = await fetch(`${UrlsConst.URL_API}/sistema/parametro/listarParametro.php`, {
        method: 'GET'
      });
      const response = await getParametros.json();
      const filtrar = search.string_search;
      const buscado = response.filter((parametro) => parametro.codigo.includes(filtrar) || parametro.nombre.includes(filtrar) || parametro.descripcion.includes(filtrar));
      setParametros(buscado);
    }
  }

  return (
    <Grid container style={{ minHeight: '100vh', justifyContent: 'center', alignContent: 'center' }}>
      <Snackbar open={alertGeneral.open} autoHideDuration={6000} onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setAlertGeneral({ ...alertGeneral, open: false })} severity={alertGeneral.type} sx={{ width: '100%' }}>
          {alertGeneral.text}
        </Alert>
      </Snackbar>
      <Grid item md={11} xs={11} style={{ justifyContent: 'center' }}>
        <Paper elevation={1} style={{ padding: '1.5em' }}>
          <Grid container style={{ justifyContent: 'space-between', textAlign: 'center' }}>
            <Typography variant='h5'>PARAMETROS</Typography>
            <Button onClick={handleClickOpen} startIcon={<AddIcon />} variant="contained" color="success" >Nuevo</Button>
          </Grid>
          <Collapse in={alert}>
            <Alert variant="outlined" severity={typeAlert}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {textAlert}
            </Alert>
          </Collapse>
          <div style={{ justifyContent: 'center' }}>
            <Grid item md={6} xs={12}>
              <TextField
                name="string_search"
                fullWidth
                label="BUSCAR"
                margin='normal'
                variant="standard"
                value={search.string_search}
                onKeyUp={cambiarBtn}
                onChange={
                  (e) => {
                    const { name, value } = e.target;
                    if (value === "") {
                      setSearch({ ...search, [name]: '' });
                      recuperarParametros();
                    } else {
                      setSearch({ ...search, [name]: value.toUpperCase() });
                    }
                  }
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={searchParametro}>
                        <SearchIcon color="warning" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </div>
          <Grid item md={12} style={{ marginTop: '20px' }}>
            <Paper elevation={1}>
              <TableContainer sx={{ maxWidth: '275px', minWidth: '100%', height: 500 }}  >
                <Table aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>CODIGO</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>NOMBRE</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>DESCRIPCION</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>TIPO PARAMETRO</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>VALOR PARAMETRO</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>ACCIONES</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? parametros.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : parametros
                    ).map((parametro) => (
                      <TableRow
                        key={parametro.codigo}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {parametro.codigo}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {parametro.nombre}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {parametro.descripcion}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {
                            parametro.tipo === 'S' ? 'TEXTO' :
                              parametro.tipo === 'N' ? 'NUMERO' :
                                parametro.tipo === 'D' ? 'FECHA' :
                                  parametro.tipo === 'B' ? 'BOLEANO' :
                                    ''
                          }
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {
                            parametro.valor_string != null ? parametro.valor_string :
                              parametro.valor_number != null ? parametro.valor_number :
                                parametro.valor_date != null ? parametro.valor_date :
                                  parametro.valor_bool != null ? 'VERDADERO' :
                                    'FALSO'
                          }
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {
                            (<div>
                              <Tooltip title="EDITAR PARAMETRO">
                                <IconButton onClick={() => {
                                  setOpenEditParametro(true);
                                  setIdParametro(parametro.codigo);
                                }} aria-label="delete" size="small">
                                  <EditIcon color="primary" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="ELIMINAR PARAMETRO">
                                <IconButton onClick={() => {
                                  setOpenDeleteParametro(true);
                                  setIdParametro(parametro.codigo);
                                }} aria-label="delete" size="small">
                                  <DeleteIcon color="error" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </div>)
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={parametros.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Paper>
      </Grid>
      <Dialog open={openNewParametro} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle>NUEVO PARAMETRO</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogPamarametro
            fntParametros={recuperarParametros}
            parametros={parametros}
            fntClose={handleClose}
            alertGeneral={alertGeneral}
            setAlertGeneral={setAlertGeneral}
          />
          <Button startIcon={<CloseIcon />} style={{ marginLeft: '6px' }} variant='outlined' color="error" onClick={handleClose}>Cancelar</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openEditParametro} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle>EDITAR PARAMETRO</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogPamarametro
            fntIdParametro={idParametro}
            fntParametros={recuperarParametros}
            parametros={[]}
            fntClose={handleClose}
            alertGeneral={alertGeneral}
            setAlertGeneral={setAlertGeneral}
          />
          <Button startIcon={<CloseIcon />} style={{ marginLeft: '6px' }} variant='outlined' color="error" onClick={handleClose}>Cancelar</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openDeleteParametro} onClose={handleClose}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <div>
            <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
          </div>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant="h5">Eliminar Parametro</Typography>
          ¿Esta seguro de eliminar el parametro?
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="contained" color="error" onClick={deleteParametro}>Aceptar</Button>
          <Button variant="contained" onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
