
import React from 'react'
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { Box } from '@mui/system';
import { Avatar, Button, Collapse, Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar, Tooltip, tooltipClasses, Typography } from '@mui/material';
import { AccountBox, AddToQueue, Assessment, Assignment, AssignmentInd, AssignmentTurnedIn, AttachMoney, ChevronLeft, Close, ContentPasteGo, Description, Difference, Engineering, Error, ExpandLess, ExpandMore, Grading, Group, Healing, LogoutSharp, ManageAccountsSharp, ManageHistory, MedicalServices, Menu, NoteAdd, Person2, PersonSharp, Plagiarism, PointOfSale, RequestPage, Task, Payments } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import { grey, orange } from '@mui/material/colors';
import DialogPasswordUpdate from '../sistema/Dialogs/dialogPasswordUpdate';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { useLocation, Link } from 'react-router-dom';
const drawerWidth = 280;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // width: `calc(${theme.spacing(0)} + 1px)`,
    width: '0px',
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: 11.5,
        Transform: 'translate(0, 0)',
    },
}));

export default function NewNavbar(props) {

    var perfilDetalle = props.perfilDetalle;
    const userlog = JSON.parse(sessionStorage.getItem("usuario_log"));
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [userData, setUserData] = React.useState({
        userName: "",
        businessName: "",
    })
    const [menUsuario, setMenUsuario] = React.useState([]);
    const [showSubMenuORD, setShowSubMenuORD] = React.useState(false);
    const [showSubMenuPRU, setShowSubMenuPRU] = React.useState(false);
    const [showSubMenuMAN, setShowSubMenuMAN] = React.useState(false);
    const [showSubMenuSYS, setShowSubMenuSYS] = React.useState(false);
    const [showSubMenuCAJ, setShowSubMenuCAJ] = React.useState(false);
    const [showSubMenuREP, setShowSubMenuREP] = React.useState(false);
    const [showMessageConfirmacion, setShowMessageConfirmacion] = React.useState({ severity: 'warning', message: '', show: false });
    const [showConfirmacion, setShowConfirmacion] = React.useState(false);
    //PASSWORD
    const [showPasswordUpdate, setShowPasswordUpdate] = React.useState(false);
    let location = useLocation();
    // console.log({ perfilDetalle });

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);

        setShowSubMenuORD(false);
        setShowSubMenuPRU(false);
        setShowSubMenuMAN(false);
        setShowSubMenuSYS(false);
        setShowSubMenuCAJ(false);
        setShowSubMenuREP(false);
    };
    const cerrarSesion = () => {
        props.parentLogOut();
        // props.setOpen(false);
    }
    const iconList = (codigo) => {
        const iconArray = [
            // { codigo: "ORD", icono: <Assignment key="ORD" className='textDrawer' /> },
            { codigo: "ORD", icono: <Assignment key="ORD" className={validRuta("ORD") && !open ? "sidebar_active_icon" : "textDrawer"} /> },
            { codigo: "ORD_HIS", icono: <ManageHistory key="ORD_HIS" className={location.pathname === '/historial' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "ORD_ORD", icono: <Plagiarism key="ORD_ORD" className={location.pathname === '/ordenservicio' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "ORD_PRO", icono: <AssignmentInd key="ORD_PRO" className={location.pathname === '/ordenprofesional' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "ORD_NEW", icono: <NoteAdd key="ORD_KEY" className={location.pathname === '/nuevaorden' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "PRU", icono: <ContentPasteGo key="PRU" className={validRuta("PRU") && !open ? "sidebar_active_icon" : "textDrawer"} /> },
            { codigo: "PRU_ORD", icono: <AssignmentTurnedIn key="PRU_ORD" className={location.pathname === '/ordenpruebas' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "PRU_PRO", icono: <AssignmentInd key="PRU_PRO" className={location.pathname === '/pruebasprofesional' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "PRU_TRA", icono: <ManageAccountsSharp key="PRU_TRA" className={location.pathname === '/pruebastrabajador' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "SYS", icono: <AddToQueue key="SYS" className={validRuta("SYS") && !open ? "sidebar_active_icon" : "textDrawer"} /> },
            { codigo: "SYS_PAR", icono: <Assessment key="SYS_PAR" className={location.pathname === '/parametro' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "SYS_TRA", icono: <ManageAccountsSharp key="SYS_TRA" className={location.pathname === '/trabajador' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "SYS_PER", icono: <AccountBox key="SYS_PER" className={location.pathname === '/perfil' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "SYS_USU", icono: <PersonSharp key="SYS_USU" className={location.pathname === '/usuario' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "MAN", icono: <Engineering key="MAN" className={validRuta("MAN") && !open ? "sidebar_active_icon" : "textDrawer"} /> },
            { codigo: "MAN_PAC", icono: <Person2 key="MAN_PAC" className={location.pathname === '/paciente' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "MAN_PRO", icono: <AttachMoney key="MAN_PRO" className={location.pathname === '/profesional' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "MAN_SER", icono: <MedicalServices key="MAN_SER" className={location.pathname === '/servicio' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "CAJ", icono: <PointOfSale key="CAJ" className={validRuta("CAJ") && !open ? "sidebar_active_icon" : "textDrawer"} /> },
            { codigo: "CAJ_COB", icono: <AttachMoney key="CAJ_COB" className={location.pathname === '/cobranza' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "CAJ_CHIC", icono: <PriceChangeIcon key="CAJ_CHIC" className={location.pathname === '/cajachica' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "CAJ_GAS", icono: <Payments key="CAJ_GAS" className={location.pathname === '/gastos' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "REP", icono: <Description key="REP" className={validRuta("REP") && !open ? "sidebar_active_icon" : "textDrawer"} /> },
            { codigo: "REP_ORD", icono: <Task key="REP_ORD" className={location.pathname === '/rordenserv' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "REP_TRAB", icono: <Grading key="REP_TRAB" className={location.pathname === '/rtrabajo' ? "sidebar_active_barra2" : "textDrawer"} /> },
            { codigo: "REP_CAJA", icono: <RequestPage key="REP_CAJA" className={location.pathname === '/reportCaja' ? "sidebar_active_barra2" : "textDrawer"} /> },
        ];
        const filteredIcon = iconArray.filter(icon => icon.codigo === codigo);
        if (filteredIcon.length > 0) {
            return filteredIcon[0].icono;
        } else {
            return <Error className="textDrawer" />
        }
    }
    const handleClick = (valor) => {
        if (valor === 'ORD' || valor === 'PRU' || valor === 'MAN' || valor === 'SYS' || valor === 'CAJ' || valor === 'REP') {
            if (open) {

                setShowSubMenuORD(!showSubMenuORD ? valor === 'ORD' : false);
                setShowSubMenuPRU(!showSubMenuPRU ? valor === 'PRU' : false);
                setShowSubMenuMAN(!showSubMenuMAN ? valor === 'MAN' : false);
                setShowSubMenuSYS(!showSubMenuSYS ? valor === 'SYS' : false);
                setShowSubMenuCAJ(!showSubMenuCAJ ? valor === 'CAJ' : false);
                setShowSubMenuREP(!showSubMenuREP ? valor === 'REP' : false);
                // setShowPaswordChange(valor === 'CAJ');
            } else {
                setOpen(!open);
            }
        }
    }
    const hadleOpenSubMenu = (valor) => {
        if (valor === 'ORD') { return showSubMenuORD; }
        if (valor === 'PRU') { return showSubMenuPRU; }
        if (valor === 'MAN') { return showSubMenuMAN; }
        if (valor === 'SYS') { return showSubMenuSYS; }
        if (valor === 'CAJ') { return showSubMenuCAJ; }
        if (valor === 'REP') { return showSubMenuREP; }
        else { return false; }
    }
    const validRuta = (v) => {

        if (v === "ORD") {
            if (location.pathname === "/nuevaorden" || location.pathname === "/ordenservicio" || location.pathname === "/ordenprofesional" || location.pathname === "/historial") { return true }
        }
        if (v === "PRU") {
            if (location.pathname === "/ordenpruebas" || location.pathname === "/pruebastrabajador" || location.pathname === "/pruebasprofesional") { return true }
        }
        if (v === "MAN") {
            if (location.pathname === "/servicio" || location.pathname === "/profesional" || location.pathname === "/paciente") { return true }
        }
        if (v === "SYS") {
            if (location.pathname === "/parametro" || location.pathname === "/trabajador" || location.pathname === "/usuario" || location.pathname === "/perfil") { return true }
        }
        if (v === "CAJ") {
            if (location.pathname === "/cobranza" || location.pathname === "/cajachica" || location.pathname === "/gastos") { return true }
        }
        if (v === "REP") {
            if (location.pathname === "/rordenserv" || location.pathname === "/rtrabajo" || location.pathname === "/reportCaja") { return true }
        }
        else { return false }
        // return true
    }
    const validRuta2 = (codigoP, codigoH, href) => {
        // console.log({ codigoP, codigoH, href })
        // if (codigoP === "PRU") {
        //     if (location.pathname === href) { return true }
        // }
    }
    return (
        <Box>
            <Dialog open={showPasswordUpdate} onClose={() => setShowPasswordUpdate(false)} sx={{ zIndex: 1310 }}>
                <DialogTitle>
                    CAMBIAR CONTRASEÑA
                    <IconButton
                        aria-label="close"
                        onClick={() => setShowPasswordUpdate(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "#757575",
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogPasswordUpdate parentClose={() => { setShowPasswordUpdate(false); }} />
                </DialogContent>
            </Dialog>
            <Button style={{ marginTop: '6px', marginLeft: '0px', height: '40px', width: '30px', position: 'fixed', background: grey[800] }}
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}>
                <Menu style={{ color: orange[700] }} />
            </Button>
            <Drawer variant="permanent" open={open} sx={{ background: grey[800] }}>
                <DrawerHeader style={{ background: grey[800], marginLeft: '0px', justifyContent: open ? 'end' : 'start' }}>
                    {open ?
                        <Stack direction="row" spacing={2} sx={{ justifyContent: "space-between", width: "100%", paddingLeft: "10px" }}>
                            <BootstrapTooltip title="Cambiar Contraseña" placement="right">
                                <Avatar onClick={() => { setShowPasswordUpdate(true); handleDrawerClose(); }} sx={{ bgcolor: orange[700], color: grey[800] }}>{userlog ? userlog.usuario.charAt(0) : 0}</Avatar>
                                {/* <Avatar sx={{ bgcolor: "#8aa4af", color: "#222d32" }}>{userlog ? userlog.usuario.charAt(0) : 0}</Avatar> */}
                            </BootstrapTooltip>
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeft style={{ color: orange[700] }} />
                            </IconButton>
                        </Stack>
                        :
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}>
                            <Menu style={{ color: orange[700] }} />
                        </IconButton>
                    }
                </DrawerHeader>
                <List className="list1 navItem">
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <Link to="/" style={{ textDecoration: 'none', color: orange[700] }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <HomeIcon sx={{ color: orange[700] }} />
                                </ListItemIcon>
                                <ListItemText primary="HOME" sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                </List>
                <List className="list1 navItem">
                    {perfilDetalle.filter(f => f.fk_log_menu_padre === null).map((menuP) => (
                        perfilDetalle.filter(el => el.fk_log_menu_padre === menuP.codigo).length > 0 ?
                            // <ListItem key={menuP.codigo} disablePadding sx={{ display: 'block', color: "#8aa4af", ":hover": { backgroundColor: "#8aa4af" } }}>
                            <ListItem key={menuP.codigo} disablePadding sx={{ display: 'block', color: orange[700] }}>
                                {open ?
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                            // borderLeft: location.pathname == ? "yellow" : "6px solid"
                                        }}
                                        onClick={() => handleClick(menuP.codigo)}
                                        key={`${menuP.codigo}-IB`}
                                        className={validRuta(menuP.codigo) ? "sidebar_active_barra" : ""}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                            key={`${menuP.codigo}-Icon`}
                                        >
                                            {iconList(menuP.codigo)}
                                        </ListItemIcon>
                                        <ListItemText key={`${menuP.codigo}-txt`} primary={menuP.nombre} sx={{ opacity: open ? 1 : 0 }} className="textDrawer" />
                                        {open ?
                                            hadleOpenSubMenu(menuP.codigo) ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" /> :
                                            ""
                                        }
                                    </ListItemButton> :
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                        onClick={() => handleClick(menuP.codigo)}
                                        key={`${menuP.codigo}-IB`}
                                        // className={location.pathname === '/nuevaorden' ? "sidebar_active" : "textDrawer2"}
                                        className={validRuta(menuP.codigo) ? "sidebar_active" : ""}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                            key={`${menuP.codigo}-Icon`}
                                        >
                                            {iconList(menuP.codigo)}
                                        </ListItemIcon>
                                        <ListItemText key={`${menuP.codigo}-txt`} primary={menuP.nombre + "--"} sx={{ opacity: open ? 1 : 0 }} className="textDrawer" />
                                    </ListItemButton>
                                }
                                <Collapse in={hadleOpenSubMenu(menuP.codigo)} timeout="auto" unmountOnExit onClick={() => setOpen(false)} key={`${menuP.codigo}-collapse`}>
                                    <List >
                                        {perfilDetalle.filter(ele => ele.fk_log_menu_padre === menuP.codigo).map((hijo) => (
                                            <ListItem key={hijo.codigo} disablePadding sx={{ display: 'block', ml: 2 }}>
                                                <Link to={hijo.href} key={`${hijo.codigo}-link`} style={{ textDecoration: 'none', color: orange[700] }}>
                                                    <ListItemButton
                                                        sx={{
                                                            minHeight: 48,
                                                            justifyContent: open ? 'initial' : 'center',
                                                            px: 2.5,
                                                        }}
                                                        onClick={() => { handleDrawerClose(); validRuta2(menuP.codigo, hijo.codigo, hijo.href); }}
                                                        key={`${hijo.codigo}-IBSM`}
                                                    >
                                                        <ListItemIcon
                                                            sx={{
                                                                minWidth: 0,
                                                                mr: open ? 3 : 'auto',
                                                                justifyContent: 'center',
                                                            }}
                                                            key={`${hijo.codigo}-Icon`}
                                                        >
                                                            {iconList(hijo.codigo)}
                                                        </ListItemIcon>
                                                        <ListItemText key={`${hijo.codigo}-txt`} primary={hijo.nombre} />
                                                    </ListItemButton>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </ListItem>
                            :
                            <ListItem key={menuP.codigo} disablePadding sx={{ display: 'block' }}>
                                <Link to={menuP.href} sx={{ textDecoration: 'none', color: (theme) => theme.palette.grey[800] }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                        onClick={() => handleClick(menuP.codigo)}
                                        key={`${menuP.codigo}-IB`}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {iconList(menuP.codigo)}
                                        </ListItemIcon>
                                        <ListItemText primary={menuP.nombre} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                    ))}
                </List>
                <Grid item xs sx={{ background: grey[800] }} ></Grid>
                <List sx={{ mt: 'auto', background: grey[800], color: orange[700] }}>
                    <ListItem disablePadding sx={{ display: 'block', color: grey[800] }}>
                        <ListItemButton
                            className="textDrawer"
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            // onClick={() => setShowConfirmacion(true)}
                            onClick={cerrarSesion}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <LogoutSharp className="textDrawer" />
                            </ListItemIcon>
                            <ListItemText primary='Cerrar Sesión' sx={{ opacity: open ? 1 : 0 }} className="textDrawer" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </Box >

    )
}
